import {sr} from "../../utils";
import {put} from "redux-saga/effects";
import {sha256} from "js-sha256"
import {
  tokenCreationRejected,
  tokenCreationSuccessful
} from "../../reducers/toolkit/profileReducer"


export function* createTokenByPassword(action) {

  localStorage.removeItem('token');
  localStorage.removeItem('session');
  localStorage.setItem('login', action.payload.username);
  localStorage.setItem('passwordHash', sha256(action.payload.password));

  yield (() => sr('ClientAuthService', 'CreateTokenByPassword', { email: action.payload.username })
    .then(async (res) => {
      await res.data.token && window.localStorage.setItem('token', res.data.token);
      await res.data.session && window.localStorage.setItem('session', res.data.session);

      if (!res.data.token && /\@|.|/g.test(action.payload.username)) {
           put(tokenCreationRejected('Неверный логин или пароль!'));
           alert('Неверный логин или пароль!')
        // // window.location.reload()
        // throw new Error('wrong pass')
      }
    }))()

  try {
    yield put(tokenCreationSuccessful());
  } catch (e) {
      yield put(tokenCreationRejected(e.message));
  }
}
