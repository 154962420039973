import React,{useState,useEffect,useRef} from 'react'
import { useSelector } from 'react-redux'

import { HeaderReports } from './HeaderReports'
import {InfoList} from '../NewReports/Financial/InfoList'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { NavReports } from './NavReports'
import { sr } from '../../utils'
import moment from "moment";
import { deliveryCycleInfoMessage } from '../../actions/ModalActions'

export const Content = () => {
  const [startDate,setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [actDate,setActDate] = useState('')
  const [cities,setCities] = useState('')
  const [info,setInfo] = useState([])
  const [selectInfo,setSelectInfo] = useState([])
  const [selectedCity,setSelectedCity] = useState()
  const [paramsInfo,setParamsInfo] = useState({})
  const [resState,setRes] = useState('stop')
  const [selectedBank,setSelectedBank] = useState('')
  const [statusFields,setStatusField] = useState('stop')
  const testRefs = useRef(null)
  const permissions = useSelector(store => store.appMain.permissions);
  const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
  const dispatch = useDispatch()
  const checkRole = (param) => {
    if (permissions.staff) {
        if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
            return true
        } else {
            return permissions.permissions.includes(param)
        }
    } else {
        return false
    }
}
  useEffect(()=>{
    handleCity()
  },[])
  

  const handleInfo = async()=>{
    // console.log(selectedCity)
    if(startDate && endDate && selectedCity){
      setRes('start')
    await sr('Reports', 'OffsettingPeriod', {
      [selectedCity.length > 1 ? null : 'cityId']:selectedCity.length > 1 ? null : selectedCity[0],
      dateFrom:moment(startDate).format('YYYY-MM-DD'),
      dateTo:moment(endDate).format('YYYY-MM-DD'),
    }).then(result => {
      setRes('resolved')
      setInfo(result.data.accounts)
      // console.log(result);
  })
}else{
  setRes('start')
  if(selectedCity){
  await sr('Reports', 'OffsettingPeriod', {
    [selectedCity.length > 1 ? null : 'cityId']:selectedCity.length > 1 ? null : selectedCity[0],
    // dateFrom:moment(startDate).format('YYYY-MM-DD'),
    // dateTo:moment(endDate).format('YYYY-MM-DD'),
  }).then(result => {
    setRes('getNodata')
    setInfo(result.data.accounts)
    // console.log(result);
})
  }
}
  }
 const handleCity= async()=>{
    await sr('SettingsService', 'GetOperatorPermissions', {
    }).then(result => {
      setCities(result.data.cities)
  })
  }
  
  return (
    <WrapperContent ref={testRefs} >
      
     <HeaderReports {...{startDate,setStartDate,endDate,setEndDate,cities,setCities,selectedCity,setSelectedCity,handleInfo,setSelectedBank,selectedBank,selectInfo,statusFields,setStatusField,info,resState,actDate,setActDate}}/>
     {info?.length > 0 
      ?
       <>
     <InfoList {...{selectInfo,setSelectInfo,info,paramsInfo,startDate,endDate,resState,setSelectedBank,selectedBank,setRes,statusFields,setStatusField,testRefs,actDate,setActDate,selectedCity}}/>
     <div style={{marginTop:44,justifySelf:'start',marginBottom:44}}>
     {/* <NavReports/> */}
     </div>
     </>
     :
     ''
      }
    </WrapperContent>
  )
}

const WrapperContent = styled.div`
display: grid;
grid-template-rows: .3fr 2fr;
/* border: 1px solid black; */
height: auto;
width: auto;
min-width:calc(100% - 40px);
`