import {takeEvery} from "redux-saga/effects";
import {getCategory, getCategoryStarted, getKitchens,
       getKithensStarted, getMenuAssort, getMenuStartedAssort, 
       GET_CATEGORY_STARTED, GET_KITCHENS_STARTED,
       GET_MENU_STARTED_ASSORT,GET_MODERATION_PRODUCT_STARTED,
       SAVE_NEW_PRODUCT_STARTED,GET_TEG_LIST_STARTED,
       SAVE_PRODUCT_CHANGES_STARTED} from "../../actions/AssortmentAction";
import {getKitchensWorkerForAssort,getCategorysWorkerForAssort,getProductsWorkerForAssort,
       submitForModeration, getModerationProductWorker, getTagListWorker, updateModerationProductWorker} from './assortmentWorker'

function* assortementWatcher(){
    yield takeEvery(GET_KITCHENS_STARTED, getKitchensWorkerForAssort)
    yield takeEvery(GET_CATEGORY_STARTED, getCategorysWorkerForAssort)
    yield takeEvery(GET_MENU_STARTED_ASSORT, getProductsWorkerForAssort)
    yield takeEvery(SAVE_NEW_PRODUCT_STARTED,submitForModeration)
    yield takeEvery(GET_MODERATION_PRODUCT_STARTED,getModerationProductWorker)
    yield takeEvery(SAVE_PRODUCT_CHANGES_STARTED,updateModerationProductWorker)
    // yield takeEvery(GET_TEG_LIST_STARTED,getTagListWorker)
}
export default assortementWatcher