import {sr,srForOld} from "../../utils";
import {put, call} from "redux-saga/effects"
import { getCategory, getCategoryError,
       getKitchens, getKithensError, 
       getMenuAssort, getMenuErrorAssort,
       getModerationProduct,
       getModerationProductError,
       saveNewProduct, 
       saveNewProductError,
       getTegList,
       getTegListError,
       saveProductChanges,
       saveProductChangesError,
    } from "../../actions/AssortmentAction";
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";

export function* getKitchensWorkerForAssort(action){
try {
    const kithens = yield sr('AppSOKitchenService', 'GetRestaurantsForOperator', {})
    yield put(getKitchens(kithens.data.items))
}catch (e) {
    yield put(getKithensError(e))
}
}

export function* getCategorysWorkerForAssort(action){
    // console.log(action.payload,'PAYS IN CAT')
try {
    const categorys = yield sr('MenuSOKitchenService','getCategorys',{id:action.payload})
    // console.log(categorys.data.result,"RESULT")
    yield put(getCategory(categorys.data.result))
}catch (e) {        
    yield put(getCategoryError(e))
    }
}

export function* getProductsWorkerForAssort(action){
try {
    const products = yield sr('MenuSOKitchenService','getProducts',action.payload)
    // console.log(products.data.result.dishes)
    if(products.data.result.dishes){
    yield put(getMenuAssort(products.data.result.dishes))
    yield put(getTegList(products.data.result.tagsList))
    // yield put(getMenu(products.data.result.dishes.historyModeration))
    }
}catch (e) {
    yield put(getMenuErrorAssort(e))  
    yield put(deliveryCycleInfoMessage('no_result'))
    }
}
export function* submitForModeration(action){
    // console.log(action.payload,'HELLO')
    try{
    const result = yield sr('MenuSOKitchenService','submitForModeration',action.payload)
    // console.log(result.data)
    yield put(saveNewProduct(result.data))
    }
    catch(e){
    yield put(saveNewProductError(e))
    }
}
// submitForModeration
export function* getModerationProductWorker(action){
    try{
    const result = yield sr('MenuSOKitchenService','getModerationProducts',action.payload)
    // console.log(result.data)
    yield put(getModerationProduct(result.data.result))
    }
    catch(e){
    yield put(getModerationProductError(e))
    // console.log("HELLo")
    }
}
export function* updateModerationProductWorker(action){
    // console.log(action.payload.newInstance,'PAYLOADSSS')
    try {
        const res = yield sr('MenuSOKitchenService','submitForModeration',action.payload.newInstance)
        yield put(saveProductChanges(res.data))
    } catch (e) {
        yield put(saveProductChangesError(e))
    }
}

// export function* getTagListWorker(action){
//     try{
//         const res = yield sr('MenuSOKitchenService','getListTegs',{})
//         yield put(getTegList(res.data.result))
//     }
//     catch(e){
//         yield put(getTegListError(e))
//     }
// }