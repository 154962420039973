import { connect } from "react-redux";
import { permissionsClear } from "./reducers/toolkit/mainAppReducer";
import { deleteToken } from "./reducers/toolkit/profileReducer";
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {

    localStorage.removeItem("token");
    localStorage.removeItem("kitchens")
    localStorage.removeItem("session");
    this.props.dispatch(permissionsClear())
    this.props.dispatch(deleteToken())
    // logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <></>
      }
  
      return this.props.children; 
    }
  }

const mapDispatchToProps = dispatch => ({
    permissionsClear: () => dispatch(permissionsClear()),
    deleteToken: () => dispatch(deleteToken()),
});


export default connect(mapDispatchToProps)(ErrorBoundary)
  