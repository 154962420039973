import React, {useEffect, useState} from 'react';
import {Form, Icon, Input, Button} from 'antd';
import {Modal} from 'semantic-ui-react';
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router-dom"
import { deliveryCycleInfoMessage } from '../actions/ModalActions';


const NormalLoginForm = (props) => {
  const [params, setParams] = useState({})
  const [redirectTrigger, setRedirectTrigger] = useState(false)
  const recoveryPasswordStatus = useSelector(store => store.profile.recoveryPasswordStatus)
  const dispatch = useDispatch()
  // const recoveryPasswordStatusSwitch = useSelector(store => store.profile.recoveryPasswordStatusSwitch)

  useEffect(() => {
    if(recoveryPasswordStatus === 'OldMail'){
      dispatch(deliveryCycleInfoMessage('modeCustomErr','Письмо устарело'))
    }
  }, [recoveryPasswordStatus])


  useEffect(() => {
    if (recoveryPasswordStatus === 'resolve') {
      setTimeout(() => {
        setRedirectTrigger(true)
        window.location.reload()
      }, 2000)
    }
  }, [recoveryPasswordStatus])

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.activatePassword({...values, ...params})
      }
    });
  };
  useEffect(() => {
    let obj = {}
    let paramsArr = window.location.href.split("sys/recoverypass&")[1].split("&")
    paramsArr.forEach(param => {
      if (param.includes('=')) {
        let splitedParam = param.split("=")
        obj[splitedParam[0]] = splitedParam[1]
      }
    })
    setParams(obj)
  }, [])

  const {getFieldDecorator} = props.form;

  return (
    <Modal open={props.open} size='small' onClose={props.onClose}>
      <Form onSubmit={handleSubmit} className="login-form" style={{padding: '22px'}}>
        {
          recoveryPasswordStatus !== 'resolve'
            ? (
              <>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Пожалуйста, введите новый пароль!'}],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                  <div style={{fontSize:17,fontWeight:600}}>Пожалуйста, введите новый пароль.</div>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Активация
                  </Button>
                </Form.Item>
              </>
            )
            : (
              <p style={{fontSize:17,fontWeight:600}}>
                Установлен новый пароль. Вы можете авторизоваться.
              </p>
            )
        }
        {
          redirectTrigger && (
            <>
                <Redirect exact to="/dashboard" />
            </>
          )
        }
      </Form>
    </Modal>
  );
}

const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(NormalLoginForm);
export default WrappedNormalLoginForm;
