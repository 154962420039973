import React from "react";
import styled from "styled-components";

const MultiSwitch = props => {
    const {title, children} = props;

    return (
        <Wrapper>
            {title ? <Title>{title}</Title> : null}
            <Container>
                {children}
            </Container>
        </Wrapper>
    )
};

const Wrapper = styled.div`
    width: 100%;
    
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
`;

const Title = styled.p`
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 21px;
    color: #222222;
`;

export default MultiSwitch;
