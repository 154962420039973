import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker"
import ru from 'date-fns/locale/ru';
import * as moment from "moment"
import styled from "styled-components";


const RangePicker = ({style, callback}) => {

  const [timeFrom, setTimeFrom] = useState('')
  const [timeTo, setTimeTo] = useState('')

  useEffect(() => {
    let from;
    let to;
    if(timeFrom){
      from = moment(timeFrom).format('YYYY-MM-DD')
    }
    if(timeTo) {
      to = moment(timeTo).format('YYYY-MM-DD')
    }

    callback({from: from || "", to: to || ""})
  }, [timeFrom, timeTo])

  return (
    <RangePickerWrapper style={style}>
      <p>Период c:</p>
      <DatePicker
        className="reports_date"
        selected={timeFrom}
        startDate={timeFrom}
        endDate={timeTo}
        selectsStart
        onChange={date => setTimeFrom(date)}
        dateFormat="d MMMM yyyy"
        locale={ru}
      />
      <p>По:</p>
      <DatePicker
        className="reports_date"
        selected={timeTo}
        startDate={timeFrom}
        endDate={timeTo}
        minData={timeFrom}
        selectsEnd
        onChange={date => setTimeTo(date)}
        dateFormat="d MMMM yyyy"
        locale={ru}
      />
    </RangePickerWrapper>
  )
}

export default RangePicker;

const RangePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .reports_date {
    width: 200px;
    height: 36px;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
  }
`
