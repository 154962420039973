import { Input, TimePicker } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CheckBox from '../../../uikit/CheckBox'
const weeks = [
    {id:1,name:'Понедельник'},
    {id:2,name:'Вторник'},
    {id:3,name:'Среда'},
    {id:4,name:'Четверг'},
    {id:5,name:'Пятница'},
    {id:6,name:'Суббота'},
    {id:7,name:'Воскресенье'},
]

const MainInfo = () => {
  return (
    <>
    <ContentModal>
    <Cols>
    <p>Название точки</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Адрес</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Сайт</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Email</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Телефон</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Вконтакте</p>
    <Input/>
    </Cols>
    <Cols>
    <p>FB</p>
    <Input/>
    </Cols>
    <Cols>
    <p>Инстаграм</p>
    <Input/>
    </Cols>
</ContentModal> 
    
    <Wrapper>
        <ItemsBlockCourier>
                <HeaderItems>
                    Режим работы
                </HeaderItems>
                <div style={{width:'auto',backgroundColor:' #FFFFFF',borderRadius:8}}>
                    <HeaderCourier>
                <ItemsContent style={{textAlign:'start'}}>
                    Рабочий
                </ItemsContent>
                <ItemsContent >
                    День недели
                </ItemsContent>
                <ItemsContent >
                    От
                </ItemsContent>
                <ItemsContent >
                    До
                </ItemsContent>
            </HeaderCourier>
                </div>
            </ItemsBlockCourier>
        <Content>
            {weeks.map(week=>
            <>
            <ItemsContent>
                <CheckBox/>
            </ItemsContent>
            <ItemsContent>
              {week.name}
            </ItemsContent>
            <ItemsContent>
            <TimePicker
                format='HH:mm'
            />
            </ItemsContent>

            <ItemsContent>
                <TimePicker
                format='HH:mm'
                />
            </ItemsContent>
            </>
            )
            }
        </Content>
    </Wrapper>
    
    </>
    

  )
}

export default MainInfo

const Wrapper= styled.div`
 border-bottom: 1px solid rgb(169,169,169,0.5);

`

const ContentModal =styled.div`
color:#161616;
font-size: 15px;
font-weight: 500;
font-family: 'Roboto';
font-style: normal;
`

const Cols = styled.div`
display:flex;
flex-direction: row;
justify-content: space-around;
text-align: start;
padding-top: 15px;
border-bottom: 1px solid rgb(169,169,169,0.5);

    p{
        width: 30%;
    }
    input{
        width:60%;
    }
`
const ItemsBlockCourier = styled.div`
display: grid;
grid-template-rows  : auto 1fr;

`
const HeaderItems = styled.div`
padding-left: 10px;
padding-top: 10px;
font-weight: 600;
font-size: 19px; 
width: 100%;
text-align: start;
padding-right: 22px;
font-family: 'Roboto';
font-style: normal;
`
const HeaderCourier = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);
border-bottom: 1px solid rgb(169,169,169,0.5);
`
const Content = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr); 
`
const ItemsContent = styled.div`
padding: 10px;
color:#999999;
font-weight: 600;
font-size: 15px; 
font-family: 'Roboto';
font-style: normal;

`