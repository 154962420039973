import { Popover } from 'antd';
import React,{memo, useCallback, useEffect,useMemo,useState} from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { DEFAULT_INPUT_STYLE_FOR_AREA } from '../commonStyles';

export const InputInfo = memo((props) => {

  const [error,setError]=useState(false)
  const [errorCause,setErrorCause]=useState([])
  const [enter,setEnter]=useState(false)

  const validatorHandler=()=>{
    const error= /@|!|#|\$|%|\^|&|\*|=|;|:/g
    if(error.test(props.value)){
      const symb = props.value.match(error)
      // console.log(symb,'SYMB')
      setError(true)
      if(/\s{2}/g.test(props.value)){
         setErrorCause([`Некоректный символ ${symb.map(el=>el)}, и лишний пробел`])
      }
     else{
       setErrorCause([`Некоректный символ ${symb.map(el=>el)}`])
     }
    }
   else if(/\s{2}/g.test(props.value)){
      setError(true)
      setErrorCause(['Лишний пробел'])
    }else{
      setError(false)

    }
  }
  const UIerror=()=>{
    if(error){
      return (<PopverContent className='content' 
       >
         {errorCause.map(el=>{
            return  el
         })}
            </PopverContent>)
    }
  }
  const memz = useCallback(()=>{
    return validatorHandler()
  },[props.value,enter])


  useEffect(() => {
    memz()
  }, [props.value,enter])
  useEffect(()=>{
    // console.log(error,errorCause,'error,errorCause')
  },[error,errorCause])
  return (
    <div
    onMouseEnter={()=>setEnter(true)}
    onMouseLeave={()=>setEnter(false)}
    style={{...props.styleContainer}}
    >
    {
      props.textArea 
    ?
    <TextAreas>
    <TextareaAutosize
    disabled = {props.disabled}
    placeholder={props.placeholder}
    value = {props.value}
    onChange = {props.onChange}
    style={{...props.style,border:[error ?'1px solid red':'none'],position:'relative'}}
    />
    </TextAreas>
    :
    <input
    disabled = {props.disabled}
    placeholder={props.placeholder}
    value = {props.value}
    onChange = {props.onChange}
    autoFocus = {props.focus}
    style={{...props.style,border:[error ?'1px solid red':'none'],position:'relative',borderRadius:6}}

    />
    }
    {
      enter 
      ?
      UIerror()
      :
      ''
    }
    </div>
  )
})
const TextAreas= styled.div`
display: flex;
 justify-content: flex-start;
  text-align: start;
  width: 100%;
  textarea {
    ${DEFAULT_INPUT_STYLE_FOR_AREA};
    margin-top: 10px;
    min-height: 59px;
    resize: none;
    border: 1px solid transparent;
  }
`
const PopverContent = styled.div`
  width:auto;
  height:auto;
  background: #FFFFFF;
border: 1px solid #EAEAEA;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  position:absolute;
  padding:10px;
  color:#161616;
  z-index:9999;
`