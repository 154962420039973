import React,{useRef,useEffect} from 'react'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart,Line} from 'react-chartjs-2'
import moment from 'moment'; 

const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
  "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь",
];

ChartJS.register(...registerables);

const GraphsOrders = ({yearsStatState,threeTeenStat,forYears,forThreeTeen,yearCalc,all}) => {
// console.log(yearsStatState,threeTeenStat,'[[[')
const refCanv = useRef(null)
const getNameHandler=(m)=>{
  const name = monthNames?.find((el,i)=>  i+1 === +m)
  // console.log(m,name,'P{P{P{')
  return name
}

// const yearCalc = () =>{
//   const nowM = +moment().format('MM')
//   const prevY = moment().subtract(1,'year').format('YYYY')
//   const monthArr = []
//   let count = 0
//   let years = prevY
//   for(let a = nowM ; monthArr.length <= 11; a++){
//       if(a < 13){
//           monthArr.push({month:String(a),years:String(years)})
//       }else{
//           a = 0
//           years = +prevY + 1
//       }
//   }   
//   // console.log(nowM,'ARRARARA')
//   return monthArr
// }
  const nameWeek =  threeTeenStat?.map((el,i)=>{
    return el.date
  }) 
  console.log(nameWeek,'DTADTATD')
  const sum = threeTeenStat?.map((el,i)=>{
    return el.sum
  }) 
// console.log(sum,yearsStatState?.map(el=>el?.sum),'JUI');

  const nameMonth = forYears && yearCalc()?.map((el,i)=>getNameHandler(el?.month) ? getNameHandler(el?.month) :null)
  // console.log(data?.data,'NAMESS')
var ctx = document.getElementById("temp-chart")?.getContext("2d");
// console.log(ctx,'CTX')
var gradient = ctx?.createLinearGradient(0, 0, 0, 100);
var gradient2 = ctx?.createLinearGradient(300,300,300,600)
useEffect(() => {
  if(gradient){
  gradient.addColorStop(1, '#79CAD6 ');   
  gradient.addColorStop(1, '#5596E1');
  gradient.addColorStop(1, '#488DDF');
  gradient.addColorStop(0.2, '#9C3BE8');
  }
  if(gradient2){
    gradient.addColorStop(1, '#79CAD6 '); 
    gradient.addColorStop(1, '#5596E1');
    gradient.addColorStop(1, '#488DDF');
    gradient.addColorStop(0, '#9C3BE8');

  }
}, [yearsStatState,threeTeenStat])


    const lineChartData = {
        labels: nameMonth ,
        datasets: [
          {
            data: yearsStatState?.map(el=>el?.allSum)  ,
            borderColor:gradient,
            fill: true,
            lineTension: 0.3,
            // strokeColor : "#ff6c23",
            //     pointColor : "#fff",
            //     pointStrokeColor : "#ff6c23",
            //     pointHighlightFill: "#fff",
            //     pointHighlightStroke: "#ff6c23",
          },
        ]
      };
      const lineChartData2 = {
        labels:nameWeek,
        datasets: [
          {
            data:sum,
            borderColor:gradient,
            fill: true,
            lineTension: 0.3,
            fillColor:gradient2,

          },
        ]
      };
    // console.log(+moment().format('MM'),'KAKA')
      return (
        <Line
          type="line"
          width={250}
          height={200}
          id='temp-chart'
          options={{
            plugins: {
              legend: {
                display:false,
              },
              title:{
                align:'end'
              },
              
              subtitle: {
                display: true,
                text: 'Выручка',
                align:'start',
                padding:{
                  bottom:21

                }
            }
            },
            elements:{
              point:{
                backgroundColor:'#F4F5F9',
                pointRadius: 3.5,
                pointHoverRadius: 3.5
              }
            },
            scales:{
              yAxes:{
                title:{
                  align:'end',
                  color: 'red',
                 
                },
                ticks:{
                  // callback:(value)=>{
                  //    console.log(value,'valsss')
                  // }
                  // stepSize:0.2
                },
                
                suggestedMin: 0,
                suggestedMax:all ? 5000 : 10000,
                // alignToPixels:100,
                // autoSkip:true
              },
              xAxis:{
                ticks:{
                maxRotation: forThreeTeen ? 0 :90,
                minRotation: forThreeTeen ? 0 :90
                }
            } 
            }
          }}
          data={forThreeTeen ? lineChartData2 :  lineChartData}
        />
      )
}

export default GraphsOrders