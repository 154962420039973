import { DatePicker, Input, TimePicker } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CheckBox from '../../../uikit/CheckBox'
import { useSelector } from 'react-redux'
const Cutoff = ({per}) => {
    // console.log(per,'PER')
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

  return (
    
    <Wrapper>
    {/* FOR RC */}
     {SelectedKitchens.type === 'cantina'

        ?
   (<>
   <div style={{textAlign:'start'}}>{per.timeFrom.slice(0,-3)}-{per.timeTo.slice(0,-3)}</div>
        <Content>
            <ItemsContent>
                РЦ-{per.id}
            </ItemsContent>
            <ItemsContent>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div><CheckBox/></div>
                <div style={{paddingLeft:10}}>Будни</div>
                </div>

                <div style={{paddingLeft:30,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div><CheckBox/></div>
                <div style={{paddingLeft:10}}>Выходные</div>
                </div>
            </ItemsContent>
            <ItemsContent>
                <div style={{paddingRight:10}}>Время</div><Input type='number'/>
            </ItemsContent>
        </Content>
        </>
        )
            :
        (<ContentBRC>
            <ItemsContent>
                <div>
                {per.timeFrom.slice(0,-3)}-{per.timeTo.slice(0,-3)}
                </div>
                <div style={{paddingLeft:15}}>
                    <CheckBox/>
                </div>
            </ItemsContent>
            <ItemsContent>
                <div style={{paddingRight:10}}>Время</div><Input type='number'/>
            </ItemsContent>
        </ContentBRC>)
        }
    </Wrapper>
  )
}

export default Cutoff

const Wrapper= styled.div`
margin-top: 15px;
`
const ContentBRC = styled.div`
display:flex;
flex-direction: row;
justify-items: start;
margin-top: 10px;
background-color: #FFFFFF;
border-radius: 8px;
`
const Content = styled.div`
display: grid;
grid-template-columns: .7fr 1fr 1fr ; 
justify-items: start;
margin-top: 10px;
background-color: #FFFFFF;
border-radius: 8px;
`
const ItemsContent = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 10px;
`