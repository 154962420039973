import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
//img
import plug_img1 from '../../images/plug_img1.svg'
import plug_img2 from '../../images/plug_img2.svg'

const Plug = ({type}) => {

const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

    switch (type) {
        case "SELECT_PERIOD":
            return (
                <PlugWrapper>
                    {SelectedKitchens.name === 'Не выбрано'
                     ?
                     <>
                     <p style={{fontSize:40,fontWeight:700,color:'#161616'}}>Выберите кухню</p>
                     <img src={plug_img1} alt="sun"/>
                     </>
                     :
                     <>
                    <img src={plug_img1} alt="sun"/>
                    <p>Выберите время доставки и заполните меню</p>
                     </>
                    }



                    
                    
                </PlugWrapper>
            )
        case "EDIT_MODE":
            return (
                <PlugWrapper noSetFoodList>
                    <img src={plug_img2} alt="not a sun"/>
                    <p>
                        Для выбора товаров нажмите «Редактировать»
                        или скопируйте  меню с другого дня
                    </p>
                </PlugWrapper>
            )
        default:
            return null
    }
}

const PlugWrapper = styled.div`
  margin: ${({noSetFoodList}) => noSetFoodList && "0 auto"};
  width: ${({noSetFoodList}) => noSetFoodList ? "400px" : '100%'};
  ${({noSetFoodList}) => !noSetFoodList && {
    marginTop: '100px',
    height: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
  }};
  
  p {
  margin-top: 10px;
  }
`

export default Plug;
