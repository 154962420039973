import {sr} from "../utils";
import * as moment from "moment";

export const GET_RESTS_STARTED = 'GET_RESTS_STARTED';
export const GET_RESTS_SUCCESS = 'GET_RESTS_SUCCESS';
export const GET_RESTS_ERROR = 'GET_RESTS_ERROR';
export const SET_ACTIVE_REST = 'SET_ACTIVE_REST';

export const GET_DAYS_STARTED = 'GET_DAYS_STARTED';
export const GET_DAYS_SUCCESS = 'GET_DAYS_SUCCESS';
export const GET_DAYS_ERROR = 'GET_DAYS_ERROR';

export const GET_MENU_STARTED = 'GET_MENU_STARTED';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';

export const SEND_SUSPEND_START = 'SEND_SUSPEND_START';
export const SEND_SUSPEND_SUCCESS = 'SEND_SUSPEND_SUCCESS';
export const SEND_SUSPEND_ERROR = 'SEND_SUSPEND_ERROR';

export const PREPARE_MENU_TO_SEND = 'PREPARE_MENU_TO_SEND';
export const SEND_MENU_START = 'SEND_MENU_START';
export const SEND_MENU_SUCCESS = 'SEND_MENU_SUCCESS';
export const SEND_MENU_ERROR = 'SEND_MENU_ERROR';
export const PUBLISHED_SUCCESS = 'PUBLISHED_SUCCESS';
export const PUBLISHED_START = 'PUBLISHED_START';

export const SLIDER_SHIFT_FORWARD = 'SHIFT_FORWARD';
export const SLIDER_SHIFT_BACK = 'SHIFT_BACK';
export const SET_ACTIVE_PERIOD = 'SET_ACTIVE_PERIOD';
export const RESET_ACTIVE_PERIOD = 'RESET_ACTIVE_PERIOD';
export const TOGGLE_ACTIVE_DISH = 'TOGGLE_ACTIVE_DISH';
export const TOGGLE_ACTIVE_CATEGORY = 'TOGGLE_ACTIVE_CATEGORY';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const REVERT_EDIT_MODE = 'REVERT_EDIT_MODE';
export const APPLY_FOOD_LIST_CHANGES = 'APPLY_FOOD_LIST_CHANGES';
export const SEND_NEW_FOOD_LIST = 'SEND_NEW_FOOD_LIST';
export const CHANGE_DISH_PRICE = 'CHANGE_DISH_PRICE';
export const CHANGE_DISH_AMOUNT = 'CHANGE_DISH_AMOUNT';
export const SHOW_BLOCK_POPUP = 'SHOW_BLOCK_POPUP';
export const HIDE_BLOCK_POPUP = 'HIDE_BLOCK_POPUP';
export const CLEAR = 'CLEAR';
//copy periods
export const COPY_MENU_DATA_SET_DATE = 'COPY_MENU_DATA_SET';
export const COPY_MENU_DATA_SET_PERIOD = 'COPY_MENU_DATA_SET_PERIOD';
export const SEND_COPY_DATE_START = 'SEND_COPY_DATE_START';
export const SEND_COPY_DATE_SUCCESS = 'SEND_COPY_DATE_SUCCESS';
export const SEND_COPY_DATE_ERROR = 'SEND_COPY_DATE_ERROR';
// export const BLOCK_PERIOD = 'BLOCK_PERIOD';

export const clearStore = () => ({
    type: CLEAR,
});

export const getRests = () => {
    return dispatch => {
        dispatch(getRestsStarted());

        sr('AppSOKitchenService', 'GetRestaurantsForOperator', {})
            .then(res => {dispatch(getRestsSuccess(res.data),console.log(res.data)) })
            .catch(err => { dispatch(getRestsError(err.message)) })
    }
}

export const getDays = (dateFrom, dateTo, activeRest, subQuery) => {
    return dispatch => {
        dispatch(getDaysStarted());

        sr('AppSOKitchenService', 'GetMenuInfoPeriod', {
            restaurantId: activeRest,
            dateFrom: dateFrom,
            dateTo: dateTo,
        })
            .then(res => {
                if(subQuery === 'update_active'){
                    dispatch(getDaysSuccess(res.data, subQuery))
                }
                dispatch(getDaysSuccess(res.data))
            })
            .catch(err => { dispatch(getDaysError(err.message)) })
    }
}

export const getMenu = data => {
    return dispatch => {
        dispatch(getMenuStarted());
        // console.log(data);

        sr('AppSOKitchenService', 'GetMenuForDay', {
            restaurantId: data.activeRest,
            date: moment(data.date).format("YYYY-MM-DD"),
            periodId: data.periodId,
        })
            .then(res => {
                dispatch(getMenuSuccess(res.data))
            })
            .catch(err => {
                dispatch(getMenuError(err.message))
            })
    }
}

export const getRestsStarted = () => ({
    type: GET_RESTS_STARTED
})

export const getRestsSuccess = payload => ({
    type: GET_RESTS_SUCCESS,
    payload: {...payload},
})

export const getRestsError = () => ({
    type: GET_RESTS_ERROR
})

export const setActiveRestToStore = payload => ({
    type: SET_ACTIVE_REST,
    payload: payload,
})

export const getDaysStarted = () => ({
    type: GET_DAYS_STARTED
})

export const getDaysSuccess = (payload, query) => {
    if(query) {
        return {
            type: GET_DAYS_SUCCESS,
            payload: {...payload, query}
        }
    }
    return {
        type: GET_DAYS_SUCCESS,
        payload: {...payload}
    }
};

export const getDaysError = payload => ({
    type: GET_DAYS_ERROR,
    payload: payload
})

export const getMenuStarted = () => ({
    type: GET_MENU_STARTED
})

export const getMenuSuccess = payload => ({
    type: GET_MENU_SUCCESS,
    payload: {...payload}
})

export const getMenuError = payload => ({
    type: GET_MENU_ERROR,
    payload: payload
})

export const sliderShiftForward = () =>({
    type: SLIDER_SHIFT_FORWARD,
})

export const sliderShiftBack = () =>({
    type: SLIDER_SHIFT_BACK,
})

export const setActivePeriod = payload =>({
    type: SET_ACTIVE_PERIOD,
    payload: payload
})

export const resetActivePeriod = () =>({
    type: RESET_ACTIVE_PERIOD,
})

export const toggleActiveDish = payload => ({
    type: TOGGLE_ACTIVE_DISH,
    payload: payload,
})

export const toggleActiveCategory = payload => ({
    type: TOGGLE_ACTIVE_CATEGORY,
    payload: payload,
})

export const setEditMode = () =>({
    type: SET_EDIT_MODE,
})

export const rollingBackChangesEditMode = () =>({
    type: REVERT_EDIT_MODE,
})

export const applyFoodListChanges = () =>({
    type: APPLY_FOOD_LIST_CHANGES,
})

export const changeDishPrice = payload =>({
    type: CHANGE_DISH_PRICE,
    payload: payload,
})

export const changeDishAmount = payload =>({
    type: CHANGE_DISH_AMOUNT,
    payload: payload,
})

export const showBlockPopup = () => ({
    type: SHOW_BLOCK_POPUP,
});

export const hideBlockPopup = () => ({
    type: HIDE_BLOCK_POPUP,
});

//send new menu settings
export const sendNewPublicMenu = payload => {
    // console.log(payload.products);
    return dispatch => {
        dispatch(sendNewPublicMenuStart());
        sr('AppSOKitchenService', 'SaveProductsInMenu', {...payload})
            .then(res => {
                if(!payload.products.length) {
                    dispatch(markPeriodStatus({
                        restaurantId: payload.restaurantId,
                        date: payload.date,
                        periodId: payload.periodId,
                        status: 'draft',
                        dateRange: payload.dateRange
                    }))
                } else {
                    dispatch(markPeriodStatus({
                        restaurantId: payload.restaurantId,
                        date: payload.date,
                        periodId: payload.periodId,
                        status: 'publish',
                        dateRange: payload.dateRange
                    }))
                }
                dispatch(sendNewPublicMenuSuccess())
            })
            .catch(err => {
                dispatch(sendNewPublicMenuError());
                // console.log(err)
            })
    }
};

export const sendNewPublicMenuStart = () => ({
    type: SEND_MENU_START,
});

export const sendNewPublicMenuSuccess = () => ({
    type: SEND_MENU_SUCCESS,
});

export const sendNewPublicMenuError = () => ({
    type: SEND_MENU_ERROR,
});

// export const updatePublicMenu = payload => {
//     return dispatch => {
//         sr('AppSOKitchenService', 'SaveProductsInMenu', {...payload})
//             .then(res => {
//                 dispatch(markPeriodStatus({
//                                     restaurantId: payload.restaurantId,
//                                     date: payload.date,
//                                     periodId: payload.periodId,
//                                     status: 'publish',
//                                 }))
//             })
//             .catch(err => {
//                 console.log(err)
//             })
//     }
// };

export const markPeriodStatus = payload => {
    return dispatch => {
        dispatch(markPeriodAsPublishedStart());
        sr('AppSOKitchenService', 'PublishMenu', {
            restaurantId: payload.restaurantId,
            date: payload.date,
            periodId: payload.periodId,
            status: payload.status,
        })
            .then(res => {
                dispatch(getDays(payload.dateRange.dateFrom, payload.dateRange.dateTo, payload.restaurantId))
                if(payload.query === "getMenu") {
                    dispatch(getMenu({
                        activeRest: payload.restaurantId,
                        date: payload.date,
                        periodId: payload.periodId,
                    }));
                }
            })
            .catch(err => {console.log(err)})
    }
};

export const markPeriodAsPublishedStart = payload => ({
    type: PUBLISHED_START,
    payload: payload
})

export const markPeriodAsPublishedSuccess = payload => ({
    type: PUBLISHED_SUCCESS,
    payload: payload
})

export const prepMenuToSend = payload => ({
    type: PREPARE_MENU_TO_SEND,
    payload: payload
});

// block periods
export const sendSuspend = payload => {
    // console.log(payload)
    return dispatch => {
        dispatch(sendSuspendStart());
        // console.log(payload);
        sr('AppSOKitchenService', 'ServiceSuspended', {
            restaurantId: payload.restaurantId,
            date: moment(payload.date).format("YYYY-MM-DD"),
            periodId: payload.periodId,
            serviceSuspended: payload.serviceSuspended
        })
            .then(res => {
                dispatch(sendSuspendSuccess({serverRes: res.data, payload: {...payload}}))
                if(payload.getDaysData) {
                    dispatch(getDays(
                            payload.getDaysData.dateFrom,
                            payload.getDaysData.dateTo,
                            payload.restaurantId,
                            'update_active',
                        ))
                }
            })
            .catch(err => {
                // console.log(err)
                dispatch(sendSuspendError(err.message))
            })
    }
};
export const sendSuspendStart = payload => ({
    type: SEND_SUSPEND_START,
});

export const sendSuspendSuccess = payload => ({
    type: SEND_SUSPEND_SUCCESS,
    payload: payload
});

export const sendSuspendError = () => ({
    type: SEND_SUSPEND_ERROR,
});

//copy menu
export const setCopyDataDate = payload => ({
    type: COPY_MENU_DATA_SET_DATE,
    payload: payload
});

export const setCopyDataPeriod = payload => ({
    type: COPY_MENU_DATA_SET_PERIOD,
    payload: payload
});

export const initCopySend = payload => {
    return dispatch => {
        dispatch(copySendingStart);
        sr('AppSOKitchenService', 'CopyMenu', {
            restaurantId: payload.restaurantId,
            dateFrom: payload.dateFrom,
            periodFrom: payload.periodFrom,
            dateTo: payload.dateTo,
            periodTo: payload.periodTo,
        })
        .then(res => {
            const x = dispatch(markPeriodStatus({
                restaurantId: payload.restaurantId,
                date: payload.dateTo,
                periodId: payload.periodTo,
                status: 'publish',
                dateRange: payload.dateRange,
                query: "getMenu",
            }));
            dispatch(copySendingSuccess())
        })
        .catch(err => { dispatch(copySendingError(err.message)) })
    }
};

const copySendingStart = () => ({
    type: SEND_COPY_DATE_START,
});

const copySendingSuccess = () => ({
    type: SEND_COPY_DATE_SUCCESS,
});

const copySendingError = () => ({
    type: SEND_COPY_DATE_ERROR,
});
