import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import WithErr from "../../../../../images/assort/Ассортимент/Alarm_light.svg"
import Check from "../../../../../images/assort/Ассортимент/ORANGEsvgCheck.svg";
import GrayCheck from "../../../../../images/assort/Ассортимент/Chield_check_light.svg";

const HeaderModalUI = ({chooseVariant,setChooseVariant,aloneProduct,aloneProductActual}) => {
  return (
    <>
    <HeaderWithActual>
                <NewVersion
                  sel={chooseVariant !== "NEW"}
                  onClick={() => setChooseVariant("NEW")}
                >
                  <div className="tittle">Новая версия</div>
                  { aloneProduct?.moderate_status === "3" 
                  ?
                  <div className="subTittleErr">Есть ошибки  {chooseVariant === "NEW" && <img src={WithErr}/>}</div>
                  :
                  <div className="subTittle">На проверке <img style={{marginLeft:8}} src={chooseVariant === "NEW" ? Check: GrayCheck}/></div>
                  }
                  {
                  aloneProduct
                  &&
                  'date_and_time_of_dispatch' in aloneProduct
                  &&
                  aloneProduct?.date_and_time_of_dispatch
                  ?
                  <div className="time">
                    с {moment(aloneProduct?.date_and_time_of_dispatch,"YYYY-MM-DD-hh:mm:ss").format('DD.MM.YY / hh:mm ')}
                  </div>
                  :
                  <div className="time">
                   Дата не найдена
                  </div>
                  }
                </NewVersion>
                <ActualVersion
                  sel={chooseVariant !== "ACTUAL"}
                  onClick={() => setChooseVariant("ACTUAL")}   
                >
                  <div className="tittle">Актуальная версия</div>
                  <div className="subTittle">Версия</div>
                  <div className="time">
                  {
                  aloneProductActual?.date_and_time_changed 
                  ?
                  `от ${moment(aloneProductActual?.date_and_time_of_changed ,"YYYY-MM-DD-hh:mm:ss").format('DD.MM.YY / hh:mm ')}`
                  :
                  ' дата не найдена'
                  }
                  </div>
                </ActualVersion>
    </HeaderWithActual>
    {
      aloneProduct?.moderate_status === "3" && aloneProduct?.note && chooseVariant === "NEW" 
      && aloneProduct?.note &&
      (
        <ErrNote>
            {aloneProduct.note}
        </ErrNote>
      )
    }
    </>
  )
}

export default HeaderModalUI

const HeaderWithActual = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 53px;
  padding-right: 53px;
  padding-top: 16px;
`;
const NewVersion = styled.div`
  justify-self: start;
  width: 100%;
    box-shadow: ${({sel})=>sel ?'none' : '-12px 4px 34px rgb(0 0 0 / 4%)'};
    border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  .tittle {
    text-align: start;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    /* color: #455385; */
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#455385")};
  }
  .subTittle {
    text-align: start;
    font-weight: 400;
    padding-top:8.5px;
    font-size: 16px;
    line-height: 19px;
    /* color: ; */
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#E39115")};
  }
  .time {
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* color: ; */
    padding-top:8.5px;
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#2C2E32")};
  }
  .subTittleErr{
    padding-top:8.5px;
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    max-height: 150px;
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#EE2B00")};
  }
`;
const ActualVersion = styled.div`
    width: 100%;
    box-shadow: ${({sel})=>sel ?'none' : '-12px 4px 34px rgb(0 0 0 / 4%)'};
    /* box-shadow: -12px 4px 34px rgb(0 0 0 / 4%); */
    border-radius: 5px 5px 5px 5px;
    justify-self: end;
  cursor: pointer;
  .sel {
    color: #a6a6a6;
  }
  .tittle {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    /* color: #455385; */
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#455385")};
  }
  .subTittle {
    text-align: start;
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top:8.5px;
    /* color: #E39115; */
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#455385")};
  }
  .time {
    text-align: start;
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top:8.5px;

    /* color: #2C2E32; */
    color: ${({ sel }) => (sel ? "#A6A6A6" : "#2C2E32")};
  }
`;
const ErrNote = styled.div`
  margin-left: 53px;
  margin-right: 53px;
  margin-top: 30px;
  border: 1px solid #EE2B00;
  text-align: start;
    max-height: 150px;

  color:#EE2B00;
  padding: 10px;
  border-radius: 8px;
`
