import React, {useEffect, useState}  from 'react'
import styled from "styled-components";
import {
    DEFAULT_INPUT_STYLE_FOR_AREA,
    DEFAULT_INPUT_STYLE_MOD,
    GIRD_CONSISTENCY_SETTINGS_MOD,
    PRODUCT_LIST_ELEMENT_CONTAINERMOD
} from "../Assort/commonStyles";
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";
import {
    changeReadyProduct,
} from "../../actions/AssortmentAction";
import {sr} from '../../utils'
import { UIcomponents } from './UIcomponents';
import ImageContainer from '../Assort/Components/ImageUI/ImageContainer';
import { deliveryCycleInfoMessage } from '../../actions/ModalActions';
import { Input } from 'antd';
import { motion } from 'framer-motion';
import Delete from '../../images/Trash_light.png'
import AdminCheckBox from '../../UI_ADMIN/AdminCheckBox';



function ProductItemMod({
    selectedCategory, addNewMode,
    productItem = [{}], id,moderationItem=[{}],
   tegs,productItemWithActive,setActions}) 
    {
    


    // const modProduct = productItem.historyModeration.slice(-1)[0]
    const dispatch = useDispatch()
    const selectedProducts = useSelector(store => store.assortment.selectedProducts);
    const tegList = useSelector(store=>store.assortment.tegList)
    const [productCategoriesState, setProductCategoriesState] = useState([])
    const [tagId,setTegId] = useState([])
    const [cause,setCause] = useState('')

    useEffect(() => {
       // console.log(productCategoriesState,'productCategoriesState.includes')
        if ( selectedCategory ) {
            if( !productCategoriesState.includes(selectedCategory) ) {
                setProductCategoriesState([selectedCategory])
                // console.log(selectedCategory,'select in useef')
            }
        }
    }, [selectedCategory])
  
    useEffect(() => {
        // console.log(productCategoriesState,'productCategoriesState.includes')
         if (productItemWithActive[0].tegs_product) {
             if( !tagId.includes(productItemWithActive[0].tegs_product) ) {
                setTegId(productItemWithActive[0].tegs_product)
                 // console.log(selectedCategory,'select in useef')
             }
         }
     }, [tegList])
    function negativeHandler(){
        if(cause.length > 0){
        sr('MenuSOKitchenService','negative',{
            moderation_id:productItemWithActive[0].moderation_id,
            note:cause
        }).then(res=>setActions(true))
        // dispatch(changeReadyProduct({prods:productItemWithActive[0].moderation_id}))
        setActions(false)
        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт отправлен на доработку'))
    }else{
        dispatch(deliveryCycleInfoMessage('empty_fields'))
    }
    } 
    function positiveHandler(){
        setActions(true)
        sr('MenuSOKitchenService','positive',{
            moderation_id:productItemWithActive[0].moderation_id,
        }).then(res=>setActions(true))
        // dispatch(changeReadyProduct({prods:productItemWithActive[0].moderation_id}))
        setActions(false)

        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт одбрен'))

    }
    function deleteHandler(){
        sr('MenuSOKitchenService','deleteModerationElement',{
            id:productItemWithActive[0].moderation_id,
        }).then(res=>setActions(true))
        // dispatch(changeReadyProduct({prods:productItemWithActive[0].moderation_id}))
        setActions(false)

        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт удален'))

    }

     
   
    const drawCategoryList = () => {
        if(productItemWithActive[0]){
            if(productItemWithActive[0].tegs_product){
        const categoryList = Object.values(productItemWithActive[0]?.tegs_product).length
            ? tegs?.map(category => {if(productItemWithActive[0]?.tegs_product?.includes(category.id)){
                return (
                    <ProductCategory key={nanoid()} >
                        {productItemWithActive[0]?.tegs_product ?
                         productItemWithActive?.dataItemProduct?.tegs_product?.toString().includes(category.id) 
                         ?
                         <>
                         <AdminCheckBox
                        />
                         <p style={{marginLeft:8}}>{category.location.ru_RU}</p>
                         </>
                         :
                         <>
                         <AdminCheckBox
                        />
                         <div style={{backgroundColor:'#F3E9CE',borderRadius:6,}}>
                             <p style={{marginLeft:8}}>{category.location.ru_RU}</p>
                        </div>
                         </>
                         :
                         ''
                        }  
                    </ProductCategory>
                )
            }
        })
            :
            null
        return (
            <ProductCategoryWrapper>
                {categoryList}
            </ProductCategoryWrapper>
        )
    }}}
    
function getPhotoWrapp(){
    if(productItemWithActive[0].temporary_picture.length > 0 ){
      return  <ImageContainer
    tempPic = {productItemWithActive[0].temporary_picture[0] ? productItemWithActive[0].temporary_picture[0]?.name : null}
    style = {{width:'90px',height:'90px',borderRadius:'6px'}}
    withBorder={true}
    border = {'mod'}
    />
    }else{
        return <ImageContainer
        media = {productItemWithActive[0].media ? productItemWithActive[0].media : null}
        style = {{width:'90px',height:'90px',borderRadius:'6px'}}
        border = {'mod'}
        />
    }
}
    return ( 

        <Wrapper>
        <ProdWrapp>
            <MainInfoProd>
                    <div>
                     <InfoItem
                     style={{
                         fontSize:20,
                         fontWeight:500,
                         color:'#2C2E32'
                         ,borderRadius:8
                         ,backgroundColor:productItemWithActive[0].name ===productItemWithActive.dataItemProduct.name ? '#FFFFFF' : '#F3E9CE'
                     }}
                     >
                        {productItemWithActive[0].name}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoItem
                     style={{
                        fontSize:14,
                        fontWeight:400,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:productItemWithActive[0].description ===productItemWithActive.dataItemProduct.description ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                        {productItemWithActive[0].description}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoItem
                     style={{
                        fontSize:20,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].price) ===productItemWithActive.dataItemProduct.price ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                        {productItemWithActive[0].price} ₽
                     </InfoItem>
                    </div>
                    <div>
                     <InfoItem>
                        {
                            getPhotoWrapp()
                        }    
                     </InfoItem>
                    </div>
                    <div>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].weight) ===productItemWithActive.dataItemProduct.weight ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                        {productItemWithActive[0].weight}
                     </InfoItem>
                    </div>
                    <div>

                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].calories) ===productItemWithActive.dataItemProduct.calories ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                     {productItemWithActive[0].calories} 
                     </InfoItem>
                    </div>
                    <div>

                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].proteins) ===productItemWithActive.dataItemProduct.proteins ? '#FFFFFF' : '#FFFFFF'
                    }}
                     >
                        {productItemWithActive[0].proteins}
                     </InfoItem>
                    </div>
                    <div>

                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].fats) ===productItemWithActive.dataItemProduct.fats ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                     {productItemWithActive[0].fats} 
                     </InfoItem>
                    </div>
                    <div>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                         ,borderRadius:8
                        ,backgroundColor:Number(productItemWithActive[0].carbo) ===productItemWithActive.dataItemProduct.carbo ? '#FFFFFF' : '#F3E9CE'
                    }}
                     >
                        {productItemWithActive[0].carbo}
                     </InfoItem>
                     
                    </div>
                    
            </MainInfoProd>
            <div
                    style={{borderBottom:'1px solid #CECECE',display:'flex',alignItems:'center',justifyContent:'space-between',padding:16}}
                    >
                    {drawCategoryList() ? drawCategoryList()  
                    : 
                    <div>
                    </div> 
                    }
                     <motion.div whileHover={{ scale: 0.9 }} style={{display:'flex',cursor:'pointer'}} onClick={deleteHandler}>
                         <div style={{color:'#EE2B00',fontSize:16,fontWeight:400,marginRight:4}}>Удалить</div>
                         <img width={24} height={24} src={Delete}/>
                    </motion.div>
                    </div>
            <ManagmentProd>
                <CauseInput>
                    <Input
                    placeholder='Введите причину отказа'
                    onChange={e=>setCause(e.target.value)}
                    value={cause}
                    />
                </CauseInput>

                <ButtonManag>
                <SendCheck whileHover={{ scale: 0.9 }} onClick={negativeHandler} >
                    Отправить на дороботку
                </SendCheck>    
                <SendGood  whileHover={{ scale: 0.9 }} onClick={positiveHandler}>
                    Одобрить
                </SendGood>
                </ButtonManag>
                
            </ManagmentProd>
            </ProdWrapp>
    </Wrapper>
    // <>
    //     <ProductListItemWrapper>
    //         <Cols>     
    //                 <ProductInfo gridSetting={GIRD_CONSISTENCY_SETTINGS_MOD} >
    //                     <div style={{width:'40%',textAlign:'start'}}>
    //                     <MainName>     
    //                         <div 
    //                         disabled={true}
    //                         style={{borderRadius:6,backgroundColor:productItemWithActive[0].name === productItemWithActive.dataItemProduct.name ? '#E6E9F0' : '#F3E9CE'}}
    //                         >{productItemWithActive[0].name}</div>
    //                         <FoodComposition>
    //                             <div style={{borderRadius:6,backgroundColor:productItemWithActive[0].description === productItemWithActive.dataItemProduct.description ? '#E6E9F0' : '#F3E9CE'}}
    //                             >{productItemWithActive[0].description}</div>
    //                         </FoodComposition>
    //                       </MainName>
    //                       </div>         
    //                      <div style={{width:'15%'}}>
    //                        <ImageWrapper >
    //                           {getPhotoWrapp()}
    //                        </ImageWrapper>
    //                     </div>

    //                     <div className='items' style={{width:'60%',display:'flex'}}>
    //                         <div type="text" style={{height:27,marginLeft:5,width:'20%',backgroundColor:Number(productItemWithActive[0].price) === productItemWithActive.dataItemProduct.price ? '#E6E9F0' : '#F3E9CE'}} 
    //                                 disabled={true}
    //                                 ><p>{productItemWithActive[0].price}</p></div>

    //                         <div type="text"  style={{height:27,marginLeft:5,width:'20%',backgroundColor:Number(productItemWithActive[0].weight) ===productItemWithActive.dataItemProduct.weight ? '#E6E9F0' : '#F3E9CE'}}  
    //                                 disabled={true}
    //                                 ><p>{productItemWithActive[0].weight}</p></div>
    //                         <div type="text" style={{height:27,marginLeft:5,width:'20%',backgroundColor:Number(productItemWithActive[0].calories) === productItemWithActive.dataItemProduct.calories ? '#E6E9F0' : '#F3E9CE'}}   
    //                                 disabled={true}
    //                                 ><p>{productItemWithActive[0].calories}</p></div>
    //                         <div type="text" style={{height:27,marginLeft:5,width:'20%',backgroundColor:Number(productItemWithActive[0].proteins) === productItemWithActive.dataItemProduct.proteins ? '#E6E9F0' : '#F3E9CE'}}  
    //                                 disabled={true}
    //                                 ><p>{productItemWithActive[0].proteins}</p></div>
    //                         <div type="text" style={{height:27,marginLeft:5,width:'20%',backgroundColor:Number(productItemWithActive[0].fats) === productItemWithActive.dataItemProduct.fats ? '#E6E9F0' : '#F3E9CE'}} 
    //                                 disabled={true} 
    //                                 ><p>{productItemWithActive[0].fats}</p></div>
    //                         <div type="text" 
    //                                 style={{height:27,width:'20%',marginLeft:5,backgroundColor:Number(productItemWithActive[0].carbo) === productItemWithActive.dataItemProduct.carbo ? '#E6E9F0' : '#F3E9CE'}}  
    //                                 disabled={true}
    //                                 ><p>{productItemWithActive[0].carbo} </p></div>
    //                         <p style={{width:'20%'}} >☆{productItemWithActive[0].rating}</p>

    //                         <p style={{width:'20%'}}>{productItemWithActive[0].sold}</p>
    //                         </div>
    //                 </ProductInfo>       
    //                 <ProductCategoryInfo style={{marginTop: 15}}>
    //                     {drawCategoryList()}
    //                 </ProductCategoryInfo>
                    
    //                     <Line/>
    //                         <div style={{padding: '15px'}}>
    //                             <UIcomponents {...{positive:positiveHandler,negative:negativeHandler,deleteH:deleteHandler,cause:cause,setCause:setCause}} />
    //                         </div>
                    
    //         </Cols>
    //     </ProductListItemWrapper>
    //     </>
    )
}

export default ProductItemMod;

const Wrapper = styled.div`
font-family: 'Roboto';
font-style: normal;
max-width: 1100px;
`
const ProdWrapp = styled.div`
display:grid;
width: 100%;
grid-template-rows: auto 1fr auto;
background: #FFFFFF;
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
border-radius: 8px;  
margin-bottom:16px;
`
const InfoProdTime = styled.div`
padding: 16px;
display: flex;

`
const MainInfoProd = styled.div`
display: grid;
padding:16px;
gap:20px;
/* grid-template-columns: 1fr 2fr .7fr 90px  repeat(5,.4fr);*/
grid-template-columns: .4fr .5fr .2fr 90px .2fr repeat(4,.1fr);
`
const ManagmentProd = styled.div`
display: flex;
justify-content: space-around;
margin-top: 27px;
margin-bottom: 24px;
`
const ColsProd = styled.div`   
border-bottom: 1px solid #CECECE;

`

const InfoItem = styled.div`
margin-top:19px;
text-align: start;
word-break: break-all;

`
const InfoHeader = styled.div`
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: start;
color: #A6A6A6;
`
const CauseInput = styled.div`
width:60%;
    input{
        height: 50px;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
        border-radius: 8px;
    }
`
const ButtonManag = styled.div`
display:flex;
`

const SendCheck = styled(motion.div)`
display:flex;
align-items: center;
padding-left:40px;
padding-right:40px;
justify-content: center;
border: 2px solid #7882A7;
border-radius: 8px;  
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;  
cursor: pointer;
`
const SendGood = styled(motion.div)`
display:flex;
align-items: center;
justify-content: center;
background: #7882A7;
border-radius: 8px;
padding-left: 16px;
padding-right: 16px;
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #FFFFFF;
margin-left: 16px;
cursor: pointer;
`
const StateProd = styled.div`
display: flex;
align-items: center;
justify-content: center;
background:${({active})=> active && '#79AD7E' };
border: ${({active})=> !active && '1px solid #000000'};
padding: 6px 16px;
border-radius: 8px;
color:${({active})=> active ? '#FFFFFF' : '#2C2E32'};
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.442px;
`


// const ProductListItemWrapper = styled.div`
//   display: flex;
//   border-radius: 5px;
//   height: auto; 
//   /* margin-bottom: 12px; */
//   box-sizing: border-box;
//   flex-direction: row;
//   width: 100%;
// `
// const Cols = styled.div`
//     /* ${PRODUCT_LIST_ELEMENT_CONTAINERMOD}; */
//     align-items: flex-start;
//     width:100%;
// `
// const MainName = styled.div`
// display: grid;
// grid-row: 1fr 1fr;
// `
// const ProductInfo = styled.div`
// display: flex;
// padding: 8px 12px;
//   /* align-items: start; */
//   width: 100%;
//   /* ${({gridSetting}) => gridSetting ? {...gridSetting} : null}; */
//   margin-top: 20px;
//   .items{
//     div{
//         border-radius: 6px;
//     }
//   }
// `
// const Col = styled.div`
//   /* width: auto; */
//   text-align: left;
//   padding: 15px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
  

//   input {
//     ${DEFAULT_INPUT_STYLE_MOD};
//   }
//   p{
//     max-width: 350px;
//   }
  
//   .nameOfProduct {
//     text-decoration: underline;
//     color: #6178ff;
//     cursor: pointer;
//     transition: color .2s ease-out;
//     width: max-content;
//   }
//   .nameOfProduct:hover {
//     color: #92a3ff;
//   }
// `
const ProductCategory = styled.div`
  display: flex;

`
// const ProductCategoryInfo = styled.div`

// `
// const Line = styled.div`
// border: 1px solid #DDDDDD ;
// `
const ProductCategoryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  > div{
    margin-right: 23px;
  } 
  > div:last-child{
    margin-right: 0;
  } 
`
const MarkerProductCategory = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(180deg, #3E89DF 0%, #2C79D0 100%);
box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602);
`
// const ImageWrapper = styled.div`
// display: flex;
// justify-content: center;
// > img {
//       margin-right: 6px;
//   }
//   > img:last-child{
//       margin-right: 0;
//   }
// `

// const FoodComposition = styled.div`
//   margin-top: 10px;
//   > div {
//     ${DEFAULT_INPUT_STYLE_FOR_AREA};
//     max-width: 450px;
//     width: auto;
//     min-height:59px;
//     resize: none;
//     border: 1px solid transparent;
//   }
// `
