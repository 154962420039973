import {
    GET_RECIPIENT_ERROR,
    GET_RECIPIENT_READY,
    SELECT_RECIPIENT,
    GET_RECIPIENT, RESET_SELECTED_RECIPIENT, SELECT_RECIPIENT_MODAL
} from "../../actions/WarehouseActions";

const initState = {
    recipientLoad: false,
    recipientSuccess: false,
    recipientError: false,
    recipientErrorMessage: '',
    recipients: [],
    selectedRecipient: null,
    selectedRecipientModal: null,
}

//
// the getRc request happens in the saga
//
export const _getRecipientReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_RECIPIENT:
            return {
                ...state,
                recipientLoad: true,
                recipientSuccess: false
            }
        case GET_RECIPIENT_READY:
            return {
                ...state,
                recipientLoad: false,
                recipientSuccess: true,
                recipients: action.payload,
                selectedRecipient: action.payload[0],
                selectedRecipientModal: action.payload[0]
            }
        case GET_RECIPIENT_ERROR:
            return {
                ...state,
                recipientLoad: false,
                recipientError: true,
                recipientErrorMessage: action.payload?.message
            }
        case SELECT_RECIPIENT:
            return {
                ...state,
                selectedRecipient: state.recipients[action.payload]
            }
        case SELECT_RECIPIENT_MODAL:
            return {
                ...state,
                selectedRecipientModal: state.recipients[action.payload],
            }
        case RESET_SELECTED_RECIPIENT:
            return {
                ...state,
                selectedRecipient: null,
            }
        default:
            return state
    }
}
