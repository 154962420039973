import React from "react";
import styled, {keyframes} from "styled-components";
import moment from "moment";
import {useDispatch} from "react-redux";
import {
  applyFoodListChanges,
  prepMenuToSend,
  rollingBackChangesEditMode,
  sendNewPublicMenu,
  sendSuspend,
  setEditMode,
  showBlockPopup,
} from "../../actions/MenuByDayActions";
//image
import publish_btn from '../../images/menu_by_day/publish_btn.svg'
import period_ban from '../../images/menu_by_day/period_ban.svg'
import period_ban_red from '../../images/menu_by_day/period_ban_red.svg'


const PublicBar = (
  {
    dateRange,
    showSendPreloader,
    preparedFoodListData,
    permissions,
    activePeriod,
    status,
    editMode,
    amountRestrictionsChecker,
    dateFrom,
    dateTo,
  }
) => {

  const dispatch = useDispatch();

  const {
    staff
  } = permissions;

  const editModeSwitch = () => {
    dispatch(setEditMode())
  };
  const rollingBack = () => {
    dispatch(rollingBackChangesEditMode())
  };
  const publishHandle = () => {
    dispatch(sendNewPublicMenu({
      restaurantId: activePeriod.activeRest,
      date: moment(activePeriod.date).format("YYYY-MM-DD"),
      periodId: activePeriod.periodId,
      products: preparedFoodListData,
      dateRange: dateRange
    }));
  };
  const acceptHandle = () => {
    dispatch(applyFoodListChanges());
    dispatch(prepMenuToSend());
  };
  const blockHandle = () => {
    dispatch(showBlockPopup())
  };
  const deleteSuspend = () => {
    debugger
    const payload = {
      restaurantId: activePeriod.activeRest,
      date: activePeriod.date,
      periodId: activePeriod.periodId,
      periodSearchId: activePeriod.id,
      serviceSuspended: "",
      getDaysData: {dateFrom, dateTo, activeRest: activePeriod.activeRest}
    };

    dispatch(sendSuspend(payload));
  };

  const preloader = () => {
    return (
      <PreloaderWrapper>
        <Preloader/>
      </PreloaderWrapper>
    )
  };

  return (
    <PublicBarWrapper>
      <SubContainer suspend={activePeriod.serviceSuspended}>
        <CommonInfo>
          <PublicChecker>
            <p>
              Меню на {moment(activePeriod.date).format('D MMMM')} на {activePeriod.period.periodFrom}
            </p>
            <Published status={activePeriod.status}>
              <p>
                {
                  activePeriod.status === "publish"
                    ? "Опубликовано"
                    : "Не опубликовано"
                }
              </p>
            </Published>
          </PublicChecker>
          <TextInfo>
            <p>Приём заказов до 10:00</p>
          </TextInfo>
        </CommonInfo>
        <BlockStatus>
          {
            !activePeriod.serviceSuspended
              ? staff.role === "admin" ?
                <button onClick={blockHandle}>Отключить <img src={period_ban} alt="ban"/></button> : null
              :
              <>
                <SuspendReason>
                  <header>
                    <img src={period_ban_red} alt="ban"/>
                    <h4>Отключено</h4>
                  </header>
                  <p>Причина:{
                    activePeriod.serviceSuspended
                  }</p>
                </SuspendReason>
                {
                  staff.role === "admin"
                    ? <DeleteSuspend onClick={deleteSuspend}/>
                    : null
                }
              </>
          }
        </BlockStatus>
        <Warnings>
          {
            amountRestrictionsChecker !== 0
              ? amountRestrictionsChecker !== 1
                ? <>
                  <WarningMarker><p>!</p></WarningMarker>
                  <p>{amountRestrictionsChecker} позиций без ограничений по количеству</p></>
                : <>
                  <div><p>!</p></div>
                  <p>{amountRestrictionsChecker} позиция без ограничений по количеству</p></>
              : null
          }
        </Warnings>
        <Controls editMode={editMode}>
          {
            editMode
              ? <ButtonCancel onClick={rollingBack}><p>Отменить</p></ButtonCancel>
              : <ButtonEdit onClick={editModeSwitch}><p>Редактировать</p></ButtonEdit>
          }
          {
            editMode
              ?
              status === "publish"
                ?
                <ButtonPublish onClick={acceptHandle}><p>Применить</p></ButtonPublish>
                :
                <ButtonPublish onClick={acceptHandle}><p>Применить</p></ButtonPublish>
              : null
          }
          {
            !editMode
              ?
              !showSendPreloader
                ? <ButtonPublish onClick={publishHandle}><p>Опубликовать</p>
                  <div><img src={publish_btn} alt="publish_btn"/></div>
                </ButtonPublish>
                : <ButtonPublish onClick={publishHandle}><p>Опубликовать</p>
                  <div>{preloader()}</div>
                </ButtonPublish>
              : null
          }
        </Controls>
      </SubContainer>
    </PublicBarWrapper>
  )
}
const PublicBarWrapper = styled.div`
  padding: 52px 0;
`
const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr;
  gap: 10px;
  background: #ffffff;
  padding: 12px 25px;
  align-items: center;

  @media (-webkit-min-device-pixel-ratio: 2),(max-width: 1200px) {
    padding: 12px 10px;
  }
`
const CommonInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const TextInfo = styled.div`
  text-align: left;

  p {
    font-size: 14px;
    line-height: 16px;
  }
`
const PublicChecker = styled.div`
  display: flex;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0;

    @media (-webkit-min-device-pixel-ratio: 2),(max-width: 1200px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`
const Published = styled.div`
  margin-left: 5px;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 16px;
  border: 2px solid #CDDC39;
  border-radius: 666px;
  background: ${({status}) => status === "publish" ? "#CDDC39" : "#fff"};

  @media (-webkit-min-device-pixel-ratio: 2),(max-width: 1200px) {
    padding: 3px 5px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    color: ${({status}) => status === "publish" ? "#ffffff" : "#000"};
    font-weight: bold;
  }
`
const BlockStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
    background: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    border-right: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    padding: 5px 10px;

    img {
      margin-left: 8px;
      background: #F44336;
      padding: 9px;
      border-radius: 6px;
    }
  }
`
const SuspendReason = styled.div`
  header {
    display: flex;
    flex-direction: row;

    img {
      margin-right: 5px;
    }
  }

  p {
    max-width: 300px;
    text-align: left;
  }
`
const DeleteSuspend = styled.div`
  cursor: pointer;
  position: relative;
  width: 26px;
  height: 26px;
  background: #EEEEEE;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: border .2s ease;

  :hover {
    border: 1px solid black;
  }

  :after {
    content: '';
    position: absolute;
    width: 2px;
    height: 12px;
    top: calc(50% - 6.5px);
    left: calc(11px);
    border-radius: 6px;
    background: #000;
    transform: rotate(45deg);
  }

  :before {
    content: '';
    position: absolute;
    width: 2px;
    height: 12px;
    top: calc(50% - 6.5px);
    left: calc(11px);
    border-radius: 6px;
    background: #000;
    transform: rotate(-45deg);
  }
`
const Warnings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`
const WarningMarker = styled.div`
  width: 20px;
  height: 20px;
  background: red;
  font-style: normal;
  font-weight: bold;
  font-size: 13.3333px;
  line-height: 16px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  
`
const Controls = styled.div`
  display: grid;
  grid-template-columns: ${({status}) => status === "publish" ? "repeat(1, .3fr)" : "repeat(2, .3fr)"};
  grid-template-columns: ${({editMode}) => editMode ? "repeat(2, .3fr)" : null};
  grid-gap: 10px;
  justify-self: end;
`
const DefaultBtn = styled.button`
  cursor: pointer;
  width: min-content;
  padding: 12px 24px;
  border-radius: 6px;
  background: none;
  border: none;
  justify-self: end;
`
const ButtonCancel = styled(DefaultBtn)`
  p {
    text-decoration: underline;
  }
`
const ButtonEdit = styled(DefaultBtn)`
  background: #E1E3EA;
`
const ButtonPublish = styled(DefaultBtn)`
  display: flex;
  align-items: center;
  background: #CDDC39;

  & div {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0 14px;
  }
`
const PreloaderWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
`
const preloaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }


  to {
    transform: rotate(360deg);
  }
`
const preloaderAnimationBA = keyframes`
  from {
    box-shadow: black 0px 0px 0px;
  }

  to {
    box-shadow: black 0px 0px 10px;
  }
`
const Preloader = styled.div`
  animation: ${preloaderAnimation} 1s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;

  :before {
    position: absolute;
    left: 0;
    top: calc(50% - 2.5px);
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }

  :after {
    position: absolute;
    right: 0;
    top: calc(50% - 2.5px);
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }
`


export default PublicBar;
