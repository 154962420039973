import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectRecipient, selectRecipientModal} from "../../actions/WarehouseActions";
import CustomSelect from "../share/UI/CustomSelect"


const RecipientSelector = ({style = {}}) => {

    const dispatch = useDispatch()
    const recipientState = useSelector(store => store.warehouse.recipientState)

    const [selectedRecipient, setSelectedRecipient] = useState(null)

    useEffect(() => {
        if (selectedRecipient !== null) {
            dispatch(selectRecipient(selectedRecipient))
            dispatch(selectRecipientModal(selectedRecipient))
        }
    }, [selectedRecipient])

    const recipientSelectionHandler = (val) => setSelectedRecipient(val)

    return <CustomSelect data={recipientState.recipients} value={recipientState.selectedRecipient?.name} handler={recipientSelectionHandler} styles={style}/>
}

export default RecipientSelector;