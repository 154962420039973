export const CONTAINER_PADDING = {padding: '8px 12px'}
export const CONTAINER_PADDING_MOD = {padding: '0px'}

export const GIRD_CONSISTENCY_SETTINGS = {
    display: 'grid',
    // gridTemplateColumns:'90% 40% repeat(8,15%)'
    gridTemplateColumns: '7fr 3fr 1.2fr 1.2fr 1.2fr repeat(4,1fr) 2fr',
}

export const GIRD_CONSISTENCY_SETTINGS_MOD = {
    display: 'grid',
    // gridTemplateColumns:'90% 40% repeat(8,15%)',
    gridTemplateColumns: '6.8fr 3fr 1.2fr 1.2fr 1.2fr repeat(4, 1fr) 2fr',
}



//productList items styled
export const PRODUCT_LIST_ELEMENT_CONTAINER = {
    display: 'flex',
    width: 'auto',
    marginBottom: 16,
    boxSizing: 'border-box',
    background:'#FFFFFF',
    boxShadow:'0px 4px 6px rgba(206, 206, 206, 0.25)',
    borderRadius: 8,
    ...CONTAINER_PADDING
}

export const PRODUCT_LIST_ELEMENT_CONTAINER2 = {
    display: 'flex',
    width: 'auto',
    marginBottom: 16,
    boxSizing: 'border-box',
    background:'#7882A7',
    boxShadow:'0px 4px 6px rgba(206, 206, 206, 0.25)',
    borderRadius: 8,
    ...CONTAINER_PADDING
}

export const DEFAULT_INPUT_STYLE = {
    width: 'calc(100% - 30px)',
    height:'auto',
    borderRadius: 6,
    border: 'none',
    background: '#E6E9F0',
}
export const DEFAULT_INPUT_STYLE_MOD = {
    width: '100%',
    height:'auto',
    borderRadius: 6,
    border: 'none',
    background: '#E6E9F0',
}

export const INPUT_STYLE_MOD = {
    width: 'auto',
    height:'auto',
    borderRadius: 6,
    border: 'none',
    background: '#F3E9CE',
}
export const DEFAULT_INPUT_STYLE_FOR_AREA = {
    width: 'calc(100% - 30px)',
    borderRadius: 6,
    border: 'none',
    background: '#E6E9F0',
    overflow: 'hidden'
}

export const PRODUCT_LIST_ELEMENT_CONTAINERMOD = {
    display: 'flex',
    width: 'auto',
    boxSizing: 'border-box',
    background:'#fff',
}