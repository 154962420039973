import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectRecipientModal} from "../../../../actions/WarehouseActions"
import CustomSelect from "../../../share/UI/CustomSelect"

const RecipientSelectorModal = ({style = {}}) => {

    const dispatch = useDispatch()
    const recipientState = useSelector(store => store.warehouse.recipientState)
    const selectedRecipientModal = useSelector(store => store.warehouse.recipientState.selectedRecipientModal)

    const [selectedRecipient, setSelectedRecipient] = useState(null)

    useEffect(() => {
        if (selectedRecipient !== null) {
            dispatch(selectRecipientModal(selectedRecipient))
        }
    }, [selectedRecipient])

    const recipientSelectionHandler = (val) => setSelectedRecipient(val)

    return <CustomSelect data={recipientState.recipients} value={selectedRecipientModal?.name} handler={recipientSelectionHandler} styles={style}/>
}

export default RecipientSelectorModal;