import {takeEvery} from "redux-saga/effects"
import {
  getCourierRouteWorker,
  getDeliveryPointsAndCouriers,
  getDetailedOrderInfoWorker, getTracks,
  redirectCouriersWorker, sendNewMarkerPosition,
  sendUpdatedComments,
  getCentersForOrder,
  updateCenter
} from "./orderMapWorkers"
import {
  getCourierRoute,
  getCourierTrack,
  getDeliveryPoints,
  getDetailedOrderInfo,
  getCenterInfo,
  redirectCouriers,
  sendNewMarkerPos,
  getCenterInfoUpdate,
  updateAddrComment, updateOrderComment
} from "../../reducers/toolkit/orderMapReducer"


export function* orderMapWatcher() {
  yield takeEvery(getDeliveryPoints, getDeliveryPointsAndCouriers)
  yield takeEvery(redirectCouriers, redirectCouriersWorker)
  yield takeEvery(getDetailedOrderInfo, getDetailedOrderInfoWorker)
  yield takeEvery(updateAddrComment, sendUpdatedComments)
  yield takeEvery(updateOrderComment, sendUpdatedComments)
  yield takeEvery(getCenterInfoUpdate,updateCenter)
  yield takeEvery(getCourierTrack, getTracks)
  yield takeEvery(getCourierRoute, getCourierRouteWorker)
  yield takeEvery(sendNewMarkerPos, sendNewMarkerPosition)
}
