import React, {memo, useEffect, useState} from "react";
import styled from "styled-components"
import {motion} from "framer-motion"
import BurgerMenu from "../share/UI/BurgerMenu"
import {useDispatch, useSelector} from "react-redux"
import {
  getDetailedOrderInfo,
  getCenterInfo,
  resetInfoUint,
  sendNewMarkerPos,
  setCenter, setNewMarkerPosition, setOldMarkerPosition,
  setZoom
} from "../../reducers/toolkit/orderMapReducer"
import {InfoSidebarHeader} from "./InfoSidebar"
import redStar from "../../images/redStar.svg"
import {BlackPoint, Checkmark, CommentIcon, Cross, EditIcon, HouseIcon, Point} from "../../images/svgs"
import AboutOrder from "../share/OrderMapCommonComponents/AboutOrder"
import OrderedUsersList from "./ListHandlers/OrderedUsersList"
import Comments from "./Comments"
import {getOperatorPeriodsInit,allPeriodsByDate,} from "../../reducers/toolkit/comonReducer"
import {cutTime} from "./CouriersStatistic"

const container = {
  hidden: {
    left: 0,
    transition: {
      ease: 'easeOut',
      duration: .2,
      when: "afterChildren"
    }
  },
  show: {
    left: '100%',
    transition: {
      ease: 'easeIn',
      duration: .2,
      delayChildren: .2,
    }
  }
}

const item = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  transition: {
    ease: 'easeIn',
    duration: .2,
  }
}

const DetailedInfo = ({selectionInfoUnit}) => {
  const dispatch = useDispatch()

  const {nativeIcon} = selectionInfoUnit.marker

  // const detailedOrderInfo = useSelector(store => store.orderMap.detailedOrderInfo)
  // const centerInfo = useSelector(store=>store.orderMap.centerInfo)
  // const couriers = useSelector(store => store.orderMap.couriers)
  // const getDetailedOrderInfoStatus = useSelector(store => store.orderMap.getDetailedOrderInfoStatus)
  // const centerInfoUpdateStatus = useSelector(store => store.orderMap.centerInfoUpdateStatus)

  // const getCenterInfoStatus = useSelector(store => store.orderMap.getCenterInfoStatus)
  // const newMarkerPosition = useSelector(store => store.orderMap.newMarkerPosition)
  // const oldMarkerPosition = useSelector(store => store.orderMap.oldMarkerPosition)

  const {
    detailedOrderInfo,
    centerInfo,
    couriers,
    getDetailedOrderInfoStatus,
    getCenterInfoStatus,
    newMarkerPosition,
    oldMarkerPosition
  } = useSelector(store => store.orderMap)
  const deliveryPoints = useSelector(store=> store.orderMap.deliveryPoints)
  const allPeriodsByDate = useSelector(store=>store.common.allPeriodsByDate)
  const dates = useSelector(store => store.orderMap.date)
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)
  const [dataIsLoad, setDataIsLoad] = useState(false)
  const [markerDrag, setMarkerDrag] = useState(false)
  const [namePeriods, setNamePeriods] = useState([])
  
  const {
    marker,
    companyId,
    companyName,
    companyAddress,
    newCompanyMarker,
    localId,
    ordId,
    courierId,
    deliveryTime,
    
  } = selectionInfoUnit

  const courierName = couriers.filter(el => el.id === courierId)[0]?.name
  const deliveryTimeCut = deliveryTime ? cutTime(deliveryTime.split(' ')[1]) : '--:--'

  useEffect(() => {
    setDataIsLoad(false)
    if(selectionInfoUnit) {
      setMarkerDrag(false)
      dispatch(getDetailedOrderInfo({orderId: selectionInfoUnit.ordId}))
    }
  }, [selectionInfoUnit]);

  useEffect(() => {
    if(getDetailedOrderInfoStatus === 'resolved') {
      setDataIsLoad(true)
    } else {
      setDataIsLoad(false)
    }
  }, [getDetailedOrderInfoStatus])
  const getName=()=>{
    if(deliveryPoints){
   const a = deliveryPoints.find(item=>item.companyId === companyId)
   if(a){
   const b = allPeriodsByDate.filter(item=> item.id===a.periodId)
      setNamePeriods(b)
   }
  }
}
  useEffect(()=>{
    getName()
  },[allPeriodsByDate,selectionInfoUnit])
  const resetMarkerReposition = () => {
    marker.setDraggable(false)
    setMarkerDrag(false)
    marker.setPosition(oldMarkerPosition)
    dispatch(setOldMarkerPosition(null))
    dispatch(setNewMarkerPosition(null))
    marker.setDraggable(false)
  }
  const ApplyMarkerReposition = () => {
    if(newMarkerPosition){
      dispatch(sendNewMarkerPos({
        company: {
          id: selectionInfoUnit.companyId,
          latitude: newMarkerPosition.lat,
          longitude: newMarkerPosition.lng,
        }
      }))
      dispatch(setOldMarkerPosition(null))
      dispatch(setNewMarkerPosition(null))
      setMarkerDrag(false)
      marker.setDraggable(false)
    }
  }

  return (
    <Wrapper
      variants={container}
      initial="hidden"
      animate="show"
      exit='hidden'
    >
      <InnerWrapper
        variants={item}
      >
        <InfoSidebarHeader>
          <MenuWrapper>
            <BurgerMenu toggle={true} toggleHandler={() => {
              const returnedIcon = marker.sideIcon ? marker.sideIcon : nativeIcon
              marker.setIcon(returnedIcon)
              dispatch(resetInfoUint())
              //setDataIsLoad(false)
              if(markerDrag) resetMarkerReposition()
            }}/>
          </MenuWrapper>
        </InfoSidebarHeader>
        <InfoSidebarContentDetails>
          <Header>
            <p>ID:{companyId}</p>
            
            {newCompanyMarker && <NewCompanyMarker src={redStar}/>}
            <DirectedCourier>
              <p>{courierName || 'Не назначен'}</p>
              <DeliveryStatus url={typeof marker.icon === 'object' ? marker.icon.url : marker.icon}/>
              {deliveryTimeCut}
            </DirectedCourier>
          </Header>
          {namePeriods.map((item)=>
          <p key={item.id}>Период:{`${item.timeFrom.slice(0,-3)}-${item.timeTo.slice(0,-3)}`}</p>
          )}
          <CompanyInfo>
            <TextInfo>
              <CompanyName>
                {companyName}
              </CompanyName>
              <CompanyAddress>
                <PointWrapper>
                  <Point/>
                </PointWrapper>
                <p>{companyAddress}</p>
              </CompanyAddress>
            </TextInfo>
            {
              markerDrag ? (
                <DragPointControl>
                  <AcceptNewPos posIsChange={!!newMarkerPosition} onClick={ApplyMarkerReposition}>
                    Принять
                  </AcceptNewPos>
                  <CancelToNativePos onClick={resetMarkerReposition}>
                    Отменить
                  </CancelToNativePos>
                </DragPointControl>
              ) : (
                <DragPointButton isDragg={markerDrag} onClick={() => {
                  dispatch(setOldMarkerPosition({lat: marker.position.lat(), lng: marker.position.lng()}))
                  marker.setDraggable(!marker.draggable)
                  setMarkerDrag(prevState => !prevState)
                }}>
                  <BlackPoint/>
                </DragPointButton>
              )
            }

          </CompanyInfo>
          {
            (dataIsLoad && detailedOrderInfo) && (
          <Comments parentOrderID={detailedOrderInfo.persons[0].orders[0].parentOrder}
          centerIds = {detailedOrderInfo.persons[0].orders[0].centerId} 
          {...{detailedOrderInfo,ordId, companyId}}/>
           )
          }
          {
            dataIsLoad && (
              <AboutOrder companyData={selectionInfoUnit}/>
            )
          }
          {
            (dataIsLoad && detailedOrderInfo) && (
              <OrderedUsersListWrapper>
                <OrderedUsersList usersList={detailedOrderInfo.persons}/>
              </OrderedUsersListWrapper>
            )
          }
        </InfoSidebarContentDetails>
      </InnerWrapper>
    </Wrapper>
  )
}

export default memo(DetailedInfo);

const Wrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  padding: 5px 10px;
  z-index: -1;

  p {
    margin: 0;
    text-align: left;
  }
`

const InfoSidebarContentDetails = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 56px);
  padding-right: 5px;
  gap: 10px;
`
const InnerWrapper = styled(motion.div)`
  height: 100%;
`

const MenuWrapper = styled(motion.div)`
  margin-left: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    margin: 0;
    font-size: 20px;
    line-height: 23px;
  }
`
const DirectedCourier = styled.div`
  display: flex;
  
  >p {
    margin-right: 5px;
  }
`

const CompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`


const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
`

const DragPointControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`
const ChangePosBtn = styled.button`
  background: none;
  padding: 1px 3px;
  font-size: 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`
const AcceptNewPos = styled(ChangePosBtn)`
  :hover{
    background: ${({posIsChange}) => posIsChange ? '#3498DB' : '#787878'};
    color: white;
  }
`
const CancelToNativePos = styled(ChangePosBtn)`
  background: #ff7474;
  color: white;
`

const CompanyName = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: #222222;
`

const CompanyAddress = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #999999;
  display: flex;
  align-items: baseline;
  gap: 2.97px
`

const PointWrapper = styled.div`
  width: 9px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DragPointButton = styled.button`
  background: #fff;
  border: 2px solid ${({isDragg}) => isDragg ? '#3498DB' : '#eee'};
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  transition: border .2s ease;
  
  :hover{
    border: 2px solid #3498DB;
  }

  > svg {
    width: 8px;
    height: 12px;
  }
  
`
const OrderedUsersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const NewCompanyMarker = styled.img`
  width: 12px;
  margin: 0 auto 0 10px;
`

const DeliveryStatus = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("${({url}) => url}");
  background-repeat: no-repeat;
  background-position: center -0.5px;
  background-size: 100%;
`
