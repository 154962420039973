import React from "react";
import ContentLoader from "react-content-loader"
import {nanoid} from "nanoid"


const InfoListLoader = (props) => {

  const drawLoaderElements = () => {
    const elemArr = []
    let step = 0
    for(let i = 0; i < 14; i++) {
      elemArr.push(<rect key={nanoid()} x="0" y={step} rx="8" ry="8" width="265" height="55" />)
      step = step + 65
    }
    return elemArr
  }

  return (
    <ContentLoader
      speed={1.5}
      width={265}
      height={900}
      viewBox="0 0 265 100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#9db2e1"
      {...props}
    >
      {
        drawLoaderElements()
      }
    </ContentLoader>
  )
}

export default InfoListLoader;
