import React, {useEffect, useState} from "react";
import Header from "../../components/Warehouse/Header";
import Content from "../../components/Warehouse/Content";
import styled from "styled-components";
import {getRecipient, getSender, resetSelectedRecipient, resetSelectedSender} from "../../actions/WarehouseActions";
import {useDispatch} from "react-redux";


const Warehouse = () => {

    const dispatch = useDispatch()

    const [targetPath, setTargetPath] = useState('storage')

    const defStyle = {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F4F5F9',
        height: '100%',
        paddingTop: 20,
    }

    useEffect(() => {
        dispatch(getRecipient())
        dispatch(getSender())

        return () => {
            dispatch(resetSelectedRecipient())
            dispatch(resetSelectedSender())
        }
    }, [])

    return (
        <div style={defStyle}>
            <Container >
                <Header {...{targetPath, setTargetPath}}/>
                <Content {...{targetPath}}/>
            </Container>
        </div>
    )
}

export default Warehouse;

const Container = styled.div`
  width: 1280px;
  margin: 0 auto;
`
