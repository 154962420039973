import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import {Btn} from '../UI/Btn'
import { motion } from 'framer-motion'
import selectSvg from '../../../images/select.svg'
import StocksItems from './Components/StocksItems'
import StocksModal from './Components/StocksModal'

const mockData = [
    {id:1,title:'NEW',deck:''},
    {id:2,title:'NEW',deck:'qeqwqeqweqeqwe'},
    {id:3,title:'NEW',deck:'qeqwqeqweqeqwe'},
    {id:4,title:'NEW',deck:'qeqwqeqweqeqwe'},

]
const Stocks = () => {
    const [selectedStocks,setSelectedStocks] = useState([])
    const [selectMult,setSelectMult] = useState(true)
    const [showModal,setShowModal] = useState(false)
    useEffect(() => {
      console.log(selectedStocks,'SRAARER')
    }, [selectedStocks])
    const handlerMultipeSelect=()=>{
      setSelectMult(prev=>!prev)
      if(!selectMult){
        setSelectedStocks([])
      }else{
        setSelectedStocks(mockData.map(el=>el.id))
      }
    }
  return (
    <>
    <div style={{display:'flex',flexDirection:'row-reverse',width:'100%',alignItems:'center'}}>
    {selectedStocks.length > 0
    ?
   <BtnDelete>
    Удалить выбранные
   </BtnDelete>
   :
   null
    }
    <BtnAdd >
      Добавить акцию
   </BtnAdd>
   </div>
    <WrapperStocks>
        <HeaderStocks>
          
            <div>
               <img src={selectSvg} onClick={handlerMultipeSelect}/>
            </div> 

            <div style={{textAlign:'start'}} >
               Заголовок новости
            </div>

            <div>
               Дата создания
            </div>
        </HeaderStocks>   
        {
         mockData.map(info=>
            <StocksItems {...{info,setShowModal,setSelectedStocks,selectedStocks}}/> 
        )
        }
        {        
        <StocksModal  {...{showModal,setShowModal}}/>
        }

</WrapperStocks>
</>

  )
}

export default Stocks

const WrapperStocks = styled.div`
background-color: #FFFFFF;
border-radius: 8px;
width: 100%;
height: max-content
`
const HeaderStocks = styled.div`
display: grid;
grid-template-columns: 40px 2fr .6fr ;
border-bottom: 1px solid rgb(169,169,169,0.5);
padding:10px 0px 10px 0px ;
color:#999999;
`
const BtnDelete = styled.div`
background-color: #3498db;
color:#fff;
padding: 15px;
height: 36px;
display: flex;
align-items: center;
cursor: pointer;
transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
` 
const BtnAdd = styled.div`
background-color: #3498db;
color:#fff;
padding: 15px;
height: 36px;
display: flex;
align-items: center;
cursor: pointer;
transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
margin-right: auto;
` 
