import React, {memo, useEffect} from "react";
import styled from "styled-components";
import InfoSidebarUnit from "../ListItemsComponents/InfoUnit"
import {nanoid} from "nanoid"
import {useDispatch, useSelector} from "react-redux"
import {
  getDeliveryPoints,
  redirectCouriers,
  resetSelectedCourier,
  resetSelectedOrdersToRedirect
} from "../../../reducers/toolkit/orderMapReducer"
import moment from "moment"


const OrdersDestinationList = ({selectedOrdersInstances}) => {
  const dispatch = useDispatch()
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)
  const selectedOrdersIds = useSelector(store => store.orderMap.selectedOrdersIds)
  const date = moment(useSelector(store => store.orderMap.date)).format('YYYY-MM-DD')
  const selectedCourier = useSelector(store => store.orderMap.selectedCourier)
  const deliveryPointsFilter = useSelector(store => store.orderMap.deliveryPointsFilter)
  const redirectCouriersStatus = useSelector(store => store.orderMap.redirectCouriersStatus)

  const getDeliveryPointsData = {
    date,
    centerIds:OptionCenter ? 0 : deliveryPointsFilter.selectedDistributionCenterId,
    periodId:OptionCenter ? 0 : deliveryPointsFilter.selectedPeriodId
  }

  const sendRedirectionData = () => {
    const dataObject = {
      orders:selectedOrdersIds,
      courierId: selectedCourier.clientId || selectedCourier.id,
    }
    dispatch(redirectCouriers(dataObject))
  }

  useEffect(() => {
    if(redirectCouriersStatus === 'done') {
      dispatch(getDeliveryPoints(getDeliveryPointsData))
    }
  }, [redirectCouriersStatus]);


  return (
    <Wrapper>
      <Controls>
        <Button apply={true} onClick={sendRedirectionData}>Применить</Button>
        <Button onClick={() => {
          selectedOrdersInstances.map(el => {
            const {nativeIcon} = el.marker
            const {nativeBigIcon} = el.marker
            const returnedIcon = el.marker.isShowing ? nativeBigIcon : nativeIcon
          })
          dispatch(resetSelectedCourier())
          dispatch(resetSelectedOrdersToRedirect())
        }}>Отмена</Button>
      </Controls>
      <Flex>
        {
          selectedOrdersInstances.map((el, id) => el !== null && <InfoSidebarUnit key={nanoid()} redirectMode={true} companyData={el} {...{id}}/>)
        }
      </Flex>
    </Wrapper>
  )
}

export default memo(OrdersDestinationList);

const Wrapper = styled.div`
  border-bottom: 2px solid silver;
`
const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
`

const Button = styled.button`
  cursor: pointer;
  background: ${({apply}) => apply ? '#85ff7a' : '#ff7a7a'};
  padding: 7px 5px;
  border: none;
  border-radius: 8px;
  font-weight: ${({apply}) => apply ? 400 : 700};
  
  :hover{
    background: ${({apply}) => apply ? '#5db159' : '#b55454'};
    color: white;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
