import React from "react";
import styled from "styled-components";
import * as _ from "underscore";
import {Select} from "antd";

const {Option} = Select;

const SimpleSelect = props => {
    const {data, selected, handleChange, title, allowClear, style, itemName, itemValue} = props;

    return (
        <Container>
            {title ? <Title>{title}</Title> : null}
            <Select
                value={selected}
                onChange={value => handleChange(value)}
                allowClear={allowClear}
                style={style}
            >
                {_.size(data) > 0 && data.map((item, index) => {
                    return (
                        <Option key={'option_item_' + index} value={itemValue ? item[itemValue] : item}>
                            {itemName ? item[itemName] : item}
                        </Option>
                    )
                })}
            </Select>
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
    
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Title = styled.p`
    margin-bottom: 4px;
    color: #666666;
`;

export default SimpleSelect;
