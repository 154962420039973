import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const AdminSwitch = (props) => {
  
  const defaultStyleSwitch = {
    plug:{
      width:'27px',
      height:'27px',
      background: '#FFFFFF',
      borderRadius: '100%',
    },
    background:{},
  }
  const [check,setCheck] = useState(false)
  const x =  props.check ? props.check : check
  // const {plug,background,animation} = props.custom 

  const toggleHandler = () =>{
  if(!props.disable){
    if(props.check && props.setCheck){
      props.setCheck()
    }else{
    setCheck(!check)
    }
  }
}


  return (
    <WrapperSwitch>
          <BackGroundSwitch onClick={props.setCheck} xs={!!x} disable={props.disable} >
                <PlugSwitch xs={!!x} animate={{x: x ? 22 : 2}}>
                </PlugSwitch>
          </BackGroundSwitch>
    </WrapperSwitch>
  )
}

export default AdminSwitch


const WrapperSwitch = styled.div`
width: 51px;
height: 31px;
`
const BackGroundSwitch = styled(motion.div)`
position: relative;
width: 100%;
height: 100%;
border-radius: 25px;
transition: all .2s ease-in-out;
cursor:${({disable})=> disable ? 'not-allowed' :'pointer'};
background:${({xs})=> !xs ?' rgba(120, 120, 128, 0.16)':'#7882A7'};
`
const PlugSwitch =  styled(motion.div)`
position: absolute;
bottom: 2px;
/* left:${({xs})=> xs ?'2px' : '22px'}; */
width:27px;
height:27px;
background: #FFFFFF;
border-radius: 100%;
/* transition: all .4s ease-in-out; */
box-shadow:${({xs})=> xs && '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)'};
`