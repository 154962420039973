import React, { useState, memo } from "react";
import styled from "styled-components";
import SidebarNav from "./SidebarNav";
import Logo from "../components/Logo";

const Sidebar = ({ path, setPath, testPath }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Burger onClick={toggleSidebar} isOpen={isOpen}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
          <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
          <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
          <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </Burger>
      <SidebarWrapper isOpen={isOpen}>
        <CloseButton onClick={toggleSidebar}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="40px" height="40px" viewBox="0 0 512 512">
            <title>ionicons-v5-m</title>
            <path d="M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z"/>
          </svg>
        </CloseButton>
        <div style={{ marginBottom: 28 }}>
          <Logo {...{ path, setPath }} />
        </div>
        <SidebarNav {...{ path, setPath, testPath }} />
      </SidebarWrapper>
    </>
  );
};

export default memo(Sidebar);

const SidebarWrapper = styled.div`
  padding: 12px;
  background: white;
  height: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 100;
  position: fixed;
  /* overflow: hidden; */
  left: ${({ isOpen }) => (isOpen ? "0" : "-250px")};
  transition: left 0.15s;

  @media (min-width: 769px) {
    left: 0;
  }
`;

const Burger = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  z-index: 101;
  cursor: pointer;
  display: ${({ isOpen }) => (isOpen ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  background: #F4F5F9;
  border: 1px solid #DDDDDD;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15); */

  @media (min-width: 769px) {
    display: none;
  }
`;

const CloseButton = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 102;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 5px;
  }
`;