import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion"
import {toggleModal} from "../../pages/Delivery/OrderMap"

const ModalWrapper = ({children}) => {

  return (
    <Wrapper
      onClick={toggleModal}
      init={{
        opacity: 0
      }}
      animate={{
        opacity: 1
      }}
    >
      <ModalContent onClick={e => e.stopPropagation()}>
        {
          children
        }
      </ModalContent>
    </Wrapper>
  )
}

export default ModalWrapper;

const Wrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.41);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
const ModalContent = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 10px;
`
