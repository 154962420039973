import React,{useState,useEffect } from "react";

export function useBase(){
    const [fileBase,setFileBase]=useState()

    const loadPhoto =(info)=>{
        getBase(info.file.originFileObj,imageUrl=>{
            setFileBase(imageUrl)
        })
    }   
    const getBase=(file,cb)=>{
        const reader = new FileReader();
        reader.onload = () => cb(reader.result);
        reader.readAsDataURL(file);
    }
    useEffect(()=>{
        if(fileBase){
            loadPhoto(fileBase)
        }
        },[])
    
    return [fileBase,loadPhoto]
}