import React, { memo, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { modalInfo, selectCategory } from "../../actions/AssortmentAction";
import { moderationMode } from "../../actions/AssortmentAction";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Shield from "../../images/assort/GRAY.svg";

const CategoriesItem = ({
  category,
  selectedCategory,
  id,
  setAddProductFieldShow,
  addProductFieldShow,
  setOpenModal,
  scrollsRef,
  mainScrollRef,
}) => {
  const pl = useSelector((state) => state.assortment.productList);
  const openModal = useSelector((store) => store.assortment.modalInfo);
  const modLength = useSelector((store) => store.assortment.prodMod);
  const LengthModeration = modLength
    .map((el) => el?.categoryId === selectedCategory?.id)
    .filter((e) => e).length;
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );

  const dispatch = useDispatch();

  const selectCategoryHandle = () => {
    // console.log(mainScrollRef,'scrollsRefscrollsRef')
    // mainScrollRef && mainScrollRef.current.scrollTo(0,0)
    window.scrollTo(0, 0);
    // mainScrollRef.current.scrollTo(0,0)
    if (SelectedKitchens.name !== "Не выбрано") {
      dispatch(selectCategory(id));
      dispatch(moderationMode(false));
      //  setOpenModal(false)
      dispatch(modalInfo(false));
      if (addProductFieldShow) {
        setAddProductFieldShow(false);
      }
    }
  };
  useEffect(() => {
    dispatch(selectCategory({}));
  }, [SelectedKitchens]);

  return (
    <CategoriesItemListElement
      onClick={selectCategoryHandle}
      isSelect={
        SelectedKitchens.name !== "Не выбрано" &&
        selectedCategory?.name === category?.name
      }
    >
      <InnerWrapper>
        <div>
          <p>
            {category?.name?.ru} • {category.numberOfProductsInTheCategory}{" "}
            {selectedCategory?.name === category?.name && ` • `}{" "}
            {selectedCategory?.name === category?.name ? (
              <img src={Shield} />
            ) : null}{" "}
            {selectedCategory?.name === category?.name && LengthModeration}{" "}
          </p>
        </div>
        <div>
          <DragNDropMark />
        </div>
      </InnerWrapper>
    </CategoriesItemListElement>
  );
};

export default CategoriesItem;

const CategoriesItemListElement = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  height: auto;
  background: ${({ isSelect }) => (isSelect ? "#7882A7" : "#FFFFFF")};
  margin-bottom: 16px;
  border-radius: 8px;
  font-style: normal;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ isSelect }) => (isSelect ? "#FFFFFF" : "#2C2E32")};
  cursor: pointer;
`;
const InnerWrapper = styled.div`
  padding: 15px 0px 14px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DragNDropMark = styled.div`
  width: 20px;
  height: 6px;
  position: relative;
  margin-right: 21px;
  &:after {
    content: "";
    margin-top: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 2px;
    border-radius: 6px;
    background: #c4c4c4;
  }
  &:before {
    content: "";
    margin-bottom: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 2px;
    border-radius: 6px;
    background: #c4c4c4;
  }
`;
