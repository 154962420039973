import {createAction, createSlice} from "@reduxjs/toolkit"
import moment from "moment"

export const mode = {
  'DEF': 'default',
  'DEST': 'dest',
}

const initialState = {
  //can be only ('default', 'dest')
  mode: mode.DEF,
  //------------------------------
  date: moment(new Date()), //always moment instance
  deliveryPointsFilter: {
    selectedCityId: localStorage.getItem('selectedCity') || null,
    selectedDistributionCenterId: null,
    selectedPeriodId: null,
  },
  deliveryPointsErrorMessage: null,
  deliveryPointsStatus: null,
  deliveryPoints: [],
  couriers: [],
  kitchens: [],
  centers:[],
  centerChange:0,
  filtering: false,
  filteredDeliveryPoints: [],
  selectionInfoUnit: null,
  showInfoSidebar: false,
  totalOrders: [0, 0],
  totalTimes: ["", ""],
  totalDistance: 0,
  totalSum: 0,
  totalSalary: 0,
  totalCash: 0,
  selectedCourier: null,
  selectedOrdersIds: [],
  selectedOrdersInstances: [],
  redirectCouriersStatus: null,
  redirectCouriersMessage: null,
  detailedOrderInfo: null,
  centerInfo:{},
  getDetailedOrderInfoStatus: null,
  getCenterInfoStatus:null,
  centerInfoUpdateStatus: null,
  targetCourierForTrackDraw: null,
  showCourierInfo: false,
  courierInfoTargetCourier: null,
  courierInfoTargetCourierId: null,
  getCouriersTracksStatus: null,
  getCouriersTracksErrorMessage: null,
  couriersTracks: [],
  oldMarkerPosition: null,
  newMarkerPosition: null,
  drawingMode: false,
  polygon: null,
  polygonMode: null,
  courierRoute: [],
  getCourierRouteStatus: false,
  courierRoutePlan: [],
  showModal: false,
  activeModal: null,
  //google apis shit
  map: null,
  maps: null,
  zoom: 12,
  center: {lat: 53.5295, lng: 49.3460},

   OptionCenter:false,
   OptionCenterStatus:''
}

export const getPermissionsAction = createAction('appMain/getPermissions')

const orderMapReducer = createSlice({
  name: 'appMain',
  initialState,
  reducers: {
    setOptionCenter(state,action){     
      state.OptionCenter = action.payload
    },
    setMode(state, action) {
      state.mode = action.payload
    },
    setDate(state, action) {
      state.date = action.payload
    },
    setPeriod(state, action) {
      state.deliveryPointsFilter.selectedPeriodId = action.payload
    },
    setDistributionCenter(state, action) {
      state.deliveryPointsFilter.selectedDistributionCenterId = action.payload
    },
    setCity(state, action) {
      state.deliveryPointsFilter.selectedCityId = action.payload
      state.deliveryPointsFilter.selectedDistributionCenterId = initialState.selectedDistributionCenterId
    },
    selectInfoUint(state, action) {
      if(state.selectionInfoUnit) {
        state.selectionInfoUnit.marker.isShowing = false
        state.selectionInfoUnit.marker.setZIndex(state.selectionInfoUnit.marker.nativeZIndex)
        state.selectionInfoUnit.marker.setDraggable(false)
        state.selectionInfoUnit.marker.setIcon(state.selectionInfoUnit.marker.sideIcon || state.selectionInfoUnit.marker.nativeIcon)

        //reset reposition if selectionInfoUnit change without apply marker reposition
        if(state.oldMarkerPosition) {
          state.selectionInfoUnit.marker.setPosition(state.oldMarkerPosition)
          state.newMarkerPosition = initialState.newMarkerPosition
          state.oldMarkerPosition = initialState.oldMarkerPosition
        }
      }
      action.payload.marker.isShowing = true
      state.selectionInfoUnit = action.payload
      action.payload.marker.setIcon(action.payload.marker.sideBigIcon || action.payload.marker.nativeBigIcon)
      action.payload.marker.setZIndex(999)
    },
    resetInfoUint(state) {
      state.selectionInfoUnit.marker.setZIndex(state.selectionInfoUnit.marker.nativeZIndex)
      state.selectionInfoUnit.marker.isShowing = false
      state.selectionInfoUnit.marker.setDraggable(false)
      state.selectionInfoUnit = initialState.selectionInfoUnit
    },
    getDeliveryPoints(state) {
      state.deliveryPointsStatus = 'pending'
      state.selectedCourier = initialState.selectedCourier
      state.deliveryPointsErrorMessage = initialState.deliveryPointsErrorMessage
      state.deliveryPoints = initialState.deliveryPoints
      state.selectionInfoUnit = initialState.selectionInfoUnit

      //reset if request in action
      state.totalOrders = initialState.totalOrders
      state.totalTimes = initialState.totalTimes
      state.totalDistance = initialState.totalDistance
      state.totalSum = initialState.totalSum
      state.totalSalary = initialState.totalSalary
      state.totalCash = initialState.totalCash
      state.redirectCouriersStatus = initialState.redirectCouriersStatus
      state.selectedCourier = initialState.selectedCourier
      state.showCourierInfo = initialState.showCourierInfo
      state.courierInfoTargetCourier = initialState.courierInfoTargetCourier
      state.courierInfoTargetCourierId = initialState.courierInfoTargetCourierId
      state.newMarkerPosition = initialState.newMarkerPosition
    },
    setDeliveryPoints(state, action) {
      state.deliveryPointsStatus = 'resolved'
      state.deliveryPoints = action.payload.map(el=> el.courierId === null ? ({...el,color:getPersonColor(el.companyId)}):({...el}))

    },
    getDeliveryPointsError(state, action) {
      state.deliveryPointsStatus = 'rejected'
      state.deliveryPoints = initialState.deliveryPoints
      state.deliveryPointsErrorMessage = action.payload
    },
    setFiltering(state, action) {
      state.filtering = action.payload
    },
    setFilteredDeliveryPoints(state, action) {
      state.filteredDeliveryPoints = action.payload
    },
    setCouriers(state, action) {
      state.couriers = action.payload.map(el => ({...el, color: getPersonColor(el.id)}))
    },
    setKitchens(state, action) {
      state.kitchens = action.payload
    },
    toggleInfoSidebar(state, action) {
      state.showInfoSidebar = action.payload ? action.payload : !state.showInfoSidebar
    },
    setMap(state, action) {
      state.map = action.payload
    },
    setMaps(state, action) {
      state.maps = action.payload
    },
    setZoom(state, action) {
      state.zoom = action.payload
    },
    setCenter(state, action) {
      state.center = action.payload
    },
    setTotalData(state, action) {
      state.totalOrders = action.payload.totalOrders
      state.totalTimes = action.payload.time
      state.totalDistance = action.payload.distance
      state.totalSum = action.payload.sum
      state.totalSalary = action.payload.salary
      state.totalCash = action.payload.cash
    },
    setSelectedCourier(state, action) {
      if(action.payload?.color) {
        state.selectedCourier = action.payload
      } else {
        state.selectedCourier = {...action.payload, color: getPersonColor(action.payload)}
      }
    },
    resetSelectedCourier(state) {
      state.selectedCourier = initialState.selectedCourier
      state.newMarkerPosition = initialState.newMarkerPosition
    },
    toggleSelectedOrdersToRedirect(state, action) {
      const orderInstance = action.payload
      const orderId = orderInstance.ordId
      const markerInstance = orderInstance.marker

      if(state.selectedOrdersIds.includes(orderId)){
        markerInstance.setIcon(markerInstance.nativeIcon)
        markerInstance.sideIcon = null
        markerInstance.sideBigIcon = null
        state.selectedOrdersInstances = state.selectedOrdersInstances.filter(el => el.ordId !== orderId)
        state.selectedOrdersIds = state.selectedOrdersIds.filter(el => el !== orderId)
      } else {
        markerInstance.setIcon(markerInstance.isShowing ? markerInstance.sideBigIcon : markerInstance.sideIcon)
        state.selectedOrdersIds = [...state.selectedOrdersIds, orderId]
        state.selectedOrdersInstances = [...state.selectedOrdersInstances, orderInstance]
      }
    },
    resetSelectedOrdersToRedirect(state) {
      state.selectedOrdersInstances.map(el => {
        if(el.marker.isShowing) {
          el.marker.setIcon(el.marker.nativeBigIcon)
        } else {
          el.marker.setIcon(el.marker.nativeIcon)
          if(!el.marker.isComplete) {
            el.marker.setZIndex(el.marker.nativeZIndex)
          }
        }

        el.marker.sideIcon = null
        el.marker.sideBigIcon = null
      })
      state.selectedOrdersInstances = initialState.selectedOrdersInstances
      state.selectedOrdersIds = initialState.selectedOrdersIds
    },
    redirectCouriers(state) {
      state.redirectCouriersStatus = 'inAction'
    },
    getCenterInfoUpdate(state){
      state.centerInfo = {}
      state.centerInfoUpdateStatus = 'pending'
    },
    // setCenterInfoUpdate(state,action){
    //   if(action.payload.status === 'resolved'){
    //     state.centerInfo = action.payload.data
    //     state.centerInfoUpdateStatus = action.payload.status
    //   }
    //   else{
    //     state.centerInfo = initialState.centerInfo
    //     state.centerInfoUpdateStatus = action.payload.status
    //   }
    // },
    
    getCenterInfo(state){
      state.centerInfo = {}
      state.getCenterInfoStatus = 'pending'
    },
    setCenterInfo(state,action){
      if(action.payload.status === 'resolved'){
        state.centerInfo = action.payload.data
        state.getCenterInfoStatus = action.payload.status
        state.centerInfoUpdateStatus = action.payload.status
      }
      else{
        state.centerInfo = initialState.centerInfo
        state.getCenterInfoStatus = action.payload.status
        state.centerInfoUpdateStatus = action.payload.status
      }
    },
    setRedirectCouriersResult(state, action) {
      state.redirectCouriersStatus = 'done'
    },
    getDetailedOrderInfo(state) {
      state.detailedOrderInfo = null;
      state.getDetailedOrderInfoStatus = 'pending'
    },
    setDetailedOrderInfo(state, action) {
      if(action.payload.status === 'resolved') {
        state.detailedOrderInfo = action.payload.data
        state.getDetailedOrderInfoStatus = action.payload.status
      } else {
        state.detailedOrderInfo = initialState.detailedOrderInfo
        state.getDetailedOrderInfoStatus = action.payload.status
      }
    },
    updateAddrComment(state, action) {
      state.detailedOrderInfo.commentAddr = action.payload.comment
    },
    updateOrderComment(state, action) {
      state.detailedOrderInfo.commentOrder = action.payload.comment
    },
    toggleShowCourierInfo(state, action) {
      if(action.payload) {
        state.courierInfoTargetCourier = action.payload
        state.courierInfoTargetCourierId = action.payload.id || action.payload.clientId
      } else {
        state.courierInfoTargetCourier = initialState.courierInfoTargetCourier
        state.courierInfoTargetCourierId = initialState.courierInfoTargetCourierId
      }
      state.showCourierInfo = !state.showCourierInfo
    },
    toggleShowCourierTrack(state, action) {
      if(action.payload) {
        state.targetCourierForTrackDraw = action.payload
      } else {
        state.targetCourierForTrackDraw = initialState.targetCourierForTrackDraw
      }
    },
    getCourierTrack(state) {
      state.couriersTracks = initialState.couriersTracks
      state.getCouriersTracksErrorMessage = initialState.getCouriersTracksErrorMessage
      state.getCouriersTracksStatus = 'pending'
    },
    setCourierTrack(state, action) {
      if(action.payload.status === 'resolved') {
        state.couriersTracks = Object.values(action.payload.data)[0]
      } else {
        state.getCouriersTracksErrorMessage = action.payload.message
      }
      state.getCouriersTracksStatus = action.payload.status
    },
    setNewMarkerPosition(state, action) {
      if(!action.payload) {
        state.newMarkerPosition = initialState.newMarkerPosition
      } else {
        state.newMarkerPosition = action.payload
      }
    },
    setOldMarkerPosition(state, action) {
      if(!action.payload) {
        state.oldMarkerPosition = initialState.oldMarkerPosition
      } else {
        state.oldMarkerPosition = action.payload
      }
    },
    sendNewMarkerPos(state, action) {
      state.newMarkerPosition = initialState.newMarkerPosition
      state.oldMarkerPosition = initialState.oldMarkerPosition
    },
    setDrawingMode(state, action) {
      if(action.payload) {
        state.drawingMode = true
      } else {
        state.drawingMode = initialState.triangulationMode
      }
    },
    setPolygon(state, action) {
      if(state.polygon) {
        state.polygon.setMap(null)
      }
      if(action.payload) {
        state.polygon = action.payload
      } else {
        state.polygon = initialState.polygon
      }
    },
    setPolygonMode(state, action) {
      state.polygonMode = action.payload
    },
    getCourierRoute(state) {
      state.courierRoute = []
      state.getCourierRouteStatus = false
    },
    setCourierRoute(state, action) {
      state.courierRoute = action.payload.data
      state.getCourierRouteStatus = true
    },
    resetCourierRoute(state) {
      state.courierRoute = []
      state.getCourierRouteStatus = false
    },
    setCourierRoutePlan(state, action) {
      state.courierRoutePlan = action.payload
    },






    //modals only
    toggleModalWrapper(state, action) {
      state.showModal = !state.showModal
      state.activeModal = action.payload?.modalName || null
    },
  }
})

export const {
  setOptionCenter,
  setMode,
  setDate,
  setPeriod,
  setDistributionCenter,
  setCity,
  selectInfoUint,
  resetInfoUint,
  getDeliveryPoints,
  setDeliveryPoints,
  getDeliveryPointsError,
  setFilteredDeliveryPoints,
  setFiltering,
  setCouriers,
  setKitchens,
  toggleInfoSidebar,
  setMap,
  setMaps,
  setZoom,
  setCenter,
  setTotalData,
  setSelectedCourier,
  resetSelectedCourier,
  toggleSelectedOrdersToRedirect,
  resetSelectedOrdersToRedirect,
  updateAddrComment,
  updateOrderComment,
  toggleShowCourierTrack,
  redirectCouriers,
  setRedirectCouriersResult,
  getDetailedOrderInfo,
  setDetailedOrderInfo,
  getCenterInfoUpdate,
  getCenterInfo,
  setCenterInfo,
  toggleShowCourierInfo,
  getCourierTrack,
  setCourierTrack,
  setNewMarkerPosition,
  setOldMarkerPosition,
  sendNewMarkerPos,
  setDrawingMode,
  setPolygon,
  setPolygonMode,
  getCourierRoute,
  setCourierRoute,
  resetCourierRoute,
  setCourierRoutePlan,
  toggleModalWrapper,
} = orderMapReducer.actions
export default orderMapReducer.reducer

export const getPersonColor = (persId) => {
  if (persId == null) {
    return 'white';
  }
  if (typeof (getPersonColor.assignedColors) === 'undefined') {  // начальная инициализация
    getPersonColor.colorsList = [
      {c: 'red'},
      {c: 'green'},
      {c: 'blue'},
      {c: 'purple'},
      {c: 'yellow'},
      {c: 'orange'},
      {c: 'pink'},
      {c: 'lightBlue'},
      {c: 'indigo'},
      {c: 'fuchsia'},
      {c: 'brown'},
      {c: 'Lime'},
      {c: 'Olive'},
      {c: 'Teal'},
      {c: 'MediumVioletRed'},
      {c: 'Chocolate'},
      {c: 'GreenYellow'},
      {c: 'DarkSlateBlue'},
      {c: 'grey'},
    ];
    getPersonColor.currentColor = 0;
    getPersonColor.assignedColors = {};
  }
  if (!getPersonColor.assignedColors[persId]) {
    getPersonColor.assignedColors[persId] = getPersonColor.colorsList[getPersonColor.currentColor] === undefined ? {c: null} : getPersonColor.colorsList[getPersonColor.currentColor];
    if (getPersonColor.currentColor === 18) {
      getPersonColor.currentColor = 0;
    } else {
      getPersonColor.currentColor++;
    }
  }
  return getPersonColor.assignedColors[persId].c;
}
