import React from "react";
import styled from "styled-components";
import {Select} from "antd";
import * as moment from "moment";
import {useDispatch} from "react-redux";
import {initCopySend, setCopyDataDate, setCopyDataPeriod} from "../../actions/MenuByDayActions";


const CopyPeriodsControl = ({dateRange, activePeriod, copyMenuData, dayRange = [], periods = []}) => {

    const dispatch = useDispatch();
    const {Option} = Select;

    const dateSelectHandler = (e) => {
        dispatch(setCopyDataDate({date: e}))
    };
    const periodSelectHandler = (e) => {
        dispatch(setCopyDataPeriod({periodId: e}))
    };
    const copyButtonHandle = () => {
        dispatch(initCopySend({
            restaurantId: activePeriod.activeRest,
            dateFrom: moment(copyMenuData.date).format("YYYY-MM-DD"),
            periodFrom: copyMenuData.periodId,
            dateTo: moment(activePeriod.date).format("YYYY-MM-DD"),
            periodTo: activePeriod.periodId,
            dateRange: dateRange,
        }))
    };

    return (
        <CopyPeriodsControlWrapper>
            <p>Скопировать с</p>
            <Select
                style={{width: 200, marginLeft: 10}}
                value={copyMenuData.date ? moment(copyMenuData.date).format("MMM DD") : null}
                onChange={dateSelectHandler}
            >
                {dayRange.map((v, idx) => {
                    return (
                        <Option key={idx + "key_qsdfasdf"} value={v}>
                            {moment(v).format("MMM DD")}
                        </Option>
                    )
                })}
            </Select>
            <Select
                style={{width: 200, marginLeft: 10}}
                value={ copyMenuData.periodId ? `${(periods[+copyMenuData.periodId - 1].timeFrom).split(":").slice(0, 2).join(":")} - ${(periods[+copyMenuData.periodId - 1].timeTo).split(":").slice(0, 2).join(":")}` : null}
                onChange={periodSelectHandler}
            >
                {periods.map((v, idx) => {
                    return (
                        <Option key={idx + "key_ajlsdfi3uef"} value={v.id}>
                            {`${v.timeFrom.split(":").slice(0, 2).join(":")} - ${v.timeTo.split(":").slice(0, 2).join(":")}`}
                        </Option>
                    )
                })}
            </Select>
            <CopyButton style={{marginLeft: 10}} onClick={copyButtonHandle}>Скопировать</CopyButton>
        </CopyPeriodsControlWrapper>
    )
};

const CopyPeriodsControlWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin: 0 auto;
  transition: background-color .2s ease;
  
  & p {
    margin: 0;
  }
  & select{
    margin: auto 0;
  }
`
const CopyButton = styled.button`
  padding: 7px 25px;
  border: none;
  background: #FFD600;
  border-radius: 6px;
  cursor: pointer;
  
  :hover {
    background: #fffb0d;
  }
`

export default CopyPeriodsControl;
