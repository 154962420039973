import '../../wdyr'
import React, {
  useEffect,
  useState,
  useRef,
  memo,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import {
  DEFAULT_INPUT_STYLE,
  DEFAULT_INPUT_STYLE_FOR_AREA,
  GIRD_CONSISTENCY_SETTINGS,
  PRODUCT_LIST_ELEMENT_CONTAINER,
  PRODUCT_LIST_ELEMENT_CONTAINER2,
} from "./commonStyles";
import { Switch } from "antd";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToSelect,
  changeStateProduct,
  modalInfo,
  removeProductFromSelect,
  saveNewProductStarted,
  saveProductChangesStarted,
} from "../../actions/AssortmentAction";
import { Upload } from "antd";
import { FormControls } from "./UtilityComponents";
import Arrow from "../../images/arrow.svg";
import Err from "../../images/Err.svg";
import moment from "moment";
import { sr } from "../../utils";
import CollapsibleBlock from "./common/CollapsibleBlock";
import FadeInOut_Y_ShiftHOC from "./common/FadeInOut_Y_ShiftHOC";
import { AnimatePresence, m } from "framer-motion";
import TextTruncate from 'react-text-truncate';
import { InputInfo } from "./Components/InputInfo";
import ImageContainer from "./Components/ImageUI/ImageContainer";
import { deliveryCycleInfoMessage } from "../../actions/ModalActions";
import ModalInfo from "./Components/ModalItems/ModalInfo";
import Check from "../../images/assort/Chield_check_light.svg";
import AlarmErr from "../../images/assort/Alarm_light.svg";
import Pen from "../../images/assort/Ассортимент/Edit_light.svg"
import LightPen from '../../images/assort/Ассортимент/Edit_light.png'
function ProductListItem({
  ActualVersion,
  selectedCategory,
  addNewMode,
  setAddProductFieldShow,
  productItem = [{}],
  id,
  moderationItem = [{}],
  addProductFieldShow,  
  setOpenModal,
  setAloneProduct,
  aloneProduct,
  styleList
}) {
  const dispatch = useDispatch();
  const selectedProducts = useSelector(
    (store) => store.assortment.selectedProducts
  );
  const statusSaveNew = useSelector((store) => store.assortment.saveProduct);
  const saveProductChanges = useSelector((store) => store.assortment.saveProductChanges);
  const selectKithens = useSelector((store) => store.assortment.selectKithens);
  const adminMode = useSelector((store) => store.assortment.ModerationMode);
  const tegList = useSelector((store) => store.assortment.tegList);
  const [productName, setProductName] = useState("");
  const [nameContent, setNameForContent] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCalories, setProductCalories] = useState("");
  const [productFats, setProductFats] = useState("");
  const [productCarbo, setProductCarbo] = useState("");
  const [productProteins, setProductProteins] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [productCategoriesState, setProductCategoriesState] = useState([]);
  const [tagId, setTegId] = useState([]);
  const [IsOpen, setIsOpen] = useState(!!addNewMode);
  const [isSelect, setIsSelect] = useState(selectedProducts.includes(id));
  const [editProduct, seteditProduct] = useState("");
  const [getsPhoto, setGetsPhoto] = useState();
  const [disButton, setDisButton] = useState(true);
  const [temporaryPic, setTemporaryPic] = useState();
  const [stateBase, setStateBase] = useState();
  const [stateMedia, setStateMedia] = useState();
  const [stateTegProduct, setTegsProduct] = useState();
  const [activeState, setActiveState] = useState(false);
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const openModal = useSelector(
    (store) => store.assortment.modalInfo
  );


  // const [openModal,setOpenModal] = useState(false)
  const clearPhoto = () => {
    setTemporaryPic("");
    setStateBase("");
    setStateMedia("");
    setGetsPhoto("");
  };
  const disAndEnable=()=>{
    if('id_product' in productItem){
    disAndEnProduct( {enab:productItem.isEnabled === '1' ? '0' : '1',flags:'mod'})
    }
    else{
    disAndEnProduct(  {enab:productItem.isEnabled === '1'  ? '0' : '1',flags:'notMod'})
    }
    
  }
  const memoClear = useCallback(() => {
    return clearPhoto();
  }, [getsPhoto, stateBase, stateMedia, temporaryPic]);

  useEffect(() => {
    if (IsOpen) {
      setDisButton(false);
    }
  }, [
    productName,
    productDescription,
    productPrice,
    productCalories,
    productFats,
    productCarbo,
    productProteins,
    productWeight,
    getsPhoto,
    ,
    tagId,
  ]);

  useEffect(() => {
    if (selectedCategory) {
      if (!productCategoriesState.includes(selectedCategory)) {
        setProductCategoriesState([selectedCategory]);
      }
    }
  }, [selectedCategory]);


  function disAndEnProduct(val) {
    const setStore = (mod,enab,data) =>{
      if(mod === 'forMod'){
      dispatch(
        changeStateProduct({
          ids: data,
          enables: val.enab,
          mod: "forMod",
          id_mod: productItem.id_product,
        })
      )
      dispatch(deliveryCycleInfoMessage('modeCustom',enab === 0 ?'Товар включен': 'Товар выключен'))
    }else{
      dispatch(
        changeStateProduct({
          ids: data,
          enables: val.enab,
          mod: "NotMod",
        })
      )
      dispatch(deliveryCycleInfoMessage('modeCustom',enab === 0 ?'Товар включен': 'Товар выключен'))
    }
  }
  // res.data.data.id
    if (val.flags === "mod") {
      // console.log('1');
      if (val.enab === "0") {
        // console.log('2');
        sr("MenuSOKitchenService", "enableProduct", {
          id:
            productItem.moderate_status === "1" ||
            productItem.moderate_status === "2"
              ? productItem.id_product
              : productItem.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? 
            setStore('forMod',0,res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      } else {
        // console.log('3');
        if (productItem.moderate_status === "2") {
          sr("MenuSOKitchenService", "disableProduct", {
            id: productItem.id_product,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? 
            setStore('forMod',1,res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
      }
      if (
        productItem.moderate_status === "1" ||
        "moderate_status" in productItem
      ) {
        // console.log('4');
        sr("MenuSOKitchenService", "disableProduct", {
          id: productItem.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ?   
            setStore('forMod',1,res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      } else {
        // console.log('5');
        sr("MenuSOKitchenService", "disableProduct", {
          id: productItem.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? 
            setStore('forMod',1,res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      }
    } else if (val.flags === "notMod") {
      // console.log('6');
      if (val.enab === "0") {
        // console.log('7');

        sr("MenuSOKitchenService", "enableProduct", {
          id:
            productItem.moderate_status === "1"
              ? productItem.id_product
              : productItem.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? 
            setStore('NotMod',0,res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      }
      if (val.enab === "1") {
        // console.log('8');
        if (productItem.moderate_status === "2") {
          sr("MenuSOKitchenService", "disableProduct", {
            id: productItem.id_product,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? 
            setStore('NotMod',1,res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
        if (
          productItem.moderate_status === "1" ||
          "moderate_status" in productItem
        ) {
          // console.log('9');
          sr("MenuSOKitchenService", "disableProduct", {
            id: productItem.id,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? 
            setStore('NotMod',1,res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        } else {
          // console.log('10');
          sr("MenuSOKitchenService", "disableProduct", {
            id: productItem.id,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? 
            setStore('NotMod',1,res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
      }
    }
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(img);
  }
  const loadPhoto = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      if (info.file.size <= 1000000) {
        setGetsPhoto(imageUrl);
      } else {
        alert("Размер картинки слишком большой");
      }
    });
  };
  useEffect(() => {
    // console.log(productCategoriesState,'productCategoriesState.includes')
    if (productItem.tegs_product) {
      if (!tagId.includes(productItem.tegs_product)) {
        setTegId(productItem.tegs_product);
        // console.log(selectedCategory,'select in useef')
      }
    }
  }, [tegList]);

  const test = [selectedCategory];
  // console.log(test,'TETSTSTT')

  // const a = test.filter(item=> item.id == productItem.categoryId)

  function sendModal() {
    ActualVersion(productItem.id_product);
  }
  const memoSend = useCallback(() => {
    return sendModal();
  }, [productItem.id_product]);

  useEffect(() => {
    if (
      adminMode
        ? moderationItem.categoryId !== undefined
        : productItem.categoryId !== undefined
    ) {
      const a = test.filter((item) => item.id == productItem.categoryId);
      setNameForContent(a);
    } else {
      console.log("void");
    }
  }, [productItem.categoryId]);

  //set data to local state
  useEffect(() => {
    if (adminMode) {
      productItem.media && setStateMedia(productItem.media);
      productItem.base64 && setStateBase(productItem.base64);
      productItem.temporary_picture &&
        setTemporaryPic(productItem.temporary_picture[0]?.name);
    } else {
      productItem.media && setStateMedia(productItem.media);
      productItem.base64 && setStateBase(productItem.base64);
      productItem.temporary_picture &&
        setTemporaryPic(productItem.temporary_picture[0]?.name);
    }
  }, []);
  useEffect(() => {
    if (adminMode) {
      productItem.name && setProductName(productItem.name);
      productItem.price && setProductPrice(productItem.price);
      productItem.weight && setProductWeight(productItem.weight);
      productItem.calories && setProductCalories(productItem.calories);
      productItem.proteins && setProductProteins(productItem.proteins);
      productItem.fats && setProductFats(productItem.fats);
      productItem.carbo && setProductCarbo(productItem.carbo);
      productItem.description && setProductDescription(productItem.description);
      productItem.tegs_product && setTegsProduct(productItem.tegs_product);
      if (addNewMode) {
        setProductCategoriesState([selectedCategory.name]);
      }
    } else {
      productItem.name && setProductName(productItem.name);
      productItem.price && setProductPrice(productItem.price);
      productItem.weight && setProductWeight(productItem.weight);
      productItem.calories && setProductCalories(productItem.calories);
      productItem.proteins && setProductProteins(productItem.proteins);
      productItem.fats && setProductFats(productItem.fats);
      productItem.carbo && setProductCarbo(productItem.carbo);
      productItem.description && setProductDescription(productItem.description);
      productItem.tegs_product && setTegsProduct(productItem.tegs_product);
      if (addNewMode) {
        setProductCategoriesState([selectedCategory.name]);
      }
    }
  }, []);

  const temps = () => {
    if (productItem.temporary_picture && productItem.base64) {
      return productItem.base64;
    }
    if (getsPhoto && productItem.base64) {
      return [getsPhoto];
    }
    if (productItem.temporary_picture) {
      return getsPhoto && [getsPhoto];;
    }
    if (getsPhoto) {
      return [getsPhoto];
    }
    if (getsPhoto && productItem.media) {
      return [getsPhoto];
    }
    if (productItem.base64) {
      return productItem.base64;
    } else {
      return null;
    }
  };

  const forTemps = () => Boolean(productItem.temporary_picture);

  useEffect(() => {
    temps();
  }, [productItem, getsPhoto]);

  
  const toggleSelect = (e) => {
      e.stopPropagation()
    if (isSelect) {
      dispatch(removeProductFromSelect(id));
    } else {
      dispatch(addProductToSelect(id));
    }
    setIsSelect((prev) => !prev);
  };

  function getPhotos() {
    const child = (isActualVersion = false) => {
      return (
        <ImageContainer
          media={productItem.media ? stateMedia : null}
          base64={productItem.base64 ? stateBase : null}
          tempPic={productItem.temporary_picture ? temporaryPic : null}
          getsPhoto={getsPhoto || null}
          style={{ width: "90px", height: "90px", borderRadius: "6px" }}
          open={IsOpen}
          clear={memoClear}
          enabled={isEnabled()}
          ActualVersion={isActualVersion ? ActualVersion : null}
        />
      );
    };

    const isEnabled = () => {
      if (productItem.isEnabled && adminMode && IsOpen) {
        return productItem.isEnabled;
      } else {
        return null;
      }
    };

    if (IsOpen) {
      if (productItem.isEnabled === "1") {
        return (
          <div
            style={{
              width: 38,
              height: 38,
              backgroundColor: "color: #6178ff",
              position: "relative",
            }}
          >
            {child()}
          </div>
        );
      } else if (stateBase || stateMedia || temporaryPic || getsPhoto) {
        return child(true);
      } else {
        return (
          <Upload fileList={""} onChange={loadPhoto}>
            {child()}
          </Upload>
        );
      }
    } else {
      return child(true);
    }
  }

  const memoPhoto = useMemo(() => {
    return getPhotos();
  }, [stateBase, stateMedia, temporaryPic, getsPhoto]);

  const openModalHandler = () => {
    setOpenModal(true);
    dispatch(modalInfo(true))
    setAloneProduct(productItem);
  };

//   if('id_product' in aloneProduct){
//     if(saveNewProductStarted && 'moderate_status' in aloneProduct){
//      return +el?.id_product === +aloneProduct?.id_product 
//     }else{
//       return el.id_product  === aloneProduct?.id
//     }
// }else{
//       return +el?.id === +aloneProduct?.id
// }
// }))
  // useEffect(()=>{
  //   console.log('JOPAwqqwwqqw');
  //   if(productItem && aloneProduct){
  //       if('id_product' in aloneProduct){
  //         if(+productItem.id_product === +aloneProduct?.id_product){
  //             setAloneProduct(productItem)
  //         }
  //       }else{
  //           if(ProductInfo?.id === +aloneProduct?.id){
  //             setAloneProduct(productItem)
  //           }
  //       }
  //   }
  // },[productItem?.isEnabled,productItem?.is_enabled,statusSaveNew,saveProductChanges,productItem,aloneProduct])
  const pens = !addProductFieldShow 
  &&
 openModal ? aloneProduct  ? 'id_product' in aloneProduct ? aloneProduct?.id_product === productItem?.id_product ? <img style={{marginLeft:8,alignSelf:'end',display:'inline',width:24,height:24}} src={LightPen}/>  : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/>: aloneProduct?.id === productItem?.id ?<img style={{marginLeft:8,alignSelf:'end',display:'inline',width:24,height:24}} src={LightPen}/>  : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/> : false : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/>
  
  // onClick={addNewMode || catVision  ? null :()=>setIsOpen(true)}
  return (
      <ProductListItemWrapper  style={styleList ?{...styleList} : null} state={activeState} onClick={openModalHandler}>
        {/* <SelectMarker onClick={toggleSelect} >
          <div className="selectMarker" data-isselect={isSelect} />
        </SelectMarker> */}
        <Cols active = {!addProductFieldShow && openModal ? !'moderation_id' in productItem ? aloneProduct  && 'id_product' in aloneProduct ? aloneProduct?.id_product === productItem?.id_product : aloneProduct?.id === productItem?.id : +productItem?.id === +aloneProduct?.id : false} >
          <ProductInfo  >
            <Name style={{textAlign:'start',display:'flex',width:'85%'}}>
              {productItem.moderate_status === "1" ? (
                <img style={{width:30,height:30}} src={Check} />
              ) : productItem.moderate_status === "3" ? (
                <img style={{width:30,height:30}} src={AlarmErr} />
              ) : null}
          
            <div>
             <div>
            {productItem.name}
               {
            !addProductFieldShow 
              &&
             openModal ? aloneProduct  ? 'id_product' in aloneProduct ? aloneProduct?.id_product === productItem?.id_product ? <img style={{marginLeft:8,alignSelf:'end',display:'inline',width:24,height:24}} src={LightPen}/>  : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/>: aloneProduct?.id === productItem?.id ?<img style={{marginLeft:8,alignSelf:'end',display:'inline',width:24,height:24}} src={LightPen}/>  : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/> : false : <img style={{marginLeft:8,alignSelf:'end',display:'inline'}} src={Pen}/>
              
               
              }
              </div> 
             </div> 
            </Name>
            <FoodComposition  style={{ textAlign: "start",width:'85%'}}>
              <TextTruncate
              line={4}
              // element="span"
              truncateText="..."
              text={productItem.description}
              // textTruncateChild={<a href="#">Read on</a>}
              />
            </FoodComposition>
            <Price>{productItem.price} ₽</Price>
            <div>
              <ImageWrapper onClick={e=>e.stopPropagation()}>{memoPhoto}</ImageWrapper>
            </div>
            <Wheight>{productItem.weight}</Wheight>
            <Calorate>
              {productItem.calories} / {productItem.proteins} / {productItem.fats} / {productItem.carbo}
            </Calorate>
            {!'id_product' in productItem || productItem.moderate_status !== '1' && productItem.moderate_status !== '3' && (
              <OnOffWrapps onClick={e=>e.stopPropagation()}>
                <Switch
                  checked={productItem.isEnabled === "0"}
                  style={{ width: 51, height: 31 }}
                  className="switch"
                  onChange={disAndEnable}
                />
              </OnOffWrapps>
            )}
          </ProductInfo>
        </Cols>
      </ProductListItemWrapper>
  );
}

export default memo(ProductListItem);



const ProductListItemWrapper = styled.div`
  display: flex;

  /* height:100%; */
  /* box-sizing: border-box; */
  /* overflow: hidden;   */
`;
const Cols = styled.div`

/* ${PRODUCT_LIST_ELEMENT_CONTAINER} */
  display: flex;
  /* height: 100%; */
  width: auto;
  margin-bottom: 16px;
  box-sizing:border-box;
  background:#FFFFFF;
  color:${({active})=>active ? '#FFFFFF' :'#2C2E32'};
  background: ${({active})=>active ? '#7882A7' : '#FFFFFF'};
  transition: all .3s ease-in;
  padding: 8px 12px;
  box-shadow:0px 4px 6px rgba(206, 206, 206, 0.25);
  border-radius: 6px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  cursor:pointer;
`;
const ProductInfo = styled.div`
  display: grid;
  grid-template-columns:1.2fr 1.2fr .7fr 90px 1fr 1fr .6fr;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  word-break: break-word;
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const FoodComposition = styled.div`
  font-weight: 400;
  /* width:85%; */
  /* padding-left: 2.7em; */
  height: 70%;
  font-size: 12px;
  line-height: 130%;
font-family: Roboto;
  /* color: #2c2e32; */
`;
const Name = styled.div`
font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  /* color: #2c2e32; */
`;
const Calorate = styled.div`
font-family: Roboto;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* color: #2c2e32; */
  text-align: start;
`;
const Price = styled.div`
  font-weight: 500;
  font-family: Roboto;

  /* font-size: 20px; */
  font-size: 1.5em;
  height:70%;
  line-height: 23px;
  /* color: #2c2e32; */
  text-align: start;
`;
const Wheight = styled(Calorate)`
text-align: center;
font-family: Roboto;
`;

const OnOffWrapps = styled.div`
  button[aria-checked="true"] {
    background-color: #7882a7;
  }
  .switch::after {
    height: 27px;
    width: 27px;
  }
`;
