import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux"
import CourierOffLineListItem from "../ListItemsComponents/CourierOffLineListItem"
import {getAllCouriers} from "../../../reducers/toolkit/comonReducer"
import {nanoid} from "nanoid"


const CouriersOffLineList = () => {

  const dispatch = useDispatch()
  const couriers = useSelector(store => store.orderMap.couriers)
  const deliveryPointsStatus = useSelector(store => store.orderMap.deliveryPointsStatus)
  const allCouriersStatus = useSelector(store => store.common.couriersStatus)
  const allCouriers = useSelector(store => store.common.couriers)
  const [courierListsDiff, setCourierListsDiff] = useState([])

  useEffect(() => {
    dispatch(getAllCouriers())
  }, [])
  useEffect(() => {
    setCourierListsDiff([])
    if(deliveryPointsStatus === 'resolved' && allCouriersStatus === 'resolved') {
      if(couriers.length !== 0) {
        const ids = couriers.map(el => el.id)
        const diff = allCouriers.filter(el => !ids.includes(el.clientId))
        // console.log(diff, 123)
        setCourierListsDiff(diff)
      } else {
        setCourierListsDiff(allCouriers)
    }
    }
  }, [couriers, allCouriers])

  return deliveryPointsStatus === 'resolved' && courierListsDiff.length && (
    <OffLineListWrapper>
      <List>
        {
          courierListsDiff.map(courierData => <CourierOffLineListItem key={nanoid()} {...{courierData}}/>)
        }
      </List>
    </OffLineListWrapper>
  )
}

export default memo(CouriersOffLineList);

const OffLineListWrapper = styled.div`
  border-top: 2px solid silver;
  padding: 10px;
`
const List = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`
