import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion"
import {Select} from "antd";
import ProductlListItem from "./ProductListItem";
import {useDispatch, useSelector} from "react-redux";
import {
  addCreatedWaybill, applyEditedWaybill,
  resetSelectedWaybill, sendCreatedWaybill,
  toggleModal, updateWaybill
} from "../../../../actions/WarehouseActions";
import * as moment from "moment";
import {nanoid} from "nanoid";
import {selectStyles} from "../../Header"
import RecipientSelectorModal from "./RecipientSelectorModal"
import SenderSelectorModal from "./SenderSelectorModal"
import AddListItem from "./AddListItem"
import {deliveryCycleInfoMessage} from "../../../../actions/ModalActions"
import ProductList from "./ProductList"

const {Option} = Select;

export const modalGrid = {
  display: 'grid',
  gridTemplateColumns: '.5fr 10fr 1fr .3fr',
}

const columnFlex = {
  display: 'flex',
  flexDirection: 'column',
}

const warningStyle = {
  border: "1px solid red"
}

const AddWaybillModal = () => {

  const dispatch = useDispatch()
  const waybillDetails = useSelector(store => store.warehouse.waybillDetails)
  const showModalReason = useSelector(store => store.warehouse.modalControl.showModalReason)
  const selectedRecipientModal = useSelector(store => store.warehouse.recipientState.selectedRecipientModal)
  const selectedSenderModal = useSelector(store => store.warehouse.senderState.selectedSenderModal)

  const sendWaybillStart = useSelector(store => store.warehouse.modalControl.sendWaybillStart)
  const sendWaybillReady = useSelector(store => store.warehouse.modalControl.sendWaybillReady)
  const newWaybillId = useSelector(store => store.warehouse.modalControl.newWaybillId)
  // this state is replies for passing new price in local waybill instance. It's needed for pushing that instance in waybill's state array
  const newWaybillPriceOnCreate = useSelector(store => store.warehouse.modalControl.newWaybillPrice)

  const updateWaybillSuccess = useSelector(store => store.warehouse.modalControl.updateWaybillSuccess)
  const updateWaybillError = useSelector(store => store.warehouse.modalControl.updateWaybillError)

  //common
  const [editMode, setEditMode] = useState(false)
  const [checked, setChecked] = useState(false)
  const [changeControl, setChangeControl] = useState(false)

  //input controls in header
  const [date, setDate] = useState(moment(new Date).format('YYYY-MM-DD'));
  const [dateValid, setDateValid] = useState(true);
  const [waybillNum, setWaybillNum] = useState('');
  const [waybillNumValid, setWaybillNumValid] = useState(true);

  //inputs control in content
  const [oldInstance, setOldInstance] = useState(null)
  const [products, setProducts] = useState([]);
  const [productsValid, setProductsValid] = useState(true);

  //react refs
  const listBodyRef = React.createRef();

  useEffect(() => {
    if (showModalReason === 'EDIT') {
      setEditMode(prevState => !prevState);
    }
  }, [])

  useEffect(() => {
    if (editMode) {
      setChangeControl(checkChanges())
    }

  }, [date, waybillNum, products, selectedRecipientModal, selectedSenderModal])

  useEffect(() => {
    if (waybillDetails) {
      setNewWaybillIfSelectedIsSet(waybillDetails)
    }
  }, [waybillDetails])

  useEffect(() => {
    if(products.length) {
      listBodyRef.current.scrollTop = listBodyRef.current.scrollHeight
    }
  }, [products])

  // put new waybill in Waybills state
  useEffect(() => {
    if (sendWaybillReady) {
      const buildedWaybill = {
        id: newWaybillId,
        waybill: waybillNum,
        counterpartieId: selectedSenderModal.id,
        recipientId: selectedRecipientModal.id,
        waybillDate: date,
        productPriceSum: newWaybillPriceOnCreate,
        orderSum: 1000,
        waybillProducts: [...products]
      }
      dispatch(addCreatedWaybill(buildedWaybill))
      dispatch(deliveryCycleInfoMessage('waybill_created', '', 4000))
      closeModal()
    }
  }, [sendWaybillReady])

  useEffect(() => {
    if (updateWaybillSuccess) {
      if (!updateWaybillError) {
        closeModal()
        dispatch(deliveryCycleInfoMessage('waybill_updated', '', 4000))
      } else {
        dispatch(deliveryCycleInfoMessage('cant_update_waybill', updateWaybillError, 4000))
      }
    }
  }, [updateWaybillSuccess])

  const title = (() => {
      switch (showModalReason) {
        case 'CREATE':
          return 'Добавить накладную'
        case 'EDIT':
          return 'Редактирование накладной'
        default:
          return ''
      }
    }
  )()

  const checkChanges = () => {
    let checkChangesInProducts = false

    if (products.length === oldInstance.products.length) {
      checkChangesInProducts = products.reduce((acc, e, i) => {
        if (JSON.stringify(e) !== JSON.stringify(oldInstance.products[i])) {
          acc = true
        }
        return acc
      }, false)
    } else {
      return true
    }

    if (checkChangesInProducts) {
      return true
    }

    if (date !== oldInstance.waybillDate) {
      return true
    }

    if (waybillNum !== oldInstance.waybill) {
      return true
    }

    if (selectedRecipientModal?.id !== oldInstance.recipientId) {
      return true
    }

    if (selectedSenderModal?.id !== oldInstance.counterpartieId) {
      return true
    }

    return false
  }

  // update waybill
  const applyChanges = (date) => {
    if (showModalReason === 'EDIT') {
      if (checkWaybillBeforeAdd()) {
        const newWaybillInstance = {
          ...oldInstance,
          counterpartieId: selectedSenderModal.id,
          recipientId: selectedRecipientModal.id,
          date,
          waybill: waybillNum,
          products
        }
        dispatch(updateWaybill({
          prevState: waybillDetails,
          newState: newWaybillInstance
        }))
      }
    }
  }

  //send new waybill on server
  const addWaybill = () => {
    if (checkWaybillBeforeAdd()) {
      const buildedWaybill = {
        waybill: waybillNum,
        counterpartieId: selectedSenderModal.id,
        recipientId: selectedRecipientModal.id,
        waybillDate: date,
        orderSum: 1000,
        waybillProducts: [...products]
      }
      dispatch(sendCreatedWaybill(buildedWaybill))
    }
  }

  const checkWaybillBeforeAdd = () => {
    setChecked(true);
    const localCheck = [false, false, false]
    if (!date) {
      setDateValid(false)
      return false
    }
    localCheck[0] = true
    setDateValid(true)
    if (!waybillNum) {
      setWaybillNumValid(false)
      return false
    }
    localCheck[1] = true
    setWaybillNumValid(true)
    if (!(products.length > 0)) {
      setProductsValid(false)
      return false
    }
    localCheck[2] = true
    setProductsValid(true)
    return !localCheck.includes(false);
  }

  // get property from existing waybill if is set
  const setNewWaybillIfSelectedIsSet = (waybillDetails) => {
    setOldInstance(waybillDetails)
    setDate(waybillDetails.waybillDate)
    setWaybillNum(waybillDetails.waybill)
    setProducts([...waybillDetails.products])
  }

  const addProduct = (productName, productCount, productId) => {
    const newProduct = {
      name: productName,
      amount: productCount,
      productId: productId,
    }
    setProducts([...products, newProduct])
  }

  // const resetChanges = () => {
  //     if( waybillDetails ) {
  //         //if edit
  //         setNewWaybillIfSelectedIsSet(waybillDetails)
  //     } else {
  //         //if create new
  //         setDate('')
  //         setWaybillNum('')
  //         setProducts([])
  //     }
  // }

  function closeModal(e) {
    if (e) {
      e.stopPropagation();
    }
    setChecked(false)
    dispatch(resetSelectedWaybill())
    dispatch(toggleModal())
  }

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <AddWaybillModalWrapper>
        <motion.div
          initial={{y: -50}}
          animate={{y: 0}}
        >
          <Modal onClick={e => e.stopPropagation()}>
            <Header>
              <h4>
                {title}
              </h4>
              <Controls>
                <div style={columnFlex}>
                  <Control>
                    <p>Поставщик</p>
                    <SenderSelectorModal style={selectStyles} externalValue={waybillDetails?.counterpartieId || ""}/>
                  </Control>
                  <Control>
                    <p>Получатель</p>
                    <RecipientSelectorModal style={selectStyles}/>
                  </Control>
                </div>
                <div style={columnFlex}>
                  <Control>
                    <p>Дата</p>
                    {
                      editMode
                        ?
                        <Input
                          style={checked ? (!dateValid ? {...warningStyle} : {}) : {}}
                          type="date"
                          placeholder="Введите дату"
                          value={moment(date).format('YYYY-MM-DD')}
                          onChange={e => setDate(e.target.value)}
                        />
                        :
                        <Input
                          style={checked ? (!dateValid ? warningStyle : {}) : {}}
                          type="date"
                          placeholder="Введите дату"
                          value={moment(date).format('YYYY-MM-DD')}
                          onChange={e => setDate(e.target.value)}
                        />
                    }
                  </Control>
                  <Control>
                    <p>Номер</p>
                    <Input
                      style={checked ? (!waybillNumValid ? warningStyle : {}) : {}}
                      placeholder="Введите номер"
                      value={waybillNum}
                      onChange={e => setWaybillNum(e.target.value)}
                    />
                  </Control>
                </div>
              </Controls>
            </Header>
            <Content>
              <div style={{height: 'calc(100% - 10px)'}}>
                <h4 style={{textAlign: 'left'}}>
                  Товары
                </h4>
                <List>
                  <ListHeader style={modalGrid}>
                    <span/>
                    <p>Название</p>
                    <p>Кол-во</p>
                    <span/>
                  </ListHeader>
                  <ListBody
                    ref={listBodyRef}
                    style={checked ? (!productsValid ? warningStyle : {}) : {}}
                  >
                    <ProductList {...{products, showModalReason, setProducts}} />
                  </ListBody>
                </List>
                {
                  selectedRecipientModal && (
                    <AddListItem {...{addProduct, products, selectedRecipientModal}}/>
                  )
                }
              </div>
            </Content>
            <Controls style={{
              justifyContent: 'space-between',
              padding: 20,
            }}>
              <CancelBtn onClick={closeModal}>Отменить</CancelBtn>
              {
                showModalReason === 'EDIT'
                  ? (
                    !sendWaybillStart && changeControl
                      ? <ApplyBtn onClick={() => {
                        applyChanges(date)
                      }}>Изменить</ApplyBtn>
                      : <ApplyBtn disabled>Изменить</ApplyBtn>

                  )
                  : (
                    !sendWaybillStart
                      ? <ApplyBtn onClick={addWaybill}>Добавить</ApplyBtn>
                      : <ApplyBtn disabled>Добавить</ApplyBtn>
                  )
              }
            </Controls>
          </Modal>
        </motion.div>
      </AddWaybillModalWrapper>
    </motion.div>
  )
}

export default AddWaybillModal;

const AddWaybillModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.43);
  z-index: 2;
`
const Modal = styled.div`
  width: 60vw;
  margin: 70px auto;
  background: #fff;
  border-radius: 6px;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  h4 {
    text-align: left;
  }
`
const Controls = styled.div`
  display: flex;

  div:first-child {
    padding-left: 0;
  }

  & > div {
    padding: 10px;
  }
`
const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  p {
    margin: 0 20px 0 0;
  }
`
const Input = styled.input`
  background: rgba(0, 0, 0, 0.13);
  border: 1px solid transparent;
  border-radius: 6px;
  width: 200px;
  padding: 5px 10px;
`
const Content = styled.div`
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  padding: 20px;
`
const ListHeader = styled.div`
  color: rgba(0, 0, 0, 0.31);
`
const List = styled.div`
  padding: 20px;
  text-align: left;
`
const ListBody = styled.div`
  height: 250px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

const CancelBtn = styled.button`
  background: none;
  padding: 5px 30px;
  border-radius: 6px;
  border: 1px solid #7e91ff;
  color: #7e91ff;
  cursor: pointer;

  :hover {
    background: #7e91ff;
    color: white;
  }
`
const disabledBtn = {
  transition: 'none',
  opacity: .3,
  backgroundColor: 'rgba(0,0,0,0.17)',
  borderColor: 'rgba(0,0,0,0.65)'
}
const ApplyBtn = styled.button`
  background: #89ffa3;
  padding: 5px 30px;
  border-radius: 6px;
  border: 1px solid #89ffa3;
  color: #000;
  transition: background-color .2s ease;
  cursor: pointer;


  ${({disabled}) => disabled ? null : ':hover { background-color: #40ff4d; }'};

  ${({disabled}) => disabled ? null : ':active { transition: none; background-color: rgba(64,255,77,0.34); border: 1px solid rgba(137,255,163,0); }'};

  ${({disabled}) => disabled ? disabledBtn : null};
`
const SuggestSearchWindow = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.41);
  top: -250%;
  left: 6%;
`
