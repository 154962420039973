import React, { useState, useCallback, useEffect, memo, useRef } from "react";
import styled from "styled-components";
import ProductList from "./ProductList";
// import ProductListItem from "./ProductListItem";
import { sr, store } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getMenu,
  getMenuStartedAssort,
  getModerationProductStarted,
  getTegList,
  getTegListStarted,
  saveNewProductStarted,
  saveNewProductWithModeration,
  selectCategory,
} from "../../actions/AssortmentAction";
import { deliveryCycleInfoMessage } from "../../actions/ModalActions";
import Packet from "../../images/assort/Packet.svg";
import ChooseKitch from "../../images/dashBoard/ChooseKitchens.png";
import { modalInfo } from "../../actions/AssortmentAction";
import { OnCheckedModal } from "./Components/Modals/OnCheckedModal";
function Content({
  addProductFieldShow,
  addCategoryFieldShow,
  setAddProductFieldShow,
  setAddCategoryFieldShow,
  selectAll,
  setProductsInSelectedCategory,
  onDrop,
  scrollsRef,
  setOpenModal,
  refForAddProd,
}) {
  const dispatch = useDispatch();
  const kithensId = useSelector((store) => store.assortment.selectKithens);
  const selectedCategory = useSelector(
    (store) => store.assortment.selectedCategory
  );
  const adminMode = useSelector((store) => store.assortment.ModerationMode);
  const moderationProduct = useSelector(
    (store) => store.assortment.productListModeration
  );
  const productList = useSelector((store) => store.assortment.productList);
  const productListModeration = useSelector(
    (store) => store.assortment.productListModeration
  );
  const tegList = useSelector((store) => store.assortment.tegList);
  const getMenus = useSelector((store) => store.assortment.getMenuAssort);
  const status = useSelector(
    (store) => store.assortment.saveProductChangesStarted
  );
  const getMenu = useSelector((store) => store.assortment.getMenuAssort);
  const saveNewProductStarted = useSelector(
    (store) => store.assortment.saveNewProductStarted
  );
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const openModal = useSelector((store) => store.assortment.modalInfo);
  const [result, setResult] = useState([]);
  const [resulty, setRes] = useState([]);
  const [tegs, setTegs] = useState([]);
  const [addNew, SetAddNew] = useState(false);
  const [prodStatus, setProdStatus] = useState(null);
  useEffect(() => {
    if (adminMode) {
      dispatch(
        getMenuStartedAssort({
          id: String(SelectedKitchens.id),
          start: 0,
          limit: 1000,
        })
      );
    } else {
      if (SelectedKitchens && selectedCategory?.id) {
        dispatch(
          getMenuStartedAssort({
            id: String(SelectedKitchens.id),
            start: 0,
            limit: 1000,
            categoryId: selectedCategory.id,
          })
        );
      }
    }
  }, [SelectedKitchens, selectedCategory?.id, adminMode]);


  // const countsPlInArray=()=>{
  //   const a  = productList.filter(els=>{
  //     if(Number(selectedCategory.id) === els.categoryId ){
  //         return els
  //     }
  //   })
  //   setResult(a)
  // }

  useEffect(() => {
    sr("MenuSOKitchenService", "getListTegs", {}).then((res) =>
      setTegs(res.data.result)
    );
  }, []);

  // useEffect(()=>{
  //   if(getMenu){
  //   countsPlInArray()
  //   }
  // },[productList])
  return (
    <ContentWrapper ref={scrollsRef}>
      {SelectedKitchens.name !== "Не выбрано" ? (
        !+selectedCategory?.id && !adminMode ? (
          <Result>
            <img src={Packet} />
          </Result>
        ) : (
          <>
            {
              <ProductList
                {...{
                  addProductFieldShow,
                  addCategoryFieldShow,
                  setAddProductFieldShow,
                  setAddCategoryFieldShow,
                  selectAll,
                  setProductsInSelectedCategory,
                  onDrop,
                  tegs,
                  openModal,
                  setOpenModal,
                  scrollsRef,
                  refForAddProd,
                  setProdStatus,
                }}
              />
            }
          </>
        )
      ) : (
        <Result>
          <img src={ChooseKitch} />
        </Result>
      )}
    </ContentWrapper>
  );
}

export default memo(Content);

const ContentWrapper = styled.main`
  position: relative;
  /* width: calc(100vw - 350px); */
  /* width: calc(100% - 30px); */
  grid-area: content;
  /* padding: 10px 188px 10px 10px; */
  /* height: calc(100vh - 206px);
  overflow-y: scroll;
  &::-webkit-scrollbar { 
   display: none; 
   } */
`;
const Result = styled.div`
  display: flex;
  align-self: center;
  justify-self: start;
`;
