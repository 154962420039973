import React,{useState,useEffect,useRef} from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import moment from "moment";
import { useDispatch } from 'react-redux';
import {deliveryCycleInfoMessage} from '../../actions/ModalActions'
import {Input, Switch,Select} from "antd";
import TextareaAutosize from 'react-textarea-autosize';
import { Btn } from '../../components/Settings/UI/Btn';
// import CourierDel from './Components/CourierDel';
import CourierDel from '../../components/Settings/MainSettings/Components/CourierDel';
import { sr } from '../../utils';



const {Option} = Select;
const weeks = [
    {id:1,name:'Понедельник'},
    {id:2,name:'Вторник'},
    {id:3,name:'Среда'},
    {id:4,name:'Четверг'},
    {id:5,name:'Пятница'},
    {id:6,name:'Суббота'},
    {id:7,name:'Воскресенье'},
]
const MainSettings = props => {
    const [commercialName,setCommercialName] = useState('')
    const [descriptionKitchen, setDescriptionKitchen] = useState('')
    const [availableOrder,setAvailableOrder] = useState('')
    const [getDataKitchen,setGetDataKitchen] = useState('')
    const [testersPhone,setTestersPhone] = useState('')
    const [typeKitchen,setTypeKitchen] = useState('')
    const [tagsKitchen,setTagsKitchens] = useState('')
    const [averageСheque,setAverageСheque] = useState('')
    const [displayRating,setDisplayRating] = useState('')
    const [deliverySelfRest,setDeliverySelfRest] = useState('')
    const [kitchenPlateImg,setKitchenPlateImg] = useState('')
    const [emailSendOrders,setEmailSendOrders] = useState('')
    const [emailForFeedback,setEmailForFeedBack] = useState('')
    const getTags = ()=>{
        sr('MenuSOKitchenService','getListTegs',{})
        .then(res=>{
            if(res.result){
                setTagsKitchens(res.data.result)
            }else{

            }
        })
    }
    useEffect(()=>{
        getTags()
    },[])
  return (
    <WrapperSettings>
        <ItemsBlock>
            <HeaderItems>
            Коммерческое название кухни
            </HeaderItems>

            <ElementItem>
             <Input
             value={commercialName}
             onChange={e=>setCommercialName(e.target.value)}
             />
            </ElementItem>
        </ItemsBlock>
        <ItemsBlock>
            <HeaderItems>
            Описание кухни
            </HeaderItems>
            <ElementItem>
                <TextareaAutosize
                value={descriptionKitchen}
                onChange={e=>setDescriptionKitchen(e.target.value)}
                />
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>        
            <HeaderItems>
        Доступно для заказа
            </HeaderItems>
            <ElementItem>
                <Switch size='large'/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
        Передавать контактные данные кухне
            </HeaderItems>
            <ElementItem>
                <Switch size='large'/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
                Телефоны тестировщиков
            </HeaderItems>
            <ElementItem>
                <Input
                value={testersPhone}
                onChange={e=>setTestersPhone(e.target.value)}
                />
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Тип кухни
            </HeaderItems>
            <ElementItem>
                <Select>
                </Select>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
             Тэги кухни
            </HeaderItems>
           
            <ElementItem style={{display:'grid',gridTemplateColumns:'repeat(6,1fr)',marginLeft:12}}>
                {tagsKitchen 
                ?
                 tagsKitchen?.map(kitchen=>{
                     return (
                         <div style={{display:'flex',flexDirection:'row'}}>
                     <p style={{paddingLeft:10,paddingRight:5,fontSize:16,fontWeight:'bold'}}>{kitchen.location?.ru_RU}</p> 
                     <Switch/>
                         </div>
                     )
                 })
                 :
                 ''
                }
            </ElementItem>
        </ItemsBlock>    

        <ItemsBlock>
            <HeaderItems>
            Средний чек кухни
            </HeaderItems>
            <ElementItem>
                <Select/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Отображать рейтинг
            </HeaderItems>
            <ElementItem>
                <Switch size='large'/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Доставляет сам ресторан
            </HeaderItems>
            <ElementItem>
                <Switch size='large'/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Плашка кухни
            </HeaderItems>
            <ElementItem>
                <img src='' alt='Kithen'/>
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Email для отправки заказов
            </HeaderItems>
            <ElementItem>
                <Input
                value={emailSendOrders}
                onChange={e=>setEmailSendOrders(e.target.value)}
                />
            </ElementItem>
        </ItemsBlock>

        <ItemsBlock>
            <HeaderItems>
            Email для получения отзывов
            </HeaderItems>
            <ElementItem>
                <Input
                value={emailForFeedback}
                onChange={e=>setEmailForFeedBack(e.target.name)}
                />
            </ElementItem>
        </ItemsBlock>
            <ItemsBlockCourier>
                <HeaderItems>
                    Режим работы доставки
                </HeaderItems>
                <div style={{width:'auto',backgroundColor:' #FFFFFF',borderRadius:8}}>
                    <HeaderCourier>
                <ItemsContent style={{textAlign:'start'}}>
                    Рабочий
                </ItemsContent>
                <ItemsContent style={{textAlign:'start'}}>
                    День недели
                </ItemsContent>
                <ItemsContent style={{textAlign:'start'}}>
                    От
                </ItemsContent>
                <ItemsContent style={{textAlign:'start'}}>
                    До
                </ItemsContent>
            </HeaderCourier>
                {weeks.map(week=>
                <CourierDel key={week.id} {...{week}}/>
                )
                }
                </div>
            </ItemsBlockCourier>
            <Btn>
              Сохранить изменения
            </Btn>
    </WrapperSettings>
  )
}

MainSettings.propTypes = {}

export default MainSettings

const WrapperSettings = styled.div`
display: grid;
grid-template-rows: auto;
`
const ItemsBlock = styled.div`
display: grid;
grid-template-columns: auto 1fr;
padding: 15px;
justify-items: start;
`
const ItemsBlockCourier = styled.div`
display: grid;
grid-template-rows  : auto 1fr;
padding: 15px;
justify-items: start;   
`
const HeaderItems = styled.div`
font-weight: 600;
font-size: 19px; 
width: 100%;
text-align: start;
padding-right: 22px;
font-family: 'Roboto';
font-style: normal;
`


const ElementItem = styled.div`
    textarea{
        resize: none;
        min-width: 450px;
        min-height: 80px;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 0px 2px 2px rgba(206, 206, 206,0.5);
    }
    input{
     min-width: 450px;
     min-height: 30px;
     border-radius: 6px;
     border: none;
     box-shadow: 0px 0px 2px 2px rgba(206, 206, 206,0.5);
    }
`
const HeaderCourier = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);
border-bottom: 1px solid rgb(169,169,169,0.5);
`
const Content = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr); 
`
const ItemsContent = styled.div`
padding: 10px;
color:#999999;
font-weight: 600;
font-size: 15px; 
font-family: 'Roboto';
font-style: normal;

`