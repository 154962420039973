import React, { memo, useEffect, useState } from "react";
import ProductItem from "./ProductItem";
import { useDispatch, useSelector } from "react-redux";
import ProductListItem from "../Assort/ProductListItem";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { sr } from "../../utils";
import Aside from "../Assort/Aside";
import HeaderMod from "./HeaderMod";
import {
  getCategoryStarted,
  getKithensStarted,
  getModerationProductStarted,
} from "../../actions/AssortmentAction";
import { isArray } from "underscore";
import axios from "axios";

export const ProductListMod = ({setActions}) => {
  const dispatch = useDispatch();
  const [newMode, addNewMode] = useState(true);
  const [moreProduct, setMoreProduct] = useState([]);
  const [allProducts, setAllproducts] = useState([]);
  const [tegs, setTegs] = useState([]);
  const [modProdHead, setModProdHead] = useState([]);
  const kitchensStore = useSelector(store=>store.appMain.kitchens)

  const productListModeration = useSelector(
    (store) => store.assortment.productListModeration
  );
  const accessibleKitchens = useSelector(
    (store) => store.assortment.accessibleKitchens
  );
  const categoryList = useSelector((store) => store.assortment.categoryList);
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );

  console.log(productListModeration, "categoryListcategoryList");

  // useEffect(() => {
  //   dispatch(getKithensStarted());
  // }, []);

  

  const productWithHeader = () => {
    const a = categoryList.map((i, ind, arr) => {
      i.productItem = productListModeration.filter(
        (el) =>{
          return(
            +el[0]?.categoryId === +i?.id ||
            +productListModeration[0]?.categoryId === +i?.id 
          )
        }
      );
      return i;
    });
    setModProdHead(a);
  };

  useEffect(() => {
    if (productListModeration?.length > 0) {
      productWithHeader();
    }
  }, [categoryList, productListModeration]);

  console.log(modProdHead, "productListModeration");

  useEffect(() => {
    sr("MenuSOKitchenService", "getListTegs", {}).then((res) => {
      setTegs(res.data.result);
    });
  }, []);

  {
    /* <ProductItem key={nanoid()} 
          {...{productItem,id,accessibleKitchens,tegs}}
          productItemWithActive={isArray(productItem)? '' : productItem}
          productItemMod = {isArray(productItem) ? productItem : '' }
          />  */
  }

  return (
    <>
      <ProductListWrapper>
        {modProdHead?.map((el) => {
          return (
            <>
              {el.productItem.length > 0 && <HeadItem key={el.id}>{el.name.ru}</HeadItem>}
              {el.productItem.map((productItem, id) => {
                return (
                  <ProductItem
                    key={nanoid()}
                    {...{ productItem, id, accessibleKitchens, tegs }}
                    productItemWithActive={
                      isArray(productItem) ? "" : productItem
                    }
                    productItemMod={isArray(productItem) ? productItem : ""}
                    kitchens={kitchensStore}
                    setActions={setActions}
                  />
                );
              })}
            </>
          );
        })}
      </ProductListWrapper>
    </>
  );
};
const ProductListWrapper = styled.div`
  margin-top: 35px;
`;
const HeadItem = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  text-align: start;
  color: #161616;
`;
