import React,{useState,useEffect} from 'react'
import { Input, Modal, Select } from 'antd'
import styled from 'styled-components'
import './App.css'
import { useDispatch, useSelector } from 'react-redux'
import { addNewKitchens, setModalView, setSelectedKitchens } from './reducers/toolkit/mainAppReducer'
import cancel from './images/MainPage/cancel .png'
import { sr } from './utils'
import { deliveryCycleInfoMessage } from './actions/ModalActions'
import { motion } from 'framer-motion'
import {useRequest} from './hooks/useRequest'
const {Option} = Select;
const cities = [
    {cityId:9,cityName:'Самара'},
    {cityId:18,cityName:'Тольятти'}
]
const AddKitchenModal = () => {

    const closeModal = () =>  dispatch(setModalView(false))
    const addNewKitchensModal = useSelector(store=>store.appMain.addNewKitchensModal)
    const [respone,Request] = useRequest()
    const [selectCity,setSelectedCity]= useState('Выберите город')
    const [selectCityId,SetSelectCityId] = useState(null)
    const [commName,setCommName] = useState('')
    const dispatch = useDispatch()
    const changeCity = (val) => {
        setSelectedCity(val)
        SetSelectCityId(cities?.find(el=> el.cityName === val)?.cityId)
    }
    const commercialNameHandler = (e) =>{
        setCommName(e.target.value)
    }
    const ErrHandler=()=>{
        dispatch(deliveryCycleInfoMessage('modeCustomErr','Ошибка создания новой кухни'))
        closeModal()
    }

    const addNewKitchen = () =>{
    if(selectCityId &&  commName){
        sr('RegisterServiceSO','CreateAccount',{
        settings:{
            // locale:'ru_RU',
            objectName:commName,
            objectCity:selectCityId,
        }
        }).then(res=>{
            if(res.result){
            let kitInstance = {
                INN: null,
                KPP: null,
                UrName: null,
                city: String(selectCityId),
                cityName: selectCity,
                id: res.data.objectId,
                logoUrl: null,
                name: commName,
                rating: null,
                status: null,
                type: "cantina",
                typeDelivery: "",
            }
                dispatch(deliveryCycleInfoMessage('modeCustom','Новая кухня успешно создана'))
                dispatch(addNewKitchens(kitInstance))
                dispatch(setSelectedKitchens(kitInstance))
                window.location.href = window.location.origin + '/dashboard' 
                closeModal()
            }else{
                ErrHandler()
            }
        })
        }
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                closeModal()
            }
            };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
    }, []);
  return (
    <Modal
    visible={addNewKitchensModal}
    footer={false}
    centered
    closable={false}
    onCancel={()=>dispatch(setModalView(false))}
    className='modals'
    maskStyle={{backgroundColor: 'rgba(44, 46, 50, 0.8)'}}
    // maskClosable={closeModal}
    // style={{borderRadius:16}}
    >
        <ContentModal>
            <div className='HeaderModal'>
                <div className='MainName'>Введите название кухни</div>
            </div>
            <div className='InnerModal'>
            <div className='DataModal'>
                  <Input
                  value={commName}
                  onChange={commercialNameHandler}
                  placeholder='Коммерческое название'
                  className='InputModal'
                  />
                  <Select 
                //   style={{width:'100%',height:50}}
                  className='SelectCustom'
                //   placeholder='Выберите город'
                  value={selectCity}
                  onChange={changeCity}
                  >
                      {
                       cities.map(city=>
                            <Option className='optionCustom'  key={city.cityId} value={city.cityName}>
                                {city.cityName}
                            </Option>    
                        )
                      }
                  </Select>
                </div>
            </div>
            <div className='Fotter'>
                <BtnSave disable={selectCityId && commName}  onClick={addNewKitchen}  whileHover={{ scale: 1.1 }}>
                      Сохранить
                </BtnSave>
            </div>
        </ContentModal>
        <CloseModal onClick={closeModal}>
            <img style={{width:40,height:40}} src={cancel}/>
        </CloseModal>
    </Modal>
  )
}

export default AddKitchenModal

const ContentModal = styled.div`
font-family: Roboto;
font-style: normal;
    .DataModal{
        display: flex;
        flex-direction: column;
    }
    input{
        box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
        border-radius: 8px;
        height: 50px;
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .MainName{
        font-weight: 700;
        font-size: 24px;
        color: #111111
    }
    .SubName{
        color: #999999;
        font-weight: 400;
        font-size: 14px;
    }
    .InnerModal{
        div{
        display: flex;

        /* margin-top: 10px; */
            p{
              width: 30%;
            }
        }
    }
    .Fotter{
        margin-top: 16px;
    }
`
const FooterModal = styled.div`
    display: flex;
    justify-content: space-between;
    .addFoot{
        background-color: #CDDC39;
        border-radius: 6px;
        color: #161616;
        padding: 10px;
        cursor: pointer;
    }
    .ExitFoot{
        background-color: #FFFFFF;
        border-radius: 6px;
        color: #3498DB;
        border: 1px solid #3498DB;
        padding: 10px;
        cursor: pointer;
    }
`
const CloseModal = styled.div`
position: absolute;
top: 0;
right: -60px;
cursor: pointer;
`

const BtnSave = styled(motion.div)`
display: flex;
align-items: center;
cursor:${({disable})=> disable ? 'pointer' : 'not-allowed'} ;
margin-left: auto;
justify-content: center;
background: #7882A7;
border-radius: 8px;
padding:10px;
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #FFFFFF;
width: 20%;
`