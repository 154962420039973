import {sr} from "../utils";

export const GET_KITCHENS = 'GET_KITCHENS';
export const GET_KITCHENS_SUCCESS = 'GET_KITCHENS_SUCCESS';
export const GET_KITCHENS_FAILED = "GET_KITCHENS_FAILED";

export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";

export const SEND_PUSH_SINGLE_MESSAGE = 'SEND_PUSH_SINGLE_MESSAGE';
export const SEND_PUSH_MESSAGE = "SEND_PUSH_MESSAGE";
export const SEND_PUSH_MESSAGE_SUCCESS = "SEND_PUSH_MESSAGE_SUCCESS";
export const SEND_PUSH_MESSAGE_ERROR = "SEND_PUSH_MESSAGE_ERROR";

export const SEND_ADMIN_COMMENT = 'SEND_ADMIN_COMMENT';
export const SEND_ADMIN_COMMENT_SUCCESS = 'SEND_ADMIN_COMMENT_SUCCESS';
export const SEND_ADMIN_COMMENT_ERROR = 'SEND_ADMIN_COMMENT_ERROR';

export const SHOW_POPUP_WRAPPER = "SHOW_POPUP_WRAPPER";
export const HIDE_POPUP_WRAPPER = "HIDE_POPUP_WRAPPER";

export const SHOW_PUSH_MESSAGE_POPUP = "SHOW_PUSH_MESSAGE_POPUP";
export const HIDE_PUSH_MESSAGE_POPUP = "HIDE_PUSH_MESSAGE_POPUP";
export const CHANGE_POPUP_MESSAGE = "CHANGE_POPUP_MESSAGE";

export const RESET_SUCCESS_MESSAGE_STATE = "RESET_SUCCESS_MESSAGE_STATE";

export const SET_DATE = 'SET_DATE';
export const SET_ACTIVE_REST = 'SET_ACTIVE_REST';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS';
export const SET_EVALUATION_FILTER = 'SET_EVALUATION_FILTER';
export const SET_ALL_EVALUATION = 'SET_ALL_EVALUATION';
export const SET_WITHOUT_COMMENTS_FILTER = 'SET_WITHOUT_COMMENTS_FILTER';
export const SET_WITH_CLIENT_COMMENTS_FILTER = 'SET_WITH_CLIENT_COMMENTS_FILTER';



export const getKitchens = () => ({
    type: GET_KITCHENS
});

export const getReviews = payload => ({
    type: GET_REVIEWS,
    payload,
})

export const sendPushMessage = payload => ({
    type: SEND_PUSH_MESSAGE,
    payload,
})

export const sendPushSingleMessage = payload => ({
    type: SEND_PUSH_SINGLE_MESSAGE,
    payload,
})


export const sendAdminComment = payload => ({
    type: SEND_ADMIN_COMMENT,
    payload
})

export const hidePopupWrapper = () => ({
    type: HIDE_POPUP_WRAPPER,
})

export const showPushMessagePopup = payload =>({
    type: SHOW_PUSH_MESSAGE_POPUP,
    payload,
})

export const changePushMessage = payload => ({
    type: CHANGE_POPUP_MESSAGE,
    payload,
})

export const setDate = payload => ({
    type: SET_DATE,
    payload,
})
export const setActiveRest = payload => ({
    type: SET_ACTIVE_REST,
    payload,
})

export const setOrders = payload => ({
    type: SET_ORDERS,
    payload,
})

export const setEvaluationFilter = payload => ({
    type: SET_EVALUATION_FILTER,
    payload,
})

export const setAllEvaluation = payload => ({
    type: SET_ALL_EVALUATION,
    payload,
})

export const setWithoutComments = payload => ({
    type: SET_WITHOUT_COMMENTS_FILTER,
    payload,
})

export const setWithClientComments = payload => ({
    type: SET_WITH_CLIENT_COMMENTS_FILTER,
    payload,
})

export const setFilteredOrders = payload => ({
    type: SET_FILTERED_ORDERS,
    payload,
})