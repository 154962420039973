import React, { Component } from "react";
import styled from "styled-components";
import { sr } from "../../utils";
import moment from "moment";
import "moment/locale/ru";
import _ from "underscore";
import { connect } from "react-redux";
import NoPermissions from "../../components/NoPermissions";
import { getPermissionsAction } from "../../reducers/toolkit/mainAppReducer";
import { deliveryCycleInfoMessage } from "../../actions/ModalActions";
import { Switch } from "antd";
import RcCC from "../../images/RcCC.svg";
import CarCC from "../../images/CarCC.svg";
import CloseCC from "../../images/CloseCC.svg";
import FoodCC from "../../images/FoodCC.svg";
import GiftCC from "../../images/GiftCC.svg";
import HzCC from "../../images/HzCC.svg";
import PhoneCC from "../../images/PhoneCC.svg";
import WebCC from "../../images/WebCC.svg";
import UnoMoneyCC from "../../images/UnoMoneyCC.svg";
import QuestCC from "../../images/QuestCC.svg";
import RcDisabled from "../../images/RcCCDisable.svg";
import InfoOrderItem from "../../components/ClientCard/InfoOrderItem";

class ClientCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      results: {},
      switchState: false,
      resultsBlacklist: false,
      message: "",
      yesterDay: moment().subtract(1, "days").format("YYYY-MM-DD"),
      todayDay: moment().format("YYYY-MM-DD"),
      setUnoMoney: false,
    };

    if (window.location.search) {
      this.state.clientPhone = window.location.search
        .split("")
        .slice(2)
        .join("");
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate() {
    console.log(this.state.results, "REEEEEs");
  }
  componentDidMount() {
    if (!this.props.getPermissionsSuccess) {
      this.props.getPermissions();
    }

    if (this.state.clientPhone) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            value: prev.clientPhone,
            clientPhone: "",
          };
        },
        () => {
          this.handleSubmit(this.state.value);
        }
      );
    }
  }

  // handleSwitch(value){
  //   this.setState({switchState: !value})
  // }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSwitchSubmitBlackList(value) {
    this.setState({ switchState: !value });

    if (this.state.switchState === true)
      sr("ClientBlackListService", "ClientsInBlackList", {
        items: { blacklist: [{ username: this.state.value, state: "2" }] },
      }).then((res) => {
        if (res.result) {
          console.log(res, "убран из чс");
          this.setState({ resultsBlacklist: false });
        }
      });
    else {
      sr("ClientBlackListService", "ClientsInBlackList", {
        items: { blacklist: [{ username: this.state.value, state: "1" }] },
      }).then((res) => {
        if (res.result) {
          console.log(res, "добавлен в чс");
          this.setState({ resultsBlacklist: true });
        }
      });
    }
  }
  filterNumber = (text) => {
    let textPhone = text
      .replace(/\s/g, "")
      .replace(/\-/g, "")
      .replace(/\(|\)/g, "");
    let sendText = "";
    let firstChar = textPhone[0];
    console.log(textPhone.length, "length");
    if (
      (firstChar === "8" && textPhone.length === 11) ||
      (firstChar === "7" && textPhone.length === 11)
    ) {
      sendText = textPhone.replace(firstChar, "+7");
    } else {
      sendText = text;
    }
    if (textPhone[0] === "9" && textPhone.length === 10) {
      sendText = textPhone.replace(/^9/, "+79");
    }
    if (textPhone[0] === "+") {
      sendText = textPhone;
    }
    if (/[A-Za-zА-яЁё]/g.test(text)) {
      sendText = text;
    }
    return sendText;
  };

  handleSubmit = (value, event = null) => {
    if (!event || event.key === "Enter") {
      console.log("Значение в поисковой строке:", this.filterNumber(value));
      sr("ClientUserService", "GetCardClientByPhone", {
        phone: this.filterNumber(value),
      }).then((res) => {
        console.log("Результат поиска по номеру телефона", res);
        this.setState({ results: res.data });
        this.setState({ resultsBlacklist: res.data.blackList });
        this.setState({ switchState: res.data.blackList });
        !res.data.clientId && alert("Нет результатов");
      });
    }
  };

  cancel = (orderId, delivered, courierId,type) => {
    let confirm = !delivered
      ? window.confirm(`Вы действительно хотите отменить заказ №${orderId}?`)
      : window.confirm(
          `Вы действительно хотите установить статус заказ №${orderId} на "доставлен"?`
        );

    if (confirm) {
      if (!delivered) {
        sr("DinnerController", "CancelOrderOperator", {
          orderId: orderId,
          // [delivered ? null : 'orderId']:delivered ? null : orderId,
          // order: {id: orderId, status: '536870912'}
          // [delivered ? 'order':null]: delivered ? {id:orderId ,status:'67108864'} : null
        }).then((res) => {
          if (res.result) {
            if ("error" in res.data.orderId) {
              this.props.deliveryCycleInfoMessage(
                "error_ClientCard",
                res.data.orderId.error
              );
            }
            if ("message" in res.data.orderId) {
              this.props.deliveryCycleInfoMessage(
                "succesDelete_ClientCard",
                res.data.orderId.message
              );
              this.handleSubmit(this.state.results.phone);
            }
            console.log("Результат по отмене", res);
          }
        });
      } else {
        if (courierId || type === 'ownDelivery') {
          sr("AppSOKitchenService", "SetOrderStatus", {
            order: { id: orderId, status: "67108864" },
            // {order:orderId,id:id}
            // {id: orderId, status: '67108864'}
          }).then((res) => {
            if (res.result) {
              if ("error" in res.data) {
                this.props.deliveryCycleInfoMessage(
                  "error_ClientCard",
                  res.data.orderId.error
                );
              }
              if ("message" in res.data) {
                this.props.deliveryCycleInfoMessage(
                  "succesDelete_ClientCard",
                  'Статус заказа установлен в "доставлено"'
                );
                this.handleSubmit(this.state.results.phone);
              }
              console.log("Результат по отмене", res);
            } else {
              this.props.deliveryCycleInfoMessage(
                "error_ClientCard",
                res.data.message
              );
            }
          });
        } else {
          this.props.deliveryCycleInfoMessage(
            "error_ClientCard",
            "Курьер не назначен на этот заказ"
          );
        }
      }
    }
  };
  // sr("AppSOKitchenService", "SetOrderStatus", {
  copyId = () => {
    this.state.results.clientId &&
      navigator.clipboard.writeText(this.state.results.clientId) &&
      alert("ID скопирован");
  };

  namePayType = (index) => {
    let name = "";

    switch (index) {
      case "1":
        name = "Наличные";
        break;
      case "8":
        name = "Картой курьеру";
        break;
      case "16":
        name = "Оплата онлайн";
        break;
      case "32":
        name = "Оплата баллами";
        break;
    }

    return name;
  };
  showAddress = (companyId) => {
    let address = _.find(this.state.results.companies, (company) => {
      return company.id === companyId;
    });
    let street = address && (address.street ? address.street : "");
    let house = address && (address.house ? address.house : "");
    let flat = address && (address.flat ? address.flat : "");

    console.log(street, house, flat, "SFH");

    return (
      (street ? street : "") +
      (house ? " " + house : "") +
      (flat ? ", оф./кв. " + flat : "")
    );
  };

  checkRole = (param) => {
    if (this.props.permissions?.staff) {
      if (
        this.props.permissions.staff.role === "admin" &&
        !this.props.permissions.permissions.length
      ) {
        return true;
      } else {
        return this.props.permissions.permissions.includes(param);
      }
    } else {
      return false;
    }
  };
  delStatus = (status) => {
    switch (status) {
      case "2":
        return "Новый";
      case "4":
        return "Скрыт от пользователя";
      case "16":
        return "Ожидает оплаты";
      case "128":
        return "Ошибка при оплате";
      case "1024":
        return "Ожидает обработки внешней системы";
      case "4096":
        return "Ошибка отправки во внешнюю систему";
      case "16384":
        return "Отправлен во внешнюю систему";
      case "131072":
        return "Готовится";
      case "1048576":
        return "Приготовлен, ожидает доставки";
      case "8388608":
        return "В пути (доставляется)";
      case "67108864":
        return "Доставлен";
      case "134217728":
        return "Отбит пречек";
      case "536870912":
        return "Отменен";
      case "1073741824":
        return "Завершен (закрыт)";
      default:
        alert("Нет таких значений");
    }
  };
  render() {
    const time = this.state.results.averageDeliveryTime;
    return (
      <Main className="client-card">
        {this.checkRole("screen_client-card") ? (
          <Container>
            <SearchBox>
              <P26 style={{ marginBottom: 30 }}>Клиенты</P26>
              <SearchInput
                type="text"
                placeholder="Поиск по номеру телефона"
                onChange={this.handleChange}
                onKeyUp={(e) => this.handleSubmit(this.state.value, e)}
                value={this.state.value}
              />
              <SearchButton onClick={() => this.handleSubmit(this.state.value)}>
                Найти
              </SearchButton>
            </SearchBox>
            {this.state.results.clientId ? (
              <BlackList>
                <BlackListText>
                  Добавить пользовавателся в Blacklist
                </BlackListText>
                <Switch
                  checked={this.state.switchState}
                  onChange={() =>
                    this.handleSwitchSubmitBlackList(this.state.switchState)
                  }
                />
              </BlackList>
            ) : null}
            <ResultsList>
              <Columns>
                <p style={{ width: "14%" }}>Номер телефона</p>
                <p style={{ width: "20%" }}>ФИО</p>
                <p style={{ width: "20%" }}>Все адреса</p>
                <p style={{ width: "20%" }}>Столовые</p>
                <p style={{ width: "13%" }}>Кол-во заказов</p>
                <p>Среднее время доставки</p>
              </Columns>
              <Results>
                <div style={{ width: "14%", paddingRight: 16 }}>
                  <p style={{ marginBottom: 22, color: "#3498db" }}>
                    {this.state.results.phone}
                  </p>
                  {this.state.results.clientId && (
                    <p style={{ marginBottom: 2, color: "#999" }}>ID</p>
                  )}
                  <p style={{ marginBottom: 2 }}>
                    {this.state.results.clientId}
                  </p>
                  <div>
                    {this.state.resultsBlacklist ? (
                      <BLackListResult>В черном списке</BLackListResult>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div style={{ width: "20%", paddingRight: 16 }}>
                  <p>{this.state.results.clientName}</p>
                </div>
                <div style={{ width: "20%", paddingRight: 16 }}>
                  {this.state.results.companies &&
                    this.state.results.companies.map((v, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            marginBottom:
                              i === this.state.results.companies.length - 1
                                ? 0
                                : 20,
                          }}
                        >
                          <p>{v.name + ` (ID ${v.id})`}</p>
                          <p>
                            {v.street} {v.house}
                            {v.floor && ", этаж " + v.floor}
                            {v.flat &&
                              (v.name === "Дом" ? ", кв." : ", оф.") + v.flat}
                          </p>
                          {this.state.results.activeCompanyId === v.id ? (
                            <div
                              style={{
                                width: 70,
                                height: 20,
                                borderRadius: 3,
                                marginTop: 2,
                                backgroundColor: "#00c53a",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 12,
                                  lineHeight: "14px",
                                  color: "#fff",
                                }}
                              >
                                Текущий
                              </p>
                            </div>
                          ) : (
                            []
                          )}
                        </div>
                      );
                    })}
                </div>
                <div style={{ width: "33%" }}>
                  {this.state.results.ordersCount &&
                    this.state.results.ordersCount.map((v, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            marginBottom:
                              i === this.state.results.ordersCount.length - 1
                                ? 0
                                : 10,
                          }}
                        >
                          <div style={{ width: "60.6%", paddingRight: 16 }}>
                            <p>{v.restName}</p>
                          </div>
                          <div style={{ width: "39.4%", paddingRight: 16 }}>
                            <p>{v.ordersCount}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {time && <p>🕒 {time.substr(0, time.indexOf("."))}</p>}
                </div>
              </Results>
            </ResultsList>
            <ResultsList>
              <P26 style={{ marginBottom: 7 }}>Последние заказы</P26>
              <Columns>
                {/* <p style={{width: "6%"}}>№ заказа</p>
                <p style={{width: "9%"}}>Дата</p>
                <p style={{width: "10%"}}>Адрес доставки</p>
                <p style={{width: "5%"}}>Сумма</p>
                <p style={{width: "9%"}}>Статус</p>
                <p style={{width: "10%"}}>Столовые</p>
                <p style={{width: "10%"}}>Коментарий</p>
                <p style={{width: "10%"}}>Период доставки</p>
                <p style={{width: "8%"}}>Время доставки</p>
                <p style={{width: "9%"}}>Курьер</p>
                <p style={{width: "7%"}}>Тип оплаты</p>
                <p style={{width: "5%"}}>Источник</p> */}
                <p style={{ width: "15%" }}>Создан</p>
                <p style={{ width: "15%" }}>Кухня</p>
                <p style={{ width: "15%" }}>Сумма</p>
                <p style={{ width: "15%" }}>Адрес доставки</p>
                <p style={{ width: "15%" }}>Отсечка</p>
                <p style={{ width: "15%" }}>Доставка</p>
                <p style={{ width: "15%" }}>
                  Курьер/Статус заказа/Время доставки
                </p>
              </Columns>
              <Results style={{ display: "block" }}>
                {this.state.results.orders &&
                  this.state.results.orders.map((v, i) => {
                    let deliveryStatus = this.delStatus(v.status);
                    return (
                      <InfoOrderItem
                        key={v.id}
                        v={v}
                        deliveryStatus={deliveryStatus}
                        showAddress={this.showAddress}
                        cancel={this.cancel}
                        companies={this.state.results.companies}
                        handleSubmit={this.handleSubmit}
                        phone={this.state.results.phone}
                        allData={this.state.results.orders}
                      />
                    );
                  })}
              </Results>
            </ResultsList>
            <ResultsList>
              <P26 style={{ marginBottom: 7 }}>Последние отзывы</P26>
              <Columns>
                <p style={{ width: "14%" }}>Дата</p>
                <p style={{ width: "17%" }}>Столовые</p>
                <p style={{ width: "35%" }}>Товар</p>
                <p style={{ width: "28%" }}>Отзыв</p>
                <p>Оценка</p>
              </Columns>
              <Results style={{ display: "block" }}>
                {this.state.results.reviews &&
                  this.state.results.reviews.map((v, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          marginBottom:
                            i === this.state.results.reviews.length - 1
                              ? 0
                              : 10,
                        }}
                      >
                        <div style={{ width: "14%", paddingRight: 16 }}>
                          <p>{moment(v.dateTo).format("DD MMMM, dddd")}</p>
                        </div>
                        <div style={{ width: "17%", paddingRight: 16 }}>
                          <p>{v.restName}</p>
                        </div>
                        <div style={{ width: "35%", paddingRight: 16 }}>
                          <p>{v.product}</p>
                        </div>
                        <div style={{ width: "28%", paddingRight: 16 }}>
                          <p>{v.comment}</p>
                        </div>
                        <div>
                          <p>{v.value}</p>
                        </div>
                      </div>
                    );
                  })}
              </Results>
            </ResultsList>
          </Container>
        ) : (
          this.props.getPermissionsSuccess && <NoPermissions />
        )}
      </Main>
    );
  }
}

const mapStateToProps = (store) => ({
  getPermissionsSuccess: store.appMain.getPermissionsSuccess,
  permissions: store.appMain.permissions,
});
// error_ClientCard
const mapDispatchToProps = (dispatch) => ({
  getPermissions: () => dispatch(getPermissionsAction()),
  deliveryCycleInfoMessage: (action, data) =>
    dispatch(deliveryCycleInfoMessage(action, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCard);

const Main = styled.div`
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: #ecf0f1;
  font-family: "Roboto", sans-serif;
  text-align: left;
`;

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 0;
`;

const SearchBox = styled.div`
  width: 100%;
  height: 256px;
  padding: 37px 45px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

const P26 = styled.div`
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  color: #333;
`;

const SearchInput = styled.input`
  display: block;
  width: 546px;
  height: 40px;
  margin-bottom: 18px;
  padding: 11px 18px;
  border: 1px solid #d0d0d0;
  outline: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  color: #333;
`;

const SearchButton = styled.button`
  width: 160px;
  height: 37px;
  border: 0;
  outline: 0;
  border-radius: 3px;
  background-color: #3598db;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 5px rgba(21, 60, 86, 0.25);
  }

  &:active {
    box-shadow: inset 2px 2px 5px rgba(21, 60, 86, 0.25);
  }
`;

const ResultsList = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #fff;
  color: #999;
  font-size: 14px;
  line-height: 16px;
`;

const Results = styled.div`
  display: flex;
  width: 100%;
  min-height: 209px;
  margin-top: 12px;
  padding: 19px 0 19px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #fff;
  font-size: 14px;
  line-height: 16px;
  color: #333;
`;

const Cancel = styled.p`
  cursor: pointer;
`;
const BlackList = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
`;

const BlackListText = styled.p`
  font-size: 17px;
  padding-right: 12px;
  font-weight: bold;
  color: #333;
`;

const BLackListResult = styled.div`
  position: absolute;
  bottom: 15px;
  left: 10px;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  color: #333;
`;
const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  opacity: ${({ price }) => (price === 0 ? "0.5" : "1")};
  font-family: "Roboto";
  font-style: normal;
  border-bottom: 1px solid #f7f7f7;
`;
const Create = styled.div`
  width: 15%;
`;
const Kitchen = styled.div`
  width: 15%;
`;
const Sum = styled.div`
  width: 15%;
`;
const Addres = styled.div`
  width: 15%;
`;
const Average = styled.div`
  width: 15%;
`;
const Delivery = styled.div`
  width: 15%;
`;
const Couriers = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
`;
