import React from 'react'
import styled from 'styled-components'
export const NavReports = () => {
  return (
    <Nav>
        <Btn>Отчет</Btn>
        <Btn>Акт</Btn>
        <Btn>Платежные поручения</Btn>
    </Nav>
  )
}

const Nav = styled.div`
justify-self: start;
align-self: center;
height:100%;
`
const Btn = styled.button`
margin-right:10px;
padding: 18px 16px;
height: 55px;
background: #7882A7;
border-radius: 8px;
color: #FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 130%;
border:none;
cursor: pointer;
  :hover{
    color:#2196F3;
  }
`
