import React, {memo, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux"
import {
  resetSelectedCourier,
  resetSelectedOrdersToRedirect,
  setSelectedCourier, toggleShowCourierInfo
} from "../../reducers/toolkit/orderMapReducer"

const defaultProps = {
  name: 'unnamed',
  color: 'white',
}

const Courier = ({courierData = defaultProps}) => {
  const dispatch = useDispatch()
  const selectedCourier = useSelector(store => store.orderMap.selectedCourier)
  const selectedCourierColor = selectedCourier?.color
  const {name, color} = courierData
  const [isSelect, setIsSelect] = useState(false)
  const localCourierId = courierData?.id || courierData?.clientId
  const selectedCourierId = selectedCourier?.id || selectedCourier?.clientId

  useEffect(() => {
    if(localCourierId === selectedCourierId) {
      setIsSelect(true)
    } else {
      setIsSelect(false)
    }
    if(!selectedCourier) {
      setIsSelect(false)
      dispatch(resetSelectedOrdersToRedirect())
    }
  }, [selectedCourier]);

  const selectionHandler = useCallback(courierData => {
    if(localCourierId === selectedCourierId) {
      dispatch(resetSelectedCourier())
    } else {
      dispatch(resetSelectedOrdersToRedirect())
      dispatch(setSelectedCourier(courierData))
    }
  }, [selectedCourier]);

  const toggleCourierInfoHandler = () => {
    dispatch(toggleShowCourierInfo(courierData))
  }

  return (
    <CourierWrapper>
      <MarkerWrapper onClick={() => {
        selectionHandler(courierData, selectedCourier)
      }} {...{isSelect}}>
        <Marker  {...{color, selectedCourierColor, isSelect}}/>
      </MarkerWrapper>
      {
        courierData.clientId ?
          <p>{name}</p>
          :
          <p onClick={toggleCourierInfoHandler}>{name}</p>
      }
    </CourierWrapper>
  )
}

export default memo(Courier);

const CourierWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  
  
  >p{
    text-decoration: underline;
    color: #3498DB;
    cursor: pointer;
  }
`

export const MarkerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: ${({isSelect}) => isSelect ? "50%" :"8px"};
  border: ${({isSelect}) => isSelect ? "1px dotted #1890ff" :"1px solid silver"};
  cursor: pointer;
  transition: all .2s ease;

  :hover {
    border-radius: 50%;
  }
`
export const Marker = styled.div`
  background: ${ ({color, selectedCourierColor, isSelect}) => color ? color : isSelect && selectedCourierColor};
  width: 20px;
  height: 20px;
  border: 1px solid silver;
  border-radius: 50%;
`
