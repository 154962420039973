import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion"

const RightControlPanel = ({children, showCourierSidebar, controlsXShift}) => {
  return (
    <Wrapper
      animate={{
        x: showCourierSidebar ? -controlsXShift : 0
      }}
      transition={{ease: "easeOut"}}
    >
      {children}
    </Wrapper>
  )
}

export default RightControlPanel;

const Wrapper = styled(motion.div)`
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 5;
  display: flex;
  gap: 15px;
`
