import trash from "../../../images/trash.svg";

import React, {memo, useEffect} from "react";
import moment from "moment";
import styled from "styled-components";
import {deleteWaybill, getWaybillDetails} from "../../../actions/WarehouseActions";
import {nanoid} from "nanoid"
import {useDispatch} from "react-redux"


const WaybillListItem = ({data, selectedSenderName, selectedRecipientName, setDeleteHover, grid}) => {

    const dispatch = useDispatch()

    const clickHandle = () => {
        dispatch(getWaybillDetails(data.id))
    }
    const deleteHandle = e => {
        e.stopPropagation()
        dispatch(deleteWaybill(data))
    }

    return (
        <ListElement
            key={nanoid()}
            style={{...grid, ...{zIndex: 1}}}
            onClick={clickHandle}
        >
            <p>{data.waybill}</p>
            <p>{moment(data.waybillDate).format("DD.MM.YYYY")}</p>
            <p>{selectedSenderName}</p>
            <p>{selectedRecipientName}</p>
            <p>{data.productPriceSum}</p>
            <DeleteWaybillBtn
                onClick={e => deleteHandle(e)}
                onMouseOver={() => setDeleteHover(true)}
                onMouseLeave={() => setDeleteHover(false)}
            >
                <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">

                        <path d="m424 64h-88v-16c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16h-88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zm-216-16c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96z"/><path d="m78.364 184c-2.855 0-5.13 2.386-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042c.136-2.852-2.139-5.238-4.994-5.238zm241.636 40c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"/>

                </svg>
            </DeleteWaybillBtn>
        </ListElement>
    )
}

export default memo(WaybillListItem);

const ListElement = styled.div`
  align-items: center;
  text-align: left;
  padding: 10px 10px;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
  border: 2px solid transparent;
  transition: transform .2s ease;
  p {
    width: 100%;
    margin: 0;
  }
  svg {
    width: 15px;
    height: 100%;
  }
  :hover {
    transform: scale(1.010);
    border: 2px solid #14142a;
  }
`
const DeleteWaybillBtn = styled.button`
  background: none;
  border: none;
  align-self: start;
  margin: 0 auto 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: all .2s ease;
  
  :hover {
    background: rgba(255,0,0,0.7);
    border-radius: 5px;
    
    >svg {
      fill: white;
    }
  }
`
