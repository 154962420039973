import React from "react";
import styled from "styled-components";

export const UIcomponents = ({positive,negative,cause,setCause,deleteH}) => {
    return (  
        <FormControlsWrapper>
            <Btn.save onClick={positive} style={{marginRight: 18}}>Одобрить</Btn.save>
            <InputInfo  placeholder="Введите причину отказа" value={cause} onChange={(e)=>setCause(e.target.value)}/>
            <Btn.change onClick ={negative}>Отправить на доработку</Btn.change>
            <Btn.delete onClick={deleteH} style={{color:'red'}}>Удалить</Btn.delete>
        </FormControlsWrapper>
    )
}

const FormControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`
const BtnParent = styled.button`
  display: flex;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all .2s ease-out;
`
const InputInfo = styled.input`
height: 32px;
margin-right: 15px;
background: #E6E9F0;
border: none;
border-radius: 6px;
`
const Btn = {

    save: styled(BtnParent)`
    align-items: center;
    justify-content: center;
      background: #3D425E;
      height: 32px;
      width: 112px;
      color: #fff;
      border: 1px solid transparent;
      :hover {
        border: 1px solid #3D425E;
        background: #fff;
        color: inherit;
      }
    `,
    delete: styled(BtnParent)`
    align-items: center;
    justify-content: center;
      background: #fff;
      height: 32px;
      width: auto;
      border: 1px solid #DDDDDD;
      :hover {
        border: 1px solid #3D425E;
      }
    `,
    change: styled(BtnParent)`
     align-items: center;
    justify-content: center;
        margin-right: auto;
        height: 32px;
        width: auto;
        background: #fff;
        border: 1px solid #DDDDDD;
      
      :hover {
        border: 1px solid #3D425E;
      }
    `
}
