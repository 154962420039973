import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSenderModal} from "../../../../actions/WarehouseActions"
import CustomSelect from "../../../share/UI/CustomSelect"

const SenderSelectorModal = ({style = {}, externalValue = null}) => {

    const dispatch = useDispatch()
    const senderState = useSelector(store => store.warehouse.senderState)
    const [selectedSender, setSelectedSender] = useState(null)

    useEffect(() => {
        if (selectedSender !== null) {
            dispatch(selectSenderModal(selectedSender))
        }
    }, [selectedSender])

    useEffect(() => {
        if(externalValue !== null) {
            const senderId = senderState.senders.reduce((acc, el, id) => {
                if(el.id === externalValue) {
                    acc = id
                }
                return acc
            }, null)

            setSelectedSender(senderId)
        }
    }, [externalValue])

    const senderSelectionHandler = (val) => setSelectedSender(val)

    return <CustomSelect
      data={senderState.senders}
      value={selectedSender}
      handler={senderSelectionHandler}
      styles={style}/>
}

export default memo(SenderSelectorModal);
