import axios from 'axios';
import {sha256} from 'js-sha256';
import {createStore} from 'redux'


function playlist(state = [], action) {
  if (action.type === 'ADD_TRACK') {
    return [...state, action.payload];
  }
  return state;
}

export const store = createStore(playlist);


export function sr(service, method, data, options) {
  let d = {service: service, method: method, data: data};

  // const api_url = "https://menuforme.online/api/";
  //Боевой
  
  // const api_url = "http://dev.menu4me.ru:81/api/";
  //Коля рабочий

  // const api_url = "https://dev.menu4me.ru/api/";
  //Сергей рабочй

  // const api_url = "https://bottest.menu4me.ru";
  //Коля домашний

  //const api_url = "http://85.112.51.9:8080/api/"; 
  //???
  
  const api_url = window.location.hostname == "admin-test.menuforme.online" ? "http://admin-test.menuforme.online/" : "https://menuforme.online/api/";
  //Сергей рабочий + боевой для клиентов


  d.data.token = window.localStorage.getItem('token') || '';
  d.data.session = window.localStorage.getItem('session') || '';
  let loginHash = sha256(localStorage.getItem('login') || '');
  let passwordHash = localStorage.getItem('passwordHash');
  // d.data.token    = 'c1eb98a2c4c4a9c6bdd217d41e7wfc1df538d1b2adea8246bd485076546d12444';
  // d.data.session  = 'a811632c6b4cdd859b45a3ecaa08344942d52b983d8dd04c0aa18f4a01d89ffc';
  // let loginHash = sha256('winch@menu4me.ru');
  // let passwordHash = '47abf66e61131e73dca06fe352a6dd7bf5d4a7a7a94ba939155686ba0f04ccc6';
  d.data.time = new Date().getTime();
  d.data.clientInfo = JSON.stringify({
    osVersion: "site",
    model: "",
    appVersion: "0.1",
    os: "site",
    brand: "",
    deviceId: "site",
    appId: "com.menu4me.dinner.admin.sitiyoffice"
  });
  d.data.signature = CalcSignature(d, loginHash, passwordHash);

  return new Promise(function (resolve, reject) {

    axios.post(api_url, JSON.stringify(d),options).then(
      function (resp) {
        if (typeof resp.data === 'object') {
          if ([0, 's151', 's152', 'cas301', 'cas302'].indexOf(resp.data.resultCode) > -1) {
            console.error(resp.data.resultCode, service, method, resp.data);
            reject(resp.data);
          } else {
            resolve(resp.data);
          }
        } else {
          console.warn('resp:', resp.data);
          reject('error!');
        }
      },
      function (error) {
      },
    );
  });
}




// export function srForOld(service,method,data){
//   let d = {service: service, method: method, data: data};
//   // const api_url = location.hostname == "localhost" ? "http://admin.menu4me.local/api/" : "http://admin.menu4me.com/api/";
//   const api_url = "https://menuforme.online/api/";
//   //const api_url = "http://85.112.51.9:8080/api/";
//   // const api_url = "http://dev.menu4me.ru:81/";
//   // const api_url = "http://dev.menu4me.ru/api/";


//   d.data.token = window.localStorage.getItem('token') || '';
//   d.data.session = window.localStorage.getItem('session') || '';
//   let loginHash = sha256(localStorage.getItem('login') || '');
//   let passwordHash = localStorage.getItem('passwordHash');
//   // d.data.token    = 'c1eb98a2c4c4a9c6bdd217d41e7fc1df538d1b2adea8246bd485076546d12444';
//   // d.data.session  = 'a811632c6b4cdd859b45a3ecaa08344942d52b983d8dd04c0aa18f4a01d89ffc';
//   // let loginHash = sha256('winch@menu4me.ru');
//   // let passwordHash = '47abf66e61131e73dca06fe352a6dd7bf5d4a7a7a94ba939155686ba0f04ccc6';
//   d.data.time = new Date().getTime();
//   d.data.clientInfo = JSON.stringify({
//     osVersion: "site",
//     model: "",
//     appVersion: "0.1",
//     os: "site",
//     brand: "",
//     deviceId: "site",
//     appId: "com.menu4me.dinner.admin.sitiyoffice"
//   });
//   d.data.signature = CalcSignature(d, loginHash, passwordHash);

//   return new Promise(function (resolve, reject) {

//     axios.post(api_url, JSON.stringify(d)).then(
//       function (resp) {
//         if (typeof resp.data === 'object') {
//           if ([0, 's151', 's152', 'cas301', 'cas302'].indexOf(resp.data.resultCode) > -1) {
//             console.error(resp.data.resultCode, service, method, resp.data);
//             reject(resp.data);
//           } else {
//             resolve(resp.data);
//           }
//         } else {
//           console.warn('resp:', resp.data);
//           reject('error!');
//         }
//       },
//       function (error) {
//       },
//     );
//   });
// }

function CalcSignature(d, loginHash, passwordHash) {
  return sha256(d.service + d.method + d.data.time + d.data.token + loginHash + d.data.session + passwordHash);
}

export function toObject(a, idField) {
  if (idField === undefined) {
    throw new Error("say  name of idField");
  }

  let r = {};
  for (let i in a) {
    r[a[i][idField]] = a[i];
    //delete a[i][idField];
  }
  return r;
}

