import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import HeaderMain from "../../components/Assort/HeaderMain";
import Content from "../../components/Assort/Content";
import Aside from "../../components/Assort/Aside";
import { OnCheckedModal } from "../../components/Assort/Components/Modals/OnCheckedModal";
import { getPermissionsAction } from "../../reducers/toolkit/mainAppReducer";
function Assortment(props) {
  const [addProductFieldShow, setAddProductFieldShow] = useState(false);
  const [addCategoryFieldShow, setAddCategoryFieldShow] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [productsInSelectedCategory, setProductsInSelectedCategory] = useState(
    []
  );
  // const [scroll,setScroll] = useState(0)
  const scrollsRef = useRef(null);
  const mainScrollRef = useRef(null);
  const [active, SetActive] = useState(false);
  const getPermissionsSuccess = useSelector(
    (store) => store.appMain.getPermissionsSuccess
  );
  const [openModal, setOpenModal] = useState(false);
  // const permissions = useSelector(store => store.appMain.permissions);
  const dispatch = useDispatch();
  const refForAddProd = useRef(null);

  // const [scroll, setScroll] = React.useState(0);

  // const handleScroll = () => {
  //   setScroll(mainScrollRef.current.scrollY);
  // };

  // const handleUpButton = () => {
  //   window.scrollTo(0, 0);
  // };

  // React.useEffect(() => {
  //   mainScrollRef.current.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  // console.log(scroll,'SCROLLL')
  //   const checkRole = (param) => {
  //     if (permissions.staff) {
  //         if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
  //             return true
  //         } else {
  //             return permissions.permissions.includes(param)
  //         }
  //     } else {
  //         return false
  //     }
  // }
  // useEffect(() => {
  //   if (!getPermissionsSuccess) {
  //       dispatch(getPermissionsAction())
  //   }
  // }, [])
  return (
    <MainWrapper ref={mainScrollRef}>
      <Container>
        {/* {checkRole('screen_assortment')
                ? */}
        <>
          <HeaderMain
            {...{
              setAddProductFieldShow,
              setAddCategoryFieldShow,
              setSelectAll,
              productsInSelectedCategory,
              scrollsRef,
              openModal,
              setOpenModal,
              refForAddProd,
            }}
          />
          <Content
            {...{
              addProductFieldShow,
              addCategoryFieldShow,
              setAddProductFieldShow,
              setAddCategoryFieldShow,
              selectAll,
              setProductsInSelectedCategory,
              scrollsRef,
              openModal,
              setOpenModal,
              refForAddProd,
            }}
          />
          {
            <Aside
              {...{
                setAddProductFieldShow,
                addProductFieldShow,
                openModal,
                setOpenModal,
                scrollsRef,
                mainScrollRef,
              }}
            />
          }
          {/* <OnCheckedModal/> */}
        </>
        {/* :
                '' */}
      </Container>
    </MainWrapper>
  );
}

export default memo(Assortment);

const MainWrapper = styled.div`
  min-height: calc(100vh - 64px);
  position: relative;
  background: #e5e5e5;
  /* overflow: scroll; */
  overflow: auto;
`;

const Container = styled.div`
  padding-top: 79px;
  display: grid;
  grid-template-areas:
    "aside header"
    "aside content";
  grid-template-columns: 0.1fr 0.9fr;
  grid-template-rows: 146px 1fr;
`;
