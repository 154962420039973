import createSagaMiddleware from 'redux-saga'
import {rootReducer} from "../reducers/rootReducer"
import thunk from "redux-thunk"
import {composeWithDevTools} from "redux-devtools-extension"
import {createStore, applyMiddleware} from "redux"
import reviewsWatcher from "../sagas/reviews/reviewsWatcher";
import WarehouseWatcher from "../sagas/warehouse/warehouseWatcher";
import mainAppWatcher from "../sagas/mainApp/mainAppWatcher"
import authWatcher from "../sagas/auth/authWatcher"
import registerServiceWatcher from "../sagas/registerService/registerServiceWatcher"
import commonWatcher from "../sagas/common/commonWatcher"
import {orderMapWatcher} from "../sagas/orderMap/orderMapWatcher"
import assortementWatcher from '../sagas/assortment/assortmentWatcher'


const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, thunk)));

sagaMiddleware.run(authWatcher);
sagaMiddleware.run(commonWatcher);
sagaMiddleware.run(orderMapWatcher);
sagaMiddleware.run(registerServiceWatcher);
sagaMiddleware.run(mainAppWatcher);
sagaMiddleware.run(reviewsWatcher);
sagaMiddleware.run(WarehouseWatcher);
sagaMiddleware.run(assortementWatcher);

export default store;
