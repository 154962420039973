//CONST
export const GET_RECIPIENT = 'GET_RECIPIENT'
export const GET_RECIPIENT_READY = 'GET_RECIPIENT_READY'
export const GET_RECIPIENT_ERROR = 'GET_RECIPIENT_ERROR'
export const SELECT_RECIPIENT = 'SELECT_RECIPIENT'
export const SELECT_RECIPIENT_MODAL = 'SELECT_RECIPIENT_MODAL'
export const RESET_SELECTED_RECIPIENT = 'RESET_SELECTED_RECIPIENT'

export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE'
export const RESET_DATE_RANGE = 'RESET_DATE_RANGE'

export const GET_DOWNLOAD_LINK = 'GET_DOWNLOAD_LINK'
export const GET_DOWNLOAD_LINK_SUCCESS = 'GET_DOWNLOAD_LINK_SUCCESS'
export const GET_DOWNLOAD_LINK_ERROR = 'GET_DOWNLOAD_LINK_ERROR'
export const RESET_DOWNLOAD_LINK = 'RESET_DOWNLOAD_LINK'

export const GET_SENDER = 'GET_PROVIDER'
export const GET_SENDER_READY = 'GET_SENDER_READY'
export const GET_SENDER_ERROR = 'GET_SENDER_ERROR'
export const SELECT_SENDER = 'SELECT_SENDER'
export const SELECT_SENDER_MODAL = 'SELECT_SENDER_MODAL'
export const RESET_SELECTED_SENDER = 'RESET_SELECTED_SENDER'

export const GET_WAYBILL = 'GET_WAYBILL'
export const GET_WAYBILL_READY = 'GET_WAYBILL_READY'
export const GET_WAYBILL_ERROR = 'GET_WAYBILL_ERROR'
export const RESET_WAYBILL = 'RESET_WAYBILL'

export const GET_WAYBILL_DETAILS = 'GET_WAYBILL_DETAILS'
export const GET_WAYBILL_DETAILS_READY = 'GET_WAYBILL_DETAILS_READY'
export const GET_WAYBILL_DETAILS_ERROR = 'GET_WAYBILL_DETAILS_ERROR'

export const GET_PRODUCT_LIST_BY_RECIPIENT = 'GET_PRODUCT_LIST_BY_RECIPIENT'
export const GET_PRODUCT_LIST_BY_RECIPIENT_READY = 'GET_PRODUCT_LIST_BY_RECIPIENT_READY'
export const GET_PRODUCT_LIST_BY_RECIPIENT_ERROR = 'GET_PRODUCT_LIST_BY_RECIPIENT_ERROR'

export const SEND_WAYBILL = 'SEND_WAYBILL'
export const SEND_WAYBILL_READY = 'SEND_WAYBILL_READY'
export const SEND_WAYBILL_ERROR = 'SEND_WAYBILL_ERROR'

export const DELETE_WAYBILL = 'DELETE_WAYBILL'
export const DELETE_WAYBILL_STATUS = 'DELETE_WAYBILL_STATUS'
export const DELETE_WAYBILL_ERROR = 'DELETE_WAYBILL_ERROR'
export const RESET_DELETE_FLAG = 'RESET_DELETE_FLAG'

export const SELECT_WAYBILL = 'SELECT_WAYBILL'
export const RESET_SELECT_WAYBILL = 'RESET_SELECT_WAYBILL'

export const TRY_CREATE_WAYBILL = 'TRY_CREATE_WAYBILL'
export const TRY_EDIT_WAYBILL = 'TRY_EDIT_WAYBILL'

export const UPDATE_WAYBILL = 'UPDATE_WAYBILL'
export const UPDATE_WAYBILL_STATUS = 'UPDATE_WAYBILL_STATUS'
export const UPDATE_WAYBILL_ERROR = 'UPDATE_WAYBILL_ERROR'

export const ADD_CREATED_WAYBILL = 'ADD_CREATED_WAYBILL'
export const RESET_CREATED_WAYBILL = 'RESET_CREATED_WAYBILL'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
//---

//ACTIONS
export const getRecipient = () => ({type: GET_RECIPIENT})
export const selectRecipient = payload => ({type: SELECT_RECIPIENT, payload})
export const selectRecipientModal = payload => ({type: SELECT_RECIPIENT_MODAL, payload})
export const resetSelectedRecipient = () => ({type: RESET_SELECTED_RECIPIENT})

export const getSender = () => ({type: GET_SENDER})
export const selectSender = payload => ({type: SELECT_SENDER, payload})
export const selectSenderModal = payload => ({type: SELECT_SENDER_MODAL, payload})
export const resetSelectedSender = () => ({type: RESET_SELECTED_SENDER})

export const selectDateRange = payload => ({type: SELECT_DATE_RANGE, payload})
export const resetDateRange = () => ({type: RESET_DATE_RANGE})

export const getStorageAsExel = payload => ({type: GET_DOWNLOAD_LINK, payload})
export const resetExelLink = () => ({type: RESET_DOWNLOAD_LINK})

export const getWaybill = payload => ({type: GET_WAYBILL, payload})
export const getWaybillDetails = payload => ({type: GET_WAYBILL_DETAILS, payload})
export const resetWaybillData = () => ({type: RESET_WAYBILL})

export const getProductListByRecipient = payload => ({type: GET_PRODUCT_LIST_BY_RECIPIENT, payload})

export const selectWaybill = payload => ({type: SELECT_WAYBILL, payload})
export const resetSelectedWaybill = () => ({type: RESET_SELECT_WAYBILL})

export const deleteWaybill = payload => ({type: DELETE_WAYBILL, payload})
export const resetDeleteFlag = () => ({type: RESET_DELETE_FLAG})


export const sendCreatedWaybill = payload => ({type: SEND_WAYBILL, payload})
export const addCreatedWaybill = payload => ({type: ADD_CREATED_WAYBILL, payload})
export const resetCreatedWaybill = () => ({type: RESET_CREATED_WAYBILL})

export const updateWaybill = payload => ({type: UPDATE_WAYBILL, payload})

export const toggleModal = payload => {
    if (payload === 'EDIT') {
        return {type: TRY_EDIT_WAYBILL}
    }
    if (payload === 'CREATE') {
        return {type: TRY_CREATE_WAYBILL}
    }
    if (!payload) {
        return {type: TOGGLE_MODAL}
    }
}

//---
