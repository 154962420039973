import React,{useEffect,useState} from 'react'
import { sr } from '../../utils';
import {Select, Switch} from "antd";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import NoPermissions from '../../components/NoPermissions'
import { ReportsItem } from '../../components/Settings/ReportsItem/ReportsItem';
import { useDispatch } from 'react-redux';
import {deliveryCycleInfoMessage} from '../../actions/ModalActions'
import MainSettings from '../../components/Settings/MainSettings/MainSettings';
import Stocks from '../../components/Settings/Stocks/Stocks';
import Modifiers from '../../components/Settings/Modifiers/Modifiers';
import AdditionalSettings from '../../components/Settings/AdditionalSettings/AdditionalSettings';
import PointsOfSale from '../../components/Settings/PointsOfSale/PointsOfSale';
import StartNotifications from '../../components/Settings/StartNotif/StartNotifications';
const {Option} = Select;
const path = [
    {id:1,name:'Основные настройки'},
    {id:2,name:'Дополнительные настройки'},
    {id:3,name:'Модификаторы/Гарниры'},
    {id:4,name:'Акции'},
    {id:5,name:'Точки продаж'},
    {id:6,name:'Договоры'},
    {id:7,name:'Стартовые уведомления'}

]
export function Settings(){
//     const [kithens,setKithens] = useState([])
//     const [selectedKithens,setSelectedKithens] = useState()
//     const [infoKithens,setInfoKithens] = useState([])
//     const [selectPath,setSelectPath] = useState(null)
//     const [stateRes,setStateRes] = useState('')
//     const [infoDel,setInfoDelivery] = useState([])
//     const permissions = useSelector(store => store.appMain.permissions);
//     const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
//     const dispatch = useDispatch()
//     const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

// const kithensReq = () =>{
//     sr('AppSOKitchenService','GetRestaurantsForOperator',{})
//     .then(res=>{
//         if(res.result){
//          setKithens(res.data.items)   
//         }else{
//          dispatch(deliveryCycleInfoMessage('error_ClientCard'))
//         }
//     })
// }
// const checkRole = (param) => {
//     if (permissions.staff) {
//         if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
//             return true
//         } else {
//             return permissions.permissions.includes(param)
//         }
//     } else {
//         return false
//     }
// }
// const getDelInfo=()=>{
//     setStateRes('start')
//     sr('SettingsServiceSO','GetRequisites',{
//         account:SelectedKitchens.id
//     }).then(res=>{
//         if(res.result){
//           setInfoDelivery(res.data.rest)
//           setStateRes('resolved')
//         }else{
//           setStateRes('reject') 
//           dispatch(deliveryCycleInfoMessage('error_ClientCard'))
//         }}
//     )
// }

// const getDel = ()=>{
//     sr('Reports', 'OffsettingPeriod',{})
//         .then(res=>{
//         if(res.result){
//           setInfoKithens(res.data.list)
//         //   setInfoDelivery(res.data.rest)
//         //   setStateRes('resolved')
//         console.log(res,'RES')
//         }else{
//           setStateRes('reject') 
//           dispatch(deliveryCycleInfoMessage('error_ClientCard'))
//         }}
//     )
// }
// useEffect(()=>{
//     kithensReq()
// },[])

// const getInfoReq=()=>{
//     console.log(permissions,'PERM');
//     setStateRes('start')
//     sr('SettingsServiceSO','GetRequisites',{
//         account:SelectedKitchens.id
//     }).then(res=>{
//         if(res.result){
//           setInfoKithens(res.data.list)
//           setInfoDelivery(res.data.rest)
//           setStateRes('resolved')
//         }else{
//           setStateRes('reject') 
//           dispatch(deliveryCycleInfoMessage('error_ClientCard'))
//         }}
//     )
// }
// useEffect(()=>{
//     if(SelectedKitchens){
//     getInfoReq()
//     }
// },[SelectedKitchens])
// // useEffect(()=>{
// //     kithensReq()
// // },[])
// useEffect(()=>{
//     getDelInfo()
// },[])
// useEffect(()=>{
//     console.log(SelectedKitchens,'SelectedKitchens');
// },[SelectedKitchens])

// const handlePath=()=>{
//     switch (selectPath) {
//         case "Договоры":
//             return (stateRes === 'resolved' ? <ReportsItem {...{infoKithens,selectedKithens}}/> : '')
//         case "Основные настройки":
//             // return ( <MainSettings/>)
//         case "Акции":
//             // return (<Stocks/>)
//         case "Модификаторы/Гарниры":
//             // return (<Modifiers/>)
//         case "Дополнительные настройки":
//             // return (<AdditionalSettings/>)
//         case "Точки продаж":
//             // return (<PointsOfSale/>)
//         case "Стартовые уведомления":
//             //    return (<StartNotifications/>)
//         default:
//             break;
//     }
// }
  return (
    <Wrapper>
        Здесь скоро будет раздел "Настройки"
        {/* <HeaderWrapp>
        </HeaderWrapp>
        
        <ContentWrapp>
           <MainBlock>
               <WrapperNav>
                    {
                      path.map(el=>{
                         return (
                             <NavItem key={el.id} onClick={()=>setSelectPath(el.name)} isSelect={selectPath === el.name ? true : false}>
                                 <p>{el.name}</p>
                             </NavItem>
                         )
                      })
                    }
               </WrapperNav>
            <Blocks>
                <Block>
                <HeaderBlock>
                    <h2 style={{fontWeight:600}}>{selectPath}</h2>
                </HeaderBlock>
                { checkRole('screen_setting')
                ?
                  handlePath()
                :
                getPermissionsSuccess && (
                    <NoPermissions />
                )
                }
                </Block>
            </Blocks>
        </MainBlock> 
        </ContentWrapp> */}
    </Wrapper>
  )
}
const Wrapper = styled.div`
display: grid;
height: calc(100vh - 64px);
justify-content: center;
align-items: center;
color:#161616;
font-size: 30px;
font-weight: 600;
`
// const Wrapper = styled.div`
// display: grid;
// grid-template-rows: auto 1fr;
// justify-items: center;
// background-color: #ecf0f1;
// min-height: 100vh;
// `
const HeaderWrapp = styled.div`
justify-self: start;
align-self: end;
padding: 30px;
`
const ContentWrapp = styled.div`
min-width: 1200px;
`
const KithensSelect = styled.div`
display: flex;
flex-direction: row;
width:auto;
`
const MainBlock = styled.div`
display: grid;
grid-template-rows:5% 1fr;
`
const Blocks = styled.div`
display: grid;
justify-items: center;
width: auto;
margin-top:50px;
`
const Block = styled.div`
display: grid;
grid-template-rows:.1fr 1fr;
justify-items: start;
justify-self: start;
width:100%
`
const HeaderBlock = styled.div`
height: auto;
`


const WrapperNav = styled.div`
display: grid;
grid-template-columns: repeat(7,auto);
height: 50px;
`
const NavItem = styled.div`
border: 2px solid #7882A7;
background-color: ${({isSelect})=>isSelect ? '#7882A7': '#FFFF'};
/* background-color: #FFFF; */
color:${({isSelect})=>isSelect ?'#FFFF':'#111111'};
border-radius: 6px;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 16px;
height: 50px;
cursor: pointer;
    :hover{
        background-color: #7882A7;
        color:#FFFF;
    }
`
