import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import WaybillListItem from "./WaybillListItem";
import {nanoid} from "nanoid";
import {useScrollObserver} from "../../../hooks/useScrollObserver"
import {useDispatch, useSelector} from "react-redux"

const WaybillTable = ({grid, senderName, recipientName, waybills = [], setIntersect}) => {
    const recipients = useSelector(store => store.warehouse.recipientState.recipients)
    const senders = useSelector(store => store.warehouse.senderState.senders)

    const [deleteHover, setDeleteHover] = useState(false)

    const listRef = useRef()
    const intersectFlag = useRef()
    const intersectEvent = useScrollObserver(listRef, intersectFlag)

    useEffect(() => {
        setIntersect(intersectEvent)
    }, [intersectEvent])

    return (
        <WaybillsTable ref={listRef}>
            {
                waybills.map((el) => {
                    const rec = Object.values(recipients).reduce((acc, elRec) => {
                        if(elRec.id === el.recipientId) {
                            acc = elRec.name
                        }
                        return acc
                    }, '')
                    const snd = Object.values(senders).reduce((acc, elRec) => {
                        if(elRec.id === el.counterpartieId) {
                            acc = elRec.name
                        }
                        return acc
                    }, '')
                    return (
                      <WaybillListItem
                        selectedSenderName={snd}
                        selectedRecipientName={rec}
                        data={el}
                        deleteHover={deleteHover}
                        setDeleteHover={setDeleteHover}
                        grid={grid}/>
                    )
                })
            }
            <div ref={intersectFlag} /> {/* flag of intersection observer */}
        </WaybillsTable>
    )
}

export default WaybillTable;

const WaybillsTable = styled.div`
  height: calc(100% - 49px);
  padding: 20px 10px;
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
    display: none;
  }
  div:nth-child(n+1) {
    margin-bottom: 10px;
  }
  div:last-child {
    margin-bottom: 0;
  }
`
