import { DatePicker, Input, Switch, TimePicker, Upload } from 'antd'
import React,{useState} from 'react'
import styled from 'styled-components'
import Close from '../../../../images/close.svg'
import MainInfo from './MainInfo'

const PointsModal = ({showModal,setShowModal}) => {

  const closeModal=()=>setShowModal(false)

  return (
    <>
    <WrapperModal style={{right: !showModal ? -649 : 0, zIndex: 10}}>
        <HeaderModal>
        <BtnModal>Сохранить</BtnModal>
        <div style={{marginLeft:'auto'}}>
          <img src={Close} style={{height:20,width:20,cursor:'pointer'}} onClick={closeModal}/>
        </div>
        </HeaderModal>
        <div>
        <MainInfo/>
        </div>
    </WrapperModal>
    </>
  )
}
export default PointsModal

const WrapperModal = styled.div`
display: grid;
grid-template-rows: auto auto 1fr;
position: fixed;
top: 64px;
width: 649px;
height: calc(100vh - 60px);
background-color: #fff;
box-shadow: 0 0 62px rgba(0, 0, 0, 0.1);
overflow: scroll;
transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
`
const HeaderModal = styled.div`
border-bottom: 1px solid rgb(169,169,169,0.5);
display: flex;
flex-direction: row;
padding: 15px;
align-items: center;
color:#161616;
font-size: 15px;
font-weight: 500;
font-family: 'Roboto';
font-style: normal;
`
const BtnModal =styled.div`
background-color: #3498db;
color:#fff;
padding: 15px;
height: 36px;
display: flex;
align-items: center;
cursor: pointer;
`
const HeaderNav =styled.div`
display: flex;
flex-direction: row;
padding: 10px 10px 10px 15px;
border-bottom: 1px solid rgb(169,169,169,0.5);
`