import React,{useEffect, useState,memo,useCallback, useMemo} from 'react'
import  ImageComponents  from './ImageComponents'
import Photo from '../../../../images/Photo.svg'

 const ImageContainer = (props) => {
    const [pic,setPic] = useState({})
const changePhoto=()=>{
  if(props.base64){
      return props.getsPhoto ? {src:props.getsPhoto} :  {src:props.base64,id:props.base64,flag:'forNew',media:props.base64}
  }
  if(props.tempPic){
      return props.getsPhoto ? {src:props.getsPhoto} : {src:`https://menuforme.online/files/moderation/${props.tempPic}.jpg`,id:props.tempPic,flag:'forMod'}
  }if(props.media){
      return props.getsPhoto ? {src:props.getsPhoto} : {src:`https://menuforme.online/img/cache/product_standard/${props.media}.jpg`,id:props.media,flag:'forSimplePhoto'}
  }if(props.getsPhoto){
      return props.getsPhoto ? {src:props.getsPhoto} : null
  }   
}
const memoized = useMemo(()=>{
  return changePhoto()
},[props.tempPic,props.base64,props.media,props.getsPhoto,props.getsPhoto])

const memoStyle = useMemo(()=>{
  return props.style
},[props.style])

// useEffect(()=>{
//  changePhoto()
// },[])
  return (
    <ImageComponents
    style={{...memoStyle}}
    src = {memoized}
    id = {memoized}
    flag = {memoized}
    ActualVersion={props.ActualVersion}
    open = {props.open}
    clear={props.clear}
    withBorder={props.withBorder}
    enabled = {props.enabled} 
    newPhoto={props.newPhoto}
    border={props.border}
    />

  )
}
export default memo(ImageContainer)