import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {toggleModal} from "../../../pages/Delivery/OrderMap"
import {useDispatch, useSelector} from "react-redux"
import {nanoid} from "nanoid"
import {redirectCouriers} from "../../../reducers/toolkit/orderMapReducer"
const plural = require('plural-ru');

let EXTERNAL_PROPS = {}

export const MODAL_NAME_REDIRECT_ORDERS = 'redirectOrdersModal'

export function toggleRedirectOrdersModal(props = null) {
  toggleModal(MODAL_NAME_REDIRECT_ORDERS)
  if(props) EXTERNAL_PROPS = {...props} = props
}

const RedirectOrdersFromCourierToCourier = () => {

  const dispatch = useDispatch()

  const {courierId, selectedOrders} = EXTERNAL_PROPS

  const couriers = useSelector(store => store.orderMap.couriers)
  const allCouriers = useSelector(store => store.common.couriers)

  const [courierListsDiff, setCourierListsDiff] = useState([])
  const [courierList, setCourierList] = useState([])

  const point = plural(selectedOrders.length, 'точку', 'точки', 'точек')

  useEffect(() => {
    if(couriers.length !== 0) {
      const ids = couriers.map(el => el.id)
      const diff = allCouriers.filter(el => !ids.includes(el.clientId))
      setCourierListsDiff(diff)
    } else {
      setCourierListsDiff(allCouriers)
    }
  }, []);

  useEffect(() => {
    if(courierListsDiff.length) {
      setCourierList([...couriers, ...courierListsDiff].filter(el => (el.id ||el.clientId) !== courierId))
    }
  }, [courierListsDiff])
  
  const sendRedirectionData = () => {
    const dataObject = {
      orders: selectedOrders,
      courierId: courierId,
    }
    console.log(selectedOrders,courierId,'SLOMANO')
    dispatch(redirectCouriers(dataObject))
  }

  return (
    <Wrapper>
      <p>Переназначить {selectedOrders.length} {point} на:</p>
      <ListWrapper>
        {
          courierList.length > 0 && courierList.map(el => <LestElement key={nanoid()} data={el} sendHandler={sendRedirectionData}/>)
        }
      </ListWrapper>
    </Wrapper>
  )
}

const LestElement = ({data, sendHandler}) => {
  const {name, color} = data
  return (
    <ElemWrapper>
      <Courier onClick={sendHandler}>
        <Color {...{color}}/>
        <p>{name}</p>
      </Courier>
    </ElemWrapper>
  )
}

export default memo(RedirectOrdersFromCourierToCourier);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ListWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

const ElemWrapper = styled.div`
  padding: 5px;
`
const Courier = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;
  
  :hover {
    background: #3498DB;
    color: #fff;
  }
`
const Color = styled.div`
  width: 20px;
  height: 20px;
  background: ${({color}) => color ? color : 'white'};
  border: 1px solid silver;
  border-radius: 50%;
`
