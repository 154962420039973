import React, {memo} from "react";
import trash from "../../../../images/trash.svg";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";


const ProductListItem = ({ style, el, id, products, setProducts }) => {

    const deleteProductItem = () => {
        const newArr = [...products]
        newArr.splice(id, 1)
        setProducts(newArr)
    }

    return (
        <ListItem style={style}>
            <p>{id + 1}.</p>
            <div>
                <p>{el.name}</p>
            </div>
            <div>
                <p>{el.amount}</p>
            </div>
            <img onClick={deleteProductItem} style={{width: '80%'}} src={trash} alt="trash"/>
        </ListItem>
    )
}

export default memo(ProductListItem);

const ListItem = styled.div`
  img {
    cursor: pointer;
  }
`
