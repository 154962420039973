import React, {useState} from "react";
import {FormControls} from "./UtilityComponents";
import {addCategory} from "../../actions/AssortmentAction";
import styled from "styled-components";
import {DEFAULT_INPUT_STYLE, PRODUCT_LIST_ELEMENT_CONTAINER} from "./commonStyles";
import {useDispatch} from "react-redux";


const AddCategory = ({setAddCategoryFieldShow}) => {

    const dispatch = useDispatch()

    const [newCategoryName, setNewCategoryName] = useState('')

    const cancelAddingCategoryHandle = () => {
        setAddCategoryFieldShow(false)
    }
    const applyAddingCategoryHandle = () => {
        dispatch(addCategory(newCategoryName))
        setAddCategoryFieldShow(false)
    }

    return (
        <AddCategoryWrapper>
            <div style={{padding: 5}}>
                <div style={{
                    margin: '2px auto',
                    width: 18,
                    height: 18,
                }}/>
            </div>
            <AddCategoryItems>
                <AddCategoryInput
                    type='text'
                    placeholder='Введите название категории'
                    value={newCategoryName}
                    onChange={e => setNewCategoryName(e.target.value)}
                />
                <FormControls {...{saveHandler: applyAddingCategoryHandle, cancelHandler: cancelAddingCategoryHandle}}/>
            </AddCategoryItems>
        </AddCategoryWrapper>
    )
}

export default AddCategory;

const AddCategoryWrapper = styled.div`
  display: flex;
`
const AddCategoryItems = styled.div`
  ${PRODUCT_LIST_ELEMENT_CONTAINER}
`
const AddCategoryInput = styled.input`
  ${DEFAULT_INPUT_STYLE};
  margin-right: 15px;
  width: 100%;
  height: 100%;
  padding: 0 5px;
`