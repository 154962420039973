import React from "react";
import styled, {keyframes} from "styled-components";


const Preloader = ({style, dotW, dotH}) => {

    return (
        <PreloaderWrapper style={style}>
            <PreloaderBody {...{dotW, dotH}}/>
        </PreloaderWrapper>
    )
}

export default Preloader;

const PreloaderWrapper = styled.div`
  position:relative;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
`
const preloaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  

  to {
    transform: rotate(360deg);
  }
`
const preloaderAnimationBA = keyframes`
  from {
    box-shadow: black 0px 0px 0px;
  }

  to {
    box-shadow: black 0px 0px 10px;
  }
`
const PreloaderBody = styled.div`
  animation: ${preloaderAnimation} 1s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;
  :before {
    position:absolute;
    left: 0;
    top: calc(50% - ${({dotH}) => dotH ? dotH / 2 : '2.5px'});
    content: '';
    width: ${({dotW}) => dotW ? dotW : '5px'};
    height: ${({dotH}) => dotH ? dotH : '5px'};
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }
  :after{
    position:absolute;
    right: 0;
    top: calc(50% - ${({dotH}) => dotH ? dotH / 2 : '2.5px'});
    content: '';
    width: ${({dotW}) => dotW ? dotW : '5px'};
    height: ${({dotH}) => dotH ? dotH : '5px'};
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }
`