import { Input, Modal, Select } from 'antd'
import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { setModalView, setSelectedKitchens } from '../../reducers/toolkit/mainAppReducer'
import Plus from '../../images/MainPage/AddKithens.svg'
import Stars from '../../images/MainPage/Stars.png'
import StarLight from '../../images/MainPage/Star_light.png'
import Shop_light from '../../images/MainPage/Shop_light.png'
import arrow from '../../images/MainPage/arrow.svg'
import alarm from '../../images/assort/Ассортимент/Alarm_light.svg'
import arrow_down from '../../images/MainPage/arrow_down.svg'
import Packet from '../../images/dashBoard/Packet.png'
import './index.css'
import { sr } from '../../utils'
import { useRef } from 'react'
import el from 'date-fns/esm/locale/el/index.js'
const {Option} = Select;

const mockData = [
    {id:1,name:'Скоро здесь будут интересные новости',desc:'Не упустите'},
    // {id:2,name:'Скоро здесь будут интересные новости',desc:'Не упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упуститеНе упустите'},
    // {id:3,name:'Скоро здесь будут интересные новости',desc:'Не упустите'},
    // {id:4,name:'Скоро здесь будут интересные новости',desc:'Не упустите'},
    // {id:5,name:'Скоро здесь будут интересные новости',desc:'Не упустите'},
]
const DashBoard = () => {
    // const [viewModal,setViewModal]=useState(false)
    const kitchensStore = useSelector(store=>store.appMain.kitchens)
    const [kitchens,setKitchens] = useState([])
    const dispatch = useDispatch()
    const refDesc = useRef(null)
    // console.log(permissions,'SSS')
    useEffect(() => {
        sr('AppSOKitchenService','GetRestaurantsForOperator',{
            fields:['ratingOld', 'ordersToday', 'ordersTomorrow']
        }).then(res=>setKitchens(res.data.items))
    }, [kitchensStore])

    const openModal=()=>{
        dispatch(setModalView(true))
    }
    const ClickItemKitchens = (el) =>{
        dispatch(setSelectedKitchens(el))
    }

    
  return (
    <Wrappers>
        {/* Здесь будет раздел "DashBoard" */}
       
        <Main>
        {
         kitchensStore?.length > 0 
         ?
         <Kithens>
        
        <h2 className='Headers'>
        Список ваших кухонь
        </h2>
        <WrapperKItem>
        {kitchens?.map(kitchen=>
            <KitchenItem key={kitchen.id} onClick={()=>ClickItemKitchens(kitchen)} >
                <Link to={`/dashboard/?=${kitchen.id}`}>
            <span style={{display:'block'}}>
                <div className='ItemHead'>
                    <div className='PhotoItem' style={{display:'flex',justifyContent:'center'}}>
                        {kitchen.logoUrl
                        ?
                    <img style={{width:44,height:44}} src={'https://'+'menuforme.online'+kitchen.logoUrl}/>
                        :
                    <div style={{backgroundColor:'#F7F7F7',borderRadius:8,width:44,height:44,display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img style={{width:26,height:26}} src={Shop_light} />
                    </div>
                        }
                    </div>
                    <div className='NameItem'>
                    <div>{kitchen.name}</div>
                    <div style={{fontSize:15,fontWeight:400,color:'#A4A4A4'}}>{kitchen.cityName}</div>
                    </div>
                    <div className='RaitingItem'>
                     <div style={{height:22}}><img style={{width:14,height:14}} src={Stars}/></div> 
                      <div style={{paddingLeft:4}}>{kitchen.rating ? kitchen.rating : 0 }</div>
                      <div style={{paddingLeft:4,height:22}}><img src={kitchen.rating ? kitchen.rating >= kitchen.ratingOld ? arrow : arrow_down : null}/></div>
                      
                    </div>
                </div>
                </span>
     </Link>
                <div className='ItemSubHead'>
     <Link to='/dashboard'>
            <span style={{display:'block'}}>
                    <div style={{display:'flex',paddingTop:16,gap:29}}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>  
                            <div style={{fontSize:24,fontWeight:700,color:'#2C2E32'}}>{kitchen.ordersToday ? kitchen.ordersToday : 0}</div>
                            <div style={{fontSize:12,fontWeight:400,color:'#A4A4A4'}}>Заказов на сегодня</div>
                        </div>

                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                            <div style={{fontSize:24,fontWeight:700,color:'#2C2E32'}}>{kitchen.ordersTomorrow ? kitchen.ordersTomorrow : 0}</div>
                            <div style={{fontSize:12,fontWeight:400,color:'#A4A4A4'}}>Заказов на завтра</div>
                        </div>
                    </div>
                </span>
                </Link>
                    <div>
                        <div>
                            <div>

                            </div>
                              <div style={{color:'#455385',textAlign:'start',fontSize:15,fontWeight:400,display:'flex',alignItems:'center'}}>
                            {
                                kitchen.type === 'cantina' && kitchen.status !== "publish"
                                ?
                                <div style={{fontSize:15,fontWeight:400,color:'#FF3D00'}}>
                                 <img src={alarm}/> Нет меню на завтра
                                </div>
                                :
                                null
                            }
                            {/* }padding-left: 42px */}

                            <Link style={{color:'#455385',paddingLeft:kitchen.type === 'cantina' && kitchen.status !== 'publish' ?42 :0}} to={`/reviews/?=${kitchen.id}`}>
                             <img style={{height:24,width:24}} src={StarLight}/>
                             Отзывы
                            </Link>
                            </div>  
                            
                        </div>
                        
                    </div>
                </div>
            </KitchenItem>
        )
    }
{/* onClick={openModal} */}
        <AddNewKitchen>
            <div className='PLUS'  onClick={openModal}>
            <img src={Plus} style={{height:24,width:24}}/>
            </div>
            <div style={{marginTop:16,fontSize:15,fontWeight:400,color:'#2C2E32'}}>Добавить кухню</div>
        </AddNewKitchen>
        </WrapperKItem>
        </Kithens>

         :
         <div className='NoKitchens'>
             <img style={{height:200,width:200}} src={Packet}/>
             <div style={{fontSize:24,fontWeight:800,color:'#2C2E32',marginTop:32}}>Создайте свою первую кухню</div>
             <div style={{fontSize:16,fontWeight:400,color:'#A6A6A6',marginTop:10}}>Это не займёт много времени</div>
             <div onClick={openModal} className='AddKitchensN '>+ Создать кухню</div>
         </div>
        }
        <News>
            {/* <h2  className='HeadersNews'>
                <div>Новости</div>
                <div style={{cursor:'pointer',width:44,height:44,color:'#FFFFFF',borderRadius:'100%',backgroundColor:'#7882A7',display:'flex',alignItems:'center',justifyContent:'center'}}>+</div>
            </h2>

            <div className='contentNews'>
            {mockData.map(el=>
                <div key={el.id} className='ItemNews'>
                    <div className='ItemHeaderNews'>
                    {el.name}
                    </div>
                    <div className='ItemSubHeaderNews'>
                    {
                    el.desc
                    }
                    </div>
                </div>
                )
            }
            </div> */}
        </News>
        </Main>
    </Wrappers>
  )
}

export default DashBoard

const Wrappers = styled.div`
display: grid;
justify-content: center;
`
// const Wrappers = styled.div`
// display: grid;
// height: calc(100vh - 64px);
// justify-content: center;
// align-items: center;
// color:#161616;
// font-size: 30px;
// font-weight: 600;
// `1.1fr .7fr
const Main = styled.div`
display: grid;
max-width: 1200px;
font-family: Roboto;
font-style: normal;
/* grid-template-columns: 1.8fr .9fr; */
grid-template-columns: 1fr;
padding-top: 40px;
padding-bottom: 40px;
grid-column-gap:117px;
    .NoKitchens{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        margin-top: 20%;
        .AddKitchensN{
        margin-top: 25px;
        display: flex;
        cursor: pointer;
        align-self: center;
        padding: 25px 17px 25px 17px;
        align-items: center;
        height: 55px;
        justify-content: center;
        width: 181px;
        background-color:#7882A7;
        border-radius: 8px;
        color:#FFFFFF;
        font-weight: 500;
        font-size: 15px;
        transition: all .1s ease-in;
        :hover{
            width: 185px;
            height: 60px;
            transition: all .1s ease-in;
            padding: 29px 20px 29px 20px;
        }
        }      
    }
.Headers{
        margin-bottom:24px;
        text-align: start;
        color: #2C2E32;
        font-weight: 800;
        font-size: 24px;
    }
    .HeadersNews{
        display: flex;
        justify-content: space-between;
        margin-bottom:24px;
        text-align: start;
        color: #2C2E32;
        font-weight: 800;
        font-size: 24px;
    }
`
const News = styled.div`
display: flex;
flex-direction: column;
    .contentNews{
        display: flex;
        flex-direction: column;
        gap:16px;
    }
    .ItemNews{
        padding-top:16px;
        padding-bottom: 31px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding-left:25px;
        border-left:8px solid #7882A7;
    }
    .ItemHeaderNews{
        word-break: break-all;
        width: 90%;
        font-weight: 500;
        font-size: 20px;
        text-align: start;
        color: #2C2E32;
    }
    .ItemSubHeaderNews{
        word-break: break-all;
        width: 70%;
        font-weight: 400;
        font-size: 16px;
        color: #2C2E32;
        text-align: start;
        padding-top: 14px;
    }
`
const WrapperKItem= styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
gap: 16px;
` 
const AddNewKitchen = styled.div`
border: 1px dashed #A0A0A0;
min-width: 353px;
max-width: 353px;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
/* @media (max-width:1200px) {
padding: 32px 55px 32px 55px;
}
@media (min-width:1200px) {
padding: 63px 110px 63px 110px;
} */
flex-direction: column;
height: 224px;
/* width: 330px; */
    .PLUS{
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 64px;
        height:64px;
        background: #7882A7;
        border-radius: 100%;
    }
`
const KitchenItem = styled.div`
 
color:#2C2E32;
background: #FFFFFF;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
border-radius: 8px;
/* height: 224px; */
max-width: 353px;
min-width: 353px;
    .arrow{
      width: 7px;
      height: 7px;
      border-top: 1px solid #6E18C0;
      border-right: 1px solid #6E18C0;
      /* margin-right: 12px;  */
    }
    .ItemHead{
        display: grid;
        grid-template-columns: .5fr 1fr .5fr;
        padding-top: 24px;
        padding-bottom: 24px;
        align-items: center;
        border-bottom: 1px solid #EBEBEB;
    }
    .RaitingItem{
        display: flex;
        align-items: center;
        font-size: 15px;
        color:#2C2E32;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.442px;
    }
    .NameItem{
        text-align: start;
    }
    .ItemSubHead{
        display: flex;
        flex-direction: column;
        padding-left:24px;
        padding-bottom: 29px;
        gap: 18px;
    }
`
const Kithens = styled.div`
    .Headers{
        display: flex;
        
    }
    .AddKitchens{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 32px;
        height: 32px;
        background-color:#3D425E;
        border-radius: 100%;
        margin-left:33px;
        cursor: pointer;
    }
    .kithensInfo{
        display: grid;
        grid-template-columns: 50px 1fr .5fr;
        padding: 10px;
        background-color:  #FFFFFF;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        margin-bottom: 16px;
    .Name{
        text-align: start;
        margin-left: 10px;
    }
    .Image{

    }
    .statusKithens{
        display: flex;
        align-items: center;
        margin-left:38px;
        .stylesStatus{
            background-color: #AFD58A;
            border-radius: 6px;
            color:#FFFFFF;
            width: 70%;
        }
    }
    }
`
