import React, { Component } from 'react';
import '../../App.css';
import styled from "styled-components";
import AddWalletModal from "../../components/AddWalletModal";
import { sr } from '../../utils';
import moment from 'moment';
import _ from "underscore";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';

import LogoWalletImg from "../../images/logo_wallet.png";
import LogoTextImg from "../../images/logo_text.png";
import SearchImg from "../../images/search.png";
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import NoPermissions from "../../components/NoPermissions";
import {connect} from "react-redux";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

registerLocale('ru', ru);

class Wallets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            value: '',
            wallets: [],
            data: '',
            isPhoneSearch: false,
            startTimeCreate: null,
            endTimeCreate: null,
            startTimeRemove: null,
            endTimeRemove: null,
            startTimeTransaction: null,
            endTimeTransaction: null,
            companyId: '',
            contracts: [],
            addFile: false,
            refreshWallets: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showResults = this.showResults.bind(this);
    }

    componentDidMount() {
        if (!this.props.getPermissionsSuccess) {
            this.props.getPermissions()
        }
    }

    addWallet = () => {
        this.setState({data: false, showModal: true, addFile: false});
        sr("WalletsController", "GetContracts", {}).then((res) => {
            console.log('Список договоров', res);
            this.setState({contracts: res.data});
        });
    }

    addFile = () => {
        this.setState({data: false, showModal: true, addFile: true});
        sr("WalletsController", "GetContracts", {}).then((res) => {
            console.log('Список договоров', res);
            this.setState({contracts: res.data});
        });
    }

    editWallet = (data) => {
        console.log(data,'DATAKEY')
        this.setState({data: data, showModal: true, addFile: false});
        sr("WalletsController", "GetContracts", {}).then((res) => {
            console.log('Список договоров', res);
            this.setState({contracts: res.data});
        });
        console.log(data)
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    cleanFilter = () => {
        this.setState({
            value: '',
            startTimeCreate: null,
            endTimeCreate: null,
            startTimeRemove: null,
            endTimeRemove: null,
            startTimeTransaction: null,
            endTimeTransaction: null,
            companyId: '',
        })
    }
    filterNumber=(text)=>{
        let textPhone = text.replace(/\s/g,'').replace(/\-/g,'').replace(/\(|\)/g,'')
        let sendText = ''
        let firstChar = textPhone[0]
        console.log(textPhone.length,'length')
        if(firstChar === '8' && textPhone.length === 11 || firstChar === '7' && textPhone.length === 11 ){
          sendText = textPhone.replace(firstChar,'+7')
        }else{
            sendText = text
        }
        if(textPhone[0] === '9' && textPhone.length === 10){
          sendText = textPhone.replace(/^9/,'+79')
        }
        if(textPhone[0] === '+'){
          sendText = textPhone
        }
        if(/[A-Za-zА-яЁё]/g.test(text)){
          sendText = text
        }
        return sendText
      }
    // Поиск по кошелькам при нажании на "🔎" или клавишу Enter
    handleSubmit = (value, event = null) => {
        if (!event || event.key === 'Enter') {
            console.log('Значение в поисковой строке:', value);
            let newValue;
            let test = /^(\+79|\+7 9|\+7\(9|\+7 \(9|\+7-9|\+7-\(9|89|8 9|8\(9|8 \(9|8-9|8-\(9)/;
            if (test.test(value)) {
                newValue = value.replace(/[^+\d]/g, '').replace(/^89/, '+79');
                this.setState({value: newValue})
            } else {
                newValue = value;
            }

            let data = {
                filterText: this.filterNumber(value),
                startTimeCreate: this.state.startTimeCreate ? moment(this.state.startTimeCreate).format('YYYY-MM-DD') : '',
                endTimeCreate: this.state.endTimeCreate ? moment(this.state.endTimeCreate).format('YYYY-MM-DD') : '',
                startTimeRemove: this.state.startTimeRemove ? moment(this.state.startTimeRemove).format('YYYY-MM-DD') : '',
                endTimeRemove: this.state.endTimeRemove ? moment(this.state.endTimeRemove).format('YYYY-MM-DD') : '',
                startTimeTransaction: this.state.startTimeTransaction ? moment(this.state.startTimeTransaction).format('YYYY-MM-DD') : '',
                endTimeTransaction: this.state.endTimeTransaction ? moment(this.state.endTimeTransaction).format('YYYY-MM-DD') : '',
                companyId: this.state.companyId,
            }

            sr("WalletsController", "FindWallets", data).then((res) => {
                console.log(data,'DATATATA')
                console.log('Результат поиска по кошелькам', res);
                if (Array.isArray(res.data.wallets)) {
                    this.setState({wallets: res.data.wallets});
                    res.data.wallets.length === 0 && this.props.deliveryCycleInfoMessage('no_results');
                }
                this.setState({refreshWallets: data})
            });
        }
    }

    refreshData = () => {
        sr("WalletsController", "GetWallet", {id: this.state.data.wallet.id}).then((res) => {
            console.log('Результат запроса по кошельку', res);
            console.log(res.data)
            this.setState({data: res.data});
        });
    }

    delWallet = (data) => {
        let isDel = window.confirm(`Кошелёк с ID ${data.wallet.id} будет удалён. Вы уверены?`);

        if (isDel) {
            sr("WalletsController", "DelWallet", {id: data.wallet.id}).then((res) => {
                if (res.data.deleted && res.result === 1) {
                    sr("WalletsController", "FindWallets", this.state.refreshWallets).then((res) => {
                        if (Array.isArray(res.data.wallets)) {
                            this.setState({wallets: res.data.wallets});
                            res.data.wallets.length === 0 && this.props.deliveryCycleInfoMessage('no_results');
                        }
                        this.setState({refreshWallets: data})
                    });
                    this.props.deliveryCycleInfoMessage('wallet_deleted');
                } else {
                    this.props.deliveryCycleInfoMessage('wallet_deleting_error');
                }
            });
        }

    }

    showResults = () => {
        if (this.state.wallets.length === 0) {
            return []
        } else {
            let results = [];
            let i = 0;
            for (let key in this.state.wallets) {
                i++;
                let result = (
                    <WalletItem key={key}>
                        <Number>{i}</Number>
                        <WalletBlock
                            disabled={moment(this.state.wallets[key].wallet.validTo).isBefore(moment())}
                            deleted={this.state.wallets[key].wallet.wType === 'deleted'}
                        >
                            {this.state.wallets[key].wallet.wType !== 'deleted' && this.checkRole('method_wallets') && (
                                <WalletButtons>
                                    <EditWallet onClick={() => this.editWallet(this.state.wallets[key])}>Изменить</EditWallet>
                                    <DeleteWallet onClick={() => this.delWallet(this.state.wallets[key])}>Удалить</DeleteWallet>
                                </WalletButtons>
                            )}
                            <Column>
                                <WalletName>{this.state.wallets[key].wallet.name}</WalletName>
                                <p style={{color: '#2196F3', marginBottom: 8}}>ID {this.state.wallets[key].wallet.id}</p>
                                <p style={{marginBottom: 8}}>Сумма: {this.state.wallets[key].wallet.amount} ₽</p>
                                {this.state.wallets[key].wallet.created && (
                                    <p style={{marginBottom: 8}}>Дата создания: {moment(this.state.wallets[key].wallet.created).format("DD.MM.YYYY")}</p>
                                )}
                                {this.state.wallets[key].wallet.validTo && (
                                    <p style={{marginBottom: 8}}>Действителен до: {moment(this.state.wallets[key].wallet.validTo).format("DD.MM.YYYY")}</p>
                                )}
                            </Column>
                            <Column>
                                <ColumnTitle>Пользователи:</ColumnTitle>
                                {this.state.wallets[key].clients.length > 0 && this.state.wallets[key].clients.map((v, i) => {
                                    return (
                                        <UserItem key={i}>
                                            <p style={{marginRight: 4, color: '#999'}}>{i + 1}. </p>
                                            <p style={{marginRight: 4, color: '#2196F3'}}>ID {v.screenId}</p>
                                            <p>{v.name}</p>
                                        </UserItem>
                                    )
                                })}
                            </Column>
                            <Column>
                                <ColumnTitle>Компании:</ColumnTitle>
                                {this.state.wallets[key].companies.length > 0 && this.state.wallets[key].companies.map((v, i) => {
                                    return (
                                        <UserItem key={i}>
                                            <p style={{marginRight: 4, color: '#999'}}>{i + 1}. </p>
                                            <p style={{marginRight: 4, color: '#2196F3'}}>ID {v.screenId}</p>
                                            <p>{v.name}</p>
                                        </UserItem>
                                    )
                                })}
                            </Column>
                            <Column>
                                <ColumnTitle>Столовые:</ColumnTitle>
                                {this.state.wallets[key].accounts.length > 0 && this.state.wallets[key].accounts.map((v, i) => {
                                    return (
                                        <UserItem key={i}>
                                            <p style={{marginRight: 4, color: '#999'}}>{i + 1}. </p>
                                            <p style={{marginRight: 4, color: '#2196F3'}}>ID {v.screenId}</p>
                                            <p>{v.name}</p>
                                        </UserItem>
                                    )
                                })}
                            </Column>
                        </WalletBlock>
                    </WalletItem>
                );
                results.push(result);
            }
            return results;
        }
    }

    updateWallet = (id) => {
        sr("WalletsController", "GetWallet", {
            id: id,
        }).then((res) => {
            this.setState(
                {wallets: [res.data], data: res.data, value: ''},
                () => console.log(this.state.wallets, this.state.data, 'кошелёк сразу после создания')
            )
        });
    }

    checkRole = (param) => {
        if (this.props.permissions?.staff) {
            if (this.props.permissions.staff.role === 'admin' && !this.props.permissions.permissions.length) {
                return true
            } else {
                return this.props.permissions.permissions.includes(param)
            }
        } else {
            return false
        }
    }
   
    render() {
        return (
            <WalletsWrapper className="wallets">
                {this.checkRole('screen_wallets') ? (
                    <Container>
                        <Header>
                            <LogoWallet/>
                            <LogoText/>
                            <SearchBox>
                                <Search type="text" placeholder="Поиск" value={this.state.value} onChange={this.handleChange} onKeyUp={(e) => this.handleSubmit(this.state.value, e)}/>
                            </SearchBox>
                            {this.checkRole('method_walletsImport') && (
                                <AddFileButton onClick={this.addFile}>Импортировать файл</AddFileButton>
                            )}
                            {this.checkRole('method_wallets') && (
                                <AddWalletButton onClick={this.addWallet}>Добавить кошелёк</AddWalletButton>
                            )}
                            <AddWalletModal close={this.closeModal} isShow={this.state.showModal} data={this.state.data} addFile={this.state.addFile}
                                            refreshData={this.refreshData} updateWallet={this.updateWallet} contracts={this.state.contracts}/>
                           
                           </Header>
                        <Main>
                            <FiltersBlock className="filters_block">
                                <p style={{marginRight: 40, height: 36, lineHeight: '36px'}}>Сортировать:</p>
                                <div>
                                    <SortBlockString>
                                        <p style={{marginRight: 24.687}}>По дате создания:</p>
                                        <p style={{marginRight: 10}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTimeCreate}
                                            startDate={this.state.startTimeCreate}
                                            endDate={this.state.endTimeCreate}
                                            selectsStart
                                            onChange={date => this.setState({startTimeCreate: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 10}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTimeCreate}
                                            startDate={this.state.startTimeCreate}
                                            endDate={this.state.endTimeCreate}
                                            minDate={this.state.startTimeCreate}
                                            selectsEnd
                                            onChange={date => this.setState({endTimeCreate: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 40}}>По дате окончания:</p>
                                        <p style={{marginRight: 10}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTimeRemove}
                                            startDate={this.state.startTimeRemove}
                                            endDate={this.state.endTimeRemove}
                                            selectsStart
                                            onChange={date => this.setState({startTimeRemove: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 10}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTimeRemove}
                                            startDate={this.state.startTimeRemove}
                                            endDate={this.state.endTimeRemove}
                                            minDate={this.state.startTimeRemove}
                                            selectsEnd
                                            onChange={date => this.setState({endTimeRemove: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                    </SortBlockString>
                                    <SortBlockString>
                                        <p style={{marginRight: 10}}>По дате транзакции:</p>
                                        <p style={{marginRight: 10}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTimeTransaction}
                                            startDate={this.state.startTimeTransaction}
                                            endDate={this.state.endTimeTransaction}
                                            selectsStart
                                            onChange={date => this.setState({startTimeTransaction: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 10}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTimeTransaction}
                                            startDate={this.state.startTimeTransaction}
                                            endDate={this.state.endTimeTransaction}
                                            minDate={this.state.startTimeTransaction}
                                            selectsEnd
                                            onChange={date => this.setState({endTimeTransaction: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 40}}>По ID компании:</p>
                                        <input
                                            type="number"
                                            value={this.state.companyId}
                                            onChange={(e) => this.setState({companyId: e.target.value})}
                                        />
                                        <CleanFiltersButton onClick={() => this.cleanFilter()}>
                                            <p>Очистить</p>
                                        </CleanFiltersButton>
                                        <FilterButton onClick={() => this.handleSubmit(this.state.value)}>
                                            <p>Найти</p>
                                        </FilterButton>
                                    </SortBlockString>
                                </div>
                            </FiltersBlock>
                            {this.showResults()}
                        </Main>
                    </Container>
                ) : (
                    this.props.getPermissionsSuccess && (
                        <NoPermissions />
                    )
                )}
            </WalletsWrapper>
        );
    }
}

const mapStateToProps = store => ({
    getPermissionsSuccess: store.appMain.getPermissionsSuccess,
    permissions: store.appMain.permissions,
});

const mapDispatchToProps = dispatch => ({
    getPermissions: () => dispatch(getPermissionsAction),
    deliveryCycleInfoMessage: (actionUser, product, timeOut) => dispatch(deliveryCycleInfoMessage(actionUser, product, timeOut)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Wallets);

const WalletsWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 64px);
    background-color: #fff;
    text-align: left;
    font-family: "Roboto", sans-serif;
`;

const Container = styled.div`
    width: 1280px;
    min-height: 100%;
    margin: 0 auto;
    padding: 0 20px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    padding-top: 30px;
`;

const LogoWallet = styled.div`
    width: 32px;
    height: 30px;
    margin-right: 9px;
    background: url(${LogoWalletImg}) center no-repeat;
    background-size: auto;
`;

const LogoText = styled.div`
    width: 153px;
    height: 24px;
    margin-right: 52px;
    background: url("${LogoTextImg}") center no-repeat;
    background-size: auto;
`;

const SearchBox = styled.div`
    position: relative;
    width: 400px;
    height: 50px;
`;

const Search = styled.input`
    width: 400px;
    height: 50px;
    padding: 16px 58px 15px 16px;
    outline: 0;
    border: 1px solid #eee;
    border-radius: 4px;
    /*background: url("${SearchImg}") 19px 13px no-repeat #eee;*/
    background: #eee;
    /*background-size: auto;*/
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #222;
    
    &:focus {
        background: #fff;
    }
`;

const Submit = styled.button`
    position: absolute;
    top: 14px;
    right: 20px;
    width: 22px;
    height: 22px;
    padding: 0;
    outline: 0;
    border: 0;
    background: url("${SearchImg}") center no-repeat;
    background-size: auto;
    cursor: pointer;
`;

const AddWalletButton = styled.button`
    width: 300px;
    height: 50px;
    margin-left: auto;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: linear-gradient(0deg, #ff1744, #ff1744), linear-gradient(90deg, #434343 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    cursor: pointer;
`;

const AddFileButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 30px;
    border-radius: 18px;
    margin-left: 20px;
    background: linear-gradient(0deg, #ff1744, #ff1744), linear-gradient(90deg, #434343 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
`;

const Main = styled.div`
    margin-top: 29px;
`;

const WalletItem = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 60px;
`;

const Number = styled.p`
    margin-top: 16px !important;
    margin-right: 20px !important;
    font-size: 16px;
    line-height: 19px;
    color: #191932;
`;

const WalletBlock = styled.div`
    position: relative;
    display: flex;
    width: 1200px;
    min-height: 325px;
    padding: 20px 20px 100px;
    background: ${({disabled, deleted}) => deleted ? '#fdd' : disabled ? '#eee' : '#fff'};
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #191932;
`;

const Column = styled.div`
    width: calc((100% - 42px) / 2);
    margin-right: 14px;
    overflow: hidden;
    
    &:first-child {
        font-size: 14px;
        line-height: 16px;
    }
    
    &:last-child {
        margin-right: 0;
    }
`;

const WalletName = styled.p`
    margin-bottom: 8px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #191932;
    word-wrap: break-word;
`;

const ColumnTitle = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px !important;
`;

const UserItem = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
`;

const WalletButtons = styled.div`
    position: absolute;
    left: 20px;
    bottom: 29px;
    display: flex;
    align-items: center;
`;

const EditWallet = styled.button`
    width: 149px;
    height: 42px;
    margin-right: 38px;
    outline: 0;
    border: 1px solid #999;
    border-radius: 66px;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #999;
    cursor: pointer;
`;

const DeleteWallet = styled.button`
    width: 63px;
    height: 19px;
    outline: 0;
    border: 0;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #999;
    cursor: pointer;
`;

const FiltersBlock = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`;

const SortBlockString = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const CleanFiltersButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    border-radius: 18px;
    margin-left: 30px;
    background: linear-gradient(0deg, #ff1744, #ff1744), linear-gradient(90deg, #434343 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
`;

const FilterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    border-radius: 18px;
    margin-left: 15px;
    background: #3ba607;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
`;
