import React, { memo, useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import GraphsOrders from '../components/DashBoard/GraphsOrders';
import GraphsRaiting from '../components/DashBoard/GraphsRaitng';
import { sr } from '../utils';
import { Link } from 'react-router-dom'
import Star from '../images/dashBoard/Star.png'
import Rating1 from '../images/dashBoard/Rating1.svg'
import Rating2 from '../images/dashBoard/Rating2.png'
import Rating3 from '../images/dashBoard/Rating3.png'
import Rating4 from '../images/dashBoard/Rating4.svg'
import Rating5 from '../images/dashBoard/Rating5.png'
import Chat from '../images/dashBoard/Chat.png'
import ChatRed from '../images/dashBoard/ChatRed.png'
import ChooseKitchens from '../images/dashBoard/ChooseKitchens.png'
import alarm from '../images/assort/Ассортимент/Alarm_light.svg'
import DefaultIMG from '../images/menu_default.png'
import Like from '../images/dashBoard/Favorite_light.png'
import plural from 'plural-ru'
import moment from 'moment';
import { motion } from 'framer-motion';

const MainPage = () => {
    const [data,setData] = useState({})
    const [ThreeTeenOrders,setThreeTeenOrders] = useState([])
    const [fiveTeenOrders,setFiveTeenOrders] = useState([])
    const [yearsStatState,setYearStat] = useState([])
    const [threeTeenStat,setThreeTeenStat] = useState([])
    const fiveTeen = data?.graphRating?.filter(el=>+el.year === +moment().format('YYYY') )
    const fullYearSum = data?.graphSum?.filter(el=>+el.year === +moment().format('YYYY') )
    const [countReviews,setCountReviews] = useState(4)
    const [countPopular,setCountPopular] = useState(3)
    const [countHeight,setCountHeight] = useState(3)
    const [countFast,setCountFast] = useState(3)
    const [countLike,setCountLike] = useState(3)
    const [reqStatus,setReqStatus] = useState(null)
    const [allSumDay,setAllSumDay] = useState(0)
    const prevM = moment().subtract(1,'month').format('MM')
    const nowDate = moment().add(1,'day').format('YYYY-MM-DD')
    const fiveTeenAgo = moment().subtract(14,'day').format('YYYY-MM-DD')
    const forDate  = moment().subtract(1,'year').format('YYYY')
    const yearsStat = data.graphSum?.filter(el=>el.year === forDate)
    const prevMstat = data.graphSum?.filter(el=> +el.month === +prevM)
    const fiveTeenDayAgo =  getRange(fiveTeenAgo,nowDate,'days').map(el=>+moment(el).format('MM'))
    const lastRating = fiveTeen?.filter(el=> fiveTeenDayAgo.includes(+el.month))[0]?.rating
    console.log(fiveTeenDayAgo ,'LAST')

    const SelectedKitchens = useSelector(
        (store) => store.appMain.selectedKitchens
        );

    useEffect(()=>{
        if(SelectedKitchens.name !== 'Не выбрано'){
            setReqStatus('pending')
        sr('DashboardSO','GetDashboard',{
            account:SelectedKitchens.id,
            // 202865,
            limitReviews:countReviews,
            limitFastRunningOutProducts:countFast,
            limitPopularProducts:countPopular,
            limitRatingProducts:countHeight,
            limitLikeProducts:countLike,
            dateFrom:moment().subtract(1,'year').format('YYYY.MM.DD'),
            dateTo:moment().add(1,'day').format('YYYY.MM.DD')
        }).then(res=>{
            if(res.result){
            setData(res.data)
            setReqStatus('resolve')
        }else{
            setReqStatus('reject')
        }
        })
    }
    },[SelectedKitchens,countPopular,countReviews,countFast,countHeight,countLike])

    const ThreeTeenHandler=()=>{
        if(data){
         const mounth = data?.graphSum?.filter(el=> +el.month === +moment().format('MM'))
         .sort((a,b)=> a.day-b.day)
         setThreeTeenOrders(mounth)
        }
    }

    // const YearStatHandler = (el) =>{
    //     console.log(el,'ELO')
    //     const months =
    //     const haveMothWithAllSum = data?.graphSum?.reduce((acc,el)=>{
    //         if(el.month === haveMonth?.month){
    //             acc.allSum = +acc.allSum  +  +el.sum
    //             // acc.sales = allSales?.length
    //             return acc
    //         }
    //     return acc
    //     },{allSum:0,sales:0})
        
    //     const finishMonth = {...haveMonth,allSum:haveMothWithAllSum?.allSum}
    // console.log(haveMothWithAllSum,'OOOOOO')
    //     if(haveMonth){
    //         return {flag:true,el:finishMonth}
    //     }else{
    //         return {flag:false}     
    //     }
    // }

    const yearCalc = () =>{
        const nowM = +moment().add(1,'month').format('MM')
        //+1
        const prevY = moment().subtract(1,'year').format('YYYY')
        const monthArr = []
        let years = prevY
        for(let a = nowM ; monthArr.length <= 11; a++){
            if(a < 13){
                monthArr.push({month:String(a),years:String(years)})
            }else{
                a = 0
                years = +prevY + 1
            }
        }   
        // console.log(nowM,'ARRARARA')
        return monthArr
    }

    const YearHandler = (element) =>{
        // console.log(element,'EEKEEE')
        const curr = data.graphSum?.filter(el=>el.month === element.month && el.year === element.years)
        // const eee = data.graphSum?.find(el=>el.month === element.month && el.year === element.years)

        const haveMothWithAllSum = curr?.reduce((acc,el)=>{
                if(el.month === element.month){
                    // console.log(acc.sum,el.sum,'asaaa')
                    acc.allSum = +acc.allSum  +  +el.sum
                    acc.month = el.month
                    acc.year = el.year
                    acc.date = moment(el.date).format('YYYY.MM')
                    acc.allSales = +el.count +  +acc.allSales
                    // acc.sales = allSales?.length
                    return acc
                }
            return acc
        },{allSum:0,month:'',year:'',date:'',allSales:''})
        
        if(curr){
            return {status:true,el:haveMothWithAllSum}
        }else{ 
            return {status:false}
        }
    }

    const YearsStaistics = () =>{
       const allMonth  = yearCalc()
       const han = allMonth.map(el=>{
           if(YearHandler(el).status){
                return YearHandler(el).el
            // }else{
           }
            //     return  {allSum: 0, month:1221, year: moment().format('YYYY'), date:moment().format('YYYY-MM')}
            // // }
       })
       console.log(han,'adadadadadad')
        setYearStat(han)
    }


useEffect(()=>{
    if(data){
        YearsStaistics()
    }   
},[data])

    const handlerVAL=(val,arr)=>{
        const a = arr?.find(el=>+el.day === +moment(val).format('DD') && +el.month === +moment(val).format('MM'))
        console.log(arr,"VAL")

        if(a){
            return {flag:true,el:a}
        }else{
            return {flag:false}     
        }
        }

    // console.log(dayCalculator(31,31),'.......')

    const ThreeTeenStatHandler=(element)=>{
        const have = fullYearSum?.find(el=>+el.day === +moment(element).format('DD') && +el.month === +moment(element).format('MM'))
        // console.log(have,'PP{')

        if(have){
            return {flag:true,el:have}
        }else{
            return {flag:false}     
        }
    }

    const ThreeTeenStat = () => {
        const prevMD = moment().subtract(4,'month').format('YYYY-MM-DD')
        const nowMww = moment().add(1,'days').format('YYYY-MM-DD')
        const diffM = getRange(prevMD,nowMww,'days').map(el=>moment(el).format('YYYY-MM-DD'))
        const allDayInPrevMonth = diffM?.map((el,i)=>{
            if(ThreeTeenStatHandler(el).flag){
                return {...ThreeTeenStatHandler(el).el}
            }else{
                return {year:moment().format('YYYY'),month:moment(el).format('MM'),day:moment(el).format('DD'),date:el,sum:0,allSum:0,count:0}
            }
        })
        const w = [...allDayInPrevMonth]
        const weeks = w.reverse().reduce((acc,el,i)=>{
            console.log(el,'JHIAOFA')
            if(acc.counter < 7){
                //  acc.weeks.push({month:el.month,year:el.year,sum:+el.sum ? +el.sum : 0,count:+el.count ? +el.count :0,date:el?.date})
                acc.sum  = +el.sum  +  +acc.sum 
                acc.counter += 1
                 return acc
            }else{
                acc.weeks.push({month:el.month,year:el.year,sum:+el.sum + acc.sum,count:+el.count ? +el.count :0,date:moment(el?.date).format("MM-DD")})
                acc.sum = 0
                acc.counter = 1
                return acc
            }
        },{weeks:[],counter:7,sum:0})

        console.log(weeks,'JHIAOFA')

        setThreeTeenStat(weeks.weeks.reverse())
    }

    const AllDays = () =>{
        const prevMD = moment().subtract(3,'month').format('YYYY-MM-DD')
        const nowMww = moment().add(1,'days').format('YYYY-MM-DD')
        const diffM = getRange(prevMD,nowMww,'days').map(el=>moment(el).format('YYYY-MM-DD'))
        const allDayInPrevMonth = diffM?.map((el,i)=>{
            if(ThreeTeenStatHandler(el).flag){
                return {...ThreeTeenStatHandler(el).el}
            }else{
                return {year:moment().format('YYYY'),month:moment(el).format('MM'),day:moment(el).format('DD'),date:el,sum:0,allSum:0,count:0}
            }
        })
        const allSumDay = allDayInPrevMonth.reduce((acc,el)=>{
                return  +acc  +  +el.sum
        },0)
        console.log(allSumDay,'ALLDAYS')
        setAllSumDay(allSumDay)
        // setThreeTeenStat(weeks.weeks.reverse())
    }

    useEffect(()=>{
        AllDays()
    },[data])

    useEffect(()=>{
        ThreeTeenStat()
    },[data])

  

    function getRange(startDate, endDate, type) {
        let fromDate = moment(startDate)
        let toDate = moment(endDate)
        let diff = toDate.diff(fromDate, type)
        let range = []
        for (let i = 0; i < diff; i++) {
          range.push(moment(startDate).add(i, type))
        }
        return range
      }

    const FiveTeenHandler=()=>{

     if(data){
        const prevM = moment().subtract(2,'month').format('YYYY-MM-DD')
        const currM = moment().add(1,'day').format('YYYY-MM-DD')

        const nowDate = moment().add(1,'day').format('YYYY-MM-DD')
        const fiveTeenAgo = moment().subtract(14,'day').format('YYYY-MM-DD')
        const diffs = getRange(fiveTeenAgo,nowDate,'days').map(el=>{
                 return moment(el).format('YYYY-MM-DD')
        })
        const diffsTEST = getRange(prevM,currM,'days').map(el=>{
            return moment(el).format('YYYY-MM-DD')
   })
   console.log(diffsTEST,'sssssss');
            const aa = diffsTEST?.map((el,i)=>{
                if(handlerVAL(el,fiveTeen).flag){
                    return handlerVAL(el,fiveTeen).el
                }else{
                    return {year:moment().format('YYYY'),month:moment(el).format('MM'),day:moment(el).format('DD'),rating: null}
                // }i === 0 ? lastRating :
                }
            })
            // console.log(aa,diffs,'jopa')
            const bb = aa?.reduce((acc,el)=>{
                if(el?.rating){
                    acc[0].rating = el.rating
                    acc[1].allDot.push(el)
                    return acc
                }else{
                    acc[1].allDot.push({...el,rating:acc[0]?.rating})
                    return acc
                }
            },[{rating:0},{allDot:[]}])

            console.log(bb[1]?.allDot?.slice()?.reverse(),bb[1]?.allDot,'REVS')
            const lastFiveTeenDays = bb[1]?.allDot?.slice()?.reverse()?.slice(0,15)

            setFiveTeenOrders(lastFiveTeenDays?.reverse())
            console.log(bb[1]?.allDot,'DOTS');
    }
        
    }
// console.log(fiveTeen,'iii')
    useEffect(()=>{
    FiveTeenHandler()
    },[data])

    useEffect(()=>{
        ThreeTeenHandler()
    },[data])

const ratingHelper=(rating)=>{
    switch (rating) {
        case '5':
            return Rating5
        case '4':
            return Rating4
        case '3':
            return Rating3            
        case '2':
            return Rating2        
        case '1':
            return Rating1    
        default:
            return
    }
}
  return (
    <Wrapper>
    {SelectedKitchens.name === 'Не выбрано'
    ?
    <img src={ChooseKitchens}/>
    :
        <Main>
        <h1 className='NameKitchen'>{SelectedKitchens.name !== 'Не выбрано' && SelectedKitchens.name}</h1>
        <Orders>
            <Today>
            <h2 className='HeaderOrder'>Сегодня</h2>
                <CardItem lineColor='green'>
                {data?.ordersToday?.menuPublished === '0' && reqStatus === 'resolve'
                ?
                <div style={{color:'#FF3D00',display:'flex',flexDirection:'column',justifyContent:'space-between',fontSize:15,fontWeight:400,height:'100%',textAlign:'start'}}>
                <div style={{display:'flex'}}>
                <img style={{height:24,width:24}} src={alarm}/> 
                <div >Нет меню на сегодня</div>
                </div>
            <Link to={`/menu_by_day/?=${SelectedKitchens.id}`} target='_blank'>
                <span style={{color:'#455385',fontSize:15,fontWeight:500,display:'block',textAlign:'end',cursor:'pointer'}}>
            Опубликовать
                </span>
            </Link>
                </div>
                :
                <>
                <HeaderCard>
                    <div className='CountOrders' >
                        Заказов {data.ordersToday?.count} на сумму {data.ordersToday?.sum} ₽ 
                    </div>
                    <div style={{paddingTop:8,paddingBottom:16}}>(из них наличными {data.ordersToday?.sumCash} ₽)</div>
                </HeaderCard>
{/* {plural(data?.ordersToday?.countDifference,'заказ','заказа','заказов')} */}

                <SubHeaderCard>
                    <div style={{paddingTop:16}}>
                    На {data?.ordersToday ? Math.abs(data?.ordersToday?.countDifference) : 0} {plural(Math.abs(data?.ordersToday?.countDifference),'заказ','заказа','заказов')} {data?.ordersToday?.countDifference > 0 ? 'больше':'меньше'}, чем в этот день неделю назад 
                    </div>
                    <div style={{paddingTop:8}}>
                    Заказывают первый раз: {data.ordersToday?.firstOrder}
                    </div>
                    <div style={{paddingTop:8}}>
                    Заказывали вчера: {data.ordersToday?.yesterdayOrder}
                    </div>
                </SubHeaderCard>
                </>
                }
                </CardItem>
            </Today> 
            <Tomorrow>


            <h2 className='HeaderOrder'>Завтра</h2>
                <CardItem lineColor='yellow'>
                    {
                    data?.ordersTomorrow?.menuPublished === '0' && reqStatus === 'resolve'
                    ?
                    <div style={{color:'#FF3D00',display:'flex',flexDirection:'column',justifyContent:'space-between',fontSize:15,fontWeight:400,height:'100%',textAlign:'start'}}>
                        <div style={{display:'flex'}}>
                        <img style={{height:24,width:24}} src={alarm}/> 
                        <div >Нет меню на завтра</div>
                        </div>

                        <Link to={`/menu_by_day/?=${SelectedKitchens.id}`} target='_blank'>
                        <span style={{color:'#455385',fontSize:15,fontWeight:500,display:'block',textAlign:'end',cursor:'pointer'}}>
                    Опубликовать
                        </span>
                        </Link>
                        </div>
                    :
                    <>
                    <HeaderCard>
                        <div className='CountOrders' >
                            Заказов {data.ordersTomorrow?.count} на сумму {data.ordersTomorrow?.sum} ₽ 
                        </div>
                        <div style={{paddingTop:8,paddingBottom:16}}>(из них наличными {data.ordersTomorrow?.sumCash} ₽)</div>
                    </HeaderCard>

                    <SubHeaderCard>
                        <div style={{paddingTop:16}}>
                        На {data?.ordersTomorrow ? Math.abs(data?.ordersTomorrow?.countDifference) : 0} {plural(Math.abs(data?.ordersTomorrow?.countDifference),'заказ','заказа','заказов')} {data?.ordersTomorrow?.countDifference > 0 ? 'больше':'меньше'}, чем в этот день неделю назад 
                        </div>
                        <div  style={{paddingTop:8}}>
                        Заказывают первый раз: {data.ordersTomorrow?.firstOrder}
                        </div>
                        <div style={{paddingTop:8}}>
                        Заказывали вчера: {data.ordersTomorrow?.yesterdayOrder}
                        </div>
                    </SubHeaderCard>
                    </>
                     }
                </CardItem>

            </Tomorrow>
            <Raitings>
            <h2 className='HeaderOrder'>Рейтинг <img style={{width:17,height:17}} src={Star} /> {data?.graphRating?.length> 0 ? data.graphRating?.slice(-1)[0]?.rating : 0}</h2>
            {
            data?.graphRating?.length> 0
            ?
                <GraphsRaiting data = {data.graphRating} fives={fiveTeenOrders }/>
            :
            <NoData>Данных пока нет</NoData>
            }
            {/* <div>Подробнее</div> */}
            </Raitings>


        </Orders>

        <Statistic>
            <FirstColStat>

            {allSumDay !== 0 ?
            <>
            <GraphStatisticMain >
                <div style={{fontSize:18,fontWeight:500,color:'#2C2E32',textAlign:'start',marginBottom:16}}>Продажи за последние 3 месяца</div>
              <GraphsOrders  {...{yearsStatState,threeTeenStat,forYears:false,forThreeTeen:true,all:true}}/>
            </GraphStatisticMain>
            
            <GraphStatisticMain >
            <div style={{fontSize:18,fontWeight:500,color:'#2C2E32',textAlign:'start',marginBottom:16}}>Продажи за последний год</div>
              <GraphsOrders {...{yearsStatState,threeTeenStat,forYears:true,forThreeTeen:false,yearCalc,all:true}}/>
            </GraphStatisticMain>
            </>
            :
            <GraphStatisticMain grid={allSumDay} >
                <div style={{fontSize:18,fontWeight:500,color:'#2C2E32',textAlign:'start',marginBottom:16}}>Продажи за последние 3 месяца</div>
              <GraphsOrders  {...{yearsStatState,threeTeenStat:data.graphSum,forYears:false,forThreeTeen:true,all:true}}/>
            </GraphStatisticMain>
            }
            <CardsStatistics>  
            <TittleStat>
            Популярые товары<br/> за последние 30 дней   
            </TittleStat>
            <SubTittleStat>
            Эти позиции заказывают больше всего
            </SubTittleStat>
            
            {
            data &&
            data?.popularProducts?.length > 0 
            
            ?
        <ScrollStat>
            {
            data?.popularProducts?.map(el=>
            <CardStatItem 
            animate={{
                scale:[0,1],
                opacity: [0,0.5,1]
            }}
            key={el.productId}>

            <div className='PhotoStat'>
            <img style={{width:'100%',height:'100%',borderRadius:8}} src={el.mediaId ? `https://menuforme.online/img/cache/product_standard/${el.mediaId}.png` : DefaultIMG}/>
            </div>

            <div className='NameStat'>
                {el.name}
            </div>

            <div className='SalesStat'>
               продано {el.count}
            </div>
            </CardStatItem>
            )
            }
       </ScrollStat>
            :
            <NoData>
                Данных пока нет
            </NoData>
            }
            {data?.popularProducts?.length > 0 && <div   style={{paddingTop:16,color:'#455385',textAlign:'start',cursor:'pointer'}}>Показать все </div>}
            
            </CardsStatistics>


            <CardsStatistics>
            <TittleStat>
            Товары с высоким<br/>рейтингом   
            </TittleStat>
            {
            data &&
            data?.ratingProducts?.length > 0 
            ?
            <ScrollStat>
            {
            data?.ratingProducts?.map(el=>
            <CardStatItem  animate={{
                scale:[0,1],
                opacity: [0,0.5,1]
            }} key={el.productId}>

            <div className='PhotoStat'>
            <img style={{width:'100%',height:'100%',borderRadius:8}} src={el.mediaId ? `https://menuforme.online/img/cache/product_standard/${el.mediaId}.png` : DefaultIMG}/>
            </div>

            <div className='NameStat'>
                {el.productName}
            </div>

            <div className='SalesStat'>
               <img style={{width:17,height:17}} src={Star} /> {el.rating}
            </div>
            </CardStatItem>
            )}
            </ScrollStat>
            :
            <NoData>
            Данных пока нет
        </NoData>
            }
            {data?.ratingProducts?.length > 0 && <div  style={{paddingTop:16,color:'#455385',textAlign:'start',cursor:'pointer'}}>Показать все </div>}

            </CardsStatistics>


            <CardsStatistics>
            <TittleStat>
            Быстро заканчиваются   
            </TittleStat>
            <SubTittleStat>
            Данные товары рекомендуется производить больше,их не хватает
            </SubTittleStat>
            {
            data &&
            data?.fastRunningOutProducts?.length > 0 
            ?
            <ScrollStat>
            {
            data?.fastRunningOutProducts?.map(el=>
            <CardStatItem  animate={{
                scale:[0,1],
                opacity: [0,0.5,1]
            }} className='FAST' style={{display:'grid',gridTemplateColumns:'50px 1fr',gridTemplateRows:'1fr .5fr'}} key={el.productId}>

            <div className='PhotoStat'>
            <img style={{width:'100%',height:'100%',borderRadius:8}} src={el.mediaId ? `https://menuforme.online/img/cache/product_standard/${el.mediaId}.png` : DefaultIMG}/>
            </div>

            <div className='NameStat' style={{display:'flex',flexDirection:'column'}}>
                {el.productName}
            
            </div>
            <div style={{gridColumn:'2/3',paddingLeft:16}}>
            <div style={{display:'flex',justifyContent:'space-between',fontSize:16,fontWeight:400,color:'#A6A6A6'}}>
            в продаже за последние 60 дней <span style={{color:'#2C2E32',fontSize:20,fontWeight:500}}>{el.amountDaysImplementation}</span> 
            </div>
            <div style={{display:'flex',justifyContent:'space-between',fontSize:16,fontWeight:400,color:'#A6A6A6',textAlign:'start'}}>
            распродан полностью <span style={{color:'#2C2E32',fontSize:20,fontWeight:500}}>{el.amountDays}</span>
            </div>
            </div>
            
            </CardStatItem>
            )}
            </ScrollStat>
            :
            <NoData>
            Данных пока нет
        </NoData>
            }
            {data?.fastRunningOutProducts?.length > 0 && <div style={{paddingTop:16,color:'#455385',textAlign:'start',cursor:'pointer'}}>Показать все </div>}

            </CardsStatistics>

            <CardsStatistics>
            <TittleStat>
            Любимые блюда   
            </TittleStat>
            {
            data &&
            data?.likeProducts?.length > 0
            ?
            <ScrollStat>
            {
            data?.likeProducts?.map(el=>
            <CardStatItem  animate={{
                scale:[0,1],
                opacity: [0,0.5,1]
            }} key={el.productId}>

            <div className='PhotoStat'>
                <img style={{width:'100%',height:'100%',borderRadius:8 }} src={el.mediaId ? `https://menuforme.online/img/cache/product_standard/${el.mediaId}.png` : DefaultIMG}/>
            </div>

            <div className='NameStat'>
                {el.productName}
            </div>

            <div className='SalesStat'>
            <img style={{width:24,height:24}} src={Like} /> {el.count}
            </div>
            </CardStatItem>
            )}
            </ScrollStat>
            :
            <NoData>
            Данных пока нет
        </NoData>
            }
            {data?.likeProducts?.length > 0 && <div  style={{paddingTop:16,color:'#455385',textAlign:'start',cursor:'pointer'}}>Показать все </div>}

            </CardsStatistics>
            </FirstColStat>
            

            <SecondColStat>
            <h2 className='HeaderOrder'>Отзывы</h2>
            <Rewies>
                {data 
                &&
                data?.reviews?.length > 0
                ?
            <ScrollStat>
                {
                data?.reviews?.map((el,i)=>
                <RewiewsItem
                animate={{
                    scale:[0,1],
                    opacity: [0,0.5,1]
                }}
                >
                    <div style={{fontSize:18,fontWeight:500,color:'#2C2E32',marginBottom:16}}>
                    Заказ № {data?.reviewsOrder[i]?.orderId} на {moment(data?.reviewsOrder[i]?.day).format('DD.MM')}
                    </div>
                    {el?.map(items=>
                    <>
                    <div style={{paddingBottom:16,display:'flex',textAlign:'start',width:'70%',color:'#2C2E32'}}>
                    {+items?.ratingValue ? <img style={{width:20,height:20,marginRight:8}} src={ratingHelper(items?.ratingValue)}/> : null} {items.productName} 
                    </div>
                    <div style={{width: '90%',marginBottom:items.comment ? 25 : 0,paddingLeft:30,color:items.comment  ? items?.ratingValue === '2' || items?.ratingValue === '3' ||items?.ratingValue === '1' ? '#EE2B00':'#2C2E32':null,display:'flex',textAlign:'start'}} >
                    {
                     items.comment  ? items?.ratingValue === '2' || items?.ratingValue === '3' ||items?.ratingValue === '1' ? <img style={{wdith:20,height:20,marginRight:8}} src={ChatRed}/> : <img style={{wdith:20,height:20,marginRight:8}} src={Chat}/>
                     :
                     null
                    }
                    {items.comment &&
                      items.comment
                    }
                    </div>
                    </>
                    )
                    
                    }
                    <div style={{fontSize:12,fontWeight:400,color:'#A4A4A4'}} >Отзыв оставлен, {moment(el[0]?.updated).format('DD MMMM')}</div>
                </RewiewsItem>
                )}    
            </ScrollStat>
                :
                <NoData>
                Данных пока нет
            </NoData>
                }
            {data?.reviews?.length > 0 && <div  style={{paddingTop:16,color:'#455385',textAlign:'start',cursor:'pointer'}}>Показать все </div>}

            </Rewies>
            </SecondColStat>

        </Statistic>
        </Main>
    }
    </Wrapper>
  )
}

export default memo(MainPage)

const Wrapper=styled.div`
/* font-family: 'Roboto';
font-style: normal; */
display:grid;
/* grid-template-columns: 1fr; */
width: 100%;
/* height: calc(100vh - 64px); */
justify-items: center;
gap: 43px;
padding-top: 39px;
h2{
    font-weight: 800;
    font-size: 24px;
    }
`

const Main = styled.div`
display: grid;
max-width: 1200px;
min-width:1200px ;
padding-bottom: 76px;
font-family: Roboto;
font-style: normal;
/* grid-template-rows: 75px .5fr 2fr; */
.NameKitchen{
    text-align: start;
    margin-bottom: 37px;
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.442px;
    color: #2C2E32;
}
`

const Orders = styled.div`
display: grid;
grid-template-columns: repeat(3,.7fr);
grid-column-gap: 20px;
min-height: 221px;
    .HeaderOrder{
        text-align: start;
    }
`
const Statistic = styled.div`
display: grid;
grid-template-columns: 2fr 1fr;
column-gap: 20px;
margin-top: 59px;
    .HeaderOrder{
            text-align: start;
        }
`
const Today = styled.div`
display: flex;
flex-direction: column;
`
const Tomorrow = styled.div`
display: flex;
/* padding-left: 20px; */
flex-direction: column;
`
const Raitings = styled.div`
display: flex;
padding-left: 20px;
flex-direction: column;
`
const CardItem = styled.div`
padding: 24px;
background: #FFFFFF;
height: 100%;
border-radius: 8px;
/* border-left:8px solid #749483; */
border-left: 8px solid ${({lineColor})=>lineColor === 'green' ? '#749483':'#E0925A'};
`
const HeaderCard = styled.div`
text-align: start;
width: 60%;
border-bottom: 1px solid #000000;
font-weight: 400;
font-size: 14px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;

        .CountOrders{
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.442px;
            color: #2C2E32;
        }
`
const SubHeaderCard = styled.div`
width: 90%;
text-align: start;
font-weight: 400;
font-size: 14px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;
`
const FirstColStat = styled.div`
display: grid;
grid-template-columns: .7fr .7fr;
/* grid-template-rows: auto 1fr 1fr; */
gap: 40px 20px;
`
const SecondColStat = styled.div`
padding-left: 20px;
`
const GraphStatisticMain = styled.div`
display: flex;
flex-direction: column;
grid-column:${({grid})=> grid === 0 && '1/3'};
`
const CardsStatistics = styled.div`
`
const RewiewsItem = styled(motion.div)`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
margin-top: 16px;
background: #FFFFFF;
border-radius: 8px;
padding-top:16px;
padding-bottom:16px;
padding-left:24px;
`
const Rewies = styled.div`
margin-top:16px;
display: flex;
flex-direction: column;
`
const TittleStat = styled.div`
text-align: start;

font-weight: 800;
font-size: 24px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;

`
const SubTittleStat = styled.div`
padding-top: 10px;
text-align: start;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;
`

const CardStatItem = styled(motion.div)`
display: grid;
grid-template-columns: 50px 1fr .5fr;
background: #FFFFFF;
border-radius: 8px;
margin-top:16px;
padding: 10px;
    .PhotoStat{
        height:50px;
        width:50px;
        border-radius: 8px;
    }
    .NameStat{
        padding-left: 16px;
        font-weight: 500;
        font-size: 15px;
        color: #2C2E32;
        text-align: start;
    }
    .SalesStat{
        /* justify-self: end; */
        font-weight: 400;
        font-size: 15px;
        /* margin-right: 24px; */
        color: #2C2E32;
    }
`
const NoData = styled.div`
width: 100%;
height: 100px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
font-size: 15px;
line-height: 130%;
color: #A6A6A6;
background: #FCFCFC;
border-radius: 8px;
margin-top: 16px;
`

const ScrollStat = styled.div`
max-height: 1000px;
overflow: auto;
`