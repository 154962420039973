import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { sr } from "../utils";
import Inf from "../images/sidebarNew/Vector.svg";
const SidebarNavElement = ({
  destiny,
  selectedChapter,
  setSelectedChapter,
  image = {},
  path,
  setPath,
  testPath,
  count,
  setCount,
}) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  // console.log(destiny, "LOCATION");
  const selectPath = () => {
    setPath(destiny);
  };

  // console.log(count, "COTTT");
  const URLS = location.pathname.slice(0, -1);

  const forQueryParam = () => {
    const symb = testPath.find((el) => el.path.includes(URLS));
    return symb;
  };
  const tests = () => selectPath();

  const isSelect = () =>
    window.location.pathname !== "/" && path
      ? path?.path === destiny.path
      : forQueryParam()?.name === destiny.path;

  useEffect(() => {
    isSelect();
  }, [path]);

  // console.log(path?.path === destiny.path,path?.path,destiny.path,'pppp')
  const HelperModal = () => <HelpModal>{image.hover}</HelpModal>;
  return (
    <SidebarNavElementWrapper
      onClick={tests}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <SidebarNavImage
        src={
          (window.location.pathname !== "/" && isSelect()) ||
          active ||
          image.path.includes(location.pathname)
            ? image.dark
            : image.default
        }
        alt="sidebar link"
      />
      {active && HelperModal()}
      {destiny.path === "Moderation" && (
        <Count>{count && count.CountKitchens}</Count>
      )}
      {destiny.path === "Moderation" && (
        <CountKitchens>
          {count && count.CountProd > 100 ? (
            <img src={Inf} height={7} width={14} alt="inf" />
          ) : (
            count?.CountProd
          )}
        </CountKitchens>
      )}
    </SidebarNavElementWrapper>
  );
};

export default SidebarNavElement;

export const SidebarNavElementWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 2em;
  cursor: pointer;
  position: relative;
  /* margin-top: 10%; */
  /* ${({ isSelect }) => isSelect && { background: "#3D425D" }} */
`;

const SidebarNavImage = styled.img`
  width: 4.5em;
  height: 4.5em;
`;
const HelpModal = styled.div`
  position: absolute;
  width: max-content;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  padding: 5px 8px;
  left: 27px;
  top: -30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.442px;
  color: #000000;
`;

const Count = styled.div`
  font-family: "Roboto";
  font-style: normal;
  position: absolute;
  padding: 1px 6px 1px 6px;
  left: 30px;
  background: #7882a7;
  border-radius: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.442px;
  color: #ffffff;
  top: -3px;
`;

const CountKitchens = styled.div`
  font-family: "Roboto";
  font-style: normal;
  position: absolute;
  bottom: 1px;
  left: 30px;
  background: #ee2b00;
  border-radius: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  padding: 1px 6px 1px 6px;
  letter-spacing: -0.442px;
  color: #ffffff;
`;
