import {sr} from "../../utils";
import {call, put} from "redux-saga/effects";
import moment from "moment";
import {
    GET_KITCHENS_FAILED,
    GET_KITCHENS_SUCCESS,
    GET_REVIEWS_FAILED,
    GET_REVIEWS_SUCCESS,
    SEND_PUSH_MESSAGE_ERROR,
    SEND_PUSH_MESSAGE_SUCCESS,
    SEND_ADMIN_COMMENT_SUCCESS,
    SEND_ADMIN_COMMENT_ERROR
} from "../../actions/ReviewsActions";
import {toggleModalWrapper} from '../../reducers/toolkit/orderMapReducer';
import {deliveryCycleInfoMessage} from '../../actions/ModalActions';


export function* getKitchensWorker() {

    const getKitchens = () => {
        return sr('AppSOKitchenService', 'GetRestaurantsForOperator', {})
    };

    try {
        const result = yield call(getKitchens);
        yield put({type: GET_KITCHENS_SUCCESS, payload: result.data.items});
    } catch (e) {
        yield put({type: GET_KITCHENS_FAILED, message: e.message});
    }
}

export function* getReviewsWorker(action) {
    const getReviews = ({date, activeRest}) => {
        const dateString = moment(date).format("YYYY-MM-DD");
        const dataToSend = {
            count: 1000,
            account: activeRest !== "all" ? activeRest : "",
            date: dateString,
        };
        return sr('ReviewsController', 'GetOrderReviewAdmin', dataToSend)
            .then(res => {
            if(res.data.items !== undefined && res.data.items.length) {
                return res.data.items.sort((a,b) => a.orderId < b.orderId ? 1 : -1);
                // window.scrollTo(0, 0);
            } else {
                return [];
            }
        })
    };

    try {
        const result = yield call(getReviews, action.payload);
        const sortResult = result.sort((a,b) => a.orderId < b.orderId ? 1 : -1);
        yield put({type: GET_REVIEWS_SUCCESS, payload: sortResult});
    } catch (e) {
        yield put({type: GET_REVIEWS_FAILED, message: e.message});
    }
}

export function* sendPushMessageWorker(action) {
    const send = ({title,orderId,message}) => {
        return sr('DeviceController', 'PushAllUsers', {
            title: title,
            message: message,
            orderId: orderId,
        })
    };
    try {
        yield call(send, action.payload);
        yield put({type: SEND_PUSH_MESSAGE_SUCCESS});
        yield put(toggleModalWrapper())
        yield put(deliveryCycleInfoMessage('sent_notifications'))
    } catch (e) {
        yield put({type: SEND_PUSH_MESSAGE_ERROR, message: e.message});
        yield put(deliveryCycleInfoMessage('error_from_server'))
    }
}

export function* sendPushSingleMessageWorker(action) {
    const send = ({title,message,orderId}) => {
        return sr('DeviceController', 'SendPushToUser', {
            title: title,
            message: message,
            orderId: orderId,
        })
    }

    try {
        yield call(send, action.payload);
        yield put({type: SEND_PUSH_MESSAGE_SUCCESS});
        yield put(toggleModalWrapper())
        yield put(deliveryCycleInfoMessage('sent_notifications'))
    } catch (e) {
        yield put({type: SEND_PUSH_MESSAGE_ERROR, message: e.message});
        yield put(deliveryCycleInfoMessage('error_from_server'))
    }
}


export function* sendAdminComment(action) {
    const send = () => {
        return sr('ReviewsController', 'SaveOrderReview', {
            orderId: action.payload.orderId,
            comment_admin: action.payload.comment,
            date: action.payload.date,
        })
    }

    try {
        yield call(send, action.payload);
        yield put({type: SEND_ADMIN_COMMENT_SUCCESS});
    } catch (e) {
        yield put({type: SEND_ADMIN_COMMENT_ERROR, message: e.message});
    }
}