import {takeEvery} from "redux-saga/effects";
import {
    getKitchens,
    getPermissions,
    getSalesReport,
} from "./mainAppWorkers";
import {getKitchensAction, getPermissionsAction, getSalesReportAction} from "../../reducers/toolkit/mainAppReducer"

function* mainAppWatcher() {
    yield takeEvery(getPermissionsAction, getPermissions)
    yield takeEvery(getKitchensAction, getKitchens)
    yield takeEvery(getSalesReportAction, getSalesReport)
}

export default mainAppWatcher;
