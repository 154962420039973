import { Input, Select } from 'antd'
import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import selectSvg from '../../images/select.svg'
import PointsItem from '../../components/Settings/PointsOfSale/Components/PointsItem'
import PointsModal from '../../components/Settings/PointsOfSale/Components/PointsModal'
const mockData = [
  {id:1,name:'NEW',town:'qwdqwdqdq'},
  {id:2,name:'NEW',town:'qeqwqeqweqeqwe'},
  {id:3,name:'NEW',town:'qeqwqeqweqeqwe'},
  {id:4,name:'NEW',town:'qeqwqeqweqeqwe'},

]
const {Option} = Select;

const PointsOfSale = () => {
  const [selectedPoints,setSelectedPoints] = useState([])
    const [selectMult,setSelectMult] = useState(true)
    const [showModal,setShowModal] = useState(false)
    
    const handlerMultipeSelect=()=>{
      setSelectMult(prev=>!prev)
      if(!selectMult){
        setSelectedPoints([])
      }else{
        setSelectedPoints(mockData.map(el=>el.id))
      }
    }
  return (
	  <>
    <WrapperMain>
    <div style={{width:'100%'}}>
      <Select
      style={{width:'100%',}}
      placeholder='Выберите...'
      >
          {
            mockData.map(el=>{
              return (
              <Option key={el.id} value={el.id} >
                {el.town}
              </Option>
              )
            })
          }
      </Select>
    </div>
    <div style={{display:'flex',flexDirection:'row',paddingBottom:15}} >
      <BtnDelete>Создать точку</BtnDelete>
      <BtnDelete>Удалить точку</BtnDelete>
    </div>
    <WrapperPoints>
    <HeaderPoints>
      
        <div>
           <img src={selectSvg} onClick={handlerMultipeSelect} style={{cursor:'pointer'}}/>
        </div> 

        <div style={{textAlign:'start'}} >
           #
        </div>

        <div style={{textAlign:'start'}}>
           Город
        </div>

        <div style={{textAlign:'start'}}>
           Имя
        </div>
    </HeaderPoints>   
    {
         mockData.map(points=>
            <PointsItem {...{points,setShowModal,setSelectedPoints,selectedPoints}}/> 
        )
    }
    {
      <PointsModal  {...{showModal,setShowModal}}/>
    }
</WrapperPoints>
</WrapperMain>
	</>
  )
}
export default PointsOfSale
const WrapperMain = styled.div`
padding: 50px;
`
const WrapperPoints = styled.div`
background-color: #FFFFFF;
border-radius: 8px;
width: 100%;
height: max-content;

`
const HeaderPoints = styled.div`
display: grid;
grid-template-columns: 40px 100px 1fr 1fr ;
border-bottom: 1px solid rgb(169,169,169,0.5);
padding:10px 0px 10px 0px ;
color:#999999;
`
const BtnDelete = styled.div`
background-color: #3498db;
color:#fff;
padding: 15px;
height: 36px;
display: flex;
align-items: center;
cursor: pointer;
margin-right: 15px;
` 