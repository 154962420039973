import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import {Select, Switch} from "antd";
import ru from 'date-fns/locale/ru';
import moment from "moment";
import { sr } from '../../utils';
import {ClockLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {hidePopupWrapper} from "../../actions/ReviewsActions";
import ReviewBody from "../../components/Review/ReviewBody";
import PushMessagePopup from "../../components/Review/PushMessagePopup";
import NoPermissions from "../../components/NoPermissions";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

const {Option} = Select;
registerLocale('ru', ru);


function Reviews() {

    const dispatch = useDispatch();
    //main store
    const {
        getPermissionsSuccess,
        permissions,
    } = useSelector(store => store.appMain);
    //reviews store
    const {
        showPopupWrapper,
    } =  useSelector(store => store.reviews);

    //date state
    const [date, setDate] = useState(new Date());
    //account filter state
    const [rests, setRests] = useState([]);
    const [activeRest, setActiveRest] = useState(null);
    //RAW data state
    const [reviews, setReviews] = useState([]);
    const [orders, setOrders] = useState({});
    //filter state
    const [evaluationFilter, setEvaluation] = useState([false, false, false, false, false, false]);
    const [allEvaluation, setAllEvaluation] = useState(true);
    const [withoutComments, setWithoutComments] = useState(false);
    const [withClientComments, setWithClientComments] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState([]);
    //paginator state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [paginatedOrders, setPaginatedOrders] = useState([]);
    //orders on page setting (edit, to show more)
    const [ordersPerPage] = useState(20);
    //loader
    const [showSpinner, setShowSpinner] = useState(false);
    //comment add
    const [commentConfirm, setCommentConfirm] = useState(false);
    const [allKitch,setAllKitch] = useState(false)
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

    useEffect(() => {
        if (!getPermissionsSuccess) {
            dispatch(getPermissionsAction())
        }
    }, []);

    useEffect(() => {
        if( getPermissionsSuccess ) {
            getAccounts();
        }
    }, [permissions]);
    //active option in selector
    useEffect(() => {
        if(rests) {
            activeOptionInSelector(rests)
        }
    }, [rests])
    //getReviews
    useEffect(() => {
        buildOrders()
    }, [reviews])
    //filtering
    useEffect(() => {
        endToEndFiltering();
    }, [orders, evaluationFilter, allEvaluation, currentPage, withoutComments, withClientComments])
    useEffect(() => {
        setCurrentPage(1)
    }, [withoutComments, evaluationFilter])
    //reset eval filter
    useEffect(() => {
        resetEvalFilter()
    }, [evaluationFilter])
    // paginator
    useEffect(() => {
        setPaginator();
    }, [filteredOrders, currentPage, evaluationFilter, withoutComments, commentConfirm])
    useEffect(() => {
        getReview()
    }, [commentConfirm,SelectedKitchens,allKitch])

    //server query
    const getAccounts = () => {
        let dateString = moment(date).format("YYYY-MM-DD");
        sr('AppSOKitchenService', 'GetRestaurantsForOperator', {})
            .then(res => {
                setRests(res.data.items);
            })
    };
    useEffect(()=>{
        
    },[SelectedKitchens])
    const getReview = () => {
        setShowSpinner(true)
        let dateString = moment(date).format("YYYY-MM-DD");
        if(activeRest || activeRest === "all") {
            sr('ReviewsController', 'GetOrderReviewAdmin', {
                count: 1000,
                account: allKitch ? "" : SelectedKitchens.id,
                // activeRest !== "all" ? activeRest : ""
                date: dateString,
            }).then(res => {
                if(res.data.items && res.data.items !== undefined && res.data.items.length ) {
                    const sortToOrdersReviews = res.data.items.sort((a,b) => a.orderId < b.orderId ? 1 : -1);
                    setReviews(sortToOrdersReviews);
                    window.scrollTo(0, 0);
                } else {
                    setReviews([]);
                }
            })
        }
    };
    const permIsSet = Object.values(permissions).length !== 0;

    const switchHandlerForKitchen=()=>{
        setAllKitch(prev=>!prev)
    }
    useMemo(() => {
        getReview();
        setCurrentPage(1)
    }, [date, activeRest])

    //parse
    const buildOrders = () => {
        const reviewToOrders = parseToOrdersObj()(reviews)
        setOrders(reviewToOrders)
    }
    //filter settings
    const resetEvalFilter = () => {
        evaluationFilter.includes(true)
            ? setAllEvaluation(false)
            : setAllEvaluation(true)

        if(evaluationFilter.filter(el => el === true).length === 6) {
            setAllEvaluation(true)
            resetEvaluation();
        }
    }
    const activeOptionInSelector = (rests) => {
        if (rests.length) {
            setActiveRest(rests[0].id)
        } else {
            setActiveRest("")
        }
    }
    const endToEndFiltering = () => {
        setFilteredOrders(
            commentsFilter(
                evalFilter(
                    clientCommentsFilter(orders)
                )
            )
        );
        setShowSpinner(false)
    }
    const changeContract = (value) => {
        setActiveRest(value)
    }
    const selectEvaluation = id => {
        const newEvaluationArr = [...evaluationFilter];
        newEvaluationArr[id] = !evaluationFilter[id];
        setEvaluation([...newEvaluationArr])
    }
    const resetEvaluation = () => {
        const resetArray = [false, false, false, false, false, false];
        setEvaluation(resetArray);
    }
    //paginator settings
    const setPaginator = () => {
        const lastOrdersIndex = currentPage * ordersPerPage;
        const firstOrdersIndex = lastOrdersIndex - ordersPerPage;

        let objToArr = Object.values(filteredOrders)
        let sortedArr = sortToDate(objToArr)
        let paginationArr = sortedArr.slice(firstOrdersIndex, lastOrdersIndex);

        setPaginatedOrders([...paginationArr])

        let tempArray = [];
        for(let i = 1; i <= Math.ceil(Object.values(filteredOrders).length / ordersPerPage); i++) {
            tempArray.push(i)
        }

        setPageNumbers([...tempArray]);
    }
    const paginate = pageNumber => {
        window.scrollTo(0, 0);
        setCurrentPage(pageNumber)
    };
    const nextPage = () => {
        if(currentPage < pageNumbers.length){
            setCurrentPage(currentPage + 1);
        }
    }
    const prevPage = () => {
        if(currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    const parseToOrdersObj = function(){
        //Замыкание для результирующего объекта
        let resultObj = {};
        return function req2 (objectArray) {
            if(objectArray.length === 0) {
                return resultObj
            }
            let iterator = 0;
            const orderID = objectArray[0].orderId;
            let tempVal = objectArray.filter(el => {
                if(el.orderId === orderID) {
                    iterator++;
                    return el;
                }
            });
            resultObj[`${orderID}`] = tempVal;
            return req2(objectArray.slice(iterator))
        }
    }
    const evalFilter = (orders) => {
        if(allEvaluation) {
            return orders
        }
        const filterLetters = []
        evaluationFilter.forEach((el, id) => {
            if(el === true) filterLetters.push(id);
        });
        let filteredOrders = Object.values(orders).filter(order => {
            let searchingValue = false;
            order.forEach(review => {
                const evalOfReview = +review.value;
                if (filterLetters.includes(evalOfReview)) {
                    searchingValue = true;
                }
            })
            if(searchingValue === true) {
                return order;
            }
        })
        setShowSpinner(false)
        return filteredOrders;
    }

    const commentsFilter = (orders) => {
        if (!withoutComments) {
            return orders
        }
        let filteredOrders = Object.values(orders).filter(order => {
            let searchingValue = false;
            order.forEach(review => {
                if(review.type === 'comment_admin') {
                    searchingValue = true;
                }
            })
            if(searchingValue !== true) {
                return order;
            }
        })
        setShowSpinner(false)
        return filteredOrders;
    }
    const clientCommentsFilter = (orders) => {
        if(!withClientComments) {
            return orders
        }
        let filteredOrders = Object.values(orders).filter(order => {
            let searchingValue = false;
            order.forEach(review => {
                if (review.comment) {
                    searchingValue = true;
                }
            })
            if(searchingValue === true) {
                return order;
            }
        })
        setShowSpinner(false)
        return filteredOrders;
    }

    const addAdminComment = (orderId, comment, date) => {
        pushCommentOnServer(orderId, comment, date);
        setCommentConfirm(prev => !prev)
    }
    const pushCommentOnServer = (orderId, comment, date) => {
        sr('ReviewsController', 'SaveOrderReview', {
            orderId: orderId,
            comment_admin: comment,
            date: date,
        }).then(res => {
            console.log(res);
        })
    }
    const sortToDate = arr => {
        return arr.sort((a,b) => {
            return new Date(a[0].update_at).getTime() < new Date(b[0].update_at).getTime() ? 1 : -1;
        })
    }

    //popup handlers
    const closePopupHandle = () => {
        dispatch(hidePopupWrapper())
    };
    const checkRole = (param) => {
        if (permissions.staff) {
            if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
                return true
            } else {
                return permissions.permissions.includes(param)
            }
        } else {
            return false
        }
    }
    const drawReviews = () => {
        return (
            <ReviewsWrapper>
                {showSpinner ? (
                    <Loader>
                        <ClockLoader loading/>
                    </Loader>
                ) : null}
                    <Container>
                        <ReviewsHeader>
                            <DateAndRest className="assembly_date">
                                <DatePicker
                                    dateFormat="E - d MMMM"
                                    selected={date}
                                    onChange={date => setDate(date)}
                                    className="datepicker"
                                    locale={ru}
                                />
                                {/* <Select
                                    style={{width: 300, marginLeft: 20}}
                                    //defaultValue={_.some(rests, rest => rest.id === '224725') ? '224725' : rests[0].id}
                                    value={activeRest}
                                    onChange={changeContract}
                                >
                                    <Option value={"all"}>Показать все</Option>
                                    {rests.map((v) => {
                                        return (
                                            <Option key={v.id} value={v.id}>
                                                {v.name}
                                            </Option>
                                        )
                                    })}

                                    
                                </Select> */}
                                <UpdateButton onClick={getReview}>
                                    <p>Обновить</p>
                                </UpdateButton>
                                <SwitchWrapps>
                                   <Switch
                                   onChange={switchHandlerForKitchen}
                                   checked={allKitch}
                                   /> 
                                   <p  style={{paddingLeft:10,color:allKitch ? '#1890ff' : 'rgba(0, 0, 0, 0.65)' }}>Показать все</p>
                                </SwitchWrapps>
                                
                            </DateAndRest>
                            <Evaluations>
                                <ButtonListTitle>
                                    <p>Оценка</p>
                                </ButtonListTitle>
                                <ButtonList>
                                    <EvaluationButton
                                        selected={allEvaluation}
                                        onClick={resetEvaluation}
                                    >Все</EvaluationButton>
                                    {
                                        evaluationFilter.map((el ,id) => {
                                            return <EvaluationButton
                                                key={id + "key"}
                                                selected={el}
                                                onClick={() => {selectEvaluation(id)}}
                                            >{id ? id :"Без рейтинга"}</EvaluationButton>
                                        })
                                    }
                                    <CheckboxWrapper>
                                        <CheckBoxBody>
                                            <Checkbox checked={withClientComments} onClick={() => {setWithClientComments(prev => !prev)}}>
                                                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.15 4.96534L0.768198 2.69695C0.592462 2.52959 0.307538 2.52959 0.131802 2.69695C-0.043934 2.86432 -0.043934 3.13568 0.131802 3.30305L2.8318 5.87447C3.00754 6.04184 3.29246 6.04184 3.4682 5.87447L8.8682 0.731617C9.04393 0.564249 9.04393 0.292893 8.8682 0.125526C8.69246 -0.0418419 8.40754 -0.0418419 8.2318 0.125526L3.15 4.96534Z" fill="#111111"/>
                                                </svg>
                                            </Checkbox>
                                            <p>С текстовыми комментариям</p>
                                        </CheckBoxBody>
                                        <CheckBoxBody>
                                            <Checkbox checked={withoutComments} onClick={() => {setWithoutComments(prev => !prev)}}>
                                                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.15 4.96534L0.768198 2.69695C0.592462 2.52959 0.307538 2.52959 0.131802 2.69695C-0.043934 2.86432 -0.043934 3.13568 0.131802 3.30305L2.8318 5.87447C3.00754 6.04184 3.29246 6.04184 3.4682 5.87447L8.8682 0.731617C9.04393 0.564249 9.04393 0.292893 8.8682 0.125526C8.69246 -0.0418419 8.40754 -0.0418419 8.2318 0.125526L3.15 4.96534Z" fill="#111111"/>
                                                </svg>
                                            </Checkbox>
                                            <p>Без комментариев администратора</p>
                                        </CheckBoxBody>
                                    </CheckboxWrapper>
                                </ButtonList>
                            </Evaluations>
                            <CategoryHeader permissions={permissions.staff.role === "admin"}>
                                {
                                    permissions.staff.role === "admin"
                                        ?
                                        <>
                                            <RowElem className='header-cell'><p>Дата и время</p></RowElem>
                                            <RowElem className='header-cell'><p>Клиент</p></RowElem>
                                            <RowElem className='header-cell'><p>Адрес</p></RowElem>
                                            <RowElem className='header-cell'><p>Доставлен</p></RowElem>
                                            <RowElem className='header-cell'><p>Отзыв о кухне</p></RowElem>
                                            <RowElem className='header-cell'><p>Отзыв о сервисе</p></RowElem>
                                            {
                                                permissions.staff.role === "admin"
                                                    ?  <RowElem className='header-cell' style={{marginLeft: 10}}><p>Комментарий</p></RowElem>
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            <RowElem><p>Дата и время</p></RowElem>
                                            <RowElem><p>Клиент</p></RowElem>
                                            {/*<RowElem><p>Адрес</p></RowElem>*/}
                                            <RowElem><p>Доставлен</p></RowElem>
                                            <RowElem><p>Отзыв о кухне</p></RowElem>
                                            <RowElem><p>Отзыв о сервисе</p></RowElem>
                                        </>
                                }

                            </CategoryHeader>
                        </ReviewsHeader>
                    </Container>
                <Container>
                    <OrderCardWrapper>
                        {
                            permIsSet !== 0 && paginatedOrders.length
                                ?   paginatedOrders.map(el => {return <ReviewBody
                                    key={Math.random() + "key4React"}
                                    data={el}
                                    permissions={permissions}
                                    getPermissionsSuccess={getPermissionsSuccess}
                                    addAdminComment={addAdminComment}
                                />})
                                :   <ReviewBody 
                                permissions={permissions}
                                />
                        }
                        {
                            pageNumbers.length > 1
                                ?   <Pagination>
                                    <Arrow onClick={prevPage}>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.871919 6.42832L6.26826 11.8229C6.50505 12.0591 6.88869 12.0591 7.12607 11.8229C7.36286 11.5867 7.36286 11.203 7.12607 10.9669L2.15776 6.00033L7.12547 1.03382C7.36226 0.797626 7.36226 0.413992 7.12547 0.177204C6.88869 -0.0589865 6.50445 -0.0589865 6.26767 0.177204L0.871321 5.5717C0.638171 5.80545 0.638171 6.19512 0.871919 6.42832Z" fill="#999999"/>
                                        </svg>
                                        <span style={{
                                            marginLeft: 10
                                        }}>Сюда</span>
                                    </Arrow>
                                    <PButtonList>
                                        {
                                            pageNumbers.map(num => <PaginationBtn className={currentPage === num ? "active-page" : ""} key={"key" + num} onClick={() => {paginate(num)}}>{num}</PaginationBtn>)
                                        }
                                    </PButtonList>
                                    <Arrow direction="right" onClick={nextPage}>
                            <span style={{
                                marginRight: 10
                            }}>Туда</span>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.12808 6.42832L1.73174 11.8229C1.49495 12.0591 1.11131 12.0591 0.873929 11.8229C0.63714 11.5867 0.63714 11.203 0.873929 10.9669L5.84224 6.00033L0.874527 1.03382C0.637739 0.797626 0.637739 0.413992 0.874527 0.177204C1.11131 -0.0589865 1.49555 -0.0589865 1.73233 0.177204L7.12868 5.5717C7.36183 5.80545 7.36183 6.19512 7.12808 6.42832Z" fill="#999999"/>
                                        </svg>
                                    </Arrow>
                                </Pagination>
                                : null
                        }
                    </OrderCardWrapper>
                </Container>
            </ReviewsWrapper>
        )
    }
    return (
        <>
            {
                getPermissionsSuccess
                    ?
                    <>
                        <PopupWrapper
                            onClick={closePopupHandle}
                            showPopupWrapper={showPopupWrapper}
                        >
                            <PushMessagePopup />
                        </PopupWrapper>
                        {
                            permIsSet
                                ?
                                checkRole('screen_reviews')
                                    ? drawReviews()
                                    : <NoPermissions />
                                :
                                null
                        }
                    </>
                    :
                    null
            }
        </>
    )
}

export default Reviews;

//main wrap
const ReviewsWrapper = styled.div`
    position: relative;
    width: 100%;
    font-family: "Roboto", sans-serif;
    text-align: left;
`
const Container = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px 5px;
    display: flex;
    flex-direction: column;
`
//loader
const Loader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
`;
const PopupWrapper = styled.div`
  ${({showPopupWrapper}) => showPopupWrapper ? {display: "flex"}  : {display: "none"}};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.41);
  z-index: 100;
`
const ReviewsHeader = styled.header`
  position: fixed;
  top: 64px;
  width: calc(100% - 64px);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  background: #F4F5F9;
  max-width: 1240px;
`
//common content styles
// const AbsoluteTop = styled.div`
//     position: fixed;
//     top: 60px;
//     left: 0;
//     right: 0;
//     padding-top: 30px;
//     background-color: #F4F5F9;
// `
const DateAndRest = styled.div`
    display: flex;
    margin-bottom: 20px;
`
const UpdateButton = styled.div`
    width: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3d425e;
    color: #fff;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
`
const Evaluations = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
`
const ButtonListTitle = styled.div`
    margin-bottom: 5px;
`
const ButtonList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const CheckboxWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const CheckBoxBody = styled.div`
  display: flex;
  padding: 2px;
`
const Checkbox = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid ${({checked}) => checked ? "#3D425E" : "#C4C4C4"};
  border-radius: 4px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  
  & svg {
    display: ${({checked}) => checked ? "block" : "none"};
  }
`
const EvaluationButton = styled.button`
    max-height: 29px;
    margin-right: 10px;
    padding: 3px 10px;
    background: ${({selected}) => selected ? '#3D425E' : '#fff'};
    color: ${({selected}) => selected ? '#fff' : '#161616'};
    border: 1px solid ${({selected}) => selected ? '#3D425E' : 'black'};
    border-radius: 1px;
  
  &:last-child {
      margin-right: 0;
  }
`
const OrderCardWrapper = styled.div`
  margin-top: 275px;
`
const CategoryHeader = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: grid;
  ${({permissions}) => permissions ? {gridTemplateColumns: ".7fr .7fr 1fr repeat(3, 1fr) 1.5fr;"} : {gridTemplateColumns: ".5fr .5fr 1fr 2fr repeat(1, 1fr);"}};
  color: #999999;
  
  ${({permissions}) => permissions ? "& div{padding: 8px}" : null};
  
  .header-cell{
    padding: 8px;
    cursor: default;
  }
  
`
const RowElem = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
`

//paginator styles
const Pagination = styled.div`
  width: 768px;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DDDDDD;
  margin: auto;
`
const Arrow = styled.button`
  margin-${({direction}) => direction === 'right' ? 'left' : 'right'}: auto;
  margin-${({direction}) => direction === 'right' ? 'right' : 'left'}: 10px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`
const PaginationBtn = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 1px;
  
  &:last-child{
    margin-right: 0;
  }
`
const PButtonList = styled.div`
  display: flex;
  
  & .active-page {
    background: #3498DB;
    color: white;
  }
`
const SwitchWrapps = styled.div`
display:flex;
align-items:center;
margin-left:15px;
color
`
