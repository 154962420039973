import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { getSalesReportAction } from '../../reducers/toolkit/mainAppReducer';

export const SummarySales = () => {
  const salesReport = useSelector((store) => store.appMain.salesReport);
  const isLoading = useSelector((store) => store.appMain.getSalesReportStarted);
  const permissions = useSelector(store => store.appMain.permissions);
  const dispatch = useDispatch();
  const formattedToday = moment().format('YYYY-MM-DD');
  const [date, setDate] = useState(formattedToday);

  const checkRole = (param) => {
    if (permissions.staff) {
        if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
            return true
        } else {
            return permissions.permissions.includes(param)
        }
    } else {
        return false
    }
  }

  if(!checkRole('screen_summarySales')) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>Нет прав!</div>;
  }

  const handleSearch = () => {
    dispatch(getSalesReportAction({ date_report: date }));
  };

  const combinedData = salesReport.salesSummary?.map((summary) => {
    const cityId = summary.ID_city;

    return {
      ...summary,
      ...salesReport.courierCount?.find((item) => item.ID_city === cityId),
      ...salesReport.payToKitchen?.find((item) => item.ID_city === cityId),
      ...salesReport.countOrders?.find((item) => item.ID_city === cityId),
      ...salesReport.ZPCouriers?.find((item) => item.ID_city === cityId),
      ...salesReport.countAddress?.find((item) => item.ID_city === cityId),
    };
  }) || [];

  return (
    <Wrapper>
      <Header>
        <InputWrapper>
          <Label>Дата:</Label>
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </InputWrapper>
        <Button onClick={handleSearch}>Найти</Button>
      </Header>

      {isLoading ? (
        <>Загрузка...</>
      ) : (
        <CardWrapper>
          {combinedData.map((item, index) => {
            const Город = item.Город;
            const Курьеров_в_рейсе = Number(item.Курьеров_в_рейсе) || 0;
            const Количество_заказов = Number(item['Кол-во_заказов']) || 0;
            const Продано_продукты = Number(item.Продано_продукты) || 0;
            const Продано_сбор = Number(item.Продано_сбор) || 0;
            const Оплачено_баллами = Number(item.Оплачено_баллами) || 0;
            const Выплата_кухням = Number(item.Выплата_кухням) || 0;
            const ЗП_курьеров = Number(item.ЗП_курьеров) || 0;
            const Адреса = Number(item.Адреса) || 0;

            const averageOrderValue = Продано_продукты / Количество_заказов || 0;
            const averageAddressValue = Продано_продукты / Адреса || 0;
            const calculatedValue = Продано_продукты + Продано_сбор - Оплачено_баллами - (Выплата_кухням + ЗП_курьеров);

            return (
              <Card key={index}>
                <Title>
                  <CityName>{Город}</CityName> - <CourierCount>{Курьеров_в_рейсе}</CourierCount>
                </Title>
                <Row>
                  <div><strong>{Продано_продукты}</strong>+</div>
                  <div style={{color: 'green'}}>{Продано_сбор}-</div>
                  <div style={{color: 'red'}}>{Оплачено_баллами}-</div>
                  <div style={{color: 'red'}}>({Выплата_кухням}-</div>
                  <div style={{color: 'red'}}>{ЗП_курьеров})=</div>
                  <Result>{calculatedValue}</Result>
                </Row>
                <Row>
                  Заказов: <strong>{item['Кол-во_заказов']}</strong> / {averageOrderValue.toFixed(0)} р/чек
                </Row>
                <Row>
                  Адресов: <strong>{Адреса}</strong> / {averageAddressValue.toFixed(0)} р/чек
                </Row>
              </Card>
            );
          })}
        </CardWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #6c757d;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ced4da;
`;

const Button = styled.button`
  margin-top: auto;
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border-radius: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
`;

const CityName = styled.div`
  font-size: 24px;
  font-weight: bold;

  span {
    font-size: 18px;
    font-weight: normal;
  }
`;

const CourierCount = styled.div`

`;

const Result = styled.span`
  font-weight: bold;
  color: blue;
`;

export default SummarySales;
