import {sr} from "../../utils";
import {call, put} from "redux-saga/effects";
import {getKitchensHandler, getPermissionsHandler, getSalesReportHandler} from "../../reducers/toolkit/mainAppReducer"


export function* getPermissions() {
    yield put(getPermissionsHandler('pending'))
    const resp = yield (() => sr('SettingsService', 'GetOperatorPermissions', {}))()

    try {
        yield put(getPermissionsHandler(resp.data));
    } catch (e) {
        yield put(getPermissionsHandler('rejected'));
    }
}

export function* getKitchens() {
    yield put(getKitchensHandler('pending'))
    const resp = yield sr('AppSOKitchenService', 'GetRestaurantsForOperator', {})
    try {
        yield put(getKitchensHandler(resp.data.items));
    } catch (e) {
        yield put(getKitchensHandler('rejected'));
    }
}

export function* getSalesReport(action) {
    yield put(getSalesReportHandler('pending'))
    const resp = yield sr('SalesReport', 'GetSalesSummary', action.payload)
    try {
        yield put(getSalesReportHandler({...resp.data}));
    } catch (e) {
        console.error(e)
        yield put(getSalesReportHandler('rejected'));
    }
}