import React,{useState,useCallback,useEffect,memo,useRef} from 'react'
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getCategoryStarted, getMenuStartedAssort, getModerationProductStarted} from '../../actions/AssortmentAction'
import { ProductListMod } from './ProductListMod'
import HeaderMod from './HeaderMod';
import { nanoid } from 'nanoid';
export const Content = ({activeRest,checkRole,permission,allKitchens}) => {
    const dispatch = useDispatch()
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)
    const allKitch = useSelector(store=> store.assortment.allKitch)
    const [actions,setActions] = useState(false)
    const productListModeration = useSelector(
      (store) => store.assortment.productListModeration
    );
    console.log(productListModeration,'actions')

    useEffect(() => {
      dispatch(getCategoryStarted(SelectedKitchens.id));
    }, []);
  useEffect(()=>{
    if(!allKitch){
      dispatch(getModerationProductStarted({
        start:0,
        limit:100,
        // account:true
        account:SelectedKitchens.id,
        }))
    }
    else{
      dispatch(getModerationProductStarted({
  start:0,
  limit:100,
  }))
  }
  },[SelectedKitchens,actions,allKitch])
  
  return (
    <ContentWrapper >
      {
        productListModeration &&
     <ProductListMod {...{setActions}} />
      }
    </ContentWrapper>
  )
}
const ContentWrapper = styled.main`
  grid-area: content;
  /* padding: 10px 188px 10px 10px; */
`