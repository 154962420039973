import { sr } from "../utils"
import React from 'react'

export const useRequest = () =>{

    const [req,setReq] = React.useState(null)
    const [status,setStatus] = React.useState(null)
    // const [option,setOption] = React.useState(null)

    const reqHandler = async ( service, method, data = {}, ) => {

      const res  = await sr(service,method,data)
      setReq({status:'pending'})
      console.log(res,"DATA FROM useRequest")
        setReq({
            result:res.data.result ? res.data : null,
            status:res.data.result ? 'resolve' : 'reject',
            message:res.data.message,
            resultCode:res.resultCode
         })
      }

    return [req,reqHandler]
}