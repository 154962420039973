import {
    GET_SENDER,
    GET_SENDER_ERROR,
    GET_SENDER_READY,
    RESET_SELECTED_SENDER,
    SELECT_SENDER,
    SELECT_SENDER_MODAL
} from "../../actions/WarehouseActions";

const initState = {
    senderLoad: false,
    senderSuccess: false,
    senderError: false,
    senderErrorMessage: '',
    senders: [],
    selectedSender: null,
    selectedSenderModal: null,
}

//
// the getRc request happens in the saga
//
export const getSenderReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_SENDER:
            return {
                ...state,
                senderLoad: true,
                senderSuccess: false,
            }
        case GET_SENDER_READY:
            return {
                ...state,
                senderLoad: false,
                senderSuccess: true,
                senders: action.payload,
            }
        case GET_SENDER_ERROR:
            return {
                ...state,
                senderLoad: false,
                senderError: true,
                senderErrorMessage: action.payload?.message,
            }
        case SELECT_SENDER:
            return {
                ...state,
                selectedSender: state.senders[action.payload],
            }
        case SELECT_SENDER_MODAL:
            return {
                ...state,
                selectedSenderModal: state.senders[action.payload],
            }
        case RESET_SELECTED_SENDER:
            return {
                ...state,
                selectedSender: null,
            }
        default:
            return state
    }
}