import {takeEvery} from "redux-saga/effects";
import {
    getKitchensWorker,
    getReviewsWorker,
    sendAdminComment,
    sendPushMessageWorker,
    sendPushSingleMessageWorker
} from "./reviewsWorkers";
import {
    GET_KITCHENS,
    GET_REVIEWS,
    SEND_PUSH_MESSAGE,
    SEND_ADMIN_COMMENT,
    SEND_PUSH_SINGLE_MESSAGE
} from "../../actions/ReviewsActions";

function* reviewsWatcher() {
    yield takeEvery(GET_KITCHENS, getKitchensWorker)
    yield takeEvery(GET_REVIEWS, getReviewsWorker)
    yield takeEvery(SEND_PUSH_MESSAGE, sendPushMessageWorker)
    yield takeEvery(SEND_PUSH_SINGLE_MESSAGE, sendPushSingleMessageWorker)
    yield takeEvery(SEND_ADMIN_COMMENT, sendAdminComment)
}

export default reviewsWatcher;
