//img
import star from '../../images/menu_by_day/star.svg'
import person_black from '../../images/menu_by_day/person_black.svg'
import person_white from '../../images/menu_by_day/person_white.svg'
//common
import React, {memo, useState} from "react";
import {changeDishAmount, changeDishPrice, toggleActiveDish} from "../../actions/MenuByDayActions";
import {useDispatch} from "react-redux";
import styled from "styled-components";



const FoodListItem = ({
  listId,
  dishId,
  name,
  price,
  evaluation,
  count,
  reviewCount,
  isActive,
  editMode
}) => {
const dispatch = useDispatch();

const setActiveDishHandle = (e) => {
dispatch(toggleActiveDish({dishId, listId}));
};

const DrawListItem = ({isActive, editMode}) => {
const [localPriceValue, setLocalPriceValue] = useState(price);
const [localAmountValue, setLocalAmountValue] = useState(count);

const changePrice = (e) => {
  const priceValue = e.target.value;
  setLocalPriceValue(priceValue);
};
const changeCount = (e) => {
  const amountValue = e.target.value;
  setLocalAmountValue(amountValue)
};
const applyPrice = () => {
  dispatch(changeDishPrice({listId, dishId, newPrice: localPriceValue}))
};
const applyAmount = () => {
  dispatch(changeDishAmount({listId, dishId, newAmount: localAmountValue}))
};

return (
<>
  <ListItemName>
    <p>{name}</p>
  </ListItemName>
  <ListItemInfo >
  {/* onKeyDown={e => console.log(e.target) */}
    {
        editMode
            ?   <Price
                editMode
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                onChange={e => changePrice(e)}
                onBlur={applyPrice}
                value={Math.floor(localPriceValue)}
                type='number'
            />
            :
            <Price disabled defaultValue={Math.floor(price) + " ₽"}/>
    }
    {
        editMode
            ?
            <Count
                editMode
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                onChange={e => changeCount(e)}
                onBlur={applyAmount}
                value={localAmountValue}
                type='number'
            />
            :

            <Count
                disabled
                defaultValue={+count + " шт"}
            />
    }
    <div style={{display: 'flex', justifyContent: 'space-around'}}>
        {evaluation ? <Eval><img src={star} alt="star"/>{evaluation}</Eval> : null}
        {
            reviewCount
                ?
                <ReviewCount isActive={isActive}>
                    {
                        isActive
                            ? <img src={person_white} alt="person"/>
                            : <img src={person_black} alt="person"/>
                    }
                    {reviewCount}
                </ReviewCount>
                :
                null
        }
    </div>
  </ListItemInfo>
</>
)
};

return editMode
?
<ListItemWrapper isActive={isActive} onMouseDown={e => setActiveDishHandle(e)}>
  <DrawListItem isActive={isActive} editMode={editMode}/>
</ListItemWrapper>
:
<ListItemWrapper>
  <DrawListItem editMode={editMode}/>
</ListItemWrapper>
};



const ListItemWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({isActive}) => !isActive ? "#fff" : "#3D425E"};
  border-radius: 6px;
  color: ${({isActive}) => !isActive ? "#000" : "#fff"};
  padding: 4px;
`
const ListItemName = styled.div`
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  padding: 4px 8px;
`
const ListItemInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7px;
  
`
const StyledInput = styled.input`
  width: 73px;
  height: 26px;
  background: #E6E9F0;
  border-radius: 6px;
  border: none;
  color: #000;
`
const Price = styled(StyledInput)`
  text-align: center;
  border: 2px solid ${({editMode}) => !editMode ? "#E6E9F0" : "#CDDC39"};
`
const Count = styled(StyledInput)`
  text-align: center;
  border: 2px solid ${({editMode}) => !editMode ? "#E6E9F0" : "#CDDC39"};
`
const Eval = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #CDDC39;
`
const ReviewCount = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({isActive}) => !isActive ? "#000" : "#fff"};
`
export default memo(FoodListItem);
