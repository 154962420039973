import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import DatePicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import {Select, Switch} from "antd";
import "react-datepicker/dist/react-datepicker.css";
import './Style.css'
import { sr } from '../../utils';
import { isArray } from 'underscore';

const {Option} = Select;
registerLocale('ru', ru);
const banks = [{id:1,name:'АльфаБанк'},{id:2,name:'СовКомБанк'}]
export const DatePickReports = ({
  startDate,setStartDate,endDate,
  setEndDate,cities,setCities,selectedCity,
  setSelectedCity,handleInfo,
  setSelectedBank,selectedBank,selectInfo
  ,statusFields,setStatusField,actDate,setActDate}) => {
    const [date,setDate] = useState(new Date())

    // useEffect(()=>{
    //   sr('Reports','OffsettingPeriod',{}).then(res=>console.log(res,'RESULT'))
    // },[])
    const changeHandle=(value)=>{ 
      setStatusField('resolved') 
      setSelectedBank(value) }
  return (
    <DatePick>
            <Items>
            <div style={{alignSelf:'center'}}>
            <Select
            style={{width:201,marginRight:32}}
            value={selectedCity}
            onChange={(value) => setSelectedCity(value)}
            mode="multiple"
            >
             {cities ?
             cities?.map(v => {
                                return (
                                    <Option key={v.id} value={v.cityId} >
                                        {v.name}
                                    </Option>
                                )
                            })
            :
            ''
             }
            </Select>
            </div>
            <div>
            <span style={{marginRight:8,fontSize:16,fontWeight:400,color:'#2C2E32'}}>с:</span>
            <DatePicker
            className='reporsInput'
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            startDate={startDate}
            endDate={endDate}
            selectsStart
            dateFormat="d MMMM yyyy"
            locale={ru}
            />
            </div>
            <div>
            <span style={{marginRight:8,fontSize:16,fontWeight:400,color:'#2C2E32'}}>по:</span>
            <DatePicker
            className='reporsInput'
            style={{marginRight:20}}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
            selectsEnd
            dateFormat="d MMMM yyyy"
            locale={ru}
            />
            </div>
            <div>
            <span style={{marginRight:8,fontSize:16,fontWeight:400,color:'#2C2E32'}}>Акт от</span>
            <DatePicker
            className='reporsInput'
            style={{marginRight:20}}
            selected={actDate}
            onChange={(date) => setActDate(date)}
            dateFormat="d MMMM yyyy"
            locale={ru}
            />
            </div>
            <div> 
              <Btn onClick={handleInfo} >Фильтр</Btn>
            </div>
            
            </Items>
    </DatePick>
  )
}

const DatePick = styled.div`
display: grid;
justify-self: start;
align-self: center;
height:48px;
margin-left: 30px;

`
const Items = styled.div`
display:grid;
grid-template-columns: repeat(5,auto);
align-items: end;
gap: 10px;
grid-template-rows: 1fr;
`
const Btn = styled.button`
margin-right:10px;
padding:16px;
height: 48px;
background: #7882A7;
border-radius: 8px;
color: #FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 130%;
text-align: center;
border:none;
cursor: pointer;
  :hover{
    color:#2196F3;
  }
`