import React from "react";
import {Select} from "antd"
import useList from "../../../../hooks/useList"

const {Option} = Select;

const CustomSelect = ({data = [], value, handler, styles}) => {

  const optionList = useList(data, ({id, data}) => <Option value={id}>{data.name}</Option>)

  return (
    <div>
      <Select
        style={styles}
        //defaultValue={_.some(rests, rest => rest.id === '224725') ? '224725' : rests[0].id}
        value={value}
        onChange={handler}
      >
        <option value="-"><p style={{fontWeight: 'bold'}}>Очистить</p></option>
        {optionList}
      </Select>
    </div>
  )
}

export default CustomSelect;
