import React, {memo, useEffect, useState} from 'react';
import MapComponentDumb from "./MapComponentDumb"
import {useDispatch, useSelector} from "react-redux"
import {
  selectInfoUint, setCenter, setNewMarkerPosition, setPolygon, setPolygonMode,
  toggleSelectedOrdersToRedirect, toggleShowCourierTrack,getDetailedOrderInfo
} from "../../reducers/toolkit/orderMapReducer"
import {deliveryCycleInfoMessage} from "../../actions/ModalActions"
import {cutTime} from "./CouriersStatistic"
import DetailedInfo from './DetailedInfo';


//функция настройки маркеров
const deliveryPointsToMarkers = (propsObj) => {
  const {
    maps,
    map,
    dp,
    couriers,
    selectHandler,
    dragHandler,
    markOnRedirectHandler,
    mode,
    namePeriods
  } = propsObj

  // const da = dp.filter(item=> item.periodId === allPeriodsByDate.id)
  const extendedDp = []
  const markers = dp.map((el) => {
    const color = couriers.reduce((acc, c) => {
      if (c.id === el.courierId) {
        acc = c.color
      }
      if(el.courierId === null && el.status !== null){
          acc = el.color
      }
      return acc
    }, '')
    //настраиваем иконку
    const ico = chooseIcoForMarker(el, color)
    const bigIco = bigIcoFactory(ico, maps)
    const pos = {lat: +el.lat, lng: +el.lng}
    let marker
    const a =namePeriods ?  namePeriods.find(item=>item.id === el.periodId) : ''

    //инит маркера
    marker = new maps.Marker({
      position: pos,
      icon: ico,
      optimized: true,
    })

    //вспомогательная внутрянка
    marker.mode = mode
    marker.nativeIcon = ico
    marker.nativeBigIcon = bigIco
    marker.sideIcon = null
    marker.sideBigIcon = null
    marker.isShowing = false
    marker.isSelected = false

    //zIndex зависит от типа точки (доставлена / неназначена и т.д.)
    marker.nativeZIndex = 1
    if(!el.deliveryTime) marker.nativeZIndex = 5
    if(!el.courierId) marker.nativeZIndex = 10
    // console.log(allPeriodsByDate,'allPeriodsByDateallPeriodsByDate')
    // попап маркера с краткой инфой
    // console.log(a,'DADA')
    const deliveryTime = el.deliveryTime ? cutTime(el.deliveryTime.split(' ')[1]) : '--:--'
    const infoWindow = new maps.InfoWindow({
      //настраивается эта хрень крайне мерзко, так что я вывел ее в отдельную функцию, там тупо верстка ничего сложного
      content: buildContentString(el.companyId,a ,deliveryTime, el.companyName, el.companyAddress),
    });

    // обработка попапа
    //
    function toggleInfoWindow(status) {
      if(!toggleInfoWindow.timeout && !marker.draggable) {
        toggleInfoWindow.timeout = setTimeout(() => {
          infoWindow.isOpen = true
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          })
        }, 1000)
      } else {
        clearTimeout(toggleInfoWindow.timeout)
        toggleInfoWindow.timeout = null
        if(!marker.draggable) {
          toggleInfoWindow(status)
        }
      }

      if(status === 'hide') {
        if(infoWindow.isOpen) {
          infoWindow.close()
        }
        clearTimeout(toggleInfoWindow.timeout)
      }
    }
    
    // console.log(dp,'DPDPDPDPDP')


    marker.addListener("click", e => {
      if (marker.mode === 'show') {
        selectHandler(el, marker)
      }
      if (marker.mode === 'select' && !marker.isComplete) {
        markOnRedirectHandler({...el, marker}, marker)
      }
    })
    marker.addListener('mouseover', e => {
      toggleInfoWindow()
    })
    marker.addListener('mouseout', e => {
      toggleInfoWindow('hide')
    })

    marker.addListener("dragend", e => {
      dragHandler({
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      })
    })

    marker.setMap(map)
    extendedDp.push({...el, marker})
    return marker
  })
  //console.log(markers, 'markers10')
  // new MarkerClusterer({ markers, map })
  return [markers, extendedDp]

}
// const getName=()=>{
//   if(deliveryPoints){
//  const a = deliveryPoints.find(item=>item.companyId === companyId)
//  if(a){
//  const b = allPeriodsByDate.filter(item=> item.id===a.periodId)
//     setNamePeriods(b)
//  }
// }
// }
// useEffect(()=>{
//   getName()
// },[])
// function getsNAmes
//строитель попапа маркера
function buildContentString(id,periodId,deliveryTime, addressName, address) {

  return (
    `<h2>ID:${id}</h2>`+
    `<p style="margin: 0">${periodId ? periodId.timeFrom.slice(0,-3):'0'}-${periodId ? periodId.timeTo.slice(0,-3): '0'}</p>` + 
    `<p style="margin: 0">${deliveryTime}</p>` +
    `<p style="margin: 0">${addressName}</p>` +
    `<p style="margin: 0">${address}</p>`
  )
}

const bigIcoFactory = (ico, maps) => {
  return {
    url: ico,
    scaledSize: new maps.Size(32, 55, 'px', 'px')
  }
}



//выбор иконки для маркера
const chooseIcoForMarker = (dPoint, color, redirect) => {
  const path = 'https://menuforme.online/img/markers/';

  if (dPoint.carPosition === 'start')
    return `${path}start-${color}.svg`;
  if (dPoint.carPosition === 'end')
    return `${path}car-${color}.svg`;

  // console.log(dPoint, 'dPoint')
  if(!redirect) {
    if (dPoint.courierId == null && dPoint.status == null) {
      if (dPoint.lat === "53.49" || dPoint.lng === "49.23") {
        return `${path}marker-empty-new.svg`;
      } else {
        return `${path}marker-empty.svg`;
      }
    }
  }

  if (dPoint.deliveryTime == null) {
    return `${path}marker-${color}-off.svg`;  
  } else {
    return `${path}marker-${color}-on.svg`;
  }
}

// поиск точки в квадрате
export function findPointsInBounds(rectangle, extendDeliveryPoints) {
  const bounds = rectangle.getBounds()
  const boundsFields = Object.values(bounds)
  return extendDeliveryPoints.filter(el => {
    const pos = {lat: +el.lat, lng: +el.lng}
    //алгоритм поиска , реверсивно повторяет contains
    if(
      pos.lat <= Object.values(boundsFields[0])[0] && pos.lat >= Object.values(boundsFields[0])[1] &&
      pos.lng >= Object.values(boundsFields[1])[0] && pos.lng <= Object.values(boundsFields[1])[1]
    ) {
      return true
    }
    //стандартный алгоритм поиска точки
    return bounds.contains(pos)
  })
}

// картографическое ядро
const MapComponent = ({setColoredPoints}) => {
  const dispatch = useDispatch()

  //настройки карты из стора
  const center = useSelector(store => store.orderMap.center)
  const zoom = useSelector(store => store.orderMap.zoom)

  //локальный массив инстансов маркеров
  const [markers, setMarkers] = useState([])


  const [selectedOrd, setsSelectedOrd] = useState(null)
  const [namePeriods, setNamePeriods] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [courierTrack, setCourierTrack] = useState(null)
  const [courierTrackEndpoints, setCourierTrackEndpoints] = useState([])
  const [tempPolygon, setTempPolygon] = useState(null)
  const [startPointForTriangulation, setStartPointForTriangulation] = useState(null)
  const [movePointForTriangulation, setMovePointForTriangulation] = useState(null)
  const [drawingPolyDone, setDrawingPolyDone] = useState(false)
  const [extendDeliveryPoints, setExtendDeliveryPoints] = useState([])
  const [courierRoutePlanInstance, setCourierRoutePlanInstance] = useState(null)

  //точки достваки с сервера (групповые заказы)
  const deliveryPoints = useSelector(store => store.orderMap.deliveryPoints)
  const detailedOrderInfo = useSelector(store =>store.orderMap.detailedOrderInfo)
 
  //-
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)
  const filtering = useSelector(store => store.orderMap.filtering) //флаг фильтра, если хоть один фильтр задействован то true
  const filteredDeliveryPoints = useSelector(store => store.orderMap.filteredDeliveryPoints) //массив фильтрованных точек
  const allPeriodsByDate = useSelector(store=>store.common.allPeriodsByDate)
  const selectionInfoUnit = useSelector(store => store.orderMap.selectionInfoUnit) //инстанс заказа по которому выведена детальная информация
  const couriers = useSelector(store => store.orderMap.couriers) // массив задействованных в работе курьеров
  const selectedCourier = useSelector(store => store.orderMap.selectedCourier) // инстанс курьера выбранного для редиректа заказов
  const couriersTracks = useSelector(store => store.orderMap.couriersTracks) //массив точек lat/lng для рисования маршрута
  const targetCourierForTrackDraw = useSelector(store => store.orderMap.targetCourierForTrackDraw) //выбранный курьер для отрисовки маршрута
  const drawingMode = useSelector(store => store.orderMap.drawingMode) //флаг рисования на карте
  const polygon = useSelector(store => store.orderMap.polygon) //инстанс полигона
  const polygonMode = useSelector(store => store.orderMap.polygonMode) //флаг поведения полигона
  const courierRoutePlan = useSelector(store => store.orderMap.courierRoutePlan) //точки для рисования маршрутного плана курьера
  //google api shit
  const map = useSelector(store => store.orderMap.map) // инстанс карты
  const maps = useSelector(store => store.orderMap.maps) // инстанс фабрики элементов google maps
  //выбрать маркер для показа детальной информации


  const selectHandler = (order, marker) => {
    dispatch(selectInfoUint({...order, marker}))   
}
  ///////////////
  //выбрать маркер для переназначения на другого курьера

  const markOnRedirectHandler = (el, marker) => {
    marker.setZIndex(-1)
    setsSelectedOrd(el)
    setSelectedMarker(marker)
  }

  //сменить положение маркера
  const dragHandler = newPos => {
    dispatch(setNewMarkerPosition({...newPos}))
  }

  //отрисовка маршрутного плана курьера
  useEffect(() => {
    if(courierRoutePlan.length) {
      if(courierRoutePlanInstance) {
        courierRoutePlanInstance.setMap(null)
        setCourierRoutePlanInstance(null)
      }
      //инстанс плана (тупа линия чорного цвета епта)
      const routePlan = new maps.Polyline({
        path: courierRoutePlan,
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      //цепляем карту к элементу (ага, не на оборот)
      routePlan.setMap(map)
      //пишем готовый инстанс в стейт
      setCourierRoutePlanInstance(routePlan)
    } else {
      //чистим за собой
      if(courierRoutePlanInstance) {
        courierRoutePlanInstance.setMap(null)
        setCourierRoutePlanInstance(null)
      }
    }
  }, [courierRoutePlan])

  //отрисовка полигона
  useEffect(() => {
    if(drawingMode) {
      //вырубаем drag mode на карте
      map.setOptions({draggable: false});

      //обработать нажатие, установить точку старта
      map.addListener('mousedown', e => {
        const startPoint = e.latLng
        setStartPointForTriangulation(startPoint)
        //сразу запускаем обработку мува курсора где записываем endPoint для полигона
        map.addListener('mousemove', e => {
          e.domEvent.stopPropagation()
          setMovePointForTriangulation(e.latLng)
        })
      })

      //чистим за собой ивент мува курсора
      map.addListener('mouseup', e => {
        maps.event.clearListeners(map, 'mousemove')
        setDrawingPolyDone(true)
      })
    } else {
      //чистим за собой, возвращаем drag карте
      if(map) {
        map.setOptions({draggable: true});
        maps.event.clearListeners(map, 'mousemove')
        maps.event.clearListeners(map, 'mousedown')
        maps.event.clearListeners(map, 'mouseup')
      }
    }
  }, [drawingMode])

  //когда дорисовали полигон...
  useEffect(() => {
    if(drawingPolyDone) {
      //кидаем инстанс в стор
      dispatch(setPolygon(tempPolygon))
      //кидаем ивент на инстанс
      // console.log(tempPolygon,'tempPolygon') 
        if(tempPolygon){ 
        tempPolygon.set("clickable", true) 
        }
        setStartPointForTriangulation(null)
        setMovePointForTriangulation(null)
        setDrawingPolyDone(false)
        setTempPolygon(null)
      /*THIS ERRORS 349*/
      //ебашим с ноги по локальному мусору
     
    }
  }, [drawingPolyDone])

  //обрабатываем запись первой точки для полигона
  useEffect(() => {
    if(startPointForTriangulation) {

      // если инстанс полигона еще каким то хуем существует, ебашим его с ноги и с карты, и с памяти
      if(tempPolygon) {
        tempPolygon.setMap(null)
        setTempPolygon(null)
      }

      //задаем начальные границы полигона в одну точку
      const newBounds = new maps.LatLngBounds(
        new maps.LatLng(startPointForTriangulation.lat(), startPointForTriangulation.lng()),
        new maps.LatLng(startPointForTriangulation.lat(), startPointForTriangulation.lng()),
      );

      //инит полигона
      const rectangle = new maps.Rectangle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
        map,
        bounds: newBounds,
      });

      // задаем поведение (щас не используется)
      rectangle.mode = polygonMode
      // по идее обработчик один, но реагирует по разному в зависимости от мода,
      // концепция поведения изменилась и сейчас только закрытие по клику,
      // но если тебе надо будет докинуть еще какое то поведение то я оставляю эту хрень тут
      // пример ниже в коменте
      const polygonClickHandler = e => {
        if(rectangle.mode === null) {
          dispatch(setPolygon())
          return;
        }

        // const pointsInBounds = findPointsInBounds(rectangle, extendDeliveryPoints)

        // if(rectangle.mode === 'redirect') {
        //   console.log(pointsInBounds)
        //   pointsInBounds.map(el => {
        //     markOnRedirectHandler(el, el.marker)
        //   })
        // }
      }

      rectangle.addListener('click', polygonClickHandler)
      setTempPolygon(rectangle)
    }
  }, [startPointForTriangulation])

  //обработка второй точки полигона (та что мувается)
  useEffect(() => {
    if(startPointForTriangulation) {
      if(movePointForTriangulation) {

        const startPointLat = startPointForTriangulation.lat()
        const startPointLng = startPointForTriangulation.lng()

        const endPointLat = movePointForTriangulation.lat()
        const endPointLng = movePointForTriangulation.lng()

        //знаю, больно, по другому гугл пытается обогнуть всю землю.
        const newBounds = new maps.LatLngBounds(
          new maps.LatLng(startPointLat, startPointLng < endPointLng ? startPointLng : endPointLng),
          new maps.LatLng(endPointLat, endPointLng > startPointLng ? endPointLng : startPointLng)
        );

        //применяем границы к полигону (при каждом перемещении мыши)
        if(tempPolygon) {
          tempPolygon.set("bounds",newBounds)
        }
      }
    }
  }, [movePointForTriangulation])

  //если выбрали курьера и нарисовали полигон, то редиректим точки в границе на выбранного курьера
  useEffect(() => {
    if(selectedCourier && polygon) {
      const points = findPointsInBounds(polygon, extendDeliveryPoints)
      points.forEach(el => {
        setTimeout(() => markOnRedirectHandler(el, el.marker), 100)
      })
      //так надо!
      setTimeout(() => {
        dispatch(setPolygon(null))
      }, 100)
    }
  }, [polygon])

  //включение/отключение  маркеров если мы фильтруем заказы
  useEffect(() => {
    if(filteredDeliveryPoints.length) {
      filteredDeliveryPoints.forEach(el => el.marker.setMap(null))
    }
    if(filtering) {
      markers.forEach(el => el.setMap(null))
      filteredDeliveryPoints.forEach(el => el.marker.setMap(map))
    } else {
      filteredDeliveryPoints.forEach(el => el.marker.setMap(null))
      markers.forEach(el => el.setMap(map))
    }
  }, [filtering, filteredDeliveryPoints])

  // рисуем трек курьера
  useEffect(() => {
    if(couriersTracks?.length && targetCourierForTrackDraw) {
      const trackCoords = couriersTracks.map(el => ({lng: el.lng, lat: el.lat}))
      const {color} = targetCourierForTrackDraw

      // начальная координата будет флажком
      const start = ({...trackCoords[0], carPosition: 'start'})
      const startIcon = chooseIcoForMarker(start, color)
      const startMarker = new maps.Marker({
        position: {lat: start.lat, lng: start.lng},
        map: map,
        icon: startIcon,
        optimized: true,
        zIndex: 9999,
      })

      //а последняя машинкой =)
      const finish = ({...trackCoords[trackCoords.length - 1], carPosition: 'end'})
      const finishIcon = chooseIcoForMarker(finish, color)
      const finishMarker = new maps.Marker({
        position: {lat: finish.lat, lng: finish.lng},
        map: map,
        icon: finishIcon,
        optimized: true,
        zIndex: 9999,
      })

      //центруем карту по флажку
      dispatch(setCenter({...trackCoords[trackCoords.length - 1]}))

      // инит трека
      const carPath = new maps.Polyline({
        path: trackCoords,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      //убераем за собой
      if(courierTrack) {
        courierTrack.setMap(null)
      }
      //ебашим с ноги по флажку и машинке
      if(courierTrackEndpoints.length) {
        courierTrackEndpoints.map(el => el.setMap(null))
      }

      //по клику ебашим с двух ног
      finishMarker.addListener("click", () => {
        dispatch(toggleShowCourierTrack())
      })
      //применяем трек и ендпоинты к карте
      carPath.setMap(map)
      startMarker.setMap(map)
      finishMarker.setMap(map)
      // бахаем инстансы в стейт
      setCourierTrack(carPath)
      setCourierTrackEndpoints([startMarker, finishMarker])
    }
  }, [couriersTracks])

  // чистим за собой трек если targetCourierForTrackDraw сдох
  useEffect(() => {
    if(!targetCourierForTrackDraw && courierTrack) {
      courierTrack.setMap(null)
      courierTrackEndpoints.map(el => el.setMap(null))

      setCourierTrack(null)
      setCourierTrackEndpoints([])
    }
  }, [targetCourierForTrackDraw, courierTrack])

  //делаем большую иконку для маркера ордер которого открыт на детальном просмотре
  useEffect(() => {
    if(selectionInfoUnit) {
      const {nativeBigIcon, sideBigIcon} = selectionInfoUnit.marker
      const icon = sideBigIcon ? sideBigIcon : nativeBigIcon
      selectionInfoUnit.marker.setIcon(icon)
    }
  }, [selectionInfoUnit])

  // обработка редиректа ордеров на курьера
  useEffect(() => {

    if (selectedOrd) {
      //получаем новую иконку с цветом курьера на которого редиректим
      const icoByCourier = chooseIcoForMarker(selectedOrd, selectedCourier.color, true)
      const bigIcoByCourier = bigIcoFactory(icoByCourier, maps)
      const courierId = selectedCourier.id || selectedCourier.clientId

      //если пытаемся назначить на тогоже самого курьера, запрещаем, кидаем предупреждение
      if(selectedOrd.courierId === courierId) {
        dispatch(deliveryCycleInfoMessage('bad_order_selection_when_courier_is_direct_on_point'))
        setsSelectedOrd(null)
        setSelectedMarker(null)
        return
      }
      //если пытаемся назначить на уже доставленный заказ, тож самое что и выше но с другим сообщением
      if(selectedOrd.deliveryTime !== null) {
        dispatch(deliveryCycleInfoMessage('bad_order_selection_when_order_is_delivered'))
        setsSelectedOrd(null)
        setSelectedMarker(null)
          return
      }
      //переписываем иконки и собераем массив точек к редиректу
      // return
      if(selectedMarker) {
        selectedMarker.sideIcon = icoByCourier
        selectedMarker.sideBigIcon = bigIcoByCourier
        //вот тут собираем
        dispatch(toggleSelectedOrdersToRedirect({...selectedOrd, marker:selectedMarker}))

        // убираем за собой
        setSelectedMarker(null)
      
      }
    }
  }, [selectedMarker])

  //меняем поведение маркеров если курьера выбрали, с "показать детальную инфу" на "выбрать к переназначению" и наоборот
  useEffect(() => {
    if (selectedCourier) {
      markers.map(el => el.mode = 'select')
    } else {
      markers.map(el => el.mode = 'show')
    }
  }, [selectedCourier])
 
  // настраиваем маркера
  useEffect(() => {
    //чистим если есть старые
    if (markers.length) {
      markers.forEach(el => {
        el.setMap(null)
      })
    }
    // console.log(couriers, 'couriers000')

    if (maps && map) { // && couriers.length && deliveryPoints.length
      // собираем инпут для deliveryPointsToMarkers
      const deliveryPointsToMarkersProps = {
        maps, // фабрика
        map, // инстанс карты
        couriers, // массив курьеров (те что в работе)
        namePeriods,
        dp: deliveryPoints, // точки доставки (ордера)
        mode: selectedCourier ? 'select' : 'show', // стандартное поведение маркера
        selectHandler, //обработчик "показать детали"
        markOnRedirectHandler, // обработчик редиректа
        dragHandler, // обработчик смены положения
      }

      // бахаем маркеры
      const [markers, extendedDp] = deliveryPointsToMarkers(deliveryPointsToMarkersProps)
      //массив инстансов маркеров
      setNamePeriods(allPeriodsByDate)
      setMarkers(markers)
      //расширенные deliveryPoints
      setColoredPoints(extendedDp)
      setExtendDeliveryPoints(extendedDp)
      // устанавливаем zIndex (он разный у разных типов точек)
      extendedDp.forEach(el => {
        el.marker.setZIndex(el.marker.nativeZIndex)
      })
    }
  }, [deliveryPoints, couriers, map, maps])
  useEffect(()=>{
    if(allPeriodsByDate){
    setNamePeriods(allPeriodsByDate)
  }else{
    setNamePeriods(allPeriodsByDate)
  }
  },[OptionCenter,markers,couriers])

  // useEffect(() => {
  //   console.log(couriers, 'couriers')
  // }, [couriers])

  //центрирование карты на выбранном маркере
//   const getName=()=>{
//     if(deliveryPoints){
//    const a = deliveryPoints.find(item=>item.companyId === )
//    if(a){
//    const b = allPeriodsByDate.filter(item=> item.id===a.periodId)
//       setNamePeriods(b)
//    }
//   }
// }

  useEffect(() => {
    if(selectionInfoUnit) {
      dispatch(setCenter({lat: +selectionInfoUnit.lat, lng: +selectionInfoUnit.lng}))
    }

  }, [selectionInfoUnit])

  useEffect(() => {
    if(selectionInfoUnit){    
      dispatch(getDetailedOrderInfo({orderId: selectionInfoUnit.ordId}))
    }
  }, [selectionInfoUnit])

  //рисуем карту, там внутри вроде все понятно
  return <MapComponentDumb {...{center, zoom}}/>
};

export default memo(MapComponent);
