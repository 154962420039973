import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {MoneyIco} from "../../../images/svgs"
import PersonalOrderList from "../ListHandlers/PersonalOrderList"
import {Link} from "react-router-dom"

const moneyFormat = str => {
  str = str + ''
  return str.split(/([0-9]{3}$)/).join(' ') + " ₽"
}

const OrderedUsersListItem = ({data}) => {

  const {name, phone, orders} = data
  const [sumPrice, setSumPrice] = useState(0)

  useEffect(() => {
    if (orders.length) {
      orders.map(el => {
        setSumPrice(prev => prev + (+el.priceResult))
      })
    }
  }, [orders]);
  // console.log('render',orders)
  return (
    <Wrapper>
      <PersonPart>
        <UserName>
          <p>
            {name}
          </p>
          <p>
            <Link to={"/client-card/?=" + phone} target={"_blank"}>
              {phone}
            </Link>
          </p>
        </UserName>
        <TotalPay>
          <MoneyIco/>
          <p>{moneyFormat(sumPrice)}</p>
        </TotalPay>
      </PersonPart>
      <OrderPart>
        <PersonalOrderList personOrderList={orders}/>
      </OrderPart>
    </Wrapper>
  )
}

export default OrderedUsersListItem;

const Wrapper = styled.div`
  text-align: left;
  padding: 10px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

const PersonPart = styled.div`
  display: flex;
  gap: 10px;
`
const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden;

  > p:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const TotalPay = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`
const OrderPart = styled.div`
  > div:nth-child(1n) {
    border-top: 1px solid silver;
  }

  > div:first-child {
    border-top: none;
  }
`
