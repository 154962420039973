import {
    TEMPORARY_VALUE,
    TEMPORARY_VALUE2,
} from "../actions/ModalActions";

const initialState = {
	temporaryStorageFacility: [],
	temporaryStorageFacility2: [],
};

export function modalReducer(state = initialState, action) {
	switch (action.type) {
        case TEMPORARY_VALUE:
            return {
                ...state,
				temporaryStorageFacility: action.payload
            };

		case TEMPORARY_VALUE2:
			return {
				...state,
				temporaryStorageFacility2: action.payload
			};

		default:
			return state;
	}
}
