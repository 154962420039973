import React, { useEffect, useState, useRef, memo, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalView,
  setSelectedKitchens,
} from "../reducers/toolkit/mainAppReducer";
import arrowSelect from "../images/forSelect.svg";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const GlobalSelectKitchen = (props) => {
  const kitchensState = useSelector((store) => store.appMain.kitchens);
  const kitchensStatus = useSelector(
    (store) => store.appMain.getKitchensSucces
  );
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const addNewKitchensModal = useSelector(
    (store) => store.appMain.addNewKitchensModal
  );

  const [view, setView] = useState(false);
  const [text, setText] = useState([]);
  const [controlsXShift, setControlsXShift] = useState(null);
  const [sizeBar, setSizeBar] = useState(50);

  // const [selectKitchen, setSelectedKitchen] = useState({})
  const dispatch = useDispatch();
  const refs = useRef(null);
  useEffect(() => {
    setControlsXShift(sizeBar);
  }, [sizeBar]);
  const viewHandler = (e) => {
    setView((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      let main = document.getElementById("mainDiv");
      if (e.path) {
        if (e.path[1] !== refs.current) {
          if (!e.path.includes(main)) {
            setView(false);
            setText([]);
          }
        }
      }
    });
    return () => {
      document.removeEventListener("click", (e) => {
        let main = document.getElementById("mainDiv");
        if (e.path) {
          if (e.path[1] !== refs.current) {
            if (!e.path.includes(main)) {
              setView(false);
              setText([]);
            }
          }
        }
      });
    };
  }, []);
  const setKitchens = (el) => {
    dispatch(setSelectedKitchens(el));
    setView(false);
    setText([]);
  };

  useEffect(() => {
    if (addNewKitchensModal) {
      setView(false);
    }
  }, [addNewKitchensModal]);

  useEffect(() => {
    if (localStorage.getItem("kitchens")) {
      dispatch(
        setSelectedKitchens(JSON.parse(localStorage.getItem("kitchens")))
      );
    } else {
      dispatch(setSelectedKitchens({ id: 1, name: "Не выбрано" }));
    }
  }, []);
  // useEffect(()=>{
  //   if (window.location.search && kitchensState?.length > 0) {
  //     const URLS =  window.location.search.replace('=','').replace('?','')
  //     const searchURL = kitchensState?.find(el=>el.id === URLS)
  //     console.log(URLS,kitchensState,'SEARCH')
  //     dispatch(setSelectedKitchens(searchURL))
  //     // this.state.clientPhone = window.location.search.split('').slice(2).join('')
  //   }
  // },[kitchensState,SelectedKitchens])


  const handleFilter = (e) => {
    if (kitchensStatus) {
      let filtred = kitchensState.filter((el) => {
        if (el.name && el.INN && el.KPP && el.UrName) {
          return (
            el.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
            el.INN.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
            el.KPP.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
            el.UrName.toLowerCase().search(e.target.value.toLowerCase()) !== -1
          );
        } else {
          return (
            el.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
          );
        }
      });
      if (filtred.length > 0) {
        setText(filtred);
      } else {
        setText(null);
      }
    }
  };
  const typeDelivery = (el) => {
    if (el.type === "cantina") {
      return (
        <div
          style={{
            height: 22,
            width: "auto",
            padding: "3px 10px",
            backgroundColor: "#F52E52",
            borderRadius: 29,
          }}
        >
          РЦ
        </div>
      );
    }
    if (el.type === "restaurant") {
      if (el.typeDelivery === "ownDelivery") {
        return (
          <div
            style={{
              height: 22,
              width: "auto",
              padding: "3px 10px",
              backgroundColor: "#6379C6",
              borderRadius: 29,
            }}
          >
            БРЦ
          </div>
        );
      } else {
        return (
          <div
            style={{
              height: 22,
              width: "auto",
              padding: "3px 10px",
              backgroundColor: "#6379C6",
              borderRadius: 29,
            }}
          >
            БРЦ(кухня)
          </div>
        );
      }
    }
    if (el.type === "banket") {
      return (
        <div
          style={{
            height: 22,
            width: "auto",
            padding: "3px 10px",
            backgroundColor: "#EC7F1B",
            borderRadius: 29,
          }}
        >
          БАНКЕТ
        </div>
      );
    }
  };
  const testHandler = () => {
    return (
      <WrapperModal view={view}>
        <FindModal>
          <input
            autoFocus={true}
            onChange={handleFilter}
            style={{
              border: "1px solid black",
              width: "100%",
              borderRadius: 8,
              height: 30,
            }}
          />
        </FindModal>
        <ContentModal>
          {text?.length > 0 ? (
            text.map((el) => {
              return (
                <ModalItems key={el.id} onClick={() => setKitchens(el)}>
                  <div
                    style={{
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                    }}
                  >
                    <div id="items">{el.name}</div>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 10,
                        color: "#fff",
                        marginLeft: "auto",
                      }}
                    >
                      {typeDelivery(el)}
                    </div>
                  </div>
                  <div style={{ fontSize: 10, color: "gray" }}>
                    {el.UrName ? el.UrName : "-"}
                  </div>

                  <div style={{ fontSize: 11, fontWeight: 600 }}>
                    ИНН {el.INN ? el.INN : 0} / КПП {el.KPP ? el.KPP : 0}
                  </div>
                </ModalItems>
              );
            })
          ) : text ? (
            kitchensState.map((el) => {
              return (
                <ModalItems key={el.id} onClick={() => setKitchens(el)}>
                  <div
                    style={{
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div id="items">{el.name}</div>{" "}
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 10,
                        color: "#fff",
                        marginLeft: "auto",
                      }}
                    >
                      {typeDelivery(el)}
                    </div>
                  </div>
                  <div style={{ fontSize: 10, color: "gray" }}>
                    {el.UrName ? el.UrName : "-"}
                  </div>

                  <div style={{ fontSize: 11, fontWeight: 600 }}>
                    ИНН {el.INN ? el.INN : 0} / КПП {el.KPP ? el.KPP : 0}
                  </div>
                  {/* <div style={{fontSize:10,color:'gray'}}>
          {el.comName}
      </div>
      
      <div style={{fontSize:11,fontWeight:600}}>
        ИНН {el.INN} / КПП {el.KPP}
      </div> */}
                </ModalItems>
              );
            })
          ) : (
            <div
              style={{
                color: "#161616",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Ничего не найдено
            </div>
          )}
        </ContentModal>
        <BtnModalAdd onClick={() => dispatch(setModalView(true))}>
          {/* <Link to="/404"> */}
          <BtnAdd>+ Добавить новую кухню</BtnAdd>
          {/* </Link> */}
        </BtnModalAdd>
      </WrapperModal>
    );
  };
  return (
    <div
      ref={refs}
      id="mainDiv"
      style={{ paddingLeft: 10, paddingRight: 10, width: "100%" }}
    >
      <PseudoSelect id="pseudo" onClick={viewHandler}>
        {SelectedKitchens ? SelectedKitchens.name : null}
        <img
          style={{
            marginLeft: 15,
            marginRight: 15,
            transform: view ? "rotate(180deg)" : "rotate(0deg)",
          }}
          src={arrowSelect}
        />
      </PseudoSelect>
      {view ? testHandler() : null}
    </div>
  );
};

export default memo(GlobalSelectKitchen);

const WrapperModal = styled.div`
  width: auto;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  top: 58px;
  right: 0;
`;
const PseudoSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.442px;
  padding-left: 10px;
  color: #2c2e32;
  cursor: pointer;
  :hover {
    box-shadow: 0px 0px 8px 5px rgba(24, 239, 255, 0.2);
  }
`;

const WrapperModalww = styled.div`
  width: 250px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  top: 58px;
`;
const ContentModal = styled.div`
  overflow-y: scroll;
  height: 200px;
`;
const ModalItems = styled.div`
  border-bottom: 1px solid rgb(169, 169, 169, 0.5);
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  cursor: pointer;
  :hover {
    #items {
      color: #34a5ff;
      transition: all 0.2s ease-out;
    }
  }
`;

const BtnModalAdd = styled.div`
  margin: 10px;
  cursor: pointer;
`;
const BtnAdd = styled.div`
  height: 42px;
  width: 100%;
  background-color: #7882a7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
`;

const FindModal = styled.div`
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(169, 169, 169, 0.5);
`;
