import React from 'react'
import styled from 'styled-components'
import selectSvg from '../../../../images/select.svg'

const PointsItem = ({points,setShowModal,setSelectedPoints,selectedPoints}) => {
    const handleSelect=(e)=>{
        e.stopPropagation()
        setSelectedPoints(prev=>{
          if(prev.includes(points.id)){
              return prev.filter(el=> el !== points.id)
          }else{
              return [...prev,points.id]
          }
        })
      }
  return (
    <WrapperPoints onClick={()=>setShowModal(true)} active={selectedPoints.includes(points.id) ? true: false}>
      
       <SelectWrapps onClick={handleSelect}>
            <img src={selectSvg}/>
        </SelectWrapps>

        <IdsWrapps>
        {points.id}
        </IdsWrapps>

        <Title active={selectedPoints.includes(points.id) ? true: false} >
            {points.town}
        </Title>

        <Names>
        {points.name}
        </Names>
    </WrapperPoints>
  )
}

export default PointsItem

const WrapperPoints = styled.div`
display: grid;
grid-template-columns: 40px 100px 1fr 1fr;
border-bottom: 1px solid rgb(169,169,169,0.5);
padding:10px 0px 10px 0px ;
background-color: ${({active})=>active ? '#2196F3':'#FFFFFF'};
`
const Title = styled.div`
padding-right: 7px;
color:${({active})=>active ?'#FFFFFF' :'#2196F3'};
text-align:start ;
`

const SelectWrapps = styled.div`
  cursor: pointer;
`

const Names = styled.div`
 text-align:start ;
`
const IdsWrapps = styled.div`
 text-align:start ;

`