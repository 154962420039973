import {combineReducers} from "@reduxjs/toolkit"
import { modalReducer } from "./modalReducer";
// import { profileReducer } from "./profileReducer";
import { menuByDayReducer } from "./menuByDayReducer";
import { reviewsReducer } from "./reviewsReducer";
import {warehouseComposeReducer} from "./warehouseReducers/warehouseComposeReducer"
import {assortmentReducer} from './assortmentReducer'
//tk implementation
import mainAppReducer from "./toolkit/mainAppReducer"
import profileReducer from "./toolkit/profileReducer"
import commonReducer from "./toolkit/comonReducer"
import orderMapReducer from "./toolkit/orderMapReducer"
import assemblyReducer from "./toolkit/assemblyReducer"



export const rootReducer = combineReducers({
  appMain: mainAppReducer,
  common: commonReducer,
  modal: modalReducer,
  orderMap: orderMapReducer,
  profile: profileReducer,
  assembly: assemblyReducer,
  menuByDay: menuByDayReducer,
  reviews: reviewsReducer,
  warehouse: warehouseComposeReducer,
  assortment: assortmentReducer,
});
