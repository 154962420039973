import { DatePicker, Input, Switch, TimePicker, Upload } from 'antd'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'
import PhotoUpload from '../../../../images/Photo.svg'
import Close from '../../../../images/close.svg'
import  {useBase}  from '../../../../hooks/useBase'

const NotifModal = ({showModal,setShowModal}) => {
  const closeModal=()=>setShowModal(false)
  const [files,setFile] = useBase()
  console.log(files)
  return (
    <>
    <WrapperModal style={{right: !showModal ? -649 : 0, zIndex: 10}}>
        <HeaderModal>
        <BtnModal>Сохранить</BtnModal>
        <div>
        <span style={{fontSize:17,color:'#666',marginRight:10,marginLeft:10}}>Активна</span>
        <Switch />
        </div>
        <div style={{marginLeft:'auto'}}>
          <img src={Close} style={{height:20,width:20,cursor:'pointer'}} onClick={closeModal}/>
        </div>
        </HeaderModal>
        <ContentModal>

          <OptionsWrapper>
          <OptionsMain>
              <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-start',paddingTop:10}}>
                <p>Показывать при каждом запуске</p>
                <Switch/>
              </div>

              <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-start',paddingTop:10}}>
                <p>Только тем кто ещё не делал заказы</p>
                <Switch/>
              </div>

              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',paddingTop:10}}>
                <p>Только тем кто неавторизован</p>
                <Switch/>
              </div>
          </OptionsMain>
          <OptionCities>
              <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <p>Входит в зону доставки</p>
            <div style={{alignSelf:'flex-start'}}>
              <Input/>
            </div>
              </div>

              <div style={{paddingTop:10}}>
                <p>Текущий город пользователя </p>
                <Input/>
              </div>
              <div style={{width:'100%',paddingTop:10}}>
              <p>Содержание</p>
              <TextareaAutosize/>
          </div>
          </OptionCities>
          </OptionsWrapper>
         
          <Dates>
            <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',marginBottom:20}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginRight:20}}>
            <p>Дата начала показа</p>
            <DatePicker/>
            </div>
            <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <p>Время начала показа</p>
            <TimePicker 
            format='HH:mm'
            />
            </div>
            
            </div>

            <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginRight:20}}>
            <p>Дата окончания показа</p>
            <DatePicker/>
              </div>

              <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
              <p>Время окончания показа</p>
              <TimePicker
              format='HH:mm'
              />
              </div>
            </div>
          </Dates>
          <WrappImage>
            <p>Изображение</p>
            <Upload
            onChange={setFile}
            fileList=''
            >
            <div>
              {files ? 
              <img src={files} style={{width:135,height:135,cursor:'pointer'}}/>
                :
              <img src={PhotoUpload} style={{width:135,height:135,cursor:'pointer'}}/>

              }
            </div>
            </Upload>
          </WrappImage>
        </ContentModal>
    </WrapperModal>
    </>
  )
}
export default NotifModal

const WrapperModal = styled.div`
display: grid;
grid-template-rows: auto 1fr;
position: fixed;
top: 64px;
width: 649px;
height: calc(100vh - 60px);
background-color: #fff;
box-shadow: 0 0 62px rgba(0, 0, 0, 0.1);
overflow: scroll;
transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
`
const HeaderModal = styled.div`
border-bottom: 1px solid rgb(169,169,169,0.5);
display: flex;
flex-direction: row;
padding: 15px;
align-items: center;
color:#161616;
font-size: 15px;
font-weight: 500;
font-family: 'Roboto';
font-style: normal;
`
const ContentModal =styled.div`
color:#161616;
font-size: 15px;
font-weight: 500;
font-family: 'Roboto';
font-style: normal;
`
const OptionsWrapper = styled.div`
display: flex;
flex-direction: row;
border-bottom: 1px solid rgb(169,169,169,0.5);
`
const OptionCities = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding-left: 30px;
padding-bottom: 15px;
    textarea{
        resize: none;
      min-width: 100%;
      min-height: 70px;
      border-radius: 8px;
    }
`
const OptionsMain = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding-left: 30px;
padding-bottom: 15px;
`
const NameStocks = styled.div`
display: flex;
flex-direction: column;
padding:30px;
margin-bottom: 30px;
    textarea{
      resize: none;
      min-width: 100%;
      min-height: 150px;
      border-radius: 8px;
    }
  border-bottom: 1px solid rgb(169,169,169,0.5);
`
const Dates= styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-bottom: 1px solid rgb(169,169,169,0.5);

`
const WrappImage = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding-left: 30px;
margin-top: 20px;
`
const BtnModal =styled.div`
background-color: #3498db;
color:#fff;
padding: 15px;
height: 36px;
display: flex;
align-items: center;
cursor: pointer;
`
const NamesBtn = styled.div`
display: flex;
flex-direction: column;
padding: 30px;
border-bottom: 1px solid rgb(169,169,169,0.5);

`