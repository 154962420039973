import React, { Component } from 'react';
import '../../App.css';
import styled from "styled-components";
import AddPromocodeModal from "../../components/AddPromocodeModal";
import { sr } from '../../utils';
import moment from 'moment';
import {Switch} from "antd";
import _ from "underscore";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';

import LogoPromocodesImg from "../../images/logo_promocodes.png";
import LogoPromocodesTextImg from "../../images/logo_promocodes_text.png";
import SearchImg from "../../images/search.png";
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import {connect} from "react-redux";
import NoPermissions from "../../components/NoPermissions";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

registerLocale('ru', ru);

class Promocodes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            value: '',
            promocodes: [],
            date: new Date(),
            data: '',
            periods: [],
            ///////////
            category: [],
            ///////////
            isPhoneSearch: false,
            startTimeCreate: null,
            endTimeCreate: null,
            startTimeRemove: null,
            endTimeRemove: null,
            // startTimeTransaction: null,
            // endTimeTransaction: null,
            // companyId: '',
            contracts: [],
            showOrders: false,
            refreshPromocodes: {},
            active: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showResults = this.showResults.bind(this);
    }

    componentDidMount = async () => {
        if (!this.props.getPermissionsSuccess) {
            this.props.getPermissions()
        }
        await new Promise(resolve => {
            this.getPeriods(resolve)
        })
        await new Promise(resolve=>{
            this.getCategory(resolve)
        })

    }

    getPeriods = (resolve) => {
        console.log('GET_PERIODS')
        sr("SettingsService", "GetOperatorPeriods", {date: moment(new Date()).format("YYYY-MM-DD")}).then(res => {
            console.log('GetOperatorPeriods', res)
            if (res.result) {
                resolve()
                this.setState({periods: res.data.periods})
            }
        })
    }
    //////////////////
    
    getCategory = (resolve)=>{
        console.log('GET_CATEGORY')
        sr("WalletsController","GetCategories",{}).then(res =>{
            console.log('GetCategories',res)
            if(res.result){
                resolve()
                this.setState({category: res.data})
            }
        })
    }
    //////////////////


    addWallet = () => {
        this.setState({data: false, showModal: true, showOrders: false});
    }

    showOrders = (data) => {
        this.setState({data: data, showModal: true, showOrders: true});
        console.log(data)
    }

    editWallet = (data) => {
        this.setState({data: data, showModal: true, showOrders: false});
        console.log(data)
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    cleanFilter = () => {
        this.setState({
            value: '',
            startTimeCreate: null,
            endTimeCreate: null,
            startTimeRemove: null,
            endTimeRemove: null,
            // startTimeTransaction: null,
            // endTimeTransaction: null,
            // companyId: '',
        })
    }

    // Поиск по кошелькам при нажании на "🔎" или клавишу Enter
    handleSubmit = (value = this.state.value, event = null) => {
        if (!event || event.key === 'Enter') {
            console.log('Значение в поисковой строке:', value);
            // let newValue;
            // let test = /^(\+79|\+7 9|\+7\(9|\+7 \(9|\+7-9|\+7-\(9|89|8 9|8\(9|8 \(9|8-9|8-\(9)/;
            // if (test.test(value)) {
            //     newValue = value.replace(/[^+\d]/g, '').replace(/^89/, '+79');
            //     this.setState({value: newValue})
            // } else {
            //     newValue = value;
            // }

            let data = {
                filterText: value,
                startTimeCreate: this.state.startTimeCreate ? moment(this.state.startTimeCreate).format('YYYY-MM-DD') : '',
                endTimeCreate: this.state.endTimeCreate ? moment(this.state.endTimeCreate).format('YYYY-MM-DD 23:59:59') : '',
                startTimeRemove: this.state.startTimeRemove ? moment(this.state.startTimeRemove).format('YYYY-MM-DD') : '',
                endTimeRemove: this.state.endTimeRemove ? moment(this.state.endTimeRemove).format('YYYY-MM-DD 23:59:59') : '',
                // startTimeTransaction: this.state.startTimeTransaction ? moment(this.state.startTimeTransaction).format('YYYY-MM-DD') : '',
                // endTimeTransaction: this.state.endTimeTransaction ? moment(this.state.endTimeTransaction).format('YYYY-MM-DD') : '',
                // companyId: this.state.companyId,
                isActive: this.state.active
            }
            sr("WalletsController", "FindPromocodes", data)
                .then((res) => {
                    console.log('Результат поиска по промокодам', res);
                    if (Array.isArray(res.data.promocodes)) {
                        this.setState({promocodes: res.data.promocodes});
                        res.data.promocodes.length === 0 && this.props.deliveryCycleInfoMessage('no_results');
                    }
                    this.setState({refreshPromocodes: data})
                })
        }
    }

    refreshData = () => {
        sr("WalletsController", "GetPromocode", {promocodeId: this.state.data.id}).then((res) => {
            console.log('Результат запроса по кошельку', res);
            this.setState({data: res.data});
        });
    }

    delWallet = (data) => {
        let isDel = window.confirm(`Кошелёк с ID ${data.id} будет удалён. Вы уверены?`);

        if (isDel) {
            sr("WalletsController", "DelPromocode", {promocodeId: data.id}).then(res => {
                console.log('Результат удаления кошелька', res);
                if (res.data.deleted && res.result === 1) {
                    sr("WalletsController", "FindPromocodes", this.state.refreshPromocodes).then((res) => {
                        console.log('Результат поиска по кошелькам', res);
                        if (Array.isArray(res.data.promocodes)) {
                            this.setState({promocodes: res.data.promocodes});
                            res.data.promocodes.length === 0 && this.props.deliveryCycleInfoMessage('no_results');
                        }
                        this.setState({refreshPromocodes: data})
                    });
                    this.props.deliveryCycleInfoMessage('promocode_deleted');
                } else {
                    this.props.deliveryCycleInfoMessage('promocode_deleting_error');
                }
            });
        }

    }

    showResults = () => {
        if (this.state.promocodes.length === 0) {
            return []
        } else {
            let results = [];
            let i = 0;
            //let sortedPromocodes = _.sortBy(this.state.promocodes, promocode => promocode.status);
            for (let key in this.state.promocodes) {
                i++;
                let result = (
                    <WalletItem key={key}>
                        <Number>{i}</Number>
                        <WalletBlock
                            disabled={moment(this.state.promocodes[key].validTo).isBefore(moment())}
                            //deleted={this.state.promocodes[key].wallet.wType === 'deleted'}
                        >
                            {/*{this.state.promocodes[key].wallet.wType !== 'deleted' && (*/}
                            {/*    <WalletButtons>*/}
                            {/*        <EditWallet onClick={() => this.editWallet(this.state.promocodes[key])}>Изменить</EditWallet>*/}
                            {/*        <DeleteWallet onClick={() => this.delWallet(this.state.promocodes[key])}>Удалить</DeleteWallet>*/}
                            {/*    </WalletButtons>*/}
                            {/*)}*/}
                            <Column>
                                <WalletName onClick={() => this.showOrders(this.state.promocodes[key])}>{this.state.promocodes[key].promocode}</WalletName>
                                {/*<p style={{color: '#2196F3', marginBottom: 8}}>ID {this.state.promocodes[key].id}</p>*/}
                                {/*<p style={{marginBottom: 8}}>Сумма: {this.state.promocodes[key].amount} ₽</p>*/}
                                {/*{this.state.promocodes[key].validFrom && (*/}
                                {/*    <p style={{marginBottom: 8}}>Дата создания: {moment(this.state.promocodes[key].validFrom).format("DD.MM.YYYY")}</p>*/}
                                {/*)}*/}
                                {/*{this.state.promocodes[key].validTo && (*/}
                                {/*    <p style={{marginBottom: 8}}>Действителен до: {moment(this.state.promocodes[key].validTo).format("DD.MM.YYYY")}</p>*/}
                                {/*)}*/}
                            </Column>
                            <Column style={{height: 41}}>
                                <ColumnTitle>Категория:</ColumnTitle>
                                <UserItem key={i}>
                                    <p>{
                                        this.state.category.find(item => item.id === this.state.promocodes[key].categoryId)
                                            ? this.state.category.find(item => item.id === this.state.promocodes[key].categoryId).name
                                            : '-'
                                    }</p>
                                </UserItem>
                            </Column>
                            <Column style={{height: 41}}>
                                <ColumnTitle>Процент скидки:</ColumnTitle>
                                <UserItem key={i}>
                                    <p>{this.state.promocodes[key].discountPerc === "0" ? '-' : `${this.state.promocodes[key].discountPerc}%`}</p>
                                </UserItem>
                            </Column>
                            <Column style={{height: 41}}>
                                <ColumnTitle>Начало:</ColumnTitle>
                                <UserItem key={i}>
                                    <p>{this.state.promocodes[key].validFrom ? moment(this.state.promocodes[key].validFrom).format('DD MMMM YYYY') : ''}</p>
                                </UserItem>
                            </Column>
                            <Column style={{height: 41}}>
                                <ColumnTitle>Конец:</ColumnTitle>
                                <UserItem key={i}>
                                    <p>{this.state.promocodes[key].validTo ? moment(this.state.promocodes[key].validTo).format('DD MMMM YYYY') : ''}</p>
                                </UserItem>
                            </Column>
                            <Column style={{height: 41}}>
                                <ColumnTitle>Активаций:</ColumnTitle>
                                <UserItem key={i}>
                                    <p>{!this.state.promocodes[key].orders ? '0' : this.state.promocodes[key].orders.length}</p>
                                </UserItem>
                            </Column>
                            {this.checkRole('method_promocode') && (
                                <>
                                    <Column style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <EditWallet onClick={() => this.editWallet(this.state.promocodes[key])}>Изменить</EditWallet>
                                    </Column>
                                    <Column style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <DeleteWallet onClick={() => this.delWallet(this.state.promocodes[key])}>Отключить</DeleteWallet>
                                    </Column>
                                </>
                            )}
                        </WalletBlock>
                    </WalletItem>
                );
                results.push(result);
            }
            return results;
        }
    }

    updateWallet = (id) => {
        sr("WalletsController", "GetPromocode", {
            promocodeId: id,
        }).then((res) => {
            console.log(res, 'resresres')
            this.setState(
                {promocodes: [res.data], data: res.data, value: ''},
                () => console.log(this.state.promocodes, this.state.data, 'кошелёк сразу после создания')
            )
        });
    }

    checkRole = (param) => {
        if (this.props.permissions?.staff) {
            if (this.props.permissions.staff.role === 'admin' && !this.props.permissions.permissions.length) {
                return true
            } else {
                return this.props.permissions.permissions.includes(param)
            }
        } else {
            return false
        }
    }

    render() {
        return (
            <WalletsWrapper className="wallets">
                {this.checkRole('screen_promocode') ? (
                    <Container>
                        <Header>
                            <LogoPromocodes/>
                            <LogoText/>
                            <SearchBox>
                                <Search type="text" placeholder="Поиск" value={this.state.value} onChange={this.handleChange} onKeyUp={(e) => this.handleSubmit(this.state.value, e)}/>
                            </SearchBox>
                            {this.checkRole('method_promocode') && (
                                <AddWalletButton onClick={this.addWallet}>Добавить промокод</AddWalletButton>
                            )}
                            <AddPromocodeModal periods={this.state.periods}  category={this.state.category} close={this.closeModal} isShow={this.state.showModal} data={this.state.data} showOrders={this.state.showOrders}
                                               refreshData={this.refreshData} updateWallet={this.updateWallet} contracts={this.state.contracts} handleSubmit={this.handleSubmit} />
                        </Header>
                        <Main>
                            <FiltersBlock className="filters_block">
                                <p style={{marginRight: 40, height: 36, lineHeight: '36px'}}>Сортировать:</p>
                                <div>
                                    <SortBlockString>
                                        <p style={{marginRight: 24.687}}>По дате начала:</p>
                                        <p style={{marginRight: 10}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTimeCreate}
                                            startDate={this.state.startTimeCreate}
                                            endDate={this.state.endTimeCreate}
                                            selectsStart
                                            onChange={date => this.setState({startTimeCreate: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 10}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTimeCreate}
                                            startDate={this.state.startTimeCreate}
                                            endDate={this.state.endTimeCreate}
                                            minDate={this.state.startTimeCreate}
                                            selectsEnd
                                            onChange={date => this.setState({endTimeCreate: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 40}}>По дате окончания:</p>
                                        <p style={{marginRight: 10}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTimeRemove}
                                            startDate={this.state.startTimeRemove}
                                            endDate={this.state.endTimeRemove}
                                            selectsStart
                                            onChange={date => this.setState({startTimeRemove: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginRight: 10, marginLeft: 10}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTimeRemove}
                                            startDate={this.state.startTimeRemove}
                                            endDate={this.state.endTimeRemove}
                                            minDate={this.state.startTimeRemove}
                                            selectsEnd
                                            onChange={date => this.setState({endTimeRemove: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                    </SortBlockString>
                                    <SortBlockString>
                                        <div style={{height: 36}} className="flex_row_start_center">
                                            <p style={{marginRight: 16}}>Только активные</p>
                                            <Switch checked={this.state.active} onChange={() => this.setState({active: !this.state.active})} />
                                        </div>
                                        <CleanFiltersButton onClick={() => this.cleanFilter()}>
                                            <p>Очистить</p>
                                        </CleanFiltersButton>
                                        <FilterButton onClick={() => this.handleSubmit(this.state.value)}>
                                            <p>Найти</p>
                                        </FilterButton>
                                    </SortBlockString>
                                </div>
                            </FiltersBlock>
                            {this.showResults()}
                        </Main>
                    </Container>
                ) : (
                    this.props.getPermissionsSuccess && (
                        <NoPermissions />
                    )
                )}
            </WalletsWrapper>
        );
    }
}

const mapStateToProps = store => ({
    getPermissionsSuccess: store.appMain.getPermissionsSuccess,
    permissions: store.appMain.permissions,
});

const mapDispatchToProps = dispatch => ({
    getPermissions: () => dispatch(getPermissionsAction()),
    deliveryCycleInfoMessage: (actionUser, product, timeOut) => dispatch(deliveryCycleInfoMessage(actionUser, product, timeOut)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Promocodes);

const WalletsWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 64px);
    background-color: #fff;
    text-align: left;
    font-family: "Roboto", sans-serif;
`;

const Container = styled.div`
    width: 1280px;
    min-height: 100%;
    margin: 0 auto;
    padding: 0 20px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    padding-top: 30px;
`;

const LogoPromocodes = styled.div`
    width: 32px;
    height: 30px;
    margin-right: 9px;
    background: url(${LogoPromocodesImg}) center no-repeat;
    background-size: auto;
`;

const LogoText = styled.div`
    width: 153px;
    height: 24px;
    margin-right: 52px;
    background: url("${LogoPromocodesTextImg}") center no-repeat;
    background-size: auto;
`;

const SearchBox = styled.div`
    position: relative;
    width: 400px;
    height: 50px;
`;

const Search = styled.input`
    width: 400px;
    height: 50px;
    padding: 16px 58px 15px 16px;
    outline: 0;
    border: 1px solid #eee;
    border-radius: 4px;
    /*background: url("${SearchImg}") 19px 13px no-repeat #eee;*/
    background: #eee;
    /*background-size: auto;*/
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #222;
    
    &:focus {
        background: #fff;
    }
`;

const Submit = styled.button`
    position: absolute;
    top: 14px;
    right: 20px;
    width: 22px;
    height: 22px;
    padding: 0;
    outline: 0;
    border: 0;
    background: url("${SearchImg}") center no-repeat;
    background-size: auto;
    cursor: pointer;
`;

const AddWalletButton = styled.button`
    width: 300px;
    height: 50px;
    margin-left: auto;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: linear-gradient(0deg, #1720D8, #1720D8), linear-gradient(90deg, #434343 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    cursor: pointer;
`;

// const AddFileButton = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 160px;
//     height: 30px;
//     border-radius: 18px;
//     margin-left: 20px;
//     background: linear-gradient(0deg, #ff1744, #ff1744), linear-gradient(90deg, #434343 0%, #000 100%);
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
//     color: #fff;
//     cursor: pointer;
// `;

const Main = styled.div`
    margin-top: 29px;
`;

const WalletItem = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /*margin-bottom: 60px;*/
    margin-bottom: 20px;
`;

const Number = styled.p`
    /*margin-top: 16px !important;*/
    margin-top: 22px !important;
    margin-right: 20px !important;
    font-size: 16px;
    line-height: 19px;
    color: #191932;
`;

const WalletBlock = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 1200px;
    /*min-height: 325px;*/
    min-height: 62px;
    /*padding: 20px 20px 100px;*/
    padding: 10px;
    background: ${({disabled, deleted}) => deleted ? '#fdd' : disabled ? '#eee' : '#fff'};
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #191932;
`;

const Column = styled.div`
    width: calc((100% - 70px) / 8);
    margin-right: 10px;
    overflow: hidden;
    
    &:first-child {
        font-size: 14px;
        line-height: 16px;
    }
    
    &:last-child {
        margin-right: 0;
    }
`;

const WalletName = styled.p`
    /*margin-bottom: 8px !important;*/
    margin-bottom: 0 !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /*color: #191932;*/
    color: #176FD8;
    word-wrap: break-word;
    display: inline-block;
    cursor: pointer;
`;

const ColumnTitle = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px !important;
`;

const UserItem = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 14px;
    /*margin-bottom: 8px;*/
`;

const WalletButtons = styled.div`
    position: absolute;
    left: 20px;
    bottom: 29px;
    display: flex;
    align-items: center;
`;

const EditWallet = styled.button`
    width: 149px;
    height: 42px;
    margin-right: 38px;
    outline: 0;
    border: 1px solid #999;
    border-radius: 66px;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #999;
    cursor: pointer;
`;

const DeleteWallet = styled.button`
    width: 164px;
    height: 19px;
    outline: 0;
    border: 0;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #999;
    cursor: pointer;
    
    padding: 0;
    margin-right: 6px;
`;

const FiltersBlock = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`;

const SortBlockString = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    height: 36px;
`;

const CleanFiltersButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    border-radius: 18px;
    /*margin-left: 30px;*/
    margin-left: auto;
    background: linear-gradient(0deg, #1720D8, #1720D8), linear-gradient(90deg, #434343 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
`;

const FilterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    border-radius: 18px;
    margin-left: 15px;
    background: #3ba607;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
`;
