import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion"
import {useSelector} from "react-redux"
import CouriersStatistic from "./CouriersStatistic"
import CourierInfo from "./CourierInfo"

const motionBlockStyle = {
  height: 'calc(100vh - 64px)',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 3
}

//работает в двух режимах, отображение списка курьеров со статистикой и детализация по курьеру
const CourierSideBar = ({coloredPoints, showCourierSidebar, setShowCourierSidebar, couriersSidebarSize, setShowCourierDetails, showCourierDetails}) => {
  const [showCourierStatistic, setShowCourierStatistic] = useState(true)
  const showCourierInfo = useSelector(store => store.orderMap.showCourierInfo)
  return (
    <CourierSideBarWrapper
      size={couriersSidebarSize}
      style={motionBlockStyle}
      initial={{x: -couriersSidebarSize}}
      animate={{
        x: showCourierSidebar ? 0 : couriersSidebarSize,
        width: couriersSidebarSize
      }}
      transition={{ease: "easeOut"}}
    >
      {
        showCourierInfo ? (
          //Детализация по курьеру
          <CourierInfo {...{coloredPoints}}/>
        ) : (
          // список с краткой статистикой
          showCourierStatistic && <CouriersStatistic {...{setShowCourierDetails, showCourierDetails, setShowCourierSidebar}}/>
        )
      }
    </CourierSideBarWrapper>
  )
}

export default memo(CourierSideBar);

const CourierSideBarWrapper = styled(motion.div)`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  height: 100%;
  background: #ffffff;
  width: ${({size}) => size}px;
  z-index: 3;
  overflow-y: auto;
  
  @media (max-width: 480px) {
    max-width: calc(100vw - 64px);
  }
`
export function gridLayout () {
  return styled.div`
    display: grid;
    grid-template-columns: ${({showDetails}) => showDetails ? '40% 15% 15% 15% 15%' : '40% 20% 40% ' };
  `
}
