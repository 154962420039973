import React from "react";
import styled from "styled-components";

const Addresses = () => {
  return <Wrappers>Здесь будет раздел "Адреса"</Wrappers>;
};

export default Addresses;
const Wrappers = styled.div`
  display: grid;
  height: calc(100vh - 64px);
  justify-content: center;
  align-items: center;
  color: #161616;
  font-size: 30px;
  font-weight: 600;
`;
