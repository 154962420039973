import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSender, selectSenderModal} from "../../actions/WarehouseActions";
import Select from "../share/UI/CustomSelect"

const SenderSelector = ({style = {}}) => {

    const dispatch = useDispatch()
    const senderState = useSelector(store => store.warehouse.senderState)

    const [selectedSender, setSelectedSender] = useState(null)

    useEffect(() => {
        if (selectedSender !== null) {
            dispatch(selectSender(selectedSender))
            dispatch(selectSenderModal(selectedSender))
        }
    }, [selectedSender])

    const senderSelectionHandler = (val) => setSelectedSender(val)

    return <Select data={senderState.senders} value={senderState.selectedSender?.name} handler={senderSelectionHandler} styles={style}/>
}

export default SenderSelector;