import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  filterStatus: 'new'
}


const assemblyReducer = createSlice({
  name: 'assembly',
  initialState,
  reducers: {
    setSelectedFilter(state, action) {
      state.filterStatus = action.payload
    },
  }
})

export const {
  setSelectedFilter
} = assemblyReducer.actions
export default assemblyReducer.reducer
