import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { imagesResource } from "../images/sidebarNav/sidebarNavResources";
import { sr } from "../utils";
import SidebarNavElement, {
  SidebarNavElementWrapper,
} from "./SidebarNavElement";

const SidebarNav = ({ path, setPath, testPath }) => {
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(null);

  const [selectedChapter, setSelectedChapter] = useState("dish");

  const getCount = () => {
    sr("MenuSOKitchenService", "numberOfProductsModeratedByCuisine", {}).then(
      (res) =>{
            setCount({
                    CountKitchens: Object.keys(res.data.result).length,
                    CountProd: Object.values(res.data.result).reduce(
                      (el, acc) => +el + +acc,
                      0
                    ),
                  })
          }
      );
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    let time = setTimeout(() => {
      getCount();
    }, 60000 * 3);
    return () => {
      clearTimeout(time);
    };
  }, [count]);

  // useEffect(() => {
  //   let timer = setTimeout(() => getCount(), 6000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <NavigationWrapper>
      <Link to={"/dashboard"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Kitchens", text: imagesResource.Kitchens.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Kitchens,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/mainSetting"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Settings", text: imagesResource.Settings.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Settings,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/map"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Delivery", text: imagesResource.Delivery.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Delivery,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/client-card"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Clients", text: imagesResource.Clients.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Clients,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/wallets"}>
        <SidebarNavElement
          {...{
            destiny: {
              path: "Financial",
              text: imagesResource.Financial.hover,
            },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Financial,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/statistics"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Partners", text: imagesResource.Partners.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Partners,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/supports"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Support", text: imagesResource.Support.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Support,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
      <Link to={"/moderation"}>
        <SidebarNavElement
          {...{
            destiny: {
              path: "Moderation",
              text: imagesResource.Moderation.hover,
            },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Moderation,
            path,
            setPath,
            testPath,
            count,
            setCount,
          }}
        />
      </Link>
      <Link to={"/workweek"}>
        <SidebarNavElement
          {...{
            destiny: { path: "Service", text: imagesResource.Service.hover },
            selectedChapter,
            setSelectedChapter,
            image: imagesResource.Service,
            path,
            setPath,
            testPath,
          }}
        />
      </Link>
    </NavigationWrapper>
  );
};

export default SidebarNav;

const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Separator = styled.div`
  width: 14px;
  height: 1px;
  background: #3d425d;
`;
