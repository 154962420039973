import React, {useEffect, useState} from "react";
import styled from "styled-components";
import RecipientSelector from "../RecipientSelector";
import SenderSelector from "../SenderSelector";
import {selectStyles} from "../Header"
import DefaultBtn from "../../share/UI/DefaultBtn"
import RangePicker from "../../share/UI/RangePicker"
import {useDispatch, useSelector} from "react-redux"
import {getStorageAsExel, resetExelLink} from "../../../actions/WarehouseActions"

const StorageHeader = ({rowFlex, columnFlex}) => {

  const dispatch = useDispatch()

  const [dateRange, setDateRange] = useState(null)
  const [buildLink, setBuildLink] = useState('')

  const selectedRecipient = useSelector(store => store.warehouse.recipientState.selectedRecipient)
  const link = useSelector(store => store.warehouse.exelLink)

  useEffect(() => {
    console.log(selectedRecipient)
    dispatch(resetExelLink())
    setBuildLink('')
    if (dateRange && selectedRecipient) {
      dispatch(getStorageAsExel({
        recipientId: selectedRecipient.id,
        dateFrom: dateRange.from,
        dateTo: dateRange.to,
      }))
    }
  }, [dateRange, selectedRecipient])

  useEffect(() => {
    if (link) {
      setBuildLink(window.location.protocol + link)
    }
  }, [link])

  return (
    <div>
      <Controls>
        <div style={columnFlex}>
          <Control>
            <p>Получатель</p>
            <RecipientSelector style={selectStyles}/>
          </Control>
        </div>
        <div style={{...rowFlex, marginBottom: 'auto'}}>
          <Control>
            <RangePicker callback={setDateRange}/>
          </Control>
        </div>
        <DefaultBtn
          style={{marginLeft: 'auto'}}
          type={'link'}
          href={buildLink}
          disabled={!buildLink.length}
        >
          <span style={{
            fontSize: 22,
            fontWeight: 300,
          }}>Скачать</span>
        </DefaultBtn>
      </Controls>
    </div>
  )
}

export default StorageHeader;

const Controls = styled.div`
  display: flex;
  align-items: center;

  div:first-child {
    padding-left: 0;
  }

  & > div {
    padding: 10px;
  }
`
const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  p {
    margin: 0 20px 0 0;
  }
`
