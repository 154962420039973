import React,{useEffect,useRef} from 'react'
import styled from 'styled-components'
import HorizontalArrow from '../../../images/HorizontalArrow.svg'
import Subtract from '../../../images/Subtract.svg'
import ArrowDownYellow from '../../../images/ArrowDownYellow.svg'
import ArrowUpGreen from '../../../images/ArrowUpGreen.svg'
import { isArray } from 'underscore'


export const InfoItem = ({el,selectInfo,setSelectInfo,info,testRef,paramsInfo,OffsettingPeriod,startDate,endDate,getXML,selectBank}) => {

  const selectHandler=()=>{
    const sel = selectInfo.includes(el.id)
    return (
      <SelectMarker 
      >
        <MainCheckbox type='checkbox' 
        className='selectMarker'
        checked={sel}
        value={selectInfo} 
        onChange={()=>setSelectInfo(prev=>{if(prev.includes(el.id)){return prev.filter(els => els !== el.id)}else{return [...prev,el.id]}})}
        />
    </SelectMarker>
    )
  }
 
  useEffect(() => {
    selectHandler()
  }, [selectInfo])
    useEffect(()=>{
      if(paramsInfo[el.id]){
        console.log(JSON.parse(paramsInfo[el?.id]))
      }
    },[paramsInfo])

    
    const GetPDFAct=(id,type)=>{
      let orderStatus = true
      OffsettingPeriod(startDate,endDate,id,orderStatus,type)
    }
    const GetPDFAct2=(id,type)=>{
      let orderStatus = true
      OffsettingPeriod(startDate,endDate,id,orderStatus,type)
    }
   
  return (
    <>
    <Wrap id='testings'>
    {selectHandler()}
    <Main>
        <Items ref={testRef} >
          
            <Col >
                <NameComs>
                  <div>{el.name}</div><div >(ID:{el.id})</div> 
                </NameComs>
                <LegalEntity>
                  {el.UrName ? el.UrName : '-' }
                  {/* {console.log(paramsInfo !==undefined ?  JSON.parse(paramsInfo[el.id]): '','POPA')} */}
                  {/* {paramsInfo[el?.id] ? JSON.parse(paramsInfo[el?.id]?.ORGANIZATION_DATA.contractName) : '-'} */}
                </LegalEntity>
            </Col>
            <Col>
                <City>
                {el.cityName ? el.cityName : '-' }
                </City>
            </Col>
            <Col>
                <Inn>
                {el.INN ? el?.INN : 'inn' in el ? el.inn  : '-'}
                /
                {el.KPP ? el?.KPP : 'kpp' in el ? el.kpp  : '-' }
                </Inn>
                <BankRec>
                <div>{el.bank?.RCBIC ? el.bank.RCBIC :  '-' }</div>
                <div>{el.bank?.accAt ? el.bank.accAt : '-' }</div>
                <div>{el.bank?.corrAcc ? el.bank.corrAcc : '-' }</div>
                </BankRec>
            </Col>
            <Col>
                <NumContract>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div>{el.NumbAcc ? el.NumbAcc : '-'}</div>
                /
                <div>{el.DateAcc ? el.DateAcc : '-'}</div>
                </div>
                <div>
                  {
                    el.type === 'cantina'
                    ?
                    'РЦ'
                    :
                    el.type === 'restaurant' && el.typeDelivery === 'ourDelivery'
                    ?
                    'БРЦ'
                    :
                    el.type === 'restaurant' && el.typeDelivery === 'ownDelivery'
                    ?
                    'БРЦ (кухня)'
                    :
                    ''
                  }
                </div>
                </NumContract>
            </Col>
            <Col>
                <ContactFace>
                <div>{el.contactPerson ? el.contactPerson.name : '-'}</div>
                <div>{el.contactPerson ? el.contactPerson.phone : 'phone' in el ? el.phone : '-'}</div>
                <div>{el.contactPerson ? el.contactPerson.email : '-'}</div>

                </ContactFace>
            </Col>
            <Col>
                <Financial>
                  <div >
                  {el?.finance?.total
                  ?
                  <div style={{display:'flex',flexDirection:'row'}} ><img src ={Subtract}/> <span>{el?.finance  ? +String(el?.finance?.total).split('.')[1] === 0 ?`${ Math.floor(el?.finance?.total)}` :`${String(el?.finance?.total).replace('.',',')}`  :'0'}</span></div>
                  :
                  ''
                  } 
                  <div style={{display:'flex',flexDirection:'row'}}> <img src ={ArrowUpGreen}/><span>{el?.finance ? +String(el?.finance?.favorCustomer).split('.')[1] === 0 ?`${ Math.floor(el?.finance?.favorCustomer)}` :`${String(el?.finance?.favorCustomer).replace('.',',')}` :'0'}</span></div>
                  <div style={{display:'flex',flexDirection:'row'}}> <img src ={ArrowDownYellow}/><span>{el?.finance ?+String(el?.finance?.favorContractor).split('.')[1] === 0 ?`${ Math.floor(+el?.finance.favorContractor)}` :`${String(el?.finance?.favorContractor).replace('.',',')}` :'0'}</span></div>
                  <div style={{display:'flex',flexDirection:'row'}}> <img src ={HorizontalArrow}/> <span>{el?.finance  ?+String(el?.finance?.betweenCustomerContractor).split('.')[1] === 0 ?`${ Math.floor(el?.be?.betweenCustomerContractor)}` :`${String(el?.finance?.betweenCustomerContractor).replace('.',',')}`  :'0'}</span></div>
                  </div>
                </Financial>
            </Col>
            {/* <Btn  >
   
    {isArray(selectInfo)
    ?
    selectInfo.includes(el.id) 
    ?
    <>
    <Item style={{textAlign:'center',width:81}} onClick={()=>GetPDFAct2(el.id,2)} >Отчет</Item>
    <Item style={{textAlign:'center',width:81}} onClick={()=>GetPDFAct(el.id,1)} >Акт</Item>
    {el.finance
    ?
    <Item style={{textAlign:'center'}} onClick={()=>getXML({[el.id]:el.finance?.betweenCustomerContractor})} >Платежка</Item>
    :
    ''
    }
    </>
    //<Item style={{textAlign:'center'}} onClick={()=>getXML(el.id,el?.finance?.betweenCustomerContractor)} >Платежка</Item>
    :
    ''
    :
    selectInfo === el.id 
    ?
    <>
    <Item style={{textAlign:'center',width:81}} onClick={()=>GetPDFAct2(el.id,2)} >Отчет</Item>
    <Item style={{textAlign:'center',width:81}} onClick={()=>GetPDFAct(el.id,1)} >Акт</Item>
    {el.finance
    ?
    <Item style={{textAlign:'center'}} onClick={()=>getXML({[el.id]:el.finance?.betweenCustomerContractor})} >Платежка</Item>
    :
    ''
    }
    </>

    :
    ''

    }
    </Btn> */}

        </Items>

    </Main>
    </Wrap>
    </>
  )
}

const Wrap = styled.div`
display: grid;
grid-template-columns: 30px 2fr 161px;
`

const Main = styled.div`
width: 100%;
background: #FFFFFF;
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
border-radius: 8px;
min-height:134px;
height: auto;
`
const Items = styled.div`
display:grid;
grid-template-columns:17% 10% 25% 15% 15% 20% ;
position: relative;
height: 100%;
border: 1px solid #F7F7F7;
`
const Col = styled.div`
padding: 10px;
font-family: 'Roboto';
font-style: normal;
color: #2C2E32;
`
const MainCheckbox = styled.input`
 display: inline;
width: 18px;
height: 18px;
cursor:pointer;
  
`
const SelectMarker =styled.div`
height: auto;
width:auto;
margin-top: 50px;
`
const NameComs = styled.div`
display: flex;
flex-direction: row;
font-weight: 500;
font-size: 16px;
line-height: 19px; 
`
const LegalEntity = styled.div`
display: flex;
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
  
`
const City = styled.div`
font-weight: 400;
font-size: 16px;
line-height: 19px; 
`
const Inn = styled.div`
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;  
`
const BankRec = styled.div`
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;  
`
const NumContract = styled.div`
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px; 
`
const ContactFace = styled.div`
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
  
`
const Financial = styled.div`
display: flex;
flex-direction:column;
align-items: center;
font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;  
width:100%;
`
const Btn = styled.div`
position: absolute;
right: -90px;
width: 100%;
top: 10px;
width: auto;
`
const Item = styled.p`
height: auto;
width: auto;
color: #FFFFFF;
padding: 5px;
border-radius: 6px;
background-color:#7882A7;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 130%;
border:none;
cursor: pointer;
  :hover{
    color:#2196F3;
  }
`
