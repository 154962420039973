import React from "react";
import { motion, AnimatePresence } from 'framer-motion'

const FadeInOut_Y_ShiftHOC = ({children, isActive, YShiftInit = -10, YShiftExit = 0, noExit}) => {

    return (
        <>
            <AnimatePresence>
                {
                    isActive && (
                        <motion.div
                            initial={{opacity: 0, y: YShiftInit}}
                            animate={{opacity: 1, y: 0}}
                            exit={!noExit && ({opacity: 0, y: YShiftExit})}
                        >
                            {
                                children
                            }
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}

export default FadeInOut_Y_ShiftHOC;
