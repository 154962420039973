import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import 'semantic-ui-css/semantic.min.css'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {sr, toObject} from '../utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {Switch, Popover, Select} from 'antd';
import _ from "underscore";
import {connect} from "react-redux";
import ru from "date-fns/locale/ru";
import NoPermissions from "../components/NoPermissions";
import {getPermissionsAction} from "../reducers/toolkit/mainAppReducer"
const {Option} = Select;

function getPersonColor(persId) {
  if (persId == null) {
    return {c: 'white'};
  }
  if (typeof (getPersonColor.assignedColors) === 'undefined') {  // начальная инициализация
    getPersonColor.colorsList = [
      {c: 'red'},
      {c: 'green'},
      {c: 'blue'},
      {c: 'purple'},
      {c: 'yellow'},
      {c: 'orange'},
      {c: 'pink'},
      {c: 'lightBlue'},
      {c: 'indigo'},
      {c: 'fuchsia'},
      {c: 'brown'},
      {c: 'Lime'},
      {c: 'Olive'},
      {c: 'Teal'},
      {c: 'MediumVioletRed'},
      {c: 'Chocolate'},
      {c: 'GreenYellow'},
      {c: 'DarkSlateBlue'},
      {c: 'grey'},
    ];
    getPersonColor.currentColor = 0;
    getPersonColor.assignedColors = {};
  }
  if (typeof (getPersonColor.assignedColors[persId]) === 'undefined') {
    getPersonColor.assignedColors[persId] = getPersonColor.colorsList[getPersonColor.currentColor] === undefined ? {c: null} : getPersonColor.colorsList[getPersonColor.currentColor];
    if (getPersonColor.currentColor === 18) {
      getPersonColor.currentColor = 0;
    } else {
      getPersonColor.currentColor++;
    }
  }
  return getPersonColor.assignedColors[persId];
}


class ClientCard extends Component {
  static defaultProps = {
    center: {lat: 53.5295, lng: 49.3460}, // центр карты по умолчанию
    zoom: 12, // увеличение карты по умолчанию
    DatePickerFocused: false,
  };

  constructor(props) {
    super(props);

    this.tracks = [];
    this.deliveryPoints = [];
    this.currentPoint = {};

    this.state = {
      couriers: [],
      selectedCourier: null,
      deleteCourier: false,
      markersFilter: 'all',
      date: new Date(),
      draggable: false,
      periods: [],
      currentPeriod: '1',
      allCouriers: [],
      isAllCouriers: false,
      isCouriersSalary: false,
      pointsList: {},
      showCourierPage: false,
      courierInfo: {},
      courierTime: [],
      flightPlanCoordinates: [],
      blackTrack: {},

      centers: [],
      selectedCenters: [],
      cities: [],
      selectedCity: '',
    };
  }



  componentDidMount = async () => {
    if (!this.props.getPermissionsSuccess) {
      await this.props.getPermissions()
    } else {
      await this.start()
    }
  }
  
  start = async () => {
    if (this.checkRole('screen_map')) {
      await sr("AppSOKitchenService", "GetCouriers", {}).then((res) => {
        this.setState({allCouriers: toObject(res.data.items, 'clientId')}, () => console.log(res.data.items, 'res.data.items'))
      })

      await this.getPeriods()

      if (this.props.permissions.centers) {
        await this.getCenters()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.markersFilter !== this.state.markersFilter) {
      this.updateDeliveryPoints(this.state.currentPeriod);
    }

    if (prevProps.isAuth !== this.props.isAuth && this.props.isAuth) {
      this.button1Click();
      this.updateDeliveryPoints(this.state.currentPeriod);
    }

    if (prevState.date !== this.state.date || prevState.currentPeriod !== this.state.currentPeriod || prevState.selectedCenters !== this.state.selectedCenters) {
      for (let i in this.deliveryPoints) {
        if (this.deliveryPoints.hasOwnProperty(i)) {
          this.deliveryPoints[i].setMap(null);
          delete this.deliveryPoints[i];
        }
      }
      this.deliveryPoints = [];
      this.updateDeliveryPoints(this.state.currentPeriod);
      this.button1Click();
    }

    if (prevState.date !== this.state.date) {
      this.getPeriods();
    }

    if (prevState.draggable !== this.state.draggable) {
      this.updateDeliveryPoints(this.state.currentPeriod);
    }

    if (prevState.selectedCity !== this.state.selectedCity) {
      localStorage.setItem('selectedCity', this.state.selectedCity)
      this.setState({selectedCenters: []})
    }

    if (prevState.selectedCenters !== this.state.selectedCenters) {
      localStorage.setItem('selectedCenters', JSON.stringify(this.state.selectedCenters))
    }

    if (prevProps.permissions !== this.props.permissions) {
      this.start().then()
    }
  }

  getCenters = () => {
    console.log(this.props.permissions, 'this.props.permissions')

    let cities = []
    this.props.permissions.centers.forEach(center => {
      if (!_.some(cities, city => city.cityId === center.cityId)) {
        cities.push({cityId: center.cityId, cityName: center.cityName})
      }
    })
    this.setState({
      centers: this.props.permissions.centers,
      cities
    }, () => this.setDefaultCity())
  }

  getPeriods = () => {
    console.log(this.state.date, 'this.state.date')
    sr("SettingsService", "GetOperatorPeriods", {date: moment(this.state.date).format("YYYY-MM-DD")}).then(res => {
      console.log(res, 'GetOperatorPeriods')
      if (res.result) {
        this.setState({periods: res.data.periods})
      }
    })
  }

  setCompanyCoords = (companyId, lat, lng) => {
    sr("AppSOKitchenService", "SetCompanyCoords", {
      "company": {id: companyId, latitude: lat, longitude: lng}
    }).then(() => {
      this.setState({draggable: false})
    });
  }

  setDate(d) {
    console.log(d);
  }

  onChange = date => this.setState({date})

  removeTrack = (persId) => {
    //console.info('removeTrack', persId);
    if (this.tracks[persId]) {
      this.tracks[persId].Polyline.setMap(null);
      delete this.tracks[persId].Polyline;
      this.tracks[persId].beginMarker.setMap(null);
      delete this.tracks[persId].beginMarker;
      this.tracks[persId].endMarker.setMap(null);
      delete this.tracks[persId].endMarker;
      this.tracks[persId] = null;
    }
  };

  setActiveOfCourier(courierId) {
    // let s = this.state.couriers;
    // s[courierId].active = !s[courierId].active;
    // this.setState({couriers: s});

    let s = this.state.allCouriers;
    s[courierId].active = !s[courierId].active;
    this.setState({allCouriers: s});
  }

  updateTrack = (persIds, isAll = false) => {
    console.info('updateTrack', persIds);
    for (let p in this.tracks) {
      if (this.tracks.hasOwnProperty(p)) {
        this.removeTrack(p);
      }
    }
    let dateString = moment(this.state.date).format("YYYY-MM-DD");
    sr('WinchController', 'GetCourierTracks', {personIds: persIds, date: dateString, periodId: this.state.currentPeriod}).then((res) => {
      for (let persId in res.data.tracks) {
        if (res.data.tracks.hasOwnProperty(persId)) {
          this.removeTrack(persId);
          let myTrack = res.data.tracks[persId];
          //myTrack = Object.values(myTrack);
          console.log(myTrack);

          let track = {
            Polyline: new this.maps.Polyline({
              //path: [{lat: 37.772, lng: -122.214}, {lat: 21.291, lng: -157.821},  {lat: -18.142, lng: 178.431}, {lat: -27.467, lng: 153.027}, {lat: 59.95, lng: 30.33}],
              /*
              path: [{lng:49.447784, lat:53.471962 },{lng:49.449844, lat:53.482408 },{lng:49.459114, lat:53.493669 },{lng:49.487953, lat:53.492646 },
                    {lng:49.493446, lat:53.499606 },{lng:49.518509, lat:53.487732 },{lng:49.504776, lat:53.471143 },{lng:49.447784, lat:53.471962}],
                    */

              path: Object.values(myTrack),
              geodesic: true,
              strokeColor: getPersonColor(persId).c,
              strokeOpacity: 1.0,
              strokeWeight: 2
            })
          };
          //this.setState({flightPath: track});
          !isAll && track.Polyline.setMap(this.map);

          track.beginMarker = new this.maps.Marker({
            position: myTrack[0],
            map: this.map,
            //icon: 'http://menuforme.online/img/tmp/start.svg',
            icon: this.spotIcon({courierId: persId, carPosition: 'start'}),
          });

          track.endMarker = new this.maps.Marker({
            position: myTrack.pop(),
            map: this.map,
            //icon: 'http://menuforme.online/img/tmp/car.svg',
            icon: this.spotIcon({courierId: persId, carPosition: 'end'}),
          });
          //console.log(track);
          this.tracks[persId] = track;
          this.map.panTo(track.endMarker.position);
        }
      }
    });
  }

  spotIcon(dPoint) {
    const color = getPersonColor(dPoint.courierId).c;
    const path = 'https://menuforme.online/img/markers/';
    if (dPoint.carPosition === 'start')
      return `${path}start-${color}.svg`;
    if (dPoint.carPosition === 'end')
      return `${path}car-${color}.svg`;

    if (dPoint.courierId == null) {
      if (dPoint.lat === "53.49" || dPoint.lng === "49.23") {
        return `${path}marker-empty-new.svg`;
      } else {
        return `${path}marker-empty.svg`;
      }
    }
    if (dPoint.deliveryTime == null) {
      //return `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png` ;
      return `${path}marker-${color}-off.svg`;
    } else {
      return `${path}marker-${color}-on.svg`;
    }
  }

  updateDeliveryPoints = (periodId = '1') => {
    let dateString = moment(this.state.date).format("YYYY-MM-DD");

    console.log(this.state.selectedCenters, 'this.state.selectedCenters')

    if (this.state.selectedCenters.length) {
      console.log(1)
      sr('WinchController', 'GetDeliveryPoints', {
        date: dateString,
        periodId: periodId,
        centerIds: this.state.selectedCenters
      }).then((res) => {
        console.log(res, '111111111111')

        this.setState({pointsList: res.data.points})

        for (let i in res.data.points) {
          if (res.data.points.hasOwnProperty(i)) {
            let point = res.data.points[i];

            if (!point.lat || !point.lng) {
              point.lat = "53.49";
              point.lng = "49.23";
            }

            if (this.deliveryPoints[point.ordId]) {  // если уже есть такая точка, удаляем её
              this.deliveryPoints[point.ordId].setMap(null);
              delete this.deliveryPoints[point.ordId];
            }

            this.deliveryPoints[point.ordId] = new this.maps.Marker({
              position: {lat: Number.parseFloat(point.lat), lng: Number.parseFloat(point.lng)},
              map: this.map,
              icon: this.spotIcon(point),
              title: point.companyName + '\nвремя доставки: ' + point.deliveryTime,
              zIndex: point.courierId ? 5 : 6,
              draggable: this.state.draggable,
            });

            this.deliveryPoints[point.ordId].addListener("dragend", (e) => {
              this.setCompanyCoords(point.companyId, e.latLng.lat(), e.latLng.lng())
            });

            if (point.courierId && this.state.markersFilter === 'not-added') {
              this.deliveryPoints[point.ordId].setMap(null);
            }
            if (!point.courierId && this.state.markersFilter === 'added') {
              this.deliveryPoints[point.ordId].setMap(null);
            }

            this.deliveryPoints[point.ordId].addListener('click', () => {
              console.log('заказ №' + point.ordId);
              console.log('ID курьера ' + this.state.selectedCourier);

              this.currentPoint = point;
              if (this.state.selectedCourier || this.state.deleteCourier) {
                if (this.currentPoint.courierId && !this.state.deleteCourier) {
                  let newCourier = window.confirm(`На этот заказ уже назначен курьер ${this.currentPoint.courierId}. Назначить нового курьера?`)
                  newCourier && this.appointCourierToOrder(this.state.selectedCourier, point.ordId);
                } else {
                  this.appointCourierToOrder(this.state.selectedCourier, point.ordId);
                }
              }
              this.setState({appointDone: true});
            });
          }
        }

        // if ( this.state.couriers.length === 0 ){
        //   res.data.couriers.forEach( (courier) => {
        //     courier.active = false;
        //   });
        // }

        if (this.state.allCouriers.length === 0) {
          res.data.couriers.forEach((courier) => {
            courier.active = false;
          });
        }

        //console.log(res.data.periods, 'res.data.periods')
        this.setState({
          couriers: toObject(res.data.couriers, 'id'),
          //periods: res.data.periods,
        });

      });
    }
  }

  appointCourierToOrder = (courierId, orderId) => {
    //console.log('appointCourierToOrder', courierId, orderId);
    sr("AppSOKitchenService", "SetOrderCourier", {order: {id: orderId, courierId: courierId}}).then(() => {
      this.updateDeliveryPoints(this.state.currentPeriod);
      this.setState({appointDone: true});
    });
  }

  appointCourierToOrders = (courierId, orderId) => {
    sr("AppSOKitchenService", "SetOrderCouriers", {orders: {id: orderId, courierId: courierId}}).then(() => {
      this.updateDeliveryPoints(this.state.currentPeriod);
      this.setState({appointDone: true});
    });
    console.log(orderId, 'dfdf')
  }

  button1Click = () => {

    for (let p in this.tracks) {
      if (this.tracks.hasOwnProperty(p)) {
        this.removeTrack(p);
      }
    }
    let filtered = {};
    for (let k in this.state.allCouriers) {
      if (this.state.allCouriers.hasOwnProperty(k) && this.state.allCouriers[k].active) {
        filtered[k] = this.state.allCouriers[k];
      }
    }

    this.updateTrack(Object.keys(filtered));

  };

  showAllGeo = () => {
    for (let p in this.tracks) {
      if (this.tracks.hasOwnProperty(p)) {
        this.removeTrack(p);
      }
    }
    this.updateTrack(Object.keys(this.state.allCouriers), true);
  };

  button2Click = () => {
    this.updateDeliveryPoints(this.state.currentPeriod);
  };

  handleApiLoaded(map, maps) {
    this.map = map;
    this.maps = maps;
    this.updateDeliveryPoints(this.state.currentPeriod);
  }

  selectCourierChange = (el) => {
    //this.selectedCourier = el.target.value;
    this.setState({selectedCourier: el.target.value})
  }

  pointsCount = (id) => {
    let count = 0;
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id) {
        count++
      }
    }

    return count
  }

  distanceSum = (id) => {
    let distance = 0;
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id) {
        distance += +(this.state.pointsList[key].distance);
      }
    }

    return distance
  }

  distanceAll = () => {
    return Object.values(this.state.couriers) && Object.values(this.state.couriers).reduce((acc, el) => acc += +el.distance, 0)
  }

  ordersTotal = (id) => {
    let total = 0;
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id) {
        total += +(this.state.pointsList[key].sumPriceResult);
      }
    }

    return total
  }

  ordersAll = () => {
    let all = 0;
    for (let key in this.state.pointsList) {
      all += +(this.state.pointsList[key].sumPriceResult);
    }

    return all
  }

  cashTotal = (id) => {
    let total = 0;
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id) {
        total += +(this.state.pointsList[key].sumPriceResultCash);
      }
    }

    return total
  }

  cashAll = () => {
    let all = 0;
    for (let key in this.state.pointsList) {
      all += +(this.state.pointsList[key].sumPriceResultCash);
    }

    return all
  }

  salariesSum = () => {
    let sum = 0
    for (let key in this.state.couriers) {
      if (this.state.couriers.hasOwnProperty(key) && this.state.couriers[key].hasOwnProperty('salary')) {
        sum += +(this.state.couriers[key].salary)
      }
    }

    return sum
  }

  courierProgress = (id) => {
    let done = 0;
    let all = 0;
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id) {
        all++;
        if (this.state.pointsList[key].deliveryTime) {
          done++
        }
      }
    }

    return done === 0 ? 0 : done / all * 100
  }

  startDelivery = (id) => {
    let times = [];
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id && this.state.pointsList[key].deliveryTime) {
        times.push(moment(this.state.pointsList[key].deliveryTime).valueOf())
      }
    }

    if (times.length > 0) {
      let minimum = Math.min(...times)
      return moment(minimum).format("HH:mm")
    } else {
      return '--:--'
    }
  }

  midTime = () => {
    let mySet = new Set();

    for (let key in this.state.pointsList) {
      if (!mySet.has(this.state.pointsList[key].courierId)) {
        mySet.add(this.state.pointsList[key].courierId)
      }
    }

    let minTimes = [], maxTimes = [];

    for (let item of mySet) {
      let times = [];
      for (let key in this.state.pointsList) {
        if (this.state.pointsList[key].courierId === item && this.state.pointsList[key].deliveryTime) {
          times.push(moment(this.state.pointsList[key].deliveryTime).valueOf())
        }
      }
      if (times.length > 0) {
        minTimes.push(Math.min(...times))
        maxTimes.push(Math.max(...times))
      }
    }

    let minSum = minTimes.reduce((a, b) => a + b, 0);
    let minRes = minSum ? moment(minSum / minTimes.length).format("HH:mm") : '--:--'

    let maxSum = maxTimes.reduce((a, b) => a + b, 0);
    let maxRes = maxSum ? moment(maxSum / maxTimes.length).format("HH:mm") : '--:--'

    return {minRes, maxRes}

  }

  endDelivery = (id) => {
    let times = [];
    for (let key in this.state.pointsList) {
      if (this.state.pointsList[key].courierId === id && this.state.pointsList[key].deliveryTime) {
        times.push(moment(this.state.pointsList[key].deliveryTime).valueOf())
      }
    }

    if (times.length > 0) {
      let maximum = Math.max(...times)
      return moment(maximum).format("HH:mm")
    } else {
      return '--:--'
    }
  }

  GetCourierCompanyTime = (courier) => {
    sr("WinchController", "GetCourierCompanyTime", {
      date: moment(this.state.date).format("YYYY-MM-DD"),
      periodId: this.state.currentPeriod,
      courierId: courier.id
    }).then((res) => {
      console.log(res, 'ressssss')
      this.setState({
        courierInfo: courier,
        courierTime: res.data.time
      }, () => this.flightPlanCoordinates(this.state.courierTime))
    });
  }

  flightPlanCoordinates = (courierTime) => {
    let coords = [];
    console.log(this.state.courierInfo, 'courierInfo')

    let dateString = moment(this.state.date).format("YYYY-MM-DD");
    sr('WinchController', 'GetCourierTracks', {
      personIds: [this.state.courierInfo.id],
      date: dateString
    }).then((res) => {
      for (let persId in res.data.tracks) {
        if (res.data.tracks.hasOwnProperty(persId)) {
          let myTrack = res.data.tracks[persId];
          coords.push({
            lat: myTrack[myTrack.length - 1].lat,
            lng: myTrack[myTrack.length - 1].lng,
          })
        }
      }

      courierTime.map(item => {
        let found = _.find(this.state.pointsList, point => !item.timeActual && point.companyId === item.companyId);
        if (found) {
          coords.push({
            lat: +found.lat,
            lng: +found.lng,
          })
        }
      })
      console.log(coords, 'coords')

      let track = {
        Polyline: new this.maps.Polyline({
          path: coords,
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 1.0,
          strokeWeight: 2
        })
      };

      this.setState({blackTrack: track, showCourierPage: true}, () => track.Polyline.setMap(this.map))
    });
  }

  calcDelta = (timeCalcSec1, timeActualSec) => {
    let h = null;
    let m = null;
    let s;
    let delta = timeCalcSec1 - timeActualSec;

    if (delta < 60 && delta > -60) {
      s = delta
    } else if (delta < 3600 && delta > -3600) {
      s = delta % 60;
      m = (delta - s) / 60;
    } else {
      s = delta % 60;
      m = ((delta - s) % 3600) / 60;
      h = (delta - m * 60 - s) / 3600;
    }

    return delta < 0
      ? '- ' + (h ? Math.abs(h) + 'ч.' : '') + (m ? ' ' + Math.abs(m) + ' мин.' : '') + (s ? ' ' + Math.abs(s) + ' сек.' : '')
      : delta === 0
        ? '0 сек.'
        : (h ? Math.abs(h) + 'ч.' : '') + (m ? ' ' + Math.abs(m) + ' мин.' : '') + (s ? ' ' + Math.abs(s) + ' сек.' : '')
  }

  setDefaultCity = () => {
    if (localStorage.getItem('selectedCity')) {
      this.setState({selectedCity: localStorage.getItem('selectedCity')}, () => this.setDefaultCenters())
    } else if (_.some(this.state.cities, city => city.cityId === '18')) {
      this.setState({selectedCity: '18'})
    } else {
      this.setState({selectedCity: this.state.cities[0].cityId})
    }
  }

  setDefaultCenters = () => {
    if (localStorage.getItem('selectedCenters')) {
      this.setState({selectedCenters: JSON.parse(localStorage.getItem('selectedCenters'))})
    }
  }

  checkRole = (param) => {
    if (this.props.permissions?.staff) {
      if (this.props.permissions.staff.role === 'admin' && !this.props.permissions.permissions.length) {
        return true
      } else {
        return this.props.permissions.permissions.includes(param)
      }
    } else {
      return false
    }
  }

  render() {
    return (
      <>
        {this.checkRole('screen_map') ? (
          <>
            <div style={{display: 'flex', position: 'relative'}}>
              <div className='selectFilters'>
                <DatePicker
                  dateFormat="d MMMM yyyy"
                  locale={ru}
                  selected={this.state.date}
                  onChange={date => this.setState({date: date})}
                  className="datepicker filterSelectDate"
                />
                {this.state.periods.length ? (
                  <Select
                    size="large"
                    value={this.state.currentPeriod}
                    onChange={(value) => this.setState({currentPeriod: value})}
                    style={{width: 150, marginLeft: 20, marginBottom: 10}}
                  >
                    {this.state.periods.map(v => {
                      return (
                        <Option key={v.id} value={v.id}>
                          {v.timeFrom.slice(0, -3) + ' - ' + v.timeTo.slice(0, -3)}
                        </Option>
                      )
                    })}
                  </Select>
                ) : null}
                {this.state.centers.length ? (
                  <>
                    <Select
                      size="large"
                      value={this.state.selectedCity}
                      onChange={(value) => this.setState({selectedCity: value})}
                      style={{width: 150, marginLeft: 20, marginRight: 20, marginBottom: 10}}
                    >
                      {this.state.cities.map(v => {
                        return (
                          <Option key={v.cityId} value={v.cityId}>
                            {v.cityName}
                          </Option>
                        )
                      })}
                    </Select>
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Выберите один или несколько РЦ"
                      value={this.state.selectedCenters}
                      onChange={(value) => this.setState({selectedCenters: value})}
                      style={{width: 250}}
                    >
                      {this.state.centers.map(v => {
                        if (v.cityId === this.state.selectedCity) {
                          return (
                            <Option key={v.centerId}>
                              {v.name}
                            </Option>
                          )
                        }
                      })}
                    </Select>
                  </>
                ) : null}
              </div>

              <div className='couriersMap'>
                <GoogleMapReact
                  bootstrapURLKeys={{key: 'AIzaSyDZbiFFOqzJz-bW-YtxdJppFY-7M2Uf6Bk'}}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}

                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}
                >
                </GoogleMapReact>
              </div>

              <div className="courier_map_date"
                   style={{width: 670, height: 'calc(100vh - 60px)', position: 'relative'}}>

                {/*<div style={{display: 'flex', justifyContent: 'space-between', paddingLeft: 5, paddingRight: 5}}>*/}
                {/*  <div style={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    flexWrap: 'wrap',*/}
                {/*  }}>*/}
                {/*    {this.state.periods.length !== 0 && this.state.periods.map((v, i) => {*/}
                {/*      return (*/}
                {/*          <button key={i} style={{*/}
                {/*            marginLeft: 1,*/}
                {/*            marginRight: 1,*/}
                {/*            marginTop: 20,*/}
                {/*            width: 96,*/}
                {/*            height: 27,*/}
                {/*            fontWeight: this.state.currentPeriod === v.id ? 'bold' : 'normal',*/}
                {/*            paddingLeft: 3,*/}
                {/*            paddingRight: 3,*/}
                {/*          }} onClick={() => this.setState({currentPeriod: v.id})}>*/}
                {/*            {v.timeFrom.slice(0, -3) + ' - ' + v.timeTo.slice(0, -3)}*/}
                {/*          </button>*/}
                {/*      )*/}
                {/*    })}*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div style={{display: 'flex', justifyContent: 'space-between', paddingLeft: 5, paddingRight: 5}}>
                  <button style={{marginTop: 10}} onClick={this.button2Click}>Обновить точки</button>
                  <button style={{marginTop: 10}} onClick={this.showAllGeo}>Показать положения курьеров</button>
                  <button style={{marginTop: 10}} onClick={this.button1Click}>Обновить треки</button>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex'}}>
                    <p>Показывать всех курьеров</p>
                    <Switch checked={this.state.isAllCouriers}
                            onChange={(e) => this.setState({isAllCouriers: e, isCouriersSalary: false})}/>
                  </div>

                  <div style={{display: 'flex'}}>
                    <p>Показать выплаты курьерам</p>
                    <Switch checked={this.state.isCouriersSalary}
                            onChange={(e) => this.setState({isCouriersSalary: e, isAllCouriers: false})}/>
                  </div>
                </div>

                {this.state.isCouriersSalary ? (
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <p style={{width: 115, marginBottom: 0, textAlign: 'center', marginRight: 5}}>выплаты</p>
                  </div>
                ) : (
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <p style={{width: 50, margin: 0, textAlign: 'left'}}>сумма</p>
                    <p style={{width: 60, marginBottom: 0, textAlign: 'left', marginLeft: 5}}>нал</p>
                  </div>
                )}

                {this.state.isCouriersSalary ? (
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{width: 50, alignSelf: 'self-start'}}>ИТОГО</p>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <p style={{
                        width: 34,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 5
                      }}>{this.midTime().minRes}</p>
                      <p style={{
                        width: 34,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 30
                      }}>{this.midTime().maxRes}</p>
                      <p style={{
                        width: 60,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 5
                      }}>{Math.round(this.distanceAll() / 1000)}км</p>
                      <p style={{width: 115, marginBottom: 0, textAlign: 'center', marginLeft: 5, paddingRight: 5}}>
                        {this.salariesSum()}₽
                      </p>
                    </div>
                  </div>
                ) : (
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{width: 50, alignSelf: 'self-start'}}>ИТОГО</p>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <p style={{
                        width: 34,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 5
                      }}>{this.midTime().minRes}</p>
                      <p style={{
                        width: 34,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 30
                      }}>{this.midTime().maxRes}</p>
                      <p style={{
                        width: 60,
                        marginBottom: 0,
                        textAlign: 'left',
                        marginLeft: 10
                      }}>{Math.round(this.distanceAll() / 1000)}км</p>
                      <p style={{width: 50, marginBottom: 0, textAlign: 'left', marginLeft: 5}}>{this.ordersAll()}₽</p>
                      <p style={{width: 60, marginBottom: 0, textAlign: 'left', marginLeft: 5}}>{this.cashAll()}₽</p>
                    </div>
                  </div>
                )}

                {this.state.isAllCouriers ? (
                  <ul className='CourierLegend' style={{padding: 0}}>
                    {Object.keys(this.state.allCouriers).map((k) => {
                      let courier = this.state.allCouriers[k];
                      return <li key={courier.clientId}
                                 style={{width: '100%', marginBottom: 2, height: 27, listStyleType: 'none'}}>
                        {/*<button style={{width: 62}} onClick={ () => {this.updateTrack([courier.clientId])} }>{courier.clientId}</button>*/}
                        <label style={{'fontWeight': courier.active ? 'bold' : 'normal', fontSize: 10}}>
                          <div className='CourierLegendColor' style={{background: getPersonColor(courier.clientId).c}}/>
                          <input type="checkbox" onChange={() => {
                            this.setActiveOfCourier(courier.clientId)
                          }} checked={courier.active} style={{marginRight: 2}}/>
                          {courier.name}
                        </label>
                        <div style={{display: 'inline-block', float: 'right', height: 27}}>
                          <button style={{width: 27, marginLeft: 5}} onClick={() => {
                            this.updateTrack([courier.clientId])
                          }}>
                            <img src="https://menuforme.online/img/markers/marker-empty.svg" width="10" alt="#"/>
                          </button>
                          <button style={{
                            width: 27,
                            height: 27,
                            fontWeight: this.state.selectedCourier === courier.clientId ? 'bold' : 'normal',
                            //float: 'right',
                            paddingLeft: 0,
                            paddingRight: 0,
                          }} onClick={() => {
                            if (this.checkRole('method_map')) {
                              if (this.state.selectedCourier === courier.clientId) {
                                this.setState({selectedCourier: null})
                              } else {
                                this.setState({selectedCourier: courier.clientId})
                              }
                              this.setState({appointDone: false, deleteCourier: false})
                            } else {
                              alert('Недостаточно прав. Обратитесь к администратору')
                            }
                          }}>Н
                          </button>

                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 20
                          }}>{this.pointsCount(courier.clientId)}</p>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 34
                          }}>{this.startDelivery(courier.clientId)}</p>
                          <div style={{
                            width: 20,
                            height: 10,
                            marginLeft: 5,
                            borderWidth: 1,
                            borderColor: '#000',
                            borderStyle: 'solid',
                            display: 'inline-block'
                          }}>
                            <div style={{
                              width: `${this.courierProgress(courier.clientId)}%`,
                              height: '100%',
                              backgroundColor: '#0f0'
                            }}/>
                          </div>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 34
                          }}>{this.endDelivery(courier.clientId)}</p>

                          <p style={{
                            marginLeft: 10,
                            display: 'inline-block',
                            width: 60
                          }}>{Math.round(this.distanceSum(courier.clientId) / 1000)}км</p>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 50
                          }}>{this.ordersTotal(courier.clientId)}₽</p>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 50
                          }}>{this.cashTotal(courier.clientId)}₽</p>
                        </div>

                      </li>;
                    })}
                    {Object.keys(this.state.allCouriers).length !== 0 ? (
                      <button style={{
                        width: 145,
                        fontWeight: this.state.deleteCourier ? 'bold' : 'normal',
                        float: 'right',
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginRight: 20,
                      }} onClick={() => {
                        this.setState({
                          appointDone: false,
                          deleteCourier: !this.state.deleteCourier,
                          selectedCourier: null
                        })
                      }}>Снять курьера</button>
                    ) : []}
                  </ul>
                ) : (
                  <ul className='CourierLegend' style={{padding: 0}}>
                    {Object.keys(this.state.couriers).map((k) => {
                      let courier = this.state.couriers[k];
                      return <li key={courier.id}
                                 style={{width: '100%', marginBottom: 2, height: 27, listStyleType: 'none'}}>
                        {/*<button style={{width: 62}} onClick={ () => {this.updateTrack([courier.id])} }>{courier.id}</button>*/}
                        <div style={{display: 'inline-flex', alignItems: 'center', marginTop: 4}}>
                          <label style={{'fontWeight': courier.active ? 'bold' : 'normal', fontSize: 10}}>
                            <div className='CourierLegendColor' style={{background: getPersonColor(courier.id).c}}/>
                            <input type="checkbox" onChange={() => {
                              this.setActiveOfCourier(courier.id)
                            }} checked={courier.active} style={{marginRight: 2}}/>

                          </label>
                          <p
                            style={{'fontWeight': courier.active ? 'bold' : 'normal', fontSize: 10, cursor: 'pointer'}}
                            onClick={() => this.GetCourierCompanyTime(courier)}
                          >
                            {courier.name}
                          </p>
                        </div>

                        <div style={{display: 'inline-block', float: 'right', height: 27}}>
                          <button style={{width: 27, marginLeft: 5}} onClick={() => {
                            this.updateTrack([courier.id])
                          }}>
                            <img src="https://menuforme.online/img/markers/marker-empty.svg" width="10" alt="#"/>
                          </button>
                          <button style={{
                            width: 27,
                            height: 27,
                            fontWeight: this.state.selectedCourier === courier.id ? 'bold' : 'normal',
                            //float: 'right',
                            paddingLeft: 0,
                            paddingRight: 0,
                          }} onClick={() => {
                            if (this.checkRole('method_map')) {
                              if (this.state.selectedCourier === courier.id) {
                                this.setState({selectedCourier: null})
                              } else {
                                this.setState({selectedCourier: courier.id})
                              }
                              this.setState({appointDone: false, deleteCourier: false})
                            } else {
                              alert('Недостаточно прав. Обратитесь к администратору')
                            }
                          }}>Н
                          </button>

                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 20
                          }}>{this.pointsCount(courier.id)}</p>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 34
                          }}>{this.startDelivery(courier.id)}</p>
                          <div style={{
                            width: 20,
                            height: 10,
                            marginLeft: 5,
                            borderWidth: 1,
                            borderColor: '#000',
                            borderStyle: 'solid',
                            display: 'inline-block'
                          }}>
                            <div style={{
                              width: `${this.courierProgress(courier.id)}%`,
                              height: '100%',
                              backgroundColor: '#0f0'
                            }}/>
                          </div>
                          <p style={{
                            marginLeft: 5,
                            display: 'inline-block',
                            width: 34
                          }}>{this.endDelivery(courier.id)}</p>

                          <p style={{
                            marginLeft: 10,
                            display: 'inline-block',
                            width: 60
                          }}>{Math.round(courier.distance / 1000)}км</p>
                          {this.state.isCouriersSalary ? (
                            <Popover
                              placement="bottomRight"
                              title="Комментарий"
                              trigger="click"
                              content={
                                <div>
                                  <p>{courier.salaryComment}</p>
                                </div>
                              }
                            >
                              <p
                                style={{
                                  marginLeft: 5,
                                  display: 'inline-block',
                                  width: 105,
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                {+courier.salary}₽
                              </p>
                            </Popover>
                          ) : (
                            <>
                              <p style={{
                                marginLeft: 5,
                                display: 'inline-block',
                                width: 50
                              }}>{this.ordersTotal(courier.id)}₽</p>
                              <p style={{
                                marginLeft: 5,
                                display: 'inline-block',
                                width: 50
                              }}>{this.cashTotal(courier.id)}₽</p>
                            </>
                          )}
                        </div>

                      </li>;
                    })}
                    {Object.keys(this.state.couriers).length !== 0 ? (
                      <button style={{
                        width: 145,
                        fontWeight: this.state.deleteCourier ? 'bold' : 'normal',
                        float: 'right',
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginRight: 20,
                      }} onClick={() => {
                        this.setState({
                          appointDone: false,
                          deleteCourier: !this.state.deleteCourier,
                          selectedCourier: null
                        })
                      }}>Снять курьера</button>
                    ) : []}
                  </ul>
                )}

                <div>
                  <p style={{marginBottom: 10}}>Показывать заказы:</p>
                  <button style={{
                    fontWeight: this.state.markersFilter === 'all' ? 'bold' : 'normal',
                    marginRight: 2,
                    width: 40
                  }} onClick={() => {
                    this.setState({markersFilter: 'all'})
                  }}>Все
                  </button>
                  <button style={{
                    fontWeight: this.state.markersFilter === 'added' ? 'bold' : 'normal',
                    marginRight: 2,
                    width: 110
                  }} onClick={() => {
                    this.setState({markersFilter: 'added'})
                  }}>Назначенные
                  </button>
                  <button style={{fontWeight: this.state.markersFilter === 'not-added' ? 'bold' : 'normal', width: 126}}
                          onClick={() => {
                            this.setState({markersFilter: 'not-added'})
                          }}>Неназначенные
                  </button>
                </div>

                {this.state.appointDone ? (
                  <div style={{marginTop: 40, width: '100%', textAlign: 'left'}}>
                    <p style={{
                      textAlign: 'center',
                      marginBottom: 10
                    }}>{this.state.selectedCourier ? 'Назначен заказ:' : 'Информация о заказе'}</p>
                    <div className="order-info" style={{paddingLeft: 20}}>
                      <p>Номер заказа: <b>{this.currentPoint.ordId}</b></p>
                      {/*<p>Курьер: <b>{ this.state.selectedCourier || this.currentPoint.courierId }</b></p>*/}
                      <p>Компания: {this.currentPoint.companyId} <b>{this.currentPoint.companyName}</b></p>
                      <p>Адрес: <b>{this.currentPoint.companyAddress}</b></p>
                      <p>Всего заказов: <b>{this.currentPoint.ordersCount}</b></p>
                      <p>На сумму: <b>{this.currentPoint.sumPriceResult * 1}</b></p>
                    </div>
                  </div>
                ) : []}

                {/* ToDo: Перемещение маркера */}
                <button style={{
                  position: 'absolute',
                  bottom: 15,
                  left: 20,
                  width: 160,
                  fontWeight: this.state.draggable ? 'bold' : 'normal'
                }} onClick={() => {
                  this.setState({draggable: !this.state.draggable});
                }}>
                  Переместить маркер
                </button>

              </div>

              {this.state.showCourierPage && (
                <div style={{
                  position: 'fixed',
                  top: 60,
                  right: 0,
                  width: 500,
                  height: 'calc(100vh - 60px)',
                  backgroundColor: '#fff',
                  padding: 20,
                  textAlign: 'left',
                }}>
                  <p style={{cursor: 'pointer', marginBottom: 40}}
                     onClick={() => this.setState({showCourierPage: false}, () => {
                       this.state.blackTrack.Polyline.setMap(null);
                     })}>Назад</p>
                  <p style={{marginBottom: 20, fontWeight: '500'}}>{this.state.courierInfo.name}</p>
                  <div style={{overflowY: 'scroll', height: 'calc(100vh - 178px)'}}>
                    {this.state.courierTime.map((item, i) => {
                      return (
                        <div key={i} style={{display: 'flex'}}>
                          <p style={{width: 25, color: '#aaa'}}>{i + 1}</p>
                          <p style={{width: 45}}>{item.companyId}</p>
                          <div style={{width: 130}}>
                            <p style={{width: 130, marginBottom: 5, wordWrap: 'break-word'}}>
                              {_.find(this.state.pointsList, point => item.companyId === point.companyId).companyName}
                            </p>
                            <p style={{width: 130, marginBottom: 14, fontSize: 9, color: '#aaa'}}>
                              {_.find(this.state.pointsList, point => item.companyId === point.companyId).companyAddress}
                            </p>
                          </div>
                          <p style={{width: 60, marginBottom: 14, textAlign: 'center'}}>
                            {
                              item.timeCalcSec1 != null && item.timeCalculation != null
                                ? '~' + moment(item.timeCalculation).add(item.timeCalcSec1, 's').format("HH:mm")
                                : '--:--'
                            }
                          </p>
                          <p style={{width: 50, marginBottom: 14, textAlign: 'center', color: '#aaa'}}>
                            {item.timeActual != null ? moment(item.timeActual).format("HH:mm") : '--:--'}
                          </p>
                          <p style={{
                            width: 110,
                            marginBottom: 14,
                            textAlign: 'center',
                            color: item.timeCalcSec1 != null && item.timeActualSec != null && +item.timeCalcSec1 - +item.timeActualSec < 0
                              ? '#ff8585'
                              : '#aaa',
                          }}>
                            {
                              item.timeCalcSec1 != null && item.timeActualSec != null
                                ? this.calcDelta(+item.timeCalcSec1, +item.timeActualSec)
                                : '-'
                            }
                          </p>
                          <p style={{width: 30, marginBottom: 14, textAlign: 'center'}}>
                            {item.timeCalculation != null ? '✔' : ''}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          this.props.getPermissionsSuccess && (
            <NoPermissions isMap/>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = store => ({
  isAuth: store.profile.isAuth,
  getPermissionsSuccess: store.appMain.getPermissionsSuccess,
  permissions: store.appMain.permissions,
});

const mapDispatchToProps = dispatch => ({
  getPermissions: () => dispatch(getPermissionsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCard);
