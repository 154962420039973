import {
    CHANGE_POPUP_MESSAGE,
    HIDE_PUSH_MESSAGE_POPUP, RESET_SUCCESS_MESSAGE_STATE,
    SEND_PUSH_MESSAGE, SEND_PUSH_MESSAGE_SUCCESS,
    SHOW_PUSH_MESSAGE_POPUP
} from "../../actions/ReviewsActions";

const initState = {
    showPushMessagePopup: false,
    pushMessageText: "",

    sendingMessageIsStart: false,
    sendingMessageIsSuccess: false,
    targetOrder: "",
}

const pushMessageReducer = (state = initState, action) => {
    switch (action.type) {
        case SHOW_PUSH_MESSAGE_POPUP:
            return {
                ...state,
                showPushMessagePopup: true,
                targetOrder: action.payload
            }
        case HIDE_PUSH_MESSAGE_POPUP:
            return {
                ...state,
                showPushMessagePopup: false,
                pushMessageText: "",
                targetOrder: "",
            };
        case CHANGE_POPUP_MESSAGE:
            return {
                ...state,
                pushMessageText: action.payload.text,
            };
        case SEND_PUSH_MESSAGE:
            return {
                ...state,
                sendingMessageIsStart: true,
            };
        case SEND_PUSH_MESSAGE_SUCCESS:
            return {
                ...state,
                sendingMessageIsStart: false,
                sendingMessageIsSuccess: false,
                showPushMessagePopup: false,
                pushMessageText: "",
                targetOrder: "",
            };
        case RESET_SUCCESS_MESSAGE_STATE:
            return {
                ...state,
                targetOrder: "",
                pushMessageText: "",
                showPopupWrapper: false,
                showPushMessagePopup: false,
            };
        default:
            return state
    }
}

export default pushMessageReducer