import React from "react";
import styled from "styled-components"


const DepotInput = ({value, setValue, inputOnKeyDown, inputRef, type = 'text'}) => {

    return (
        <>
            <Input
                ref={inputRef}
                type={type}
                style={{width: '100%'}}
                onChange={setValue}
                onKeyDown={inputOnKeyDown}
                value={value}
            />
        </>
    )
}

export default DepotInput;

const Input = styled.input`
  background: rgba(0,0,0,0.13);
  border: 1px solid transparent;
  border-radius: 6px;
  width: 200px;
  padding: 5px 10px;
`