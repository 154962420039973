import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import moment from "moment";
import {useDispatch} from "react-redux";
import Default from '../../images/menu_default.png'
import {showPushMessagePopup} from "../../actions/ReviewsActions";
import { ItemDescription } from "semantic-ui-react";
import axios from "axios";

const ReviewBody = ({getPermissionsSuccess, permissions, data, addAdminComment}) => {
console.log(data,"REVBODY")
  const dispatch = useDispatch();

  const [parseData, setParseData] = useState({});
  const [showTextarea, setShowTextArea] = useState(false)
  const [adminComment, setAdminComment] = useState("");
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [stateLink,setStateLink] = useState(null)
  useEffect(() => {
    if (adminComment) {
      setSaveConfirm(prev => !prev);
    }
  }, [])
  //build order card
  useEffect(() => {
    if (data) {
      setParseData(parseDataToObject(data));
    }
  }, [data])
  useEffect(() => {
    if (parseData.existAdminComment) {
      setShowTextArea(false);
    }
  }, [parseData])
  console.log(parseData,'parseData')
  const checkRole = (param) => {
    if (permissions.staff) {
      if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
        return true
      } else {
        return permissions.permissions.includes(param)
      }
    } else {
      return false
    }
  }

  
  const parseDataToObject = (data) => {
    let parseFirstData = true;
    let resultObj = data.reduce((acc, el) => {
      if (!acc.orderId) acc['orderId'] = el.orderId;
      if (!acc.client) acc['client'] = `${el.clientId ? `[${el.clientId}]` : ""} ${el.clientName || ""} ${el.clientPhone || ""}`;
      if (!acc.clientId) acc['clientId'] = `[${el.clientId}]` || "";
      if (!acc.phone) acc['phone'] = el.clientPhone;
      // if (!acc.addressId) acc['addressId'] = `${el.companyId} РКС`;
      if (!acc.address) acc['address'] = `${el.street}, д ${el.house}${el.flat ? ` оф ${el.flat}` : ""}`;
      if(!acc.images) acc['image'] = el.images
      // if (!acc.timeTo) acc['timeTo'] = moment(el.timeTo, "YYYY-MM-DD h:mm:ss").format('LLL');
      if (!acc.companyName) acc['companyName'] = el.companyName;
      if (!acc.timeDelivery) acc['timeDelivery'] = moment(el.timeDelivery, "YYYY-MM-DD h:mm:ss").format('LLL');
      if (el.type === "product_quality") {
        if (!acc.date) acc['date'] = moment(el['update_at'], "YYYY-MM-DD h:mm:ss").format('LLL');
        if (!acc.dateForComment) acc['dateForComment'] = el['update_at'];
        if (parseFirstData) {
          acc['kitchenReview'] = [];
          parseFirstData = false;
        }
        if (!acc.restName) acc['restName'] = el.restName;
        // С остальных собираем отзывы с оценками и коментами
        acc.kitchenReview.push(
          {
            'evaluation': el.value,
            'product': el.name,
            'prise': el.price,
            'comment': el.comment ? " - " + el.comment : "",
            'image': el.imageUrl || "",
          }
        )
      }
      if (el.type === "courier_quality") {
        if (!acc.date) acc['date'] = moment(el['update_at'], "YYYY-MM-DD h:mm:ss").format('LLL');
        if (!acc.dateForComment) acc['dateForComment'] = el['update_at'];
        acc['courierService'] = {
          'evaluation': el.value,
          'serviceName': el.name || "Курьер",
          'comment': el.comment ? " - " + el.comment : "",
        }
      }
      if (el.type === "delivery_speed") {
        if (!acc.date) acc['date'] = moment(el['update_at'], "YYYY-MM-DD h:mm:ss").format('LLL');
        if (!acc.dateForComment) acc['dateForComment'] = el['update_at'];
        acc['deliveryService'] = {
          'evaluation': el.value,
          'serviceName': "Скорость доставки",
          'comment': el.comment ? " - " + el.comment : "",
        }
      }
      if (el.type === "comment_admin") {
        setShowComment(prev => !prev);
        acc['existAdminComment'] = el.comment;
      }
      return acc;
    }, {})
    return resultObj;
  };
  const checkServiceEvaluation = (serviceData) => {
    if (Object.keys(serviceData).length) {
      return (
        <li>
          <p><EvalSpan
            evaluation={+serviceData.evaluation}>{serviceData.evaluation}</EvalSpan> {serviceData.serviceName}</p>
          <ClientComment>{serviceData.comment}</ClientComment>
        </li>
      )
    }
  }

  

  const deleteComment = (orderId, date) => {
    setAdminComment("");
    addAdminComment(orderId, adminComment, date);
    setAdminComment("");
    setSaveConfirm(prev => !prev);
    setShowComment(prev => !prev);
  }
  const saveComment = (orderId, date) => {
    addAdminComment(orderId, adminComment, date);
    setSaveConfirm(prev => !prev);
    setShowTextArea(prev => !prev);
    setShowComment(prev => !prev);
  }
  const pushMessageButtonHandle = (orderId) => {
    dispatch(showPushMessagePopup(orderId));
  }
  const drawAdminComment = (orderId, dateForComment, existAdminComment) => {
    return (
      <ReviewAdminComment>
        {
          getPermissionsSuccess && checkRole('method_reviews') && (
            <>
              <PushMessage onClick={() => pushMessageButtonHandle(orderId)}>+ Push уведомление</PushMessage>
              {
                !showComment && <AddComment onClick={() => setShowTextArea(true)}>Комментарий +</AddComment>
              }
            </>
          )
        }
        <Comment textAreaResize={saveConfirm}>
          {
            showTextarea && (
              <>
                <textarea name="adminComment" onChange={e => setAdminComment(e.target.value)} value={adminComment}/>
                {
                  !saveConfirm
                    ?
                    <ButtonWrapper>
                      <DismissComment onClick={() => setShowTextArea(prev => !prev)}>Отмена</DismissComment>
                      <SaveComment onClick={() => saveComment(orderId, dateForComment)}>Сохранить</SaveComment>
                    </ButtonWrapper>
                    :
                    <ButtonWrapper>
                      <DismissComment onClick={() => setShowTextArea(prev => !prev)}>Отмена</DismissComment>
                      <SaveComment onClick={() => saveComment(orderId, dateForComment)}>Сохранить</SaveComment>
                    </ButtonWrapper>
                }
              </>
            )
          }
          {
            existAdminComment && (
              <>
                <AdminComment>
                  {existAdminComment}
                </AdminComment>
                {
                  getPermissionsSuccess && checkRole('method_reviews') && (
                    <ButtonWrapper justify="flex-end">
                      <DeleteComment onClick={() => deleteComment(orderId, dateForComment)}>Удалить
                        комменатрий</DeleteComment>
                    </ButtonWrapper>
                  )
                }
              </>
            )
          }
        </Comment>
      </ReviewAdminComment>
    )
  }


// const dwn = (link) =>{
//   axios({
//     url: link,
//     method: 'GET',
//     responseType: 'blob',
//   }).then(response => {
//     const blob_file = response.data;
//     const file_url = URL.createObjectURL(blob_file);
//     window.open(file_url); // open file in new tab
//   });
// }

// const dwn= (link) =>{
//   var a = document.createElement('a')
//   td.appendChild(a)
//   a.href = 'link'
//   a.download = ''
//   a.click()
// }

// const test = (link) =>{
//   var newWin = window.open(link, "hello", "width=200,height=200");
//   console.log(newWin,"WIN")
//   newWin.onload = function() {
//     var a = document.querySelector('img');
//     var blob = new Blob([link], {'type':link});
//     a.href = window.URL.createObjectURL(blob);
//     a.download = 'test';
//     a.click();
//   }
// };

//   function testImage(url, timeout) {
//     timeout = timeout || 5000;
//     let res = ''
//     var img = new Image();
//     img.onerror = img.onabort = function() {
//             res = 'er'
//       };
//     img.onload = function() {
//             res = 'good'
//     };
//     if(res === 'good'){
//         return 'good'
//     }
//     if(res === 'er'){
//         return 'er'
//     }
// }


  const buildReview = () => {
    const {
      orderId = "",
      date = "",
      dateForComment = "",
      client = "",
      phone = "",
      // addressId = "",
      address = "",
      // timeTo = "",
      clientId,
      timeDelivery = "",
      companyName = "",
      kitchenReview = "",
      existAdminComment,
      courierService = {},
      deliveryService = {},
      restName = "",
      image=[]
    } = parseData;
    return (
      <>
      <OrderData permissions={permissions.staff.role === "admin"}>
        <DateTimeCell permissions={permissions.staff.role !== "admin"} className="review-data-cell"><p>{date}</p>
        </DateTimeCell>
        {
          permissions.staff.role === "admin"
            ? <ClientCell permissions={permissions.staff.role !== "admin"} className="review-data-cell"><Link
              to={"/client-card/?=" + phone} target={"_blank"}><p>{client}</p></Link></ClientCell>
            :
            <ClientCell permissions={permissions.staff.role !== "admin"} className="review-data-cell"><p>{clientId}</p>
            </ClientCell>
        }
        {
          permissions.staff.role === "admin"
            ?
            <AddressCell permissions={permissions.staff.role !== "admin"} className="review-data-cell">
              <p>
                {companyName}
              </p>
              <p>
                {address}
              </p>
            </AddressCell>
            :
            null
        }
        <DeliveredCell permissions={permissions.staff.role !== "admin"} className="review-data-cell">
          <p>{timeDelivery}</p></DeliveredCell>
        <KitchenReviewCell permissions={permissions.staff.role !== "admin"} className="review-data-cell">
          <p><span style={{background: '#AED582', borderRadius: '3px', padding: "2px"}}>{restName}</span></p>
          <ul>
            {
              kitchenReview
                ? kitchenReview.map(el => {
                  return (
                    <li className="kitchen-review-list-item" key={"" + Math.random()}>
                      <p><EvalSpan
                        evaluation={+el.evaluation}>{+el.evaluation === 0 ? " - " : el.evaluation}</EvalSpan> {el.product} ({+el.prise}₽)
                      </p>
                      <ClientComment>{el.comment}</ClientComment>
                      
                    </li>
                  )
                })
                : null
            }
          </ul>
        </KitchenReviewCell>
        <ServiceReviewCell permissions={permissions.staff.role !== "admin"} className="review-data-cell">
          <ServiceReviewList>
            <p>Доставка</p>
            {checkServiceEvaluation(courierService)}
            {checkServiceEvaluation(deliveryService)}
          </ServiceReviewList>
        </ServiceReviewCell>
        {
          permissions.staff.role === "admin"
            ? drawAdminComment(orderId, dateForComment, existAdminComment)
            : null
        }
      </OrderData>
      <PhotoOrder>
      {image 
      &&
      <div
      style={{
        margin:7,
        fontSize:20
      }}
      >Фото отзыва</div>
      }
    <div style={{display:'flex',flexWrap:'wrap'}}>
      {
      Array.isArray(image) &&
        (
            image.map(imgs=>{
            // console.log(imgs.path.split(''),'COCO')
            // tokenizerPath(imgs.path)
            // console.log(testImage('https://menuforme.online'+imgs.path.substr(34)),'OAHIU')
            return(
        <div
        style={{borderRadius:8,margin:7}}
        >
        {/* href={'https://menuforme.online'+imgs.path.substr(35)} */}
          <a
          target='_blank'
          href={'https://menuforme.online'+imgs.path}
          // href={'https://menuforme.online'+imgs.path.substr(34)}

          >
            <img
            style={{borderRadius:8}}
            // src={'https://menuforme.online'+imgs.path.substr(34)}
            src={'https://menuforme.online'+imgs.path}
            // src={testImage('https://menuforme.online'+imgs.path.substr(34)) === 'good' ? 'https://menuforme.online'+imgs.path.substr(34) : Default }
            alt="product image"
            width='90px'
            height='90px'
            className="product-image"
          />
          </a>
        </div>
            )
          }
          )
        )
        }
        </div>
      </PhotoOrder>
      </>
    )
  }
  const showPlug = () => {
    return <FilterOutPlug>По таким фильтрам нет отзывов</FilterOutPlug>
  }
  const drawCard = () => {
    if (data) {
      return buildReview()
    } else {
      return showPlug()
    }
  }

  //layout
  return (
    <ReviewBodyWrapper>
      {drawCard()}
    </ReviewBodyWrapper>
  )
}


export default memo(ReviewBody);


const ReviewBodyWrapper = styled.div`
  display:grid;
  grid-template-rows: 1fr auto;
  border-radius: 6px;
  overflow: hidden;
  margin: 10px 0;
  background: ${({data}) => data ? "none" : "#fff"};
`
const FilterOutPlug = styled.p`
  text-align: center;
  width: 100%;
`
const OrderData = styled.div`
  ${({permissions}) => permissions
          ? {
            display: "grid",
            gridTemplateColumns: ".7fr .7fr 1fr repeat(3, 1fr) 1.5fr;",
            width: "100%",
          }
          : {
            display: "grid",
            gridTemplateColumns: ".5fr .5fr 1fr 2fr repeat(1, 1fr)",
          }};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  width: 100%;

  & .review-data-cell {
    background: #fff;
    text-align: left;
    padding: 8px;

    & p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
const DateTimeCell = styled.div` min-width: 100%; `
const ClientCell = styled.div`  `
const AddressCell = styled.div`  `
const DeliveredCell = styled.div` `
const KitchenReviewCell = styled.div`
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    .kitchen-review-list-item {
      margin-bottom: 12px;

      .product-image {
        border-radius: 10px;
      }
    }

    & li p {
      margin-bottom: .2em;
    }
  }
`
const ClientComment = styled.p`
  color: #388E3C;
  font-weight: bold;
`
const EvalSpan = styled.span`
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  background: ${({evaluation}) => evaluation !== 0 ? evaluation <= 2 ? "#F44336" : evaluation === 3 ? "yellow" : evaluation <= 5 ? "#AED582" : null : null};
`
const ServiceReviewCell = styled.div`
  border-radius: 0 6px 6px 0;
`
const ServiceReviewList = styled.ul`
  padding: 0;
  list-style: none;

  p {
    margin-bottom: 10px;
  }

  li p {
    margin-bottom: .2em;
  }
`
const ReviewAdminComment = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 6px;
  margin-left: 10px;
  padding: 8px;
  background: #fff;
`
const Comment = styled.div`
  border-radius: 6px;
  width: 100%;

  & textarea {
    width: 100%;
    height: 80px;
    background: #EEEEEE;
    resize: none;
    padding: 10px;
    margin-bottom: 12px;
  }
`
const PushMessage = styled.button`
  cursor: pointer;
  width: 100%;
  height: 36px;
  background: #fff;
  border: 1px solid #DDDDDD;
  border-radius: 1px;
  margin-bottom: 15px;
`
const PushMessagePopup = styled.div`

`
const AddComment = styled.button`
  width: 100%;
  height: 36px;
  background: #fff;
  border: 1px solid #DDDDDD;
  border-radius: 1px;
  margin-bottom: 15px;
`
const AdminComment = styled.p`
  background: #EEEEEE;
  border: 1px solid black;
  padding: 10px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({justify}) => justify ? 'flex-end' : 'space-between'};
`
const DismissComment = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
const SaveComment = styled.span`
  text-decoration: underline;
  color: #87b9ff;
  cursor: pointer;
`
const DeleteComment = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
const PhotoOrder = styled.div`
display: flex;
flex-direction: column;
padding: 8px;
`