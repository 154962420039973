import * as _ from "underscore";
import moment from "moment";

export const TEMPORARY_VALUE = "TEMPORARY_VALUE";
export const TEMPORARY_VALUE2 = "TEMPORARY_VALUE2";

window.getCookie = function (name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
};

export const deliveryCycleInfoMessage = (actionUser, messText = null, timeOut = 2500, isHaveOrder = false) => async (dispatch, getState) => {
    const mathRandomNumber = Math.floor(Math.random()*10000);
    let state = getState().modal.temporaryStorageFacility;
    let objectTemporaryInfo = {};
    let newTemporaryState = [];

    switch(actionUser){
        case 'something_added': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Добавлено'};
            break;
        case 'adding_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Ошибка добавления', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'something_removed': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Удалено'};
            break;
        case 'removing_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Ошибка удаления', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'transaction_added': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Успешно'};
            break;
        case 'transaction_adding_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Ошибка при создании транзакции', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'saved': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Сохранено'};
            break;
        case 'name_empty': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Введите название кошелька', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'promocode_empty': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Введите название промокода', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'contract_empty': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Выберите договор или "Без договора"', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'wallet_created': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Кошелёк создан'};
            break;
        case 'wallet_created_error': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Номер телефона не зарегистрирован', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'promocode_created': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Промокод создан'};
            break;
        case 'promocode_created_error': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Ошибка создания промокода', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'wallet_deleted': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Кошелёк удалён'};
            break;
        case 'wallet_deleting_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Ошибка удаления', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'promocode_deleted': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Промокод отключен'};
            break;
        case 'promocode_deleting_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Ошибка отключения', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'file_uploaded': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Файл загружен', messText: messText};
            break;
        case 'no_results': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Нет результатов', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'packed': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заказ собран'};
            break;
        case 'unpacked': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заказ разобран'};
            break;
        case 'need_to_fill_dates': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заполните диапазон дат', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'no_rests_for_report': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Нет ресторанов для отчёта', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'error_from_server': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'no_company_id': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Введите id компании', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'not_auth': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Вы не авторизировались!', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'report_sended': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Отчёт отправлен на', messText: messText};
            break;
        case 'no_contract': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Выберите договор', color: 'rgba(255, 0, 0, 0.65)'};
            break;

        case 'waybill_deleted': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Накладная удалена', messText: ''};
            break;
        case 'cant_delete_waybill': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка удаления', messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'waybill_updated': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Накладная обновлена', messText: ''};
            break;
        case 'cant_update_waybill': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка обновления', messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'waybill_created': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Накладная создана', messText: ''};
            break;
        case 'bad_order_selection_when_order_is_delivered': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка назначения', messText: 'Нельзя переназначать доставленные заказы'};
            break;
        case 'bad_order_selection_when_courier_is_direct_on_point': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка назначения', messText: 'Курьер уже назначен на эту точку'};
            break;
        case 'bad_poly': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка области', messText: 'Нарисованных областей не обнаружено'};
            break;
            ////
        case 'sent_notifications': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: 'Уведомление отправлено адресатам',  color: 'rgba(73, 230, 41, 0.80)'};
            break;
        case 'empty_fields': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Поле "причина" должно быть заполнено',  color: 'rgba(255, 0, 0, 0.65)'};
            break; 
        case 'error_ClientCard': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: messText,  color: 'rgba(255, 0, 0, 0.65)'};   
            break;
        case 'succesDelete_ClientCard': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: messText,  color: 'rgba(0, 197, 58)'};   
            break;
        case 'empty_fieldsGlobal': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Поле должно быть заполнено',  color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'assortmentProductCheck': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: 'Продукт отправлен на модерацию',  color: 'rgba(0, 197, 58)'};
            break;
        case 'moderationModification': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: 'Продукт отправлен на доработку',  color: 'rgba(0, 197, 58)'};
            break;
        case 'modeCustom': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: messText,  color: 'rgba(0, 197, 58)'};
            break;
        case 'modeCustomErr': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: messText,  color: 'rgba(255, 0, 0, 0.65)'};
            break;
            ////
        default: objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Попробуйте позже', color: 'rgba(255, 0, 0, 0.65)'};
    }

    if(_.size(getState().modal.temporaryStorageFacility) !== 0){
        newTemporaryState.push(objectTemporaryInfo, ...state);
    }else{
        newTemporaryState.push(objectTemporaryInfo);
    }

    dispatch({
        type: TEMPORARY_VALUE,
        payload: newTemporaryState
    });

    setTimeout(()=>{
        dispatch({
            type: TEMPORARY_VALUE,
            payload: _.filter(getState().modal.temporaryStorageFacility, (item)=>{return item.actionId !== mathRandomNumber})
        });
    }, timeOut)
};

export const incomingCallMessage = (data, timeOut = 60000) => async (dispatch, getState) => {
    const mathRandomNumber = Math.floor(Math.random()*10000);
    let state = getState().modal.temporaryStorageFacility2;
    let objectTemporaryInfo = [];
    let newTemporaryState = [];

    if (_.size(data) !== 0) {
        objectTemporaryInfo = data
        _.map(objectTemporaryInfo, item => {
            item.actionId = mathRandomNumber;
            item.time = moment().format('HH:mm:ss');
        })
    }

    if (_.size(getState().modal.temporaryStorageFacility2) !== 0) {
        newTemporaryState.push(...objectTemporaryInfo, ...state);
    } else {
        newTemporaryState.push(...objectTemporaryInfo);
    }

    dispatch({
        type: TEMPORARY_VALUE2,
        payload: newTemporaryState
    });

    setTimeout(() => {
        dispatch({
            type: TEMPORARY_VALUE2,
            payload: _.filter(getState().modal.temporaryStorageFacility2, (item) => {
                return item.actionId !== mathRandomNumber
            })
        });
    }, timeOut)
};

export const hideCallMessage = (id) => async (dispatch, getState) => {
    dispatch({
        type: TEMPORARY_VALUE2,
        payload: _.filter(getState().modal.temporaryStorageFacility2, (item) => {
            return item.actionId !== id
        })
    });
}
