import React, {useState} from "react";
import styled from "styled-components";
import {hideBlockPopup, sendSuspend} from "../../actions/MenuByDayActions";
import {useDispatch} from "react-redux";
//img
import period_ban from '../../images/menu_by_day/period_ban.svg'
import period_ban_disable from '../../images/menu_by_day/period_ban_dis.svg'
import * as moment from "moment";


const BlockPopup = ({dateFrom, dateTo, closePopupHandle, activePeriod}) => {

    const dispatch = useDispatch();

    const [commentText, setCommentText] = useState("");

    const commentAreaHandler = (e) => {
        setCommentText(e.target.value);
    };

    const cancelHandle = () => {
        setCommentText("");
        closePopupHandle();
    };

    const commentApply = () => {
        const periodIsDef = activePeriod.periodIsDefined[activePeriod.periodId - 1];
        const payload = {
            restaurantId: activePeriod.activeRest,
            date: activePeriod.date,
            periodId: activePeriod.periodId,
            periodSearchId: periodIsDef ? periodIsDef.id : null,
            serviceSuspended: commentText,
            getDaysData: { dateFrom, dateTo, activeRest: activePeriod.activeRest }
        };
        dispatch(sendSuspend(payload));
        dispatch(hideBlockPopup());
    };
    const dateStr = moment(activePeriod.date).format('MMM DD');

    return (
        <BlockPopupWrapper onClick={e => e.stopPropagation()}>
            <header>
                Вы уверены что хотите отключить {dateStr[0].toUpperCase() + dateStr.slice(1)} "{activePeriod.periodRange.timeFrom} - {activePeriod.periodRange.timeTo}"?
            </header>
            <TextareaWrapper>
                <textarea onChange={commentAreaHandler} name="comment" placeholder="Введите причину" value={commentText}/>
            </TextareaWrapper>
            <ControlsWrapper>
                <CancelButton onClick={cancelHandle}>Отмена</CancelButton>
                {
                    commentText.length
                        ? <DangerBtn onClick={commentApply}><img src={period_ban} alt="ban"/>Отключить</DangerBtn>
                        : <DangerBtn disabled><img src={period_ban_disable} alt="ban"/>Отключить</DangerBtn>
                }
            </ControlsWrapper>

        </BlockPopupWrapper>
    )
};

const BlockPopupWrapper = styled.div`
  width: 332px;
  height: 297px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 21px;
  
  header{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
`
const TextareaWrapper = styled.div`
  flex-grow: 2;
  padding: 12px 0;
  textarea{
    padding: 5px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: #E6E9F0;
    resize: none;
  }
  & .danger_button{
    padding: 12px 22px;
    background: #000;
  }
`
const DangerBtn = styled.button`
  cursor: ${({disabled}) => disabled ? "auto" : "pointer"};
  padding: 12px 22px;
  background: ${({disabled}) => disabled ? "#E1E3EA" : "#F44336"};
  border-radius: 6px;
  border: none;
  color: ${({disabled}) => disabled ? "#999" : "#fff" };
  transition: background-color .4s ease;
  img{
    margin-right: 5px;
  }
`
const CancelButton = styled.button`
  cursor: pointer;
  text-decoration: underline;
  background: none;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border .2s ease;
  
  :hover {
    border: 1px solid #999;
  }
`
const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default BlockPopup;
