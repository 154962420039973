import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import CategoriesItem from "./CategoriesItem";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import {
  getCategory,
  modalInfo,
  moderationMode,
  selectCategory,
} from "../../actions/AssortmentAction";
import Shield from "../../images/assort/GRAY.svg";
import OrangeSVG from "../../images/assort/Chield_check_light.svg";

const Categories = ({
  categoryList,
  setAddProductFieldShow,
  addProductFieldShow,
  openModal,
  setOpenModal,
  scrollsRef,
  mainScrollRef,
}) => {
  const dispatch = useDispatch();
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const selectedCategory = useSelector(
    (store) => store.assortment.selectedCategory
  );
  const moderMod = useSelector((store) => store.assortment.ModerationMode);
  const modLength = useSelector((store) => store.assortment.prodMod);
  const adminMode = useSelector((store) => store.assortment.ModerationMode);
  const sorterCategory = () =>
    categoryList?.sort(
      (a, b) =>
        b.numberOfProductsInTheCategory - a.numberOfProductsInTheCategory
    );
  const CheckedProductMod = () => {
    if (SelectedKitchens.name !== "Не выбрано") {
      dispatch(moderationMode(true));
      dispatch(selectCategory({ id: "-1", name: "wqq" }));
      // setOpenModal(false)
      dispatch(modalInfo(false));
      setAddProductFieldShow(false);
    }
  };

  return (
    <CategoriesWrapper>
      <CategoryListWrapper>
        {categoryList?.length > 0 && (
          <ModBtn
            mod={SelectedKitchens.name !== "Не выбрано" && moderMod}
            onClick={CheckedProductMod}
          >
            <div style={{ marginLeft: 5 }}>
              <img src={adminMode ? Shield : OrangeSVG} />
            </div>
            <div>
              <p
                style={{
                  marginLeft: "10px",
                  color: adminMode ? "#FFFFFF" : "#E39115",
                }}
              >
                На проверке • {modLength && modLength.length}
              </p>
            </div>
          </ModBtn>
        )}
        <CategoryList>
          {
            // SelectedKitchens.name !== 'Не выбрано'
            // &&
            sorterCategory().map((category, id) => {
              return (
                <CategoriesItem
                  key={category.id}
                  {...{
                    category,
                    selectedCategory,
                    id,
                    setAddProductFieldShow,
                    addProductFieldShow,
                    setOpenModal,
                    scrollsRef,
                    mainScrollRef,
                  }}
                />
              );
            })
          }
        </CategoryList>
      </CategoryListWrapper>
    </CategoriesWrapper>
  );
};

export default memo(Categories);

const CategoriesWrapper = styled.div`
  padding-left: 16px;
  padding-right: 53px;
`;
const CategoryListWrapper = styled.div``;
const CategoryList = styled.div`
  padding: 0;
  list-style: none;
`;
const ModBtn = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  height: 50px;
  width: 258px;
  background: ${({ mod }) => (mod ? "#7882A7" : "#fff")};
  margin-bottom: 16px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${({ mod }) => (mod ? "#fff" : "#111111")};
  cursor: pointer;
`;
