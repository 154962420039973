import React, {useEffect} from "react";
import Waybills from "./Waybills/Waybills";
import Storage from "./Storage/Storage";
import styled from "styled-components";
import AddWaybillModal from "./Waybills/WaybillModal/AddWaybillModal"
import {useSelector} from "react-redux"


const Content = ({targetPath}) => {

    const showModal = useSelector(store => store.warehouse.modalControl.showModal)

    const renderContentByPath = () => {
        switch (targetPath) {
            case 'storage':
                return <Storage />
            case 'waybills':
                return <Waybills />
            default:
                return <Storage />
        }
    }

    return (
        <ContentWrapper>
            { renderContentByPath() }
            {
                showModal && <AddWaybillModal />
            }
        </ContentWrapper>
    )
}

export default Content;

const ContentWrapper = styled.div`
  height: calc(100vh - 265px);
`