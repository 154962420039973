import React from "react";
import styled from "styled-components";
import {MoneyIco} from "../../../images/svgs"
import Money from '../../../images/orderMap/Money_light.png'
import Bag from '../../../images/orderMap/Bag_light.png'

const KitchenStatisticListItem = ({data}) => {
  const {name, logoUrl, priceResult, priceCash, ordersPackCount} = data

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={logoUrl} alt="kitchen logo"/>
      </ImageWrapper>
      <DataWrapper>
        <h4>{name}</h4>
        <MoneyInfo>
          <TotalMoney>
            <img src={Money} alt='Money'/>
            {priceResult} ₽
          </TotalMoney>
          {
            !!priceCash && <Cash>{priceCash}</Cash>
          }
        </MoneyInfo>
      </DataWrapper>
      <Price>
        <img src={Bag} alt="Bag" style={{marginRight:3}}/>
        <div
        style={{
          fontSize:15,
          fontWeight:400,
        }}
        >
        {ordersPackCount}
        </div>
      </Price>
    </Wrapper>
  )
}

export default KitchenStatisticListItem;

const Wrapper = styled.div`
  background: #F2F2F2;
  display: flex;
  padding: 5px;
  gap: 10px;
  color:#2C2E32;
`

const ImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
  
  >img{
    width: 100%;
  }
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const MoneyInfo = styled.div`
  display: flex;
  gap: 10px;
  
  > p {
    margin: 0;
  }
`

const TotalMoney = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const Cash = styled.div`
  color: #81B163;
  margin-left: 38px;
`
const Price = styled.div`
display: flex;
align-items: center;
margin-left:auto;
margin-right: 15px;
`