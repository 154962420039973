import {sr} from "../../utils";
import {put} from "redux-saga/effects"
import moment from "moment"
import {getAllCouriersError, getAllPeriodsHandler, setAllCouriers} from "../../reducers/toolkit/comonReducer"

export function* getAllPeriods(action) {
  yield put(getAllPeriodsHandler('pending'))
  const periods = yield (() => sr('SettingsService', 'GetOperatorPeriods', {
    date: moment(action.payload.dates).format("YYYY-MM-DD"),
    notForCenters:action.payload.value,
  }))()

  try {
    yield put(getAllPeriodsHandler(periods.data))
  } catch (e) {
    yield put(getAllPeriodsHandler('rejected'))
  }
}

export function* getAllCouriersWorker() {
  try {
    const request = yield sr('AppSOKitchenService', 'GetCouriers', {})
    yield put(setAllCouriers(request.data.items))
  } catch (e) {
    yield put(getAllCouriersError(e));
  }
}

