import React from 'react'
import { CSSTransition } from 'react-transition-group';
import { TransitionGroup } from 'semantic-ui-react';
import  styled  from 'styled-components'
export const OnCheckedModal = () => {
  return (
    <CSSTransition in={true} timeout={300}>
      <PreviewActionUser>
    <WrapperModal>
        <ModalHeader>
        Отправлено на проверку 
        </ModalHeader>
        <ModalBody>
         йвцйвй
        </ModalBody>
    </WrapperModal>
    </PreviewActionUser>
    </CSSTransition>
  )
}
const PreviewActionUser = styled(TransitionGroup)`
  position: fixed;
  bottom: 10px;
  left: 40px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
  user-select: none;
  
  @media(max-width: 992px){
      top: 13px;
      left: 19px;
      right: 19px;
      bottom: unset;
  }
`;
const WrapperModal = styled.div`
width: 352px;
height: 100px;
background-color: white;
`
const ModalHeader =styled.div`
    
`
const ModalBody = styled.div`
    
`