import React from 'react'
import { useSelector } from 'react-redux'
import NoPermissions from '../../components/NoPermissions'
import styled from 'styled-components'
import Reports from '../Settings/Reports'

const FinancialReports = () => {
  const permissions = useSelector(store => store.appMain.permissions);
  const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
  const checkRole = (param) => {
    if (permissions.staff) {
        if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
            return true
        } else {
            return permissions.permissions.includes(param)
        }
    } else {
        return false
    }
}
  return (
    <>{checkRole('screen_reports') ? <Reports/> : ''}</>
  )
}

export default FinancialReports
const Wrappers = styled.div`
display: grid;
height: calc(100vh - 64px);
justify-content: center;
align-items: center;
color:#161616;
font-size: 30px;
font-weight: 600;
`